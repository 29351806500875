import React from 'react'

export const Alarma = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.5 8.5C23.327 8.5 24 7.827 24 7C24 6.173 23.327 5.5 22.5 5.5C21.673 5.5 21 6.173 21 7C21 7.827 21.673 8.5 22.5 8.5ZM22.5 6.5C22.7755 6.5 23 6.724 23 7C23 7.276 22.7755 7.5 22.5 7.5C22.2245 7.5 22 7.276 22 7C22 6.724 22.2245 6.5 22.5 6.5Z" fill="#635F76"/>
        <path d="M0.5 0.5V3.819C0.5 3.9995 0.532 4.1775 0.5955 4.346L3.2885 11.527C3.503 12.0985 4.052 12.484 4.6605 12.4975L6.5 17.096V17.5C6.5 18.603 7.397 19.5 8.5 19.5H23.5C24.603 19.5 25.5 18.603 25.5 17.5V17.096L27.3395 12.497C27.948 12.4835 28.4965 12.098 28.7115 11.5265L31.4045 4.3455C31.468 4.177 31.5 3.9995 31.5 3.819V0.5H0.5ZM30.5 1.5V3.5H1.5V1.5H30.5ZM20 4.5H25V7C25 8.3785 23.8785 9.5 22.5 9.5C21.1215 9.5 20 8.3785 20 7V4.5ZM5.7385 12.5H9.6615L11.2615 16.5H7.3385L5.7385 12.5ZM16.5 16.5V12.5H21.2615L19.6615 16.5H16.5ZM15.5 16.5H12.3385L10.7385 12.5H15.5V16.5ZM23.5 18.5H8.5C7.9485 18.5 7.5 18.0515 7.5 17.5H24.5C24.5 18.0515 24.0515 18.5 23.5 18.5ZM24.6615 16.5H20.7385L22.3385 12.5H26.2615L24.6615 16.5ZM27.775 11.1755C27.702 11.3695 27.514 11.5 27.307 11.5H4.693C4.4855 11.5 4.2975 11.3695 4.2245 11.1755L1.7215 4.5H19V7C19 8.9295 20.57 10.5 22.5 10.5C24.43 10.5 26 8.9295 26 7V4.5H30.2785L27.775 11.1755Z" fill="#635F76"/>
        <path d="M16 30.4999C12.1705 30.4999 8.56897 28.9559 5.85947 26.1524L5.14047 26.8479C8.04047 29.8474 11.897 31.4999 16 31.4999C20.103 31.4999 23.9595 29.8474 26.8595 26.8474L26.1405 26.1519C23.431 28.9559 19.8295 30.4999 16 30.4999Z" fill="#635F76"/>
        <path d="M7.14743 24.8544C9.58793 27.2809 12.7939 28.4934 15.9999 28.4934C19.2059 28.4934 22.4119 27.2804 24.8524 24.8544L24.1474 24.1454C19.6549 28.6114 12.3454 28.6114 7.85243 24.1454L7.14743 24.8544Z" fill="#635F76"/>
        <path d="M22.8404 22.8662L22.1599 22.1337C18.7634 25.2872 13.2369 25.2872 9.84044 22.1337L9.15994 22.8662C11.0459 24.6177 13.5229 25.4932 16.0004 25.4932C18.4779 25.4932 20.9544 24.6177 22.8404 22.8662Z" fill="#635F76"/>
    </svg>
  )
}
