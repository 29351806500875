import React from 'react'

export const CentroComercial = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_436_3014)">
            <path d="M30.0242 29.6452H29.5484V3.47581C29.5484 3.21316 29.3357 3 29.0726 3H11.9435C11.6804 3 11.4677 3.21316 11.4677 3.47581V11.5645H2.42742C2.1643 11.5645 1.95161 11.7777 1.95161 12.0403V29.6452H1.47581C1.21269 29.6452 1 29.8583 1 30.121V32.0242C1 32.2868 1.21269 32.5 1.47581 32.5H30.0242C30.2873 32.5 30.5 32.2868 30.5 32.0242V30.121C30.5 29.8583 30.2873 29.6452 30.0242 29.6452ZM22.8871 29.6452V27.2661C22.8871 25.9543 21.8199 24.8871 20.5081 24.8871C19.1963 24.8871 18.129 25.9543 18.129 27.2661V29.6452H17.1774V23.9355H28.5968V29.6452H22.8871ZM21.9355 29.6452H19.0806V27.2661C19.0806 26.4791 19.7211 25.8387 20.5081 25.8387C21.295 25.8387 21.9355 26.4791 21.9355 27.2661V29.6452ZM17.1774 12.5161H19.0806V15.371H17.1774V12.5161ZM17.1774 18.2258H19.0806V21.0806H17.1774V18.2258ZM16.2258 18.2258H12.4194V14.4194H16.2258V18.2258ZM11.4677 18.2258H7.66129V14.4194H11.4677V18.2258ZM6.70968 18.2258H2.90323V14.4194H6.70968V18.2258ZM6.70968 19.1774V22.9839H2.90323V19.1774H6.70968ZM7.66129 19.1774H11.4677V22.9839H7.66129V19.1774ZM12.4194 19.1774H16.2258V22.9839H12.4194V19.1774ZM28.5968 3.95161V9.66129H12.4194V3.95161H28.5968ZM12.4194 10.6129H28.5968V22.9839H17.1774V22.0323H19.5565C19.8196 22.0323 20.0323 21.8191 20.0323 21.5565V17.75C20.0323 17.4874 19.8196 17.2742 19.5565 17.2742H17.1774V16.3226H19.5565C19.8196 16.3226 20.0323 16.1094 20.0323 15.8468V12.0403C20.0323 11.7777 19.8196 11.5645 19.5565 11.5645H16.7016H12.4194V10.6129ZM16.2258 12.5161V13.4677H2.90323V12.5161H16.2258ZM2.90323 23.9355H16.2258V29.6452H12.8952V25.3629C12.8952 25.1003 12.6825 24.8871 12.4194 24.8871H6.70968C6.44656 24.8871 6.23387 25.1003 6.23387 25.3629V29.6452H2.90323V23.9355ZM7.18548 29.6452V25.8387H9.08871V29.6452H7.18548ZM10.0403 25.8387H11.9435V29.6452H10.0403V25.8387ZM29.5484 31.5484H1.95161V30.5968H6.70968H12.4194H29.5484V31.5484Z" fill="#635F76"/>
            <path d="M15.2742 7.39446L15.3241 7.49485C15.4859 7.81745 16.0136 7.81745 16.1754 7.49485L16.2258 7.39446V8.70958H17.1774V5.37894C17.1774 5.15816 17.0256 4.96641 16.8106 4.91598C16.5974 4.86459 16.3742 4.96879 16.2758 5.16625L15.75 6.21826L15.2242 5.16625C15.1257 4.96879 14.9026 4.86507 14.6894 4.91598C14.4744 4.96641 14.3226 5.15816 14.3226 5.37894V8.70958H15.2742V7.39446Z" fill="#635F76"/>
            <path d="M19.0806 7.75804H20.0323V8.70965H20.9839V6.33062C20.9839 5.54363 20.3434 4.9032 19.5564 4.9032C18.7695 4.9032 18.129 5.54363 18.129 6.33062V8.70965H19.0806V7.75804ZM19.5564 5.85481C19.8191 5.85481 20.0323 6.06845 20.0323 6.33062V6.80642H19.0806V6.33062C19.0806 6.06845 19.2938 5.85481 19.5564 5.85481Z" fill="#635F76"/>
            <path d="M22.4113 8.70965H23.8387V7.75804H22.8871V4.9032H21.9355V8.23384C21.9355 8.49649 22.1482 8.70965 22.4113 8.70965Z" fill="#635F76"/>
            <path d="M25.2662 8.70965H26.6936V7.75804H25.742V4.9032H24.7903V8.23384C24.7903 8.49649 25.003 8.70965 25.2662 8.70965Z" fill="#635F76"/>
            <path d="M21.4597 16.3226H27.1694C27.4325 16.3226 27.6452 16.1094 27.6452 15.8468V12.0403C27.6452 11.7777 27.4325 11.5645 27.1694 11.5645H21.4597C21.1966 11.5645 20.9839 11.7777 20.9839 12.0403V15.8468C20.9839 16.1094 21.1966 16.3226 21.4597 16.3226ZM26.6936 15.371H24.7903V12.5161H26.6936V15.371ZM21.9355 12.5161H23.8387V15.371H21.9355V12.5161Z" fill="#635F76"/>
            <path d="M21.4597 22.0322H27.1694C27.4325 22.0322 27.6452 21.8191 27.6452 21.5564V17.75C27.6452 17.4873 27.4325 17.2742 27.1694 17.2742H21.4597C21.1966 17.2742 20.9839 17.4873 20.9839 17.75V21.5564C20.9839 21.8191 21.1966 22.0322 21.4597 22.0322ZM26.6936 21.0806H24.7903V18.2258H26.6936V21.0806ZM21.9355 18.2258H23.8387V21.0806H21.9355V18.2258Z" fill="#635F76"/>
        </g>
        <defs>
            <clipPath id="clip0_436_3014">
            <rect width="32" height="32" fill="white"/>
            </clipPath>
        </defs>
    </svg>
  )
}
