import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlockTitle } from "../../Components/BlockTitle/BlockTitle";
import { CustomButton } from "../../Components/CustomButton/CustomButton";
import { Description } from "../../Components/Description/Description";
import { ExclusiveToggleButtons } from "../../Components/ExclusiveToggleButtons/ExclusiveToggleButtons";
import { ModalAyuda } from "../../Components/ModalAyuda/ModalAyuda";
import { ModalConexion } from "../../Components/ModalConexion/ModalConexion";
import { ModalEspera } from "../../Components/ModalEspera/ModalEspera";
import { Notification } from "../../Components/Notification/Notification";
import { SubBlockTitle } from "../../Components/SubBlockTitle/SubBlockTitle";
import { Subtitle } from "../../Components/Subtitle/Subtitle";
import { TipoItem } from "../../model/tipoItemEnum";
import "../LayoutPregunta/LayoutPregunta.scss";
import { postRespuesta, subirImagen } from "../LayoutPregunta/PreguntasAPI";
import {
  getPreguntas,
  obtenerPreguntasStatus,
  postGrupoImagenes, selectBienes,
  selectId,
  selectPreguntas,
  selectStatusGetPreguntas,
  selectUploadStatus,
  validarCamposObligatorios
} from "../LayoutPregunta/preguntaSlice";

const LayoutPreguntaSeleccionUnica = () => {
  const dispatch = useDispatch();
  const preguntas = useSelector(selectPreguntas);
  const preguntaId: any = useSelector(selectId);
  const bienes = useSelector(selectBienes);
  const status = useSelector(selectUploadStatus);
  const [openAyuda, setOpenAyuda] = useState(false);
  const [openEspera, setOpenEspera] = useState(false)
  const [loading, setLoading] = useState(false);
  const [noConnection, setNoConnection] = useState(false)
  const [uploadError, setUploadError] = useState(false);
  const statusSiguientePregunta = useSelector(selectStatusGetPreguntas);
  const obligatorios = useSelector(validarCamposObligatorios);
  const containerRef = useRef<any>()

  //@ts-ignore
  const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

  useEffect(() => {
    window.scrollTo(0, 0);
    containerRef?.current?.scrollTo(0, 0)
    //@ts-ignore
    dispatch(obtenerPreguntasStatus(preguntaId))
  }, [preguntas.id]);

  const toBienesData = (data: any[], id: number) => {
    return data.reduce(
      (acc: any, d: any) => {
        return {
          ...acc,
          imagenes: [...acc.imagenes, d.imagen],
          detalles: [...acc.detalles, d.detalle],
          importes: [...acc.importes, d.importe],
        };
      },
      { id, imagenes: [], detalles: [], importes: [] }
    );
  };

  const toPhotosData = (data: any[], id: number) => {
    return data.reduce(
      (acc: any, d: any) => {
        return {
          ...acc,
          imagenes: [...acc.imagenes, d.imagen],
          ids: [...acc.ids, d.imagenId],
          idsGrupo: [...acc.idsGrupo, d.grupoImagenId],
        };
      },
      { id, imagenes: [], ids: [], idsGrupo: [] }
    );
  };

  const handleSiguiente = async () => {
    if (preguntas.items[0].tipoItem === TipoItem.listaBienes) {
      setLoading(true);
      setOpenEspera(true)
      setUploadError(false);

      if (bienes.length) {
        let results: any = [];
        const photos = bienes.map((b: any, index: number) => {
          return {
            id: preguntas.items[0].id,
            imagenes: [b.imagen],
            detalles: [b.detalle],
            importes: [b.importe],
            esUltima: index === bienes.length - 1,
          };
        });

        for (let i = 0; i < photos.length; i++) {
          // @ts-ignore
          try {
            const response = await subirImagen(photos[i]);
            results = [...results, response];
          }
          catch(error) {
            console.log(error)
          }
        }

        const badStatus = results.some((r: any) => {
          return r.status !== 200;
        });

        //Si todas las imagenes dieron OK, el postRespuesta(preguntas) YA lo hizo la API de imagenes
        if (!badStatus) {
          // @ts-ignore
          dispatch(getPreguntas(preguntaId));
        } else {
          //Alguna imagen fallo y no se proceso postPregunta
          setUploadError(true);
          console.log('entre else')
        }
      } else {
        const response = await postRespuesta(preguntas);
        if (response.status === 200)
        // @ts-ignore
          dispatch(getPreguntas(preguntaId));
      }
      setOpenEspera(false);
    } else if (preguntas.items[0].tipoItem === TipoItem.BloqueImagenes) {
      const response: any = await dispatch(
        // @ts-ignore
        postGrupoImagenes(toPhotosData(bienes, preguntas.items[0].id))
      );
      if (response.payload.status === 200) {
        // @ts-ignore
        dispatch(getPreguntas(preguntaId));
      }
    } else {
      setLoading(true)
      const response = await postRespuesta(preguntas);
      if (response.status === 200)
        // @ts-ignore
        dispatch(getPreguntas(preguntaId));
    }
  };

  useEffect(() => {
    if(statusSiguientePregunta !== "loading")
      setLoading(false)
  }, [statusSiguientePregunta])

  const renderInputs = (e: any, index: number) => {
    switch (e.tipoItem) {
      case TipoItem.OptionMultipleChoice: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <ExclusiveToggleButtons items={e} path={[index]} key={e.keyId} />
          </>
        );
      }
    }
  };

  const renderContent = () => {
    return (
      <>
        <div>
          <div className="layout-pregunta-content-title">
            <BlockTitle
              text={preguntas.titulo}
              icon={preguntas.iconoPrincipal}
              ayuda={preguntas.ayuda ? preguntas.ayuda : null}
              fn={setOpenAyuda}
            />
          </div>
          {preguntas.subtitulo ? <Subtitle text={preguntas.subtitulo} /> : null}
        </div>
        {preguntas.titulo1 ? <SubBlockTitle text={preguntas.titulo1} /> : null}
        {preguntas.texto ? <Description text={preguntas.texto} /> : null}
        {preguntas.items?.map((e: any, index: number) => {
          return <Fragment key={e.id} >{renderInputs(e, index)}</Fragment>;
        })}
        {preguntas.tip ? (
          <Notification text={preguntas.tip} icon={preguntas.iconoTip} />
        ) : null}
      </>
    );
  };

  const handleContinue = () => {
    const online = window.navigator.onLine
    if(!online) {
      setNoConnection(true);
      return; 
    }
    if (loading) {
      return () => null;
    } else {
      return handleSiguiente()
    }
  }

  return (
    <>
      <div className="layout-pregunta" ref={containerRef}>
        <div className="layout-pregunta-content">{renderContent()}</div>
      </div>
      <div className="layout-pregunta-content-actions">
        <CustomButton
          type="primary"
          text="Continuar"
          loading={loading}
          disable={!obligatorios}
          onClick={handleContinue}
        />
        {openAyuda ? (
          <ModalAyuda
            open={openAyuda}
            texto={preguntas.ayuda.texto}
            titulo={preguntas.ayuda.titulo}
            imagen={preguntas.ayuda.imagen}
            onClose={() => setOpenAyuda(false)}
          />
        ) : null}
        <ModalEspera key={preguntas.keyId} open={openEspera} />
        <ModalConexion open={noConnection} onClick={(e: boolean) => setNoConnection(e)} />
      </div>
    </>
  );
};

export default LayoutPreguntaSeleccionUnica;