//import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { esES } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Calendar } from "../../Assets/svgs/Calendar";
import { ErrorIcon } from "../../Assets/svgs/ErrorIcon";
import { changeValue } from "../../features/LayoutPregunta/preguntaSlice";
import "../CustomInput/CustomInput.scss";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import {
  isChrome,
  isDesktop,
  isFirefox,
  isIE,
  isMobile,
  isSafari,
} from "react-device-detect";

interface IProps {
  id?: number;
  label: string;
  prefetchedValue?: any;
  disabled?: boolean;
  error?: string;
  index?: number;
  length?: number;
  onChange?: any;
  defaultValue?: any;
  isSuboption?: boolean;
  indexSubpregunta?: number;
  tworows?: boolean;
  triggerReset?: boolean;
  callbackValue?: any;
}

export const DateInput = ({
  index,
  label,
  prefetchedValue,
  error,
  tworows = false,
  triggerReset = false,
  callbackValue,
  disabled,
}: IProps) => {
  const [value, setValue] = useState("");
  const [startDate, setStartDate] = useState<Date | undefined>();
  const dispatch = useDispatch();
  registerLocale("es", es);
  const locales = ["es"] as const;

  useEffect(() => {
    setValue("");
    dispatch(changeValue({ value: "", index: index }));
  }, [triggerReset]);

  useEffect(() => {
    if (!prefetchedValue) {
      return;
    } else {
      setValue(prefetchedValue);
      const formatedDate = prefetchedValue.replace(/-/g, '\/').replace(/T.+/, '') 
      console.log(formatedDate)
      const date = new Date(formatedDate)
      setStartDate(date)
      dispatch(changeValue({ value: prefetchedValue, index: index }));
    }
  }, []);

  const handleChange = (e: any) => {
    setValue(e);
    setStartDate(e);
    const formatDate = dayjs(e).format("YYYY-MM-DD");
    dispatch(changeValue({ value: formatDate, index: index }));
    if (callbackValue) callbackValue(formatDate);
  };

  const renderDefaultDatePicker = () => {
    return (
      <LocalizationProvider
        localeText={
          esES.components.MuiLocalizationProvider.defaultProps.localeText
        }
        adapterLocale={locales[0]}
        dateAdapter={AdapterDayjs}
      >
        <MuiDatePicker
          value={value}
          inputFormat="DD/MM/YYYY"
          onChange={(newValue: any) => handleChange(newValue)}
          disabled={disabled}
          renderInput={({ inputRef, inputProps, InputProps }) => {
            return (
              <div
                className="custom-input"
                style={tworows ? { width: "50%" } : {}}
              >
                <input
                  className={`custom-input-input ${error ? "input-error" : ""}`}
                  ref={inputRef}
                  {...inputProps}
                />
                <label className={value && "filled"}>{label}</label>
                <span className="custom-input-calendar">
                  <Calendar />
                </span>
              </div>
            );
          }}
        />
        {error ? (
          <span className="error-message-input">
            <ErrorIcon />
            {error}
          </span>
        ) : null}
      </LocalizationProvider>
    );
  };

  const renderSecondaryDatePicker = () => {
    return (
      <div className="custom-input">
        <DatePicker
          selected={startDate}
          onChange={(date) => handleChange(date)}
          locale="es"
          showYearDropdown={true}
          wrapperClassName="datepicker-input"
          scrollableYearDropdown
          yearDropdownItemNumber={100}
          dateFormat="dd/MM/yyyy"
        />
        <label className={value && "filled"}>{label}</label>
        <span className="custom-input-calendar">
          <Calendar />
        </span>
      </div>
    );
  };

  const renderDatePicker = () => {
    if (isDesktop) {
      return renderSecondaryDatePicker();
    } else {
      return renderDefaultDatePicker();
    }
  };

  return <>{renderDatePicker()}</>;
};
