import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleValue,
  selectPreguntas,
} from "../../features/LayoutPregunta/preguntaSlice";
import { CustomInput } from "../CustomInput/CustomInput";
import { ExclusiveMultipleChoice } from "../ExclusiveMultipleChoice/ExclusiveMultipleChoice";
import { InputCurrency } from "../InputCurrency/InputCurrency";
import { MultipleChoice } from "../MultipleChoice/MultipleChoice";
import { NumberInput } from "../NumberInput/NumberInput";
import { ToggleButtonList } from "../ToggleButtonList/ToggleButtonList";
import { ToggleButtons } from "../ToggleButtons/ToggleButtons";

export const ExpandedToggleButtons = (props: any) => {
  const preguntas = useSelector(selectPreguntas);
  const [value, setValue] = useState([]);
  const dispatch = useDispatch();

/*   const onChange = (value: any, index: number) => {
    const currentValue =
      preguntas.items[props.path[0]].opciones[index].selected;
    setValue(value);
    dispatch(
      handleValue({
        path: [...props.path, "opciones", index, "selected"],
        value: currentValue ? null : true,
      })
    );
  }; */

  const onChangeInput = (value: any, index: number, indexSub: number) => {
    console.log(value, index, indexSub);
    dispatch(
      handleValue({
        path: [
          ...props.path,
          "opciones",
          index,
          "subpreguntas",
          indexSub,
          "valor",
        ],
        value: value,
      })
    );
  };

  const handleInputComentarioChange = (value: any, index: number) => {
    dispatch(
      handleValue({
        path: [...props.path, "opciones", index, "comentarios"],
        value: value,
      })
    );
  };

  return (
    <ToggleButtonList value={value} path={props.path} onChange={(e: any) => console.log(e)}>
      {props.items.opciones.map((o: any, index: number) => {
        return (
          <ToggleButtons
            key={o + index}
            path={props.path}
            icon={o.icono}
            selected={true}
            index={index}
            value={o.titulo}
            subtitle={o.subtitulo}
            hideCheckbox={true}
          >
            {o.subopciones?.length && o.tipoDeDatoValor === 6 ? (
              <MultipleChoice
                key={o.id + index}
                items={o.subopciones}
                titulo={o.tituloSubopciones}
                path={[...props.path, "opciones", index]}
              />
            ) : null}
            {o.subopciones?.length && o.tipoDeDatoValor !== 6 ? (
              <ExclusiveMultipleChoice
                items={o.subopciones}
                id={o.id}
                type="subopcion"
                titulo={o.tituloSubopciones}
                path={[...props.path, "opciones", index]}
              />
            ) : null}
            {o.subpreguntas?.length
              ? o.subpreguntas.map((s: any, indexSub: number) => {
                  if (s.tipoItem === 1) {
                    return (
                      <div key={s.id + index} style={{ marginBottom: "16px" }}>
                        <span
                          style={{
                            fontSize: "14px",
                            marginBottom: "16px",
                            display: "flex",
                          }}
                        >
                          {s.consigna}
                        </span>
                        <NumberInput
                          key={s.keyId}
                          index={index}
                          indexSubpregunta={indexSub}
                          label={s.placeholder}
                          prefetchedValue={s.valor}
                          onChange={onChangeInput}
                        />
                      </div>
                    );
                  }
                  if (s.tipoItem === 3) {
                    return (
                      <div key={s.id + index} style={{ marginBottom: "16px" }}>
                        <span
                          style={{
                            fontSize: "14px",
                            marginBottom: "16px",
                            display: "flex",
                          }}
                        >
                          {s.consigna}
                        </span>
                        <CustomInput
                          type="text"
                          key={s.keyId}
                          index={index}
                          indexSubpregunta={indexSub}
                          label={s.placeholder}
                          prefetchedValue={s.valor}
                          onChange={onChangeInput}
                        />
                      </div>
                    );
                  }
                  if (s.tipoItem === 5) {
                    return (
                      <ExclusiveMultipleChoice
                        key={s.id + index}
                        items={s.opciones}
                        id={s.id}
                        titulo={s.consigna}
                        type="subpregunta"
                        subpregunta
                        path={[
                          ...props.path,
                          "opciones",
                          index,
                          "subpreguntas",
                          indexSub,
                        ]}
                      />
                    );
                  } else {
                    return (
                      <MultipleChoice
                        key={s.id + index}
                        items={s.opciones}
                        id={s.id}
                        titulo={s.consigna}
                        subpregunta
                        path={[
                          ...props.path,
                          "opciones",
                          index,
                          "subpreguntas",
                          indexSub,
                        ]}
                      />
                    );
                  }
                })
              : null}
            {!o.subopciones?.length &&
            !o.subpreguntas?.length &&
            o.tieneComentarios &&
            o.tipoInputComentarios === 1 ? (
              <InputCurrency
                label={o.comentariosPlaceholder}
                isSuboption
                prefetchedValue={o.comentarios}
                onChange={handleInputComentarioChange}
                index={index}
              />
            ) : null}
            {!o.subopciones?.length &&
            !o.subpreguntas?.length &&
            o.tieneComentarios &&
            o.tipoInputComentarios === 0 ? (
              <CustomInput
                type="text"
                label={o.comentariosPlaceholder}
                isSuboption
                prefetchedValue={o.comentarios}
                onChange={handleInputComentarioChange}
                index={index}
              />
            ) : null}
          </ToggleButtons>
        );
      })}
    </ToggleButtonList>
  );
};
