import React from 'react'

export const PulsadorManualIncendios = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="2.5" stroke="#635F76" />
      <rect
        x="3.69995"
        y="8.5"
        width="24.6"
        height="14"
        rx="2.5"
        stroke="#635F76"
      />
      <rect x="13.3" y="24.5" width="5.4" height="2" rx="1" stroke="#635F76" />
      <path
        d="M16.0396 18.0742C14.8413 18.0742 13.8667 17.1605 13.8667 16.0371C13.8667 14.9137 14.8413 14 16.0396 14C17.2379 14 18.2125 14.9137 18.2125 16.0371C18.2125 17.1605 17.2379 18.0742 16.0396 18.0742ZM16.0396 15.1188C15.4996 15.1188 15.0601 15.5308 15.0601 16.0371C15.0601 16.5433 15.4996 16.9554 16.0396 16.9554C16.5796 16.9554 17.0191 16.5433 17.0191 16.0371C17.0191 15.5308 16.5796 15.1188 16.0396 15.1188Z"
        fill="#635F76"
      />
      <g clip-path="url(#clip0_3181_47390)">
        <path
          d="M19.4666 16H23.1999"
          stroke="#635F76"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.4666 16L21.0666 17.5"
          stroke="#635F76"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.4666 16L21.0666 14.5"
          stroke="#635F76"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g clip-path="url(#clip1_3181_47390)">
        <path
          d="M12.5334 16L8.80011 16"
          stroke="#635F76"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.5334 16L10.9334 14.5"
          stroke="#635F76"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.5334 16L10.9334 17.5"
          stroke="#635F76"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g clip-path="url(#clip2_3181_47390)">
        <path
          d="M14.7557 5H14.4001L16.0001 3.5L17.6001 5H17.2446"
          stroke="#635F76"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.7556 5V6.16667C14.7556 6.25507 14.7931 6.33986 14.8598 6.40237C14.9264 6.46488 15.0169 6.5 15.1112 6.5H16.8889C16.9832 6.5 17.0737 6.46488 17.1404 6.40237C17.207 6.33986 17.2445 6.25507 17.2445 6.16667V5"
          stroke="#635F76"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3181_47390">
          <rect
            width="6.4"
            height="6"
            fill="white"
            transform="translate(18.1333 13)"
          />
        </clipPath>
        <clipPath id="clip1_3181_47390">
          <rect
            width="6.4"
            height="6"
            fill="white"
            transform="translate(13.8667 19) rotate(180)"
          />
        </clipPath>
        <clipPath id="clip2_3181_47390">
          <rect
            width="4.26667"
            height="4"
            fill="white"
            transform="translate(13.8667 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

