import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Add } from '../../Assets/svgs/Add';
import { handleBienes, selectBienes, selectPreguntas } from '../../features/LayoutPregunta/preguntaSlice';
import { CardBienes } from '../CardBienes/CardBienes';
import './BienesList.scss';
import { CardBienesHogar } from '../CardBienes/CardBienesHogar';

export const BienesListHogar = (props: any) => {
    const [cards, setCards] = useState<any>([])
    const [unconfirmedCards, setUnconfirmedCards] = useState(false)
    const preguntas = useSelector(selectPreguntas)
    const dispatch = useDispatch()
    const bienes = useSelector(selectBienes);
  
    const newCard = () => {
        return {
            id: uuidv4(),
            importe: "",
            detalle: "",
            imagen: "",
            confirmada: false,
        }
    }

    
    useEffect( () => {
        dispatch(handleBienes(cards))
        const hasUnconfirmedCards = (cards: any) => {
            const unconfirmedCards = cards.filter((c: any) => {
                return c.confirmada === false
            })
            
            const hasUnconfirmedCards = unconfirmedCards.length > 0
            setUnconfirmedCards(hasUnconfirmedCards)
            props.hasUnconfirmedCards(hasUnconfirmedCards)
        }

        hasUnconfirmedCards(cards)
    }, [cards])

    useEffect( () => {
        if(preguntas.items[0].imagenes && preguntas.items[0].imagenes.length && (bienes && bienes.length)) {
            const confirmedCards = bienes.filter((b: any) => b.confirmada && !b.url)
            confirmedCards && setCards([...cards, ...preguntas.items[0].imagenes, ...confirmedCards])
        } else if (preguntas.items[0].imagenes && preguntas.items[0].imagenes.length) {
            setCards([...cards, ...preguntas.items[0].imagenes])
        } else if (bienes && bienes.length) {
            const confirmedCards = bienes.filter((b: any) => b.confirmada && !b.url)
            confirmedCards.length && setCards([...cards, ...confirmedCards])
        }
    }, [])

    const removeCard = (id: string) => {
        const newCards = cards.filter((c: any) => c.id !== id)
        setCards(newCards)
    }

    const handleChange = (data: any) => {
        const newCards = cards.map((c: any) => {
            if(c.id === data.id) {
                return {...c, importe: data.importe, detalle: data.detalle, imagen: data.imagen || c.imagen, confirmada: data.confirmada, tipoElemento: data.tipoElemento}
            } else {
                return c
            }
        })

        setCards(newCards)
    }

    const renderCards = () => {
        if (cards.length) {
            return cards.map((c: any) => <CardBienesHogar
                                            fetchedThumbnail={c.urlThumbnail ? c.urlThumbnail : c.imagen}
                                            defaultDetalle={c.detalle}
                                            defaultImporte={c.importe}
                                            defaultTipoElemento={c.tipoElemento}
                                            confirmarText={props.confirmarText}
                                            key={c.id} id={c.id}
                                            alreadyUploaded={c.arn ? true : false}
                                            delete={removeCard}
                                            onChange={handleChange}
                                            />)
        }
    }

    return (
        <div className="bienes-list">
            <div className="bienes-list-cards">
                {cards.length ? <span>
                    {`${cards.length} ${cards.length === 1 ? 'Elemento añadido' : 'Elementos añadidos'}`}
                </span> : null}
                {renderCards()}
            </div>
            <button disabled={unconfirmedCards} className="bienes-list-button" onClick={() => setCards([...cards, newCard()])}>
                <Add />
                <span>{props.buttonText ? props.buttonText : "Añadir un nuevo elemento"}</span>
            </button>
        </div>
    )
}
