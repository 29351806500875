import axios from "axios";
import conf from "../../app/config";

const aseguradoras = axios.create({
  baseURL: conf("BASE_URL") + "aseguradoras/"
});

export const getLogo = async (idInspeccion: number) => {
    const results = await aseguradoras.post('/urlLogoSegunInspeccionId', {id: idInspeccion})
    return results.data.logo
}
