import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Error } from "../../Assets/svgs/Error";
import { Warning } from "../../Assets/svgs/Warning";
import { CustomButton } from "../CustomButton/CustomButton";
import "./IncidenciaAlert.scss";

export const IncidenciaAlert = (props: any) => {
  const [open, setOpen] = useState(false)


  useEffect(() => {
    if (props.open) {
      setOpen(props.open)
    }
  }, [props])

  const renderButtons = () => {
    if (props.items?.botonPrimario && props.items?.tipoAlerta === 1) {
      return (
        <CustomButton
          type="primary"
          text="Si, es correcto"
          onClick={() => console.log("Click!")}
        />
      );
    }
  };

  const renderIcon = () => {
    if (props.items && props.items.tipoAlerta === 1) {
      return <Warning />
    } else {
      return <Error />
    }
  }

  const renderDescripcion = () => {
    if (props.items?.descripcion) {
      return (
        <p className="incidencia-descripcion">{props.items?.descripcion}</p>
      );
    }
  };

  return (
    <Modal open={open} onClose={() => console.log("lala")}>
      <div className="incidencia">
        {renderIcon()}
        <h1>{props.items?.titulo}</h1>
        {renderDescripcion()}
        <div className="incidencia-data">
          <p>{props.items?.descripcion}</p>
          <span>{props.items?.valorDescripcion}</span>
        </div>
        <p>{props.items?.textoPregunta}</p>
        <CustomButton
          type="primary"
          text={props.items?.botonPrimario}
          onClick={() => console.log("Click!")}
        />
        <CustomButton
          type="secondary"
          text={props.items?.botonSecundario}
          onClick={() => setOpen(false)}
        />
      </div>
    </Modal>
  );
};
