import React from "react";

export const ErrorIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00001 8.33325C6.8352 8.33325 6.67408 8.38213 6.53704 8.47369C6.4 8.56526 6.29319 8.69541 6.23011 8.84768C6.16704 8.99995 6.15054 9.16751 6.18269 9.32916C6.21485 9.49081 6.29421 9.6393 6.41076 9.75584C6.5273 9.87239 6.67579 9.95175 6.83744 9.98391C6.99909 10.0161 7.16664 9.99956 7.31892 9.93649C7.47119 9.87341 7.60134 9.7666 7.6929 9.62956C7.78447 9.49252 7.83335 9.3314 7.83335 9.16659C7.83335 8.94557 7.74555 8.73361 7.58927 8.57733C7.43299 8.42105 7.22103 8.33325 7.00001 8.33325ZM7.00001 7.33325C7.17682 7.33325 7.34639 7.26301 7.47142 7.13799C7.59644 7.01297 7.66668 6.8434 7.66668 6.66659V4.66659C7.66668 4.48977 7.59644 4.32021 7.47142 4.19518C7.34639 4.07016 7.17682 3.99992 7.00001 3.99992C6.8232 3.99992 6.65363 4.07016 6.52861 4.19518C6.40358 4.32021 6.33335 4.48977 6.33335 4.66659V6.66659C6.33335 6.8434 6.40358 7.01297 6.52861 7.13799C6.65363 7.26301 6.8232 7.33325 7.00001 7.33325ZM7.00001 0.333252C5.68147 0.333252 4.39254 0.724245 3.29621 1.45679C2.19988 2.18933 1.3454 3.23052 0.840816 4.4487C0.336232 5.66687 0.20421 7.00731 0.461445 8.30052C0.718679 9.59373 1.35362 10.7816 2.28597 11.714C3.21832 12.6463 4.4062 13.2813 5.69941 13.5385C6.99262 13.7957 8.33306 13.6637 9.55124 13.1591C10.7694 12.6545 11.8106 11.8 12.5431 10.7037C13.2757 9.60739 13.6667 8.31846 13.6667 6.99992C13.6647 5.23241 12.9617 3.53786 11.7119 2.28804C10.4621 1.03823 8.76752 0.335218 7.00001 0.333252ZM7.00001 12.3333C5.94518 12.3333 4.91403 12.0205 4.03697 11.4344C3.15991 10.8484 2.47632 10.0154 2.07266 9.0409C1.66899 8.06636 1.56337 6.994 1.76916 5.95944C1.97495 4.92487 2.4829 3.97456 3.22878 3.22868C3.97466 2.4828 4.92497 1.97485 5.95953 1.76906C6.9941 1.56328 8.06645 1.66889 9.04099 2.07256C10.0155 2.47623 10.8485 3.15982 11.4345 4.03688C12.0206 4.91394 12.3333 5.94509 12.3333 6.99992C12.3317 8.41391 11.7693 9.76952 10.7695 10.7694C9.76962 11.7692 8.414 12.3316 7.00001 12.3333Z"
        fill="#E64B54"
      />
    </svg>
  );
};
