import React, { useEffect } from 'react'
import './LayoutRelleno.scss'
import { AiraLogo } from "../../Assets/svgs/AiraLogo";
import { useDispatch, useSelector } from 'react-redux';
import { comenzarInspeccion, handleInspeccion, obtenerPreguntasStatus, selectId, selectPreguntas } from '../LayoutPregunta/preguntaSlice';
import { selectLogo } from '../Registro/userSlice';
import { useNavigate } from 'react-router';

export const LayoutRelleno = () => {
  const dispatch = useDispatch()
  const inspeccionId = useSelector(selectId)
  const preguntas = useSelector(selectPreguntas)
  const logo = useSelector(selectLogo)
  const navigate = useNavigate()

  useEffect(() => {
    const id = localStorage.getItem('idInspeccion')
    const user = localStorage.getItem('userName')
    if(id !== inspeccionId) {
      //@ts-ignore
      dispatch(handleInspeccion({ id, user }))
      //@ts-ignore
      dispatch(comenzarInspeccion({id}))
      //@ts-ignore
      dispatch(obtenerPreguntasStatus({id}))
    }
  }, [])

  useEffect(() => {
    if  (preguntas.resultCode && preguntas.resultCode === 2) {
      navigate('/completada')
    }
  }, [preguntas])

  return (
      <div className="layout-relleno">
          <div className="logo-aira">
            {inspeccionId ? <img className="success-confirmar-logo" src={logo} alt="Logo aseguradora" /> : null}
          </div>
      </div>
  )
}
