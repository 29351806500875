import React from 'react'

export const Paletizado = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M28 17.4C28 17.621 27.8209 17.8 27.6 17.8L4.4 17.8001C4.17908 17.8001 4 17.621 4 17.4001C4 17.1791 4.17908 17.0001 4.4 17.0001L27.6 17C27.8209 17 28 17.1791 28 17.4Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28 19.4C28 19.621 27.8209 19.8 27.6 19.8L4.4 19.8001C4.17908 19.8001 4 19.621 4 19.4001C4 19.1791 4.17908 19.0001 4.4 19.0001L27.6 19C27.8209 19 28 19.1791 28 19.4Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28 7.40005C28 7.62096 27.8209 7.80005 27.6 7.80005L4.4 7.80005C4.17908 7.80005 4 7.62096 4 7.40005C4 7.17914 4.17908 7.00005 4.4 7.00005L27.6 7.00005C27.8209 7.00005 28 7.17913 28 7.40005Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28 9.40005C28 9.62096 27.8209 9.80005 27.6 9.80005L4.4 9.80005C4.17908 9.80005 4 9.62096 4 9.40005C4 9.17914 4.17908 9.00005 4.4 9.00005L27.6 9.00005C27.8209 9.00005 28 9.17913 28 9.40005Z" fill="#635F76"/>
        <path d="M25.5 9.5V7.5" stroke="#635F76"/>
        <path d="M25.5 19.5001V17.5001" stroke="#635F76"/>
        <path d="M6.5 19.5001V17.5001" stroke="#635F76"/>
        <path d="M16.5 19.5001V17.5001" stroke="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28 12.4C28 12.621 27.8209 12.8 27.6 12.8L4.4 12.8001C4.17908 12.8001 4 12.621 4 12.4001C4 12.1791 4.17908 12.0001 4.4 12.0001L27.6 12C27.8209 12 28 12.1791 28 12.4Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28 14.4C28 14.621 27.8209 14.8 27.6 14.8L4.4 14.8001C4.17908 14.8001 4 14.621 4 14.4001C4 14.1791 4.17908 14.0001 4.4 14.0001L27.6 14C27.8209 14 28 14.1791 28 14.4Z" fill="#635F76"/>
        <path d="M25.5 14.5V12.5" stroke="#635F76"/>
        <path d="M6.5 14.5V12.5" stroke="#635F76"/>
        <path d="M16.5 14.5V12.5" stroke="#635F76"/>
        <path d="M6.5 9.5V7.5" stroke="#635F76"/>
        <path d="M16.5 9.5V7.5" stroke="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28 22.4C28 22.621 27.8209 22.8 27.6 22.8L4.4 22.8001C4.17908 22.8001 4 22.621 4 22.4001C4 22.1791 4.17908 22.0001 4.4 22.0001L27.6 22C27.8209 22 28 22.1791 28 22.4Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28 24.4C28 24.621 27.8209 24.8 27.6 24.8L4.4 24.8001C4.17908 24.8001 4 24.621 4 24.4001C4 24.1791 4.17908 24.0001 4.4 24.0001L27.6 24C27.8209 24 28 24.1791 28 24.4Z" fill="#635F76"/>
        <path d="M25.5 24.5V22.5" stroke="#635F76"/>
        <path d="M6.5 24.5V22.5" stroke="#635F76"/>
        <path d="M16.5 24.5V22.5" stroke="#635F76"/>
    </svg>
  )
}
