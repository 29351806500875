import React from 'react'

export const Menu = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.84003 5.76001H13.44" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.84003 11.52H13.44" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.99994 18H19.9999" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.7542 11.5882L20.9072 9.43523C21.0499 9.29248 21.1301 9.09886 21.1301 8.89698C21.1301 8.6951 21.0499 8.50149 20.9072 8.35874L18.7542 6.20577" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
