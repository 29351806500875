import React from 'react'

export const Template = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.1667 4.66666H5.83335C5.18902 4.66666 4.66669 5.18899 4.66669 5.83332V8.16666C4.66669 8.81099 5.18902 9.33332 5.83335 9.33332H22.1667C22.811 9.33332 23.3334 8.81099 23.3334 8.16666V5.83332C23.3334 5.18899 22.811 4.66666 22.1667 4.66666Z" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.5 14H5.83335C5.18902 14 4.66669 14.5223 4.66669 15.1667V22.1667C4.66669 22.811 5.18902 23.3333 5.83335 23.3333H10.5C11.1444 23.3333 11.6667 22.811 11.6667 22.1667V15.1667C11.6667 14.5223 11.1444 14 10.5 14Z" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.3333 14H23.3333" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.3333 18.6667H23.3333" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.3333 23.3333H23.3333" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
