import React from 'react'

export const Desconozco = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.0001 4.60002C9.7041 4.60002 4.60015 9.70398 4.60015 16C4.60015 22.2961 9.7041 27.4 16.0001 27.4C22.2962 27.4 27.4001 22.2961 27.4001 16C27.4001 9.70398 22.2962 4.60002 16.0001 4.60002ZM3.40015 16C3.40015 9.04124 9.04136 3.40002 16.0001 3.40002C22.9589 3.40002 28.6001 9.04124 28.6001 16C28.6001 22.9588 22.9589 28.6 16.0001 28.6C9.04136 28.6 3.40015 22.9588 3.40015 16Z" fill="#635F76"/>
        <path d="M16 23.75C16.6904 23.75 17.25 23.1904 17.25 22.5C17.25 21.8096 16.6904 21.25 16 21.25C15.3096 21.25 14.75 21.8096 14.75 22.5C14.75 23.1904 15.3096 23.75 16 23.75Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M14.431 9.71267C15.1802 9.40235 16.0046 9.32116 16.7999 9.47936C17.5952 9.63756 18.3258 10.028 18.8992 10.6014C19.4726 11.1748 19.863 11.9054 20.0212 12.7007C20.1794 13.496 20.0983 14.3204 19.7879 15.0696C19.4776 15.8188 18.9521 16.4591 18.2779 16.9096C17.7713 17.2481 17.1978 17.468 16.6 17.5564V18.0006C16.6 18.3319 16.3314 18.6006 16 18.6006C15.6687 18.6006 15.4 18.3319 15.4 18.0006V17.0006C15.4 16.8414 15.4632 16.6888 15.5758 16.5763C15.6883 16.4638 15.8409 16.4006 16 16.4006C16.5736 16.4006 17.1343 16.2305 17.6112 15.9118C18.0881 15.5932 18.4598 15.1403 18.6793 14.6104C18.8988 14.0805 18.9562 13.4974 18.8443 12.9348C18.7324 12.3723 18.4562 11.8555 18.0506 11.45C17.6451 11.0444 17.1283 10.7682 16.5658 10.6563C16.0032 10.5444 15.4201 10.6018 14.8902 10.8213C14.3603 11.0408 13.9074 11.4125 13.5888 11.8894C13.2701 12.3663 13.1 12.927 13.1 13.5006C13.1 13.8319 12.8314 14.1006 12.5 14.1006C12.1687 14.1006 11.9 13.8319 11.9 13.5006C11.9 12.6897 12.1405 11.897 12.591 11.2227C13.0415 10.5485 13.6818 10.023 14.431 9.71267Z" fill="#635F76"/>
    </svg>
  )
}
