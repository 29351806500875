import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import { JsonAnimation } from "../JsonAnimation/JsonAnimation";
import "./ModalEspera.scss";

export const ModalEspera = (props: any) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
      setOpen(props.open)
  }, [props])

  return (
    <Modal open={open}>
      <div className="espera">
        <JsonAnimation width="88px" height="88px" animation="loader"/>
        <p>Esta operación puede tardar unos segundos</p>
      </div>
    </Modal>
  );
};
