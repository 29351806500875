import React from "react";
import { Icons } from "../../Assets/Icons";
import { Help } from "../../Assets/svgs/Help";
import "./BlockTitle.scss";

interface IProps {
  text: string;
  icon: string;
  ayuda?: any;
  fn?: any;
}

export const BlockTitle = ({ text, icon, ayuda, fn }: IProps) => {
  const icons = Icons();

  const fillExceptions = ['send', 'id', 'home']

  return (
    <div className="block-title">
      <div
        className={`${
          fillExceptions.includes(icon) ? "block-title-icon2" : "block-title-icon"
        }`}
      >
        {icon ? icons[icon] : null}
      </div>
      <h1>
        {text}{" "}
        {ayuda ? (
          <span className="content-ayuda" onClick={() => fn(true)}>
            <Help />
          </span>
        ) : null}
      </h1>
    </div>
  );
};
