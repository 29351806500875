import React from "react";

const OtrosServicios = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3647_10335)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.3335 13.9997C1.3335 13.6315 1.63197 13.333 2.00016 13.333H14.0002C14.3684 13.333 14.6668 13.6315 14.6668 13.9997C14.6668 14.3679 14.3684 14.6663 14.0002 14.6663H2.00016C1.63197 14.6663 1.3335 14.3679 1.3335 13.9997Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.01699 1.43257C9.21354 1.55405 9.33317 1.76862 9.33317 1.99968V13.9997C9.33317 14.3679 9.03469 14.6663 8.6665 14.6663C8.29831 14.6663 7.99984 14.3679 7.99984 13.9997V3.07836L3.99984 5.07836V13.9997C3.99984 14.3679 3.70136 14.6663 3.33317 14.6663C2.96498 14.6663 2.6665 14.3679 2.6665 13.9997V4.66634C2.6665 4.41383 2.80917 4.18299 3.03503 4.07006L8.36836 1.40339C8.57502 1.30006 8.82045 1.3111 9.01699 1.43257Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.11204 4.29694C8.31627 3.99058 8.73019 3.9078 9.03654 4.11204L13.0365 6.7787C13.222 6.90235 13.3334 7.1105 13.3334 7.3334V14.0001C13.3334 14.3683 13.0349 14.6667 12.6667 14.6667C12.2985 14.6667 12.0001 14.3683 12.0001 14.0001V7.69019L8.29694 5.22144C7.99058 5.0172 7.9078 4.60329 8.11204 4.29694Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.00016 5.33301C6.36835 5.33301 6.66683 5.63148 6.66683 5.99967V6.00634C6.66683 6.37453 6.36835 6.67301 6.00016 6.67301C5.63197 6.67301 5.3335 6.37453 5.3335 6.00634V5.99967C5.3335 5.63148 5.63197 5.33301 6.00016 5.33301Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.00016 7.33301C6.36835 7.33301 6.66683 7.63148 6.66683 7.99967V8.00634C6.66683 8.37453 6.36835 8.67301 6.00016 8.67301C5.63197 8.67301 5.3335 8.37453 5.3335 8.00634V7.99967C5.3335 7.63148 5.63197 7.33301 6.00016 7.33301Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.00016 9.33301C6.36835 9.33301 6.66683 9.63148 6.66683 9.99967V10.0063C6.66683 10.3745 6.36835 10.673 6.00016 10.673C5.63197 10.673 5.3335 10.3745 5.3335 10.0063V9.99967C5.3335 9.63148 5.63197 9.33301 6.00016 9.33301Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.00016 11.333C6.36835 11.333 6.66683 11.6315 6.66683 11.9997V12.0063C6.66683 12.3745 6.36835 12.673 6.00016 12.673C5.63197 12.673 5.3335 12.3745 5.3335 12.0063V11.9997C5.3335 11.6315 5.63197 11.333 6.00016 11.333Z"
          fill="#635F76"
        />
      </g>
      <defs>
        <clipPath id="clip0_3647_10335">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OtrosServicios;
