import React from "react";

const Salud = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3647_10242)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.25229 3.25278C3.62736 2.87771 4.13607 2.66699 4.6665 2.66699H5.99984C6.36803 2.66699 6.6665 2.96547 6.6665 3.33366C6.6665 3.70185 6.36803 4.00033 5.99984 4.00033H4.6665C4.48969 4.00033 4.32012 4.07056 4.1951 4.19559C4.07008 4.32061 3.99984 4.49018 3.99984 4.66699V12.667C3.99984 12.8438 4.07008 13.0134 4.1951 13.1384C4.32012 13.2634 4.48969 13.3337 4.6665 13.3337H11.3332C11.51 13.3337 11.6796 13.2634 11.8046 13.1384C11.9296 13.0134 11.9998 12.8438 11.9998 12.667V4.66699C11.9998 4.49018 11.9296 4.32061 11.8046 4.19559C11.6796 4.07056 11.51 4.00033 11.3332 4.00033H9.99984C9.63165 4.00033 9.33317 3.70185 9.33317 3.33366C9.33317 2.96547 9.63165 2.66699 9.99984 2.66699H11.3332C11.8636 2.66699 12.3723 2.87771 12.7474 3.25278C13.1225 3.62785 13.3332 4.13656 13.3332 4.66699V12.667C13.3332 13.1974 13.1225 13.7061 12.7474 14.0812C12.3723 14.4563 11.8636 14.667 11.3332 14.667H4.6665C4.13607 14.667 3.62736 14.4563 3.25229 14.0812C2.87722 13.7061 2.6665 13.1974 2.6665 12.667V4.66699C2.6665 4.13656 2.87722 3.62785 3.25229 3.25278Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.3335 2.66634C6.96531 2.66634 6.66683 2.96482 6.66683 3.33301C6.66683 3.7012 6.96531 3.99967 7.3335 3.99967H8.66683C9.03502 3.99967 9.3335 3.7012 9.3335 3.33301C9.3335 2.96482 9.03502 2.66634 8.66683 2.66634H7.3335ZM5.3335 3.33301C5.3335 2.22844 6.22893 1.33301 7.3335 1.33301H8.66683C9.7714 1.33301 10.6668 2.22844 10.6668 3.33301C10.6668 4.43758 9.7714 5.33301 8.66683 5.33301H7.3335C6.22893 5.33301 5.3335 4.43758 5.3335 3.33301Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 9.33366C6 8.96547 6.29848 8.66699 6.66667 8.66699H9.33333C9.70152 8.66699 10 8.96547 10 9.33366C10 9.70185 9.70152 10.0003 9.33333 10.0003H6.66667C6.29848 10.0003 6 9.70185 6 9.33366Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00016 7.33301C8.36835 7.33301 8.66683 7.63148 8.66683 7.99967V10.6663C8.66683 11.0345 8.36835 11.333 8.00016 11.333C7.63197 11.333 7.3335 11.0345 7.3335 10.6663V7.99967C7.3335 7.63148 7.63197 7.33301 8.00016 7.33301Z"
          fill="#635F76"
        />
      </g>
      <defs>
        <clipPath id="clip0_3647_10242">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Salud;
