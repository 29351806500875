import React from 'react'

export const Gimnasio = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.58809 14.9535C7.9679 14.9535 7.4679 14.4487 7.4679 13.8333V5.22754C7.4679 4.60735 7.9679 4.10254 8.58809 4.10254C9.20828 4.10254 9.70828 4.60735 9.70828 5.22273V13.8285C9.70828 14.4487 9.20828 14.9535 8.58809 14.9535ZM8.58809 4.58331C8.23713 4.58331 7.94866 4.87177 7.94866 5.22273V13.8285C7.94866 14.1843 8.23713 14.4679 8.58809 14.4679C8.94386 14.4679 9.22751 14.1795 9.22751 13.8285V5.22273C9.22751 4.87177 8.94386 4.58331 8.58809 4.58331Z" fill="#635F76" stroke="#635F76" strokeWidth="0.3"/>
        <path d="M7.7083 13.5593C6.60253 13.5593 5.70349 12.6602 5.70349 11.5545V7.50159C5.70349 6.39582 6.60253 5.49678 7.7083 5.49678C7.84291 5.49678 7.94868 5.60255 7.94868 5.73717V13.3189C7.94868 13.4487 7.83811 13.5593 7.7083 13.5593ZM7.46791 5.99678C6.74195 6.11217 6.18426 6.74198 6.18426 7.50159V11.5545C6.18426 12.3141 6.74195 12.9439 7.46791 13.0593V5.99678ZM24.2323 14.9535C23.6121 14.9535 23.1121 14.4487 23.1121 13.8333V5.22755C23.1121 4.60736 23.6169 4.10736 24.2323 4.10736C24.8477 4.10736 25.3525 4.60736 25.3525 5.22275V7.84294H24.8718V5.22275C24.8718 4.86698 24.5833 4.58332 24.2323 4.58332C23.8766 4.58332 23.5929 4.87178 23.5929 5.22275V13.8285C23.5929 14.1843 23.8814 14.4679 24.2323 14.4679C24.5881 14.4679 24.8718 14.1795 24.8718 13.8285V8.80448H25.3525V13.8285C25.3525 14.4487 24.8525 14.9535 24.2323 14.9535Z" fill="#635F76" stroke="#635F76" strokeWidth="0.3"/>
        <path d="M25.1121 13.5593V13.0785C25.9535 13.0785 26.6362 12.3958 26.6362 11.5545V7.50157C26.6362 6.66023 25.9535 5.97753 25.1121 5.97753V5.49677C26.2179 5.49677 27.1169 6.3958 27.1169 7.50157V11.5545C27.1169 12.6602 26.2179 13.5593 25.1121 13.5593ZM25.1121 25.9535H7.70828C7.57366 25.9535 7.4679 25.8477 7.4679 25.7131C7.4679 23.8429 8.98713 22.3237 10.8573 22.3237H21.9631C23.8333 22.3237 25.3525 23.8429 25.3525 25.7131C25.3525 25.8477 25.2467 25.9535 25.1121 25.9535ZM7.95828 25.4727H24.8621C24.7371 23.9824 23.4871 22.8045 21.9631 22.8045H10.8573C9.33328 22.8045 8.07847 23.9824 7.95828 25.4727Z" fill="#635F76" stroke="#635F76" strokeWidth="0.3"/>
        <path d="M25.1121 28.7179H7.70831C7.57369 28.7179 7.46792 28.6122 7.46792 28.4776V25.7131C7.46792 25.5785 7.57369 25.4728 7.70831 25.4728H25.1121C25.2468 25.4728 25.3525 25.5785 25.3525 25.7131V28.4776C25.3525 28.6122 25.2468 28.7179 25.1121 28.7179ZM7.94869 28.2372H24.8718V25.9535H7.94869V28.2372ZM5.94388 10.4872H4.34292C4.20831 10.4872 4.10254 10.3814 4.10254 10.2468V8.80447C4.10254 8.66986 4.20831 8.56409 4.34292 8.56409H5.94388V9.04486H4.58331V10.0064H5.94388V10.4872Z" fill="#635F76" stroke="#635F76" strokeWidth="0.3"/>
        <path d="M23.3477 8.56409H9.4679V9.04486H23.3477V8.56409Z" fill="#635F76" stroke="#635F76" strokeWidth="0.3"/>
        <path d="M28.4775 10.4872H26.8766V10.0064H28.2372V9.04486H26.8766V8.56409H28.4775C28.6122 8.56409 28.7179 8.66986 28.7179 8.80447V10.2468C28.7179 10.3814 28.6122 10.4872 28.4775 10.4872Z" fill="#635F76" stroke="#635F76" strokeWidth="0.3"/>
        <path d="M23.3477 10.0064H9.4679V10.4872H23.3477V10.0064Z" fill="#635F76" stroke="#635F76" strokeWidth="0.3"/>
        <path d="M12.6603 8.80448H12.1795V7.27563H11.218V8.80448H10.7372V7.03525C10.7372 6.90063 10.843 6.79486 10.9776 6.79486H12.4199C12.5545 6.79486 12.6603 6.90063 12.6603 7.03525V8.80448ZM12.4199 22.8045H10.9776C10.843 22.8045 10.7372 22.6987 10.7372 22.5641V10.2564H11.218V22.3237H12.1795V11.2083H12.6603V22.5641C12.6603 22.6987 12.5545 22.8045 12.4199 22.8045ZM22.0833 8.80448H21.6026V7.27563H20.641V8.80448H20.1603V7.03525C20.1603 6.90063 20.266 6.79486 20.4006 6.79486H21.8429C21.9776 6.79486 22.0833 6.90063 22.0833 7.03525V8.80448ZM21.8429 22.8045H20.4006C20.266 22.8045 20.1603 22.6987 20.1603 22.5641V10.2468H20.641V22.3237H21.6026V10.2564H22.0833V22.5641C22.0833 22.6987 21.9776 22.8045 21.8429 22.8045Z" fill="#635F76" stroke="#635F76" strokeWidth="0.3"/>
    </svg>
  )
}
