import React, { useEffect, useRef, useState } from "react";
import "./AttachFile.scss";
import { Plus } from "../../Assets/svgs/Plus";
import { Edit } from "../../Assets/svgs/Edit";
import { Trash } from "../../Assets/svgs/Trash";
import { useDispatch } from "react-redux";
import {
  editArchivos,
  handleArchivos,
  removeArchivos,
} from "../../features/LayoutPregunta/preguntaSlice";
import { v4 as uuidv4 } from "uuid";
import * as R from "ramda";

interface IPropsAttachFile {
  valorDefault: any[];
}

interface fileState {
  id: string;
  file: File;
}

const AttachFile = ({ valorDefault }: IPropsAttachFile) => {
  const [files, setFiles] = useState<fileState[]>([]);
  const inputRef: any = useRef([]);
  const dispatch = useDispatch();

  const handleChange = (e: any) => {
    const selectedFile = { id: uuidv4(), file: e.target.files[0] };
    setFiles([...files, selectedFile]);
    dispatch(handleArchivos(selectedFile));
  };

  const handleChangeEdit = (index: number, e: any, id: string) => {
    const selectedFile = e.target.files[0];
    setFiles((prevState) =>
      R.update(index, { id: id, file: selectedFile }, prevState)
    );
    dispatch(editArchivos({ index, id, file: selectedFile }));
  };

  const handleDelete = (id: string, index: number) => {
    const newFiles = files.filter((f) => f.id !== id);
    setFiles(newFiles);
    dispatch(removeArchivos(id));
  };

  const handleEdit = (index: number) => {
    inputRef.current[index].click();
  };

  const renderFileName = () => {
    return files.map((f, i) => {
      return (
        <div className="attach-input-file" key={f.id}>
          <p>{f.file && f.file.name}</p>
          <div className="attach-input-file-actions">
            <div
              className="attach-input-file-actions-edit"
              onClick={() => handleEdit(i)}
            >
              <Edit />
              <input
                ref={(e) => (inputRef.current[i] = e)}
                type="file"
                accept="image/jpeg,image/gif,image/png,application/pdf"
                onChange={(e) => handleChangeEdit(i, e, f.id)}
              />
            </div>
            <div
              className="attach-input-file-actions-delete"
              onClick={() => handleDelete(f.id, i)}
            >
              <Trash />
            </div>
          </div>
        </div>
      );
    });
  };

  const renderInput = () => {
    return (
      <label className="attach-input-button">
        <div className="attach-input-button-addicon">
          <Plus />
        </div>
        <input
          className="attach-input"
          type="file"
          accept="image/jpeg,image/gif,image/png,application/pdf"
          onChange={handleChange}
        />
      </label>
    );
  };

  return (
    <div className="attach-input">
      <div className="attach-input-file-default">
        {valorDefault && valorDefault.length ? valorDefault.map((e: any) => <p>{e.nombreAdjunto}</p>) : null}
      </div>
      {renderFileName()}
      {renderInput()}
    </div>
  );
};

export default AttachFile;
