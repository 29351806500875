import React from 'react'

export const Calendar = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6 6C5.44772 6 5 6.44772 5 7V19C5 19.5523 5.44772 20 6 20H18C18.5523 20 19 19.5523 19 19V7C19 6.44772 18.5523 6 18 6H6ZM3 7C3 5.34315 4.34315 4 6 4H18C19.6569 4 21 5.34315 21 7V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V7Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M16 2C16.5523 2 17 2.44772 17 3V7C17 7.55228 16.5523 8 16 8C15.4477 8 15 7.55228 15 7V3C15 2.44772 15.4477 2 16 2Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8 2C8.55228 2 9 2.44772 9 3V7C9 7.55228 8.55228 8 8 8C7.44772 8 7 7.55228 7 7V3C7 2.44772 7.44772 2 8 2Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M3 11C3 10.4477 3.44772 10 4 10H20C20.5523 10 21 10.4477 21 11C21 11.5523 20.5523 12 20 12H4C3.44772 12 3 11.5523 3 11Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7 15C7 14.4477 7.44772 14 8 14H10C10.5523 14 11 14.4477 11 15V17C11 17.5523 10.5523 18 10 18H8C7.44772 18 7 17.5523 7 17V15Z" fill="#89869B"/>
    </svg>
  )
}
