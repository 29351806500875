import { Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CustomButton } from "../CustomButton/CustomButton";
import "./ModalAyuda.scss";

export const ModalAyuda = (props: any) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (props.open) {
      setOpen(props.open)
    }
  }, [props])

  const handleClose = () => {
    setOpen(false)
    props.onClose()
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="ayuda">
        <h1>{props.titulo}</h1>
        <div className="ayuda-texto" dangerouslySetInnerHTML={{__html: props.texto}}></div>
        {props.imagen ? <img src={props.imagen} alt=""/> : null}
        <CustomButton
          type="secondary"
          text="Cerrar"
          onClick={handleClose}
        />
      </div>
    </Modal>
  );
};
