
export enum TipoItem{
    InputText = 3,
    InputNumber = 1, //Ej 10.A
    InputDate = 4, //Ej 9.A
    SimpleOption = 6,
    OptionMultipleChoice = 5,
    MultipleChoice = 66,
    DropdownInputYear = 2, //Ej 11.A (Con imagen, con )
    Checkbox = 8,
    listaBienes = 9,
    BloqueImagenes = 10,
    CurrencyInput = 11,
    CardSuperficie = 12,
    CardConOpciones = 13,
    BienesAltoValorHogar = 14,
    TextArea = 18,
    DropdownInput = 16,
    ExpandedToggleButtons = 17,
    InputMail = 20,
    InputMovil = 19,
    AdjuntarArchivo = 21
}