import React from 'react'

export const Bell = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.99991 6C9.99991 5.46957 10.2106 4.96086 10.5857 4.58579C10.9608 4.21071 11.4695 4 11.9999 4C12.5303 4 13.039 4.21071 13.4141 4.58579C13.7892 4.96086 13.9999 5.46957 13.9999 6C15.1483 6.54303 16.1273 7.38833 16.832 8.4453C17.5366 9.50227 17.9403 10.7311 17.9999 12V15C18.0752 15.6217 18.2953 16.2171 18.6427 16.7381C18.9901 17.2592 19.455 17.6914 19.9999 18H3.99991C4.54485 17.6914 5.00972 17.2592 5.3571 16.7381C5.70447 16.2171 5.92465 15.6217 5.99991 15V12C6.05947 10.7311 6.46321 9.50227 7.16786 8.4453C7.87251 7.38833 8.8515 6.54303 9.99991 6" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9 18V19C9 19.7956 9.31607 20.5587 9.87868 21.1213C10.4413 21.6839 11.2044 22 12 22C12.7956 22 13.5587 21.6839 14.1213 21.1213C14.6839 20.5587 15 19.7956 15 19V18" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <circle cx="17.6" cy="6.60001" r="5" fill="#89869B" stroke="white" strokeWidth="2"/>
    <circle cx="17.6" cy="6.59999" r="0.8" fill="white"/>
    </svg>
  )
}
