import Lottie from "lottie-web";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as animationData from "../../Assets/jsonAnimations/animatedSearch.json";
import { CustomButton } from "../../Components/CustomButton/CustomButton";
import { postRespuesta } from "../LayoutPregunta/PreguntasAPI";
import {
  getPreguntas,
  selectId,
  selectPreguntas,
  selectStatusGetPreguntas,
} from "../LayoutPregunta/preguntaSlice";
import "./LayoutCarga.scss";

export const LayoutCargarSimple = () => {
  const lottieInstance = useRef<any>();
  const preguntas = useSelector(selectPreguntas);
  const divRef = useRef<any>();
  const dispatch = useDispatch();
  const preguntaId = useSelector(selectId);
  const [status, setStatus] = useState("success");
  const statusFetch = useSelector(selectStatusGetPreguntas);
  const refreshTime = 60000;

  const getSiguientePregunta = async () => {
    setStatus("loading");
    //@ts-ignore
    const response: any = await dispatch(getPreguntas(preguntaId));
    console.log(response);
    if (response.payload.status === 200) {
      setStatus("success");
      return response;
    }
  };

  const handleSiguiente = async () => {
    console.log("AUTOCONFIRMANDO");
    const response = await postRespuesta(preguntas);
    if (response.status === 200)
      // @ts-ignore
      getSiguientePregunta();
  };

  useEffect(() => {
    setTimeout(() => {
      handleSiguiente();
    }, 500);
  }, []);

  useEffect(() => {
    if (divRef.current) {
      lottieInstance.current = Lottie.loadAnimation({
        animationData,
        container: divRef.current,
      });
    }
  }, []);

  return (
    <div className="layout-carga">
      <div className="layout-carga-text">
        <div className="layout-carga-svg" ref={divRef}></div>
        <p className="layout-carga-msg1">
          Estamos intentando localizar los datos catastrales a partir de la
          información que nos has dado.
        </p>
        <p className="layout-carga-msg2">
          Sólo faltan unos segundos más. Por favor espera.
        </p>
      </div>
    </div>
  );
};
