import React from 'react'

export const Turismo = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7ZM7 10C7 7.23858 9.23858 5 12 5C14.7614 5 17 7.23858 17 10C17 12.7614 14.7614 15 12 15C9.23858 15 7 12.7614 7 10Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.133 2.50166C15.4082 2.02284 16.0195 1.85778 16.4983 2.133C17.5884 2.75954 18.5354 3.60718 19.2784 4.62146C20.0214 5.63574 20.544 6.79422 20.8127 8.02249C21.0814 9.25076 21.0902 10.5216 20.8385 11.7535C20.5868 12.9854 20.0802 14.151 19.3513 15.1754C18.6223 16.1998 17.6872 17.0605 16.6059 17.702C15.5246 18.3436 14.321 18.7518 13.0725 18.9006C11.824 19.0493 10.5582 18.9353 9.35647 18.5658C8.15468 18.1963 7.04348 17.5794 6.09425 16.755C5.6773 16.3928 5.63288 15.7612 5.99505 15.3442C6.35721 14.9273 6.98882 14.8828 7.40578 15.245C8.14442 15.8866 9.00909 16.3666 9.94426 16.6541C10.8794 16.9416 11.8644 17.0304 12.8359 16.9146C13.8074 16.7989 14.7439 16.4812 15.5854 15.982C16.4268 15.4828 17.1545 14.8131 17.7217 14.0159C18.2889 13.2187 18.6831 12.3117 18.879 11.3532C19.0748 10.3946 19.068 9.40564 18.8589 8.44987C18.6498 7.4941 18.2432 6.59263 17.665 5.80337C17.0868 5.01411 16.3499 4.35452 15.5017 3.86698C15.0229 3.59176 14.8578 2.98049 15.133 2.50166Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12 17C12.5523 17 13 17.4477 13 18V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V18C11 17.4477 11.4477 17 12 17Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7 22C7 21.4477 7.44772 21 8 21H16C16.5523 21 17 21.4477 17 22C17 22.5523 16.5523 23 16 23H8C7.44772 23 7 22.5523 7 22Z" fill="#89869B"/>
    </svg>
  )
}
