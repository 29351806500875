import React from "react";

export const Warning = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="32" fill="#FDF6F0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 26.6667C32.7364 26.6667 33.3334 27.2636 33.3334 28V30.6667C33.3334 31.4031 32.7364 32 32 32C31.2636 32 30.6667 31.4031 30.6667 30.6667V28C30.6667 27.2636 31.2636 26.6667 32 26.6667ZM32 34.6667C32.7364 34.6667 33.3334 35.2636 33.3334 36V36.0133C33.3334 36.7497 32.7364 37.3467 32 37.3467C31.2636 37.3467 30.6667 36.7497 30.6667 36.0133V36C30.6667 35.2636 31.2636 34.6667 32 34.6667Z"
        fill="#E79142"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.9552 19.1786C30.5707 18.8157 31.2721 18.6243 31.9867 18.6243C32.7012 18.6243 33.4027 18.8157 34.0182 19.1786C34.6295 19.539 35.1338 20.0555 35.4795 20.675L44.9402 36.9981C44.9716 37.0522 44.9992 37.1086 45.0227 37.1666C45.2674 37.7716 45.3604 38.4273 45.2936 39.0765C45.2267 39.7257 45.002 40.3487 44.6391 40.8911C44.2762 41.4335 43.786 41.8789 43.2115 42.1884C42.6369 42.4979 41.9953 42.6621 41.3427 42.6666L41.3333 42.6667H22.6667C22.6423 42.6667 22.6181 42.666 22.594 42.6647C22.547 42.6673 22.4994 42.6674 22.4513 42.6649C21.8141 42.632 21.194 42.4472 20.6428 42.1259C20.0916 41.8046 19.6252 41.356 19.2826 40.8177C18.9401 40.2794 18.7313 39.667 18.6736 39.0315C18.616 38.3961 18.7112 37.7561 18.9513 37.1649C18.9747 37.1075 19.002 37.0517 19.0331 36.9981L28.4938 20.6751C28.8395 20.0555 29.3438 19.539 29.9552 19.1786ZM22.593 40.002C22.6174 40.0007 22.6419 40 22.6667 40H41.3279C41.5441 39.9979 41.7565 39.9432 41.9469 39.8406C42.1384 39.7375 42.3018 39.589 42.4228 39.4082C42.5438 39.2274 42.6186 39.0197 42.6409 38.8033C42.6606 38.6128 42.6389 38.4207 42.5778 38.2398L33.1664 22.0019L33.1533 21.9788C33.038 21.7704 32.869 21.5967 32.6638 21.4758C32.4587 21.3548 32.2248 21.291 31.9867 21.291C31.7485 21.291 31.5147 21.3548 31.3095 21.4758C31.1043 21.5967 30.9353 21.7704 30.82 21.9788L30.8069 22.0019L21.395 38.2408C21.335 38.4172 21.3125 38.6045 21.3294 38.7906C21.3486 39.0025 21.4182 39.2066 21.5324 39.386C21.6466 39.5655 21.802 39.715 21.9858 39.8221C22.1695 39.9292 22.3762 39.9908 22.5886 40.0018C22.5901 40.0018 22.5915 40.0019 22.593 40.002Z"
        fill="#E79142"
      />
    </svg>
  );
};
