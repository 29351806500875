import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { Info } from "../../Assets/svgs/Info";
import { Map } from "../../Assets/svgs/Map";
import { BlockTitle } from "../../Components/BlockTitle/BlockTitle";
import { CustomButton } from "../../Components/CustomButton/CustomButton";
import { CustomInput } from "../../Components/CustomInput/CustomInput";
import { Description } from "../../Components/Description/Description";
import { IncidenciaAlert } from "../../Components/IncidenciaAlert/IncidenciaAlert";
import { Notification } from "../../Components/Notification/Notification";
import { SubBlockTitle } from "../../Components/SubBlockTitle/SubBlockTitle";
import { YearDropdownInput } from "../../Components/YearDropdownInput/YearDropdownInput";
import { TipoItem } from "../../model/tipoItemEnum";
import { postRespuesta } from "../LayoutPregunta/PreguntasAPI";
import {
  getPreguntas, selectId,
  selectPreguntas,
  validarCamposObligatorios
} from "../LayoutPregunta/preguntaSlice";
import "./DatosEmpresa.scss";
import { ModalConexion } from "../../Components/ModalConexion/ModalConexion";
import dayjs from "dayjs";
import { DateInput } from "../../Components/DateInput/DateInput";

export const DatosIniciales = () => {
  const dispatch = useDispatch();
  const preguntas = useSelector(selectPreguntas);
  const preguntaId: any = useSelector(selectId);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false)
  const [inputHasError, setInputHasError] = useState(false)
  const [noConnection, setNoConnection] = useState(false)
  const obligatorios = useSelector(validarCamposObligatorios);

  useEffect(() => {
    if (preguntas.incidencias && preguntas.incidencias.length) {
      setOpen(true);
    }
  }, [preguntas]);

  const handleSiguiente = async () => {
    try { setLoader(true) } catch {}
    const response = await postRespuesta(preguntas);
    
    if (response.status === 200) {
      // @ts-ignore
      dispatch(getPreguntas(preguntaId));
      try { setLoader(false) } catch {}
    }else{
      try { setLoader(false) } catch {}
    }
  };

  const renderItem = (item: any, index: number) => {
      switch (item.tipoItem) {
        case TipoItem.InputText: {
          return (
            <CustomInput
              key={item.keyId}
              inputKey={item.key}
              type="text"
              label={item.placeholder}
              index={index}
              prefetchedValue={item.valor}
              hasError={(error) => setInputHasError(error)}
            />
          );
        }
        case TipoItem.InputDate: {
          return (
            <DateInput
              key={item.keyId}
              index={index}
              label={item.placeholder}
              prefetchedValue={item.valor && dayjs(item.valor).format("YYYY-MM-DD")}
            />
          )
        }
      }
  }

  const renderInputs = () => {
    return preguntas.items.map((e: any, index: number) => {
      return renderItem(e, index)
    });
  };

  const handleContinue = () => {
    const online = window.navigator.onLine
    if(!online) {
      setNoConnection(true);
      return; 
    } else {
      return handleSiguiente()
    }
  }

  return (
    <div className="datos-empresa">
      <div className="datos-empresa-content">
        <BlockTitle
          icon={preguntas.iconoPrincipal}
          text={preguntas.titulo}
        />
        <div className="datos-empresa-text">
          <SubBlockTitle text="Datos del cliente" />
          <Description text={preguntas.texto} />
        </div>
        <div className="datos-empresa-inputs">{renderInputs()}</div>
        <Notification
          iconComponent={<Info />}
          text="Hemos rellenado los datos a partir de la información aportada por su mediador."
        />
      </div>
      <IncidenciaAlert
        open={open}
        items={preguntas.incidencias ? preguntas.incidencias[0] : {}}
      />
      <div className="datos-empresa-buttons">
        <CustomButton
          type="primary"
          text="Continuar"
          loading={loader}
          disable={!obligatorios || inputHasError}
          onClick={handleContinue}
        >
        </CustomButton>
      </div>
      <ModalConexion open={noConnection} onClick={(e: boolean) => setNoConnection(e)} />
    </div>
  );
};
