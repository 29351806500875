import React from "react";
import "./SubBlockTitle.scss";

interface IProps {
  text: string;
}

export const SubBlockTitle = ({ text }: IProps) => {
  return (
    <div className="subblock-title">
      <h2>{text}</h2>
    </div>
  );
};
