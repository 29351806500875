import React from 'react'

export const Granel = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M28.6519 21.8898C28.4961 21.6328 28.4824 21.5645 28.3402 21.4031V14.6738C28.3402 13.3832 27.8754 12.1254 27.0496 11.1437C27.4761 10.966 27.6703 10.4766 27.4898 10.0582L27.323 9.66719C27.1535 9.2625 26.757 9 26.3168 9H19.6832C19.2429 9 18.8464 9.2625 18.6742 9.66719L18.5074 10.0582C18.3269 10.482 18.5265 10.966 18.9504 11.1437C18.1246 12.1254 17.6597 13.3832 17.6597 14.6738V21.4031C17.5175 21.5672 17.5011 21.6355 17.348 21.8898C17.0636 22.382 17.4191 23 17.9879 23H28.0121C28.28 23 28.5179 22.8605 28.6519 22.6309C28.7859 22.3984 28.7859 22.1223 28.6519 21.8898ZM19.0324 10.2824L19.1992 9.89141C19.2812 9.69727 19.4726 9.57148 19.6832 9.57148H26.314C26.5246 9.57148 26.716 9.69727 26.798 9.89141L26.9648 10.2824C27.0359 10.452 26.9129 10.6379 26.7297 10.6379H19.2675C19.0843 10.6379 18.9613 10.4492 19.0324 10.2824ZM28.0121 22.4285H17.9879C17.8593 22.4285 17.7773 22.2891 17.8429 22.177L18.0125 21.8844C18.13 21.6766 18.3515 21.5508 18.5867 21.5508H20.9683C21.1269 21.5508 21.2527 21.4223 21.2527 21.2664C21.2527 21.1105 21.1242 20.982 20.9683 20.982H18.5867C18.4636 20.982 18.3433 21.0012 18.2285 21.0367V14.6738C18.2285 13.3805 18.748 12.1254 19.6613 11.2094H26.3359C27.2465 12.1254 27.7687 13.3805 27.7687 14.6738V21.034C27.6539 20.9984 27.5336 20.9793 27.4105 20.9793H25.9914C25.8328 20.9793 25.707 21.1078 25.707 21.2637C25.707 21.4195 25.8355 21.548 25.9914 21.548H27.4105C27.9273 21.548 28.0586 22.0539 28.157 22.1742C28.2226 22.2891 28.1406 22.4285 28.0121 22.4285Z" fill="#635F76" stroke="#635F76" strokeWidth="0.2"/>
        <path d="M26.7461 18.0426V14.1434C26.7461 13.6868 26.3742 13.3176 25.9203 13.3176H20.0797C19.623 13.3176 19.2539 13.6895 19.2539 14.1434V18.0426C19.2539 18.4993 19.6258 18.8711 20.0797 18.8711H25.9176C26.3742 18.8711 26.7461 18.4993 26.7461 18.0426ZM19.8254 18.0426V14.1434C19.8254 14.0012 19.9402 13.8891 20.0797 13.8891H25.9176C26.0598 13.8891 26.1719 14.004 26.1719 14.1434V18.0426C26.1719 18.1848 26.057 18.2997 25.9176 18.2997H20.0797C19.9402 18.2997 19.8254 18.1848 19.8254 18.0426Z" fill="#635F76" stroke="#635F76" strokeWidth="0.2"/>
        <path d="M23.0001 14.6328C22.3329 14.6328 21.3129 15.6773 20.9137 16.5441C20.695 17.0172 21.0477 17.5559 21.57 17.5559H24.4329C24.9551 17.5559 25.3051 17.0145 25.0891 16.5441C24.6872 15.6773 23.6672 14.6328 23.0001 14.6328ZM24.4301 16.9844H21.5672C21.4633 16.9844 21.3868 16.8777 21.4305 16.782C21.7997 15.9836 22.6911 15.2016 22.9973 15.2016C23.3063 15.2016 24.1977 15.9809 24.5641 16.782C24.6106 16.8777 24.5368 16.9844 24.4301 16.9844Z" fill="#635F76" stroke="#635F76" strokeWidth="0.2"/>
        <path d="M14.6519 21.8898C14.4961 21.6328 14.4824 21.5645 14.3402 21.4031V14.6738C14.3402 13.3832 13.8754 12.1254 13.0496 11.1437C13.4761 10.966 13.6703 10.4766 13.4898 10.0582L13.323 9.66719C13.1535 9.2625 12.757 9 12.3168 9H5.68317C5.24293 9 4.84645 9.2625 4.67418 9.66719L4.50739 10.0582C4.32692 10.482 4.52653 10.966 4.95036 11.1437C4.12457 12.1254 3.65973 13.3832 3.65973 14.6738V21.4031C3.51754 21.5672 3.50114 21.6355 3.34801 21.8898C3.06364 22.382 3.4191 23 3.98786 23H14.0121C14.28 23 14.5179 22.8605 14.6519 22.6309C14.7859 22.3984 14.7859 22.1223 14.6519 21.8898ZM5.03239 10.2824L5.19918 9.89141C5.28122 9.69727 5.47262 9.57148 5.68317 9.57148H12.314C12.5246 9.57148 12.716 9.69727 12.798 9.89141L12.9648 10.2824C13.0359 10.452 12.9129 10.6379 12.7297 10.6379H5.26754C5.08434 10.6379 4.96129 10.4492 5.03239 10.2824ZM14.0121 22.4285H3.98786C3.85934 22.4285 3.77731 22.2891 3.84293 22.177L4.01246 21.8844C4.13004 21.6766 4.35153 21.5508 4.58668 21.5508H6.96832C7.12692 21.5508 7.2527 21.4223 7.2527 21.2664C7.2527 21.1105 7.12418 20.982 6.96832 20.982H4.58668C4.46364 20.982 4.34332 21.0012 4.22848 21.0367V14.6738C4.22848 13.3805 4.74801 12.1254 5.66129 11.2094H12.3359C13.2465 12.1254 13.7687 13.3805 13.7687 14.6738V21.034C13.6539 20.9984 13.5336 20.9793 13.4105 20.9793H11.9914C11.8328 20.9793 11.707 21.1078 11.707 21.2637C11.707 21.4195 11.8355 21.548 11.9914 21.548H13.4105C13.9273 21.548 14.0586 22.0539 14.157 22.1742C14.2226 22.2891 14.1406 22.4285 14.0121 22.4285Z" fill="#635F76" stroke="#635F76" strokeWidth="0.2"/>
        <path d="M12.7461 18.0426V14.1434C12.7461 13.6868 12.3742 13.3176 11.9203 13.3176H6.07969C5.62305 13.3176 5.25391 13.6895 5.25391 14.1434V18.0426C5.25391 18.4993 5.62578 18.8711 6.07969 18.8711H11.9176C12.3742 18.8711 12.7461 18.4993 12.7461 18.0426ZM5.82539 18.0426V14.1434C5.82539 14.0012 5.94023 13.8891 6.07969 13.8891H11.9176C12.0598 13.8891 12.1719 14.004 12.1719 14.1434V18.0426C12.1719 18.1848 12.057 18.2997 11.9176 18.2997H6.07969C5.94023 18.2997 5.82539 18.1848 5.82539 18.0426Z" fill="#635F76" stroke="#635F76" strokeWidth="0.2"/>
        <path d="M9.00005 14.6328C8.33287 14.6328 7.31294 15.6773 6.91373 16.5441C6.69498 17.0172 7.04771 17.5559 7.56997 17.5559H10.4329C10.9551 17.5559 11.3051 17.0145 11.0891 16.5441C10.6872 15.6773 9.66724 14.6328 9.00005 14.6328ZM10.4301 16.9844H7.56724C7.46333 16.9844 7.38677 16.8777 7.43052 16.782C7.79966 15.9836 8.69107 15.2016 8.99732 15.2016C9.3063 15.2016 10.1977 15.9809 10.5641 16.782C10.6106 16.8777 10.5368 16.9844 10.4301 16.9844Z" fill="#635F76" stroke="#635F76" strokeWidth="0.2"/>
    </svg>
  )
}
