import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { AiraLogo } from "../../Assets/svgs/AiraLogo";
import { Close } from "../../Assets/svgs/Close";
import "./ModalTerminosYCondiciones.scss";
import TerminosYCondiciones from "./TerminosYCondiciones";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { CustomTextRenderer } from "react-pdf/dist/cjs/shared/types";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ModalTerminosYCondiciones = (props: any) => {
  const [disableButton, setDisableButton] = useState(true);
  const [idAgente, setIdAgente] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [numPages, setNumPages] = useState(0);

  useEffect(() => {
    if (props.selected) {
      setChecked(props.selected);
    }
  }, []);

  const handleChange = (e: any) => {
    setChecked(!checked);
    props.onChange();
  };

  const textRenderer = (pageInfo: any) => {
    console.log(pageInfo);
    return pageInfo.str;
  };

  return (
    <>
      <div className="tos-checkbox">
        <label>
          <input type="checkbox" onChange={handleChange} />
          <svg
            className={`checkbox ${checked ? "checkbox-active" : ""}`}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.99994 13L8.99994 18L19.9999 7.00001"
              stroke={checked ? "#ffffff" : "none"}
              strokeWidth="4"
              strokeLinecap="round"
            />
          </svg>
        </label>
        <p>
          Acepto la{" "}
          <span onClick={() => setOpen(true)}>
            {props.politicasCustom
              ? props.politicasCustom.titulo
              : "política de privacidad"}
          </span>
        </p>
      </div>
      <Modal
        open={open}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <div className="modal-tos">
          <div className="modal-tos-header">
            <AiraLogo />
            <div
              className="modal-tos-header-close"
              onClick={() => setOpen(false)}
            >
              <Close />
            </div>
          </div>
          <div className="modal-tos-text">
            {props.politicasCustom ? (
              <div
                className="modal-tos-text-html"
                dangerouslySetInnerHTML={{
                  __html: props.politicasCustom.valor.toString(),
                }}
              ></div>
            ) : (
              <TerminosYCondiciones />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalTerminosYCondiciones;
