import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Error } from "../../Assets/svgs/Error";
import { Warning } from "../../Assets/svgs/Warning";
import { CustomButton } from "../../Components/CustomButton/CustomButton";
import { postRespuesta } from "../LayoutPregunta/PreguntasAPI";
import { getPreguntas, selectId, selectPreguntas } from "../LayoutPregunta/preguntaSlice";
import "./LayoutMensaje.scss";

export const LayoutMensaje = () => {
    const dispatch = useDispatch();
    const preguntas = useSelector(selectPreguntas);
    const preguntaId: any = useSelector(selectId);

    const handleSiguiente = async () => {
        const response = await postRespuesta(preguntas)
        console.log(response)
        if (response.status === 200) {
            // @ts-ignore
            dispatch(getPreguntas(preguntaId))
        } else {
            console.log(response.status)
        }
    }

  /*   const renderIcon = () => {
        if (preguntas.tipoAlerta === 1) {
            return <Warning />
        } else {
            return <Error />
        }
    } */

    const renderDescripcion = () => {
        if (preguntas.texto) {
            return (
                <p className="layout-mensaje-descripcion">{preguntas.texto}</p>
            );
        }
    };

    return (
        <div className="layout-mensaje">
            <div className="layout-mensaje-top">
                {/* renderIcon() */}
                <Warning />
                <h1>{preguntas.titulo}</h1>
                {renderDescripcion()}
            </div>
{/*             <div className="layout-mensaje-data">
                <p>{preguntas.labelValor}</p>
                <span>{preguntas.valorIngresado}</span>
            </div> */}
            {/* {preguntas.textoPregunta ? <p>{preguntas.textoPregunta}</p> : null} */}
            <CustomButton
                type="primary"
                text={preguntas.titulo1}
                onClick={handleSiguiente}
            />
            {preguntas.recordatorio ? <span className="layout-mensaje-recordatorio">{preguntas.recordatorio}</span> : null}
        </div>
    );
};
