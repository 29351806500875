import React, { useEffect, useState } from "react";
import "./CustomCheckbox.scss";
import { ItemControl } from "../../model/itemControl";
import { NumericLiteral } from "typescript";
import { useDispatch } from "react-redux";
import { changeValue } from "../../features/LayoutPregunta/preguntaSlice";

interface IProps {
  label?: string;
  rounded?: boolean;
  onChange?: any;
  singleCheckbox?: boolean
  itemId?: number;
  choiceIndex: number;
  selected?: any;
  indexSubpregunta?: any;
  index?: number;
}

export const CustomCheckbox = ({ label, rounded, onChange, itemId, choiceIndex, singleCheckbox, index, selected, indexSubpregunta }: IProps) => {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch()

  const handleChange = () => {
    setChecked(!checked);
    if(indexSubpregunta || indexSubpregunta === 0) {
      onChange(!checked, index, indexSubpregunta.indexSub, indexSubpregunta.indexOpcion)
      return;
    }
    if (onChange) onChange(itemId, choiceIndex, !checked)
    if (singleCheckbox) dispatch(changeValue({ value: !checked, index: choiceIndex }))
  };

  useEffect(() => {
    if (selected) setChecked(true)
  }, [])

  return (
    <div className="custom-checkbox">
      <label>
        <input type="checkbox" value={selected || checked} onChange={handleChange} />
        <svg
          className={`checkbox ${checked ? "checkbox-active" : ""} ${rounded ? "checkbox-rounded" : ""
            } ${rounded && checked ? "checkbox-rounded-active" : ""}`}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.99994 13L8.99994 18L19.9999 7.00001"
            stroke={checked ? "#ffffff" : "none"}
            strokeWidth="4"
            strokeLinecap="round"
          />
        </svg>
      </label>
      <span style={{ fontWeight: rounded ? 600 : 400 }}>{label}</span>
    </div>
  );
};
