import React from 'react'

export const HelpCircle = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12 16C12.5523 16 13 16.4477 13 17V17.01C13 17.5623 12.5523 18.01 12 18.01C11.4477 18.01 11 17.5623 11 17.01V17C11 16.4477 11.4477 16 12 16Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.4747 6.34495C10.9741 6.09784 11.524 5.97003 12.0812 5.97157C12.6384 5.97312 13.1877 6.10398 13.6857 6.35386C14.1837 6.60373 14.6169 6.9658 14.9512 7.41156C15.2856 7.85732 15.5118 8.37459 15.6123 8.92266C15.7127 9.47073 15.6846 10.0346 15.53 10.57C15.3755 11.1053 15.0988 11.5975 14.7218 12.0077C14.3447 12.4179 13.8776 12.7351 13.3571 12.9341C13.3487 12.9373 13.3402 12.9404 13.3317 12.9434C13.2294 12.9794 13.1416 13.0476 13.0815 13.1378C13.0214 13.2279 12.9922 13.3352 12.9983 13.4434C13.0296 13.9948 12.608 14.4672 12.0566 14.4984C11.5052 14.5297 11.0328 14.1081 11.0015 13.5567C10.9709 13.0156 11.1168 12.4793 11.4174 12.0284C11.7149 11.5821 12.1479 11.2435 12.6523 11.0623C12.8798 10.9737 13.084 10.8341 13.2492 10.6543C13.4168 10.472 13.5398 10.2532 13.6085 10.0153C13.6772 9.77737 13.6897 9.52674 13.645 9.28316C13.6004 9.03957 13.4998 8.80967 13.3512 8.61156C13.2027 8.41344 13.0101 8.25252 12.7888 8.14147C12.5674 8.03041 12.3233 7.97225 12.0757 7.97156C11.828 7.97088 11.5836 8.02768 11.3617 8.13751C11.1397 8.24734 10.9463 8.40719 10.7966 8.60447C10.4628 9.04445 9.83549 9.13052 9.3955 8.7967C8.95552 8.46289 8.86946 7.8356 9.20327 7.39562C9.54006 6.95172 9.97528 6.59206 10.4747 6.34495Z" fill="#89869B"/>
    </svg>
  )
}
