import React from 'react'

export const Office = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2 21C2 20.4477 2.44772 20 3 20H21C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8 8C8 7.44772 8.44772 7 9 7H10C10.5523 7 11 7.44772 11 8C11 8.55228 10.5523 9 10 9H9C8.44772 9 8 8.55228 8 8Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8 12C8 11.4477 8.44772 11 9 11H10C10.5523 11 11 11.4477 11 12C11 12.5523 10.5523 13 10 13H9C8.44772 13 8 12.5523 8 12Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8 16C8 15.4477 8.44772 15 9 15H10C10.5523 15 11 15.4477 11 16C11 16.5523 10.5523 17 10 17H9C8.44772 17 8 16.5523 8 16Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13 8C13 7.44772 13.4477 7 14 7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H14C13.4477 9 13 8.55228 13 8Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13 12C13 11.4477 13.4477 11 14 11H15C15.5523 11 16 11.4477 16 12C16 12.5523 15.5523 13 15 13H14C13.4477 13 13 12.5523 13 12Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13 16C13 15.4477 13.4477 15 14 15H15C15.5523 15 16 15.4477 16 16C16 16.5523 15.5523 17 15 17H14C13.4477 17 13 16.5523 13 16Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7 4C6.73478 4 6.48043 4.10536 6.29289 4.29289C6.10536 4.48043 6 4.73478 6 5V21C6 21.5523 5.55228 22 5 22C4.44772 22 4 21.5523 4 21V5C4 4.20435 4.31607 3.44129 4.87868 2.87868C5.44129 2.31607 6.20435 2 7 2H17C17.7956 2 18.5587 2.31607 19.1213 2.87868C19.6839 3.44129 20 4.20435 20 5V21C20 21.5523 19.5523 22 19 22C18.4477 22 18 21.5523 18 21V5C18 4.73478 17.8946 4.48043 17.7071 4.29289C17.5196 4.10536 17.2652 4 17 4H7Z" fill="#89869B"/>
    </svg>
  )
}
