import React from 'react'

export const Inspecciones = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.00015 4.99998H7.00015C6.46972 4.99998 5.96101 5.2107 5.58594 5.58577C5.21087 5.96084 5.00015 6.46955 5.00015 6.99998V19C5.00015 19.5304 5.21087 20.0391 5.58594 20.4142C5.96101 20.7893 6.46972 21 7.00015 21H17.0002C17.5306 21 18.0393 20.7893 18.4144 20.4142C18.7894 20.0391 19.0002 19.5304 19.0002 19V6.99998C19.0002 6.46955 18.7894 5.96084 18.4144 5.58577C18.0393 5.2107 17.5306 4.99998 17.0002 4.99998H15.0002" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13 3H11C9.89543 3 9 3.89543 9 5C9 6.10457 9.89543 7 11 7H13C14.1046 7 15 6.10457 15 5C15 3.89543 14.1046 3 13 3Z" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 12H9.01091" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.9998 12H14.9998" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 16H9.01091" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.9998 16H14.9998" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
