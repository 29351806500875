import { Icons } from "../../Assets/Icons";
import { ExclusiveMultipleChoice } from "../ExclusiveMultipleChoice/ExclusiveMultipleChoice";
import "./ChoiceCard.scss";

export const ChoiceCard = (props: any) => {
  const icons = Icons()

  return (
    <div className="choice-card">
      <div className="choice-card-container">
        <div className="choice-card-header">
          <div className="choice-card-header-icon">
            {icons[props.item.icono]}
          </div>
          <span className="choice-card-header-title">
            {props.item.consigna}
          </span>
        </div>
        <div className="choice-card-options">
          <ExclusiveMultipleChoice
            items={props.item.opciones}
            id={props.item.id}
            type="opcion"
            path={[...props.path, "opciones"]}
          />
        </div>
      </div>
    </div>
  );
};
