import React from "react";

export const Propietario = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3125 6.00009C21.8656 6.00009 20.6885 7.15892 20.6885 8.58337C20.6885 10.0078 21.8656 11.1666 23.3125 11.1666C24.7595 11.1666 25.9366 10.0078 25.9366 8.58337C25.9366 7.15892 24.7595 6.00009 23.3125 6.00009ZM23.3125 9.78732C22.6382 9.78732 22.0896 9.24722 22.0896 8.58337C22.0896 7.91952 22.6382 7.37942 23.3125 7.37942C23.9869 7.37942 24.5355 7.91952 24.5355 8.58337C24.5355 9.24722 23.9869 9.78732 23.3125 9.78732Z"
        fill="#635F76"
      />
      <path
        d="M20.3871 2C15.0865 2 10.7741 6.2454 10.7741 11.4636C10.7741 12.6237 10.9835 13.7511 11.3969 14.8216L2.20521 23.8711C2.07383 24.0005 2 24.1759 2 24.3588V29.3104C2 29.6913 2.31364 30.0001 2.70055 30.0001H6.84554C7.03114 30.0001 7.20912 29.9276 7.3405 29.7985L8.73733 28.4254C8.86898 28.296 8.94292 28.1204 8.94292 27.9374V26.2488H10.6559C11.0429 26.2488 11.3565 25.9401 11.3565 25.5592V24.8832H12.0432C12.43 24.8832 12.7437 24.5744 12.7437 24.1935V22.7095H14.2511C14.4369 22.7095 14.6151 22.6369 14.7465 22.5075L16.9753 20.3134C18.0629 20.7208 19.2082 20.9272 20.387 20.9272C25.6876 20.9272 30.0001 16.6818 30.0001 11.4635C30.0001 6.2454 25.6877 2 20.3871 2ZM20.3871 19.5478C19.2405 19.5478 18.1314 19.3198 17.0908 18.8701C16.8217 18.7538 16.5187 18.8178 16.3203 19.0082C16.3181 19.0102 16.316 19.0118 16.3139 19.014L13.9611 21.3302H12.0433C11.6564 21.3302 11.3427 21.639 11.3427 22.0199V23.5039H10.6561C10.2692 23.5039 9.9555 23.8126 9.9555 24.1935V24.8695H8.24242C7.85557 24.8695 7.54187 25.1783 7.54187 25.5592V27.6513L6.55568 28.6207H3.40122V27.6461L11.9639 19.2165C12.2101 18.9741 12.2101 18.5811 11.9639 18.3387C11.7176 18.0964 11.3186 18.0963 11.0721 18.3387L3.40122 25.8905V24.6445L12.7169 15.4729C12.719 15.4709 12.7206 15.469 12.7226 15.4669C12.916 15.2718 12.9812 14.9734 12.8631 14.7083C12.4067 13.6839 12.1754 12.5922 12.1754 11.4637C12.1754 7.00599 15.8592 3.37939 20.3873 3.37939C24.9154 3.37939 28.5991 7.00594 28.5991 11.4637C28.599 15.9213 24.9151 19.5478 20.3871 19.5478Z"
        fill="#635F76"
      />
    </svg>
  );
};
