import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Suitcase } from "../../Assets/svgs/Suitcase";
import { BlockSteps } from "../../Components/BlockSteps/BlockSteps";
import { BlockTitle } from "../../Components/BlockTitle/BlockTitle";
import { CustomButton } from "../../Components/CustomButton/CustomButton";
import { Instruction } from "../../Components/Instruction/Instruction";
import { Subtitle } from "../../Components/Subtitle/Subtitle";
import { TipoRamos } from "../../model/ramos";
import { confirmarFormulario, postRespuesta } from "../LayoutPregunta/PreguntasAPI";
import {
  changeValue,
  fetchPreguntaAnterior,
  selectId,
  selectPreguntas,
} from "../LayoutPregunta/preguntaSlice";
import "./LayoutConfirmar.scss";
import { TipoItem } from "../../model/tipoItemEnum";
import TextArea from "../../Components/TextArea/TextArea";
import { ModalConexion } from "../../Components/ModalConexion/ModalConexion";

export const LayoutConfirmar = () => {
  const dispatch = useDispatch();
  const preguntas = useSelector(selectPreguntas);
  const preguntaId = useSelector(selectId);
  const navigator = useNavigate();
  const [ramo, setRamo] = useState("")
  const [loading, setLoading] = useState(false)
  const [noConnection, setNoConnection] = useState(false)

  const handleConfirm = async () => {
    setLoading(true)
    const responsePregunta = await postRespuesta(preguntas);
    if (responsePregunta.status === 200) {
      const responseConfirmar: any = await confirmarFormulario(preguntaId);
      if (responseConfirmar.data.resultCode === 0) {
        navigator("/finalizado");
        setLoading(false)
      }
    }
  };

  useEffect(() => {
    const ramoId = localStorage.getItem("ramoId");
    if(ramoId)
      ramoName(ramoId)
  }, [])

  const ramoName = (ramoId: any) => {
    switch (ramoId) {
      case TipoRamos.Hogar: {
        setRamo("VIVIENDA");
        return;
      }
      case TipoRamos.Comercio: {
        setRamo("COMERCIO");
        return;
      }
    }
  };

  const handleNotaChange = (value: string, index: number) => {
    dispatch(changeValue({ value: value, index: index }))
  }


  const renderInputs = (e: any, index: number) => {
    switch (e.tipoItem) {
      case TipoItem.TextArea: {
        return <TextArea consigna={e.consigna} etiqueta={e.etiqueta} onInputChange={handleNotaChange} index={index} defaultValue={e.valor}/>;
      }
    }
  };
  
  const handleContinue = () => {
    const online = window.navigator.onLine
    if(!online) {
      setNoConnection(true);
      return; 
    }
    if (loading) {
      return () => null;
    } else {
      return handleConfirm()
    }
  }

  return (
    <div className="layout-confirmar">
      <div className="layout-confirmar-content">
        <BlockTitle text={preguntas.titulo} icon={preguntas.iconoPrincipal} />
        <div className="layout-confirmar-content-titles">
          <Subtitle text={preguntas.titulo1} />
          <Instruction text={preguntas.texto} />
        </div>
        {preguntas.items?.map((e: any, index: number) => {
          return <Fragment key={e.id}>{renderInputs(e, index)}</Fragment>;
        })}
      </div>
      <div className="layout-confirmar-actions">
        <CustomButton
          type="primary"
          text="Confirmar"
          loading={loading}
          onClick={handleContinue}
        />
      </div>
      <ModalConexion open={noConnection} onClick={(e: boolean) => setNoConnection(e)} />
    </div>
  );
};
