import React from "react";

const Garage = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.547 23.6553C22.3127 23.6553 22.1158 23.8553 22.1158 24.1153C22.1158 24.3753 22.3033 24.5753 22.547 24.5753C23.1377 24.5753 23.6158 25.0853 23.6158 25.7153V28.1853H10.3408V25.7153C10.3408 25.0853 10.8189 24.5753 11.4095 24.5753C11.6439 24.5753 11.8408 24.3753 11.8408 24.1153C11.8408 23.8553 11.6533 23.6553 11.4095 23.6553C10.3502 23.6553 9.48767 24.5753 9.48767 25.7053V28.2053C9.48767 28.6853 9.8533 29.0753 10.3033 29.0753H23.6533C24.1033 29.0753 24.4689 28.6853 24.4689 28.2053V25.7053C24.4689 24.5753 23.6064 23.6553 22.547 23.6553Z"
        fill="#635F76"
      />
      <path
        d="M13.622 30.7453H11.6158V29.5453H10.7626V30.8453C10.7626 31.2953 11.1095 31.6553 11.522 31.6553H13.7064C14.1283 31.6553 14.4658 31.2853 14.4658 30.8453V29.5453H13.6126V30.7453H13.622Z"
        fill="#635F76"
      />
      <path
        d="M22.3408 30.7453H20.3345V29.5453H19.4814V30.8453C19.4814 31.2953 19.8283 31.6553 20.2408 31.6553H22.4251C22.847 31.6553 23.1845 31.2853 23.1845 30.8453V29.5453H22.3314V30.7453H22.3408Z"
        fill="#635F76"
      />
      <path
        d="M12.1126 26.4853H13.8376C14.072 26.4853 14.2689 26.2853 14.2689 26.0253C14.2689 25.7653 14.0814 25.5653 13.8376 25.5653H12.1126C11.8783 25.5653 11.6814 25.7653 11.6814 26.0253C11.6814 26.2853 11.8689 26.4853 12.1126 26.4853Z"
        fill="#635F76"
      />
      <path
        d="M21.8439 26.4853C22.0782 26.4853 22.2751 26.2853 22.2751 26.0253C22.2751 25.7653 22.0876 25.5653 21.8439 25.5653H20.1189C19.8845 25.5653 19.6876 25.7653 19.6876 26.0253C19.6876 26.2853 19.8751 26.4853 20.1189 26.4853H21.8439Z"
        fill="#635F76"
      />
      <path
        d="M24.047 21.8353H22.2189L20.9908 19.8553C20.6908 19.3753 20.1845 19.0853 19.6314 19.0853H14.3533C13.8001 19.0853 13.2845 19.3753 12.9939 19.8553L11.7658 21.8353H9.90952C9.67515 21.8353 9.47827 22.0353 9.47827 22.2953C9.47827 22.5553 9.66577 22.7553 9.90952 22.7553H11.7751C11.8876 22.7553 11.9814 22.7953 12.0658 22.8853C12.0845 22.9053 12.1033 22.9153 12.122 22.9353L12.8345 23.4553C12.9845 23.5953 13.1626 23.6653 13.3595 23.6653H20.6064C20.8033 23.6653 20.9908 23.5853 21.1314 23.4553L21.8439 22.9353C21.8439 22.9353 21.8814 22.9053 21.9001 22.8853C21.9751 22.8053 22.0783 22.7553 22.1908 22.7553H24.0564C24.2908 22.7553 24.4876 22.5553 24.4876 22.2953C24.4876 22.0353 24.3001 21.8353 24.0564 21.8353H24.047ZM21.3283 22.1953L20.6251 22.7153C20.6251 22.7153 20.597 22.7353 20.5876 22.7453H13.3876C13.3876 22.7453 13.3595 22.7253 13.3501 22.7153L12.647 22.1953C12.647 22.1953 12.6189 22.1753 12.6001 22.1653L13.7158 20.3653C13.8564 20.1453 14.1001 20.0053 14.3626 20.0053H19.6408C19.9033 20.0053 20.1564 20.1453 20.2876 20.3653L21.3939 22.1553C21.3939 22.1553 21.347 22.1753 21.3283 22.1953Z"
        fill="#635F76"
      />
      <path
        d="M25.4439 16.2853H8.5033V17.1953H25.4439V16.2853Z"
        fill="#635F76"
      />
      <path
        d="M25.4439 14.2354H8.5033V15.1454H25.4439V14.2354Z"
        fill="#635F76"
      />
      <path
        d="M31.772 11.6553L17.1939 2.33532C17.0626 2.24532 16.8939 2.24532 16.7533 2.33532L2.18452 11.6553C1.97827 11.7853 1.91264 12.0653 2.03452 12.2853C2.11889 12.4253 2.25952 12.5053 2.40014 12.5053C2.47514 12.5053 2.55014 12.4853 2.61577 12.4453L3.80639 11.6853V31.2053C3.80639 31.4553 3.99389 31.6653 4.23764 31.6653H7.22827C7.46264 31.6653 7.65952 31.4653 7.65952 31.2053V13.0953H26.3158V31.2053C26.3158 31.4553 26.5033 31.6653 26.747 31.6653H29.7376C29.972 31.6653 30.1689 31.4653 30.1689 31.2053V11.6853L31.3408 12.4353C31.547 12.5653 31.8095 12.4953 31.9314 12.2753C32.0533 12.0553 31.9876 11.7753 31.7814 11.6453L31.772 11.6553ZM29.3064 30.7453H27.1689V12.6353C27.1689 12.3853 26.9814 12.1753 26.7376 12.1753H7.22827C6.99389 12.1753 6.79702 12.3753 6.79702 12.6353V30.7453H4.66889V11.1353L16.9783 3.26532L29.3064 11.1453V30.7453Z"
        fill="#635F76"
      />
    </svg>
  );
};

export default Garage;
