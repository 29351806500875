import React from "react";

const Yeso = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="12.8134"
        height="21.0048"
        rx="0.2"
        transform="matrix(0.866025 -0.5 0.866025 0.5 1.70691 9.25659)"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinejoin="round"
      />
      <path
        d="M19.8975 19.9412C19.8975 19.8308 19.975 19.6964 20.0707 19.6412L30.6478 13.5345C30.8391 13.424 30.9942 13.5136 30.9942 13.7345L30.9942 14.9422C30.9942 15.0527 30.9167 15.187 30.821 15.2422L20.0707 21.449C19.975 21.5042 19.8975 21.4594 19.8975 21.349L19.8975 19.9412Z"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinejoin="round"
      />
      <path
        d="M1.70691 9.62839C1.70691 9.40748 1.862 9.31794 2.05332 9.42839L19.7244 19.6308C19.82 19.686 19.8976 19.8203 19.8976 19.9308L19.8976 21.3862C19.8976 21.4966 19.82 21.5414 19.7244 21.4862L1.88011 11.1838C1.78446 11.1286 1.70691 10.9942 1.70691 10.8838L1.70691 9.62839Z"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.36097 9.39917C1.26889 9.24969 1.32647 9.07635 1.53371 8.9567L12.284 2.75C12.571 2.58431 13.0363 2.58431 13.3233 2.75L31.1675 13.0524C31.3751 13.1722 31.4325 13.3459 31.3399 13.4955C31.3405 13.5083 31.3407 13.5214 31.3407 13.5346V14.7424C31.3407 15.0738 31.1081 15.4767 30.8211 15.6424L20.2158 21.7653C20.1512 21.945 19.9818 22.0079 19.7773 21.9138C19.7272 21.903 19.6834 21.8788 19.6481 21.8422L1.88012 11.5839C1.59315 11.4182 1.36051 11.0153 1.36051 10.6839L1.36051 9.42849C1.36051 9.41859 1.36066 9.40881 1.36097 9.39917ZM19.8823 19.3502C19.8325 19.3032 19.7794 19.2627 19.7244 19.2309L2.42417 9.2426L12.8037 3.25L30.2861 13.3435L20.0707 19.2414C20.0273 19.2665 19.9851 19.297 19.9447 19.3319L19.8976 19.3591L19.8823 19.3502ZM20.244 20.9491V20.1309C20.244 20.1284 20.244 20.126 20.2439 20.1235V19.9414L20.2758 19.923C20.3262 19.9055 20.3738 19.8842 20.4172 19.8591L30.6479 13.9524V14.9424L20.244 20.9491ZM2.05333 9.8567V10.8839L19.5511 20.9862V20.1414C19.5511 20.1389 19.5511 20.1363 19.5512 20.1338V19.9334C19.4889 19.9142 19.4305 19.8894 19.378 19.8591L2.05333 9.8567Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.617 16.5664L30.3439 17.5634L20.0565 23.5028C20.0148 23.5269 19.9744 23.5564 19.9359 23.5902L19.8978 23.6122L19.8897 23.6075C19.8423 23.5619 19.7917 23.5228 19.739 23.4924L2.36673 13.4625L4.04782 12.4919L3.67734 12.2766L3.41302 12.1249L1.54831 13.2015C1.35831 13.3112 1.3055 13.4701 1.38987 13.6071C1.38963 13.6153 1.38951 13.6235 1.38951 13.6319L1.38951 14.9371C1.38951 15.241 1.60283 15.6104 1.86597 15.7624L19.6765 26.0452C19.7042 26.0715 19.7371 26.09 19.7741 26.1001C19.9691 26.1963 20.1315 26.1382 20.1911 25.9664L30.8356 19.8208C31.0988 19.6688 31.3121 19.2994 31.3121 18.9955V17.7379C31.3121 17.7263 31.3118 17.7148 31.3114 17.7035C31.3964 17.5663 31.3437 17.4071 31.1534 17.2972L29.2543 16.2007C29.0475 16.3192 28.8346 16.4414 28.617 16.5664ZM20.2155 24.3176V25.2188L30.6768 19.1789V18.1225L20.3743 24.0707C20.3326 24.0947 20.2868 24.115 20.2383 24.1314L20.2153 24.1447V24.3036L20.2155 24.3176ZM2.02479 15.1205V14.0267L19.4214 24.0706C19.4695 24.0984 19.5231 24.1211 19.5802 24.1388V24.314L19.58 24.328V25.256L2.02479 15.1205Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.617 20.9981L30.3439 21.9951L20.0565 27.9346C20.0148 27.9586 19.9744 27.9881 19.9359 28.022L19.8978 28.0439L19.8897 28.0392C19.8423 27.9937 19.7917 27.9546 19.739 27.9242L2.36673 17.8943L4.04782 16.9237L3.67734 16.7084L3.41302 16.5566L1.54831 17.6332C1.35831 17.7429 1.3055 17.9018 1.38987 18.0389C1.38963 18.047 1.38951 18.0553 1.38951 18.0636L1.38951 19.3689C1.38951 19.6727 1.60283 20.0422 1.86597 20.1941L19.6765 30.477C19.7042 30.5033 19.7371 30.5218 19.7741 30.5319C19.9691 30.6281 20.1315 30.57 20.1911 30.3982L30.8356 24.2525C31.0988 24.1006 31.3121 23.7311 31.3121 23.4273V22.1697C31.3121 22.158 31.3118 22.1465 31.3114 22.1353C31.3964 21.9981 31.3437 21.8388 31.1534 21.7289L29.2543 20.6325C29.0475 20.751 28.8346 20.8731 28.617 20.9981ZM20.2155 28.7494V29.6505L30.6768 23.6107V22.5542L20.3743 28.5024C20.3326 28.5265 20.2868 28.5467 20.2383 28.5632L20.2153 28.5764V28.7353L20.2155 28.7494ZM2.02479 19.5523V18.4585L19.4214 28.5024C19.4695 28.5302 19.5231 28.5529 19.5802 28.5705V28.7457L19.58 28.7598V29.6878L2.02479 19.5523Z"
        fill="#635F76"
      />
    </svg>
  );
};

export default Yeso;
