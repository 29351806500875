import React from "react";

const Volumetrico = () => {
  return (
    <svg
      width="61"
      height="77"
      viewBox="0 0 61 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.0976 70.8056C27.2359 70.8056 23.5324 69.2678 20.8018 66.5304C18.0712 63.793 16.5371 60.0804 16.5371 56.2091V47.2035C16.5371 46.5104 16.6738 45.8242 16.9394 45.1843C17.205 44.5444 17.5942 43.9635 18.0846 43.475C18.575 42.9864 19.1569 42.6 19.7969 42.3378C20.4369 42.0757 21.1223 41.943 21.8136 41.9474H40.3816C41.073 41.943 41.7583 42.0757 42.3983 42.3378C43.0383 42.6 43.6203 42.9864 44.1107 43.475C44.6011 43.9635 44.9903 44.5444 45.2559 45.1843C45.5214 45.8242 45.6582 46.5104 45.6581 47.2035V56.2091C45.6581 60.0804 44.1241 63.793 41.3935 66.5304C38.6628 69.2678 34.9593 70.8056 31.0976 70.8056ZM21.8136 44.3913C21.0723 44.4 20.3638 44.699 19.8396 45.2246C19.3154 45.7501 19.0171 46.4603 19.0084 47.2035V56.2091C19.0084 59.4233 20.2821 62.5058 22.5492 64.7786C24.8164 67.0514 27.8914 68.3282 31.0976 68.3282C34.3039 68.3282 37.3788 67.0514 39.646 64.7786C41.9132 62.5058 43.1869 59.4233 43.1869 56.2091V47.2035C43.1782 46.4603 42.8799 45.7501 42.3556 45.2246C41.8314 44.699 41.1229 44.4 40.3816 44.3913H21.8136Z"
        fill="#D0DFFB"
      />
      <path
        d="M30.7975 76.4309C25.2499 76.4309 19.929 74.2239 16.0031 70.2946C12.0772 66.3653 9.86725 61.0348 9.8584 55.4735V5.25609C9.87597 3.85596 10.4432 2.51917 11.4371 1.53534C12.431 0.551508 13.7715 -0.000110761 15.1683 1.66819e-08H46.3933C47.8015 1.66819e-08 49.1521 0.560819 50.1479 1.55908C51.1437 2.55735 51.7032 3.91128 51.7032 5.32304V55.5404C51.6767 61.0843 49.4636 66.3926 45.5468 70.3065C41.63 74.2204 36.3278 76.4221 30.7975 76.4309ZM15.1683 2.37696C14.4301 2.38577 13.7251 2.68593 13.2063 3.21237C12.6874 3.7388 12.3964 4.44906 12.3965 5.18913V55.4065C12.6788 60.098 14.8606 65.2748 18.2742 68.4966C21.6878 71.7184 26.1091 73.2946 30.7975 73.2946C35.4859 73.2946 39.9074 71.7184 43.3209 68.4966C46.7345 65.2748 48.8494 60.098 49.1317 55.4065V5.18913C49.1318 4.44906 48.8408 3.7388 48.3219 3.21237C47.803 2.68593 47.0981 2.38577 46.3599 2.37696H15.1683Z"
        fill="#D0DFFB"
      />
      <path
        d="M30.9975 24.4725C30.1785 24.4725 29.3779 24.2291 28.6969 23.7729C28.0159 23.3168 27.4851 22.6684 27.1717 21.9099C26.8582 21.1513 26.7762 20.3166 26.936 19.5113C27.0958 18.7061 27.4902 17.9664 28.0693 17.3858C28.6485 16.8052 29.3863 16.4099 30.1896 16.2497C30.9929 16.0895 31.8255 16.1717 32.5822 16.4859C33.3389 16.8001 33.9857 17.3322 34.4407 18.0149C34.8957 18.6976 35.1386 19.5002 35.1386 20.3212C35.1386 21.4222 34.7023 22.4781 33.9257 23.2566C33.1491 24.0352 32.0958 24.4725 30.9975 24.4725ZM30.9975 18.6808C30.6739 18.6808 30.3575 18.777 30.0884 18.9573C29.8193 19.1375 29.6095 19.3937 29.4857 19.6935C29.3618 19.9932 29.3294 20.323 29.3926 20.6413C29.4557 20.9595 29.6116 21.2518 29.8404 21.4812C30.0693 21.7106 30.3608 21.8668 30.6783 21.9301C30.9957 21.9934 31.3247 21.961 31.6237 21.8368C31.9227 21.7126 32.1783 21.5024 32.3581 21.2326C32.5379 20.9628 32.6339 20.6457 32.6339 20.3212C32.6339 19.8862 32.4615 19.4689 32.1546 19.1613C31.8477 18.8536 31.4315 18.6808 30.9975 18.6808Z"
        fill="#D0DFFB"
      />
      <path
        d="M9.55764 74.4558L10.6931 74.8575L9.8916 77.0001L8.75615 76.5984C5.50101 75.4008 2.83791 72.9814 1.32976 69.8514C-0.178385 66.7215 -0.413401 63.1263 0.674392 59.8258L1.24212 58.6875L3.51302 59.491L3.11228 60.6292C2.144 63.3199 2.27859 66.2855 3.48658 68.8769C4.69458 71.4682 6.87756 73.4743 9.55764 74.4558Z"
        fill="#D0DFFB"
      />
      <path
        d="M7.48708 62.2369C7.23011 62.9886 7.12397 63.7838 7.17475 64.5767C7.22553 65.3696 7.43224 66.1447 7.78297 66.8572C8.13371 67.5697 8.62154 68.2057 9.21841 68.7285C9.81527 69.2513 10.5094 69.6506 11.2608 69.9035L12.3962 70.3052L11.5947 72.5817L10.4593 72.18C9.41574 71.8157 8.45434 71.2482 7.63039 70.51C6.80643 69.7719 6.1362 68.8778 5.65823 67.879C5.18026 66.8803 4.90399 65.7966 4.84532 64.6905C4.78665 63.5843 4.94673 62.4774 5.31636 61.4335L5.61692 60.2617L7.88783 61.0652L7.48708 62.2369Z"
        fill="#D0DFFB"
      />
      <path
        d="M58.0148 60.6292L57.6141 59.491L59.885 58.6875L60.2857 59.8258C61.4138 63.1435 61.1993 66.7723 59.6883 69.9332C58.1773 73.0942 55.4902 75.5352 52.204 76.7323L51.3357 77.0001L50.5342 74.7236L51.6696 74.3218C54.289 73.306 56.4136 71.3118 57.5968 68.7583C58.7801 66.2049 58.9299 63.2911 58.0148 60.6292Z"
        fill="#D0DFFB"
      />
      <path
        d="M53.1724 61.0652L55.4433 60.2617L55.844 61.4C56.5824 63.5134 56.4559 65.834 55.4921 67.8541C54.5283 69.8743 52.8057 71.4296 50.7011 72.18L49.5657 72.5817L48.7642 70.3052L49.8996 69.9035C51.4014 69.3654 52.6301 68.2541 53.3182 66.8119C54.0063 65.3696 54.0979 63.7132 53.5731 62.2035L53.1724 61.0652Z"
        fill="#D0DFFB"
      />
    </svg>
  );
};

export default Volumetrico;
