import React from "react";

const Ciclomotor = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.24727 24.3685H4.00448C3.7455 24.3685 3.53564 24.1587 3.53564 23.8997V20.6707C3.53564 17.3196 6.2616 14.5937 9.61192 14.5937H15.0638C15.3228 14.5937 15.5327 14.8035 15.5327 15.0625V21.2854H19.4821C21.0925 21.2854 22.4022 19.9756 22.4022 18.3652V10.072C22.4022 9.81301 22.6121 9.60315 22.8711 9.60315H24.3617C24.5842 9.60315 24.7755 9.75943 24.8209 9.97673L26.8071 19.5723C26.813 19.5998 26.816 19.6273 26.8168 19.6556L26.8302 20.1922C26.8369 20.4511 26.6322 20.6662 26.3732 20.6729C26.3695 20.6729 26.365 20.6729 26.3613 20.6729C26.1075 20.6729 25.8992 20.4705 25.8932 20.216L25.8806 19.7218L23.9799 10.5416H23.3399V18.3659C23.3399 20.4928 21.6097 22.2238 19.4821 22.2238H15.0638C14.8049 22.2238 14.595 22.0139 14.595 21.755V15.5321H9.61192C6.77881 15.5321 4.47332 17.8376 4.47332 20.6714V23.4316H5.24727C5.50625 23.4316 5.71611 23.6415 5.71611 23.9005C5.71611 24.1594 5.50625 24.3685 5.24727 24.3685ZM22.8711 24.3685H12.7353C12.4763 24.3685 12.2664 24.1587 12.2664 23.8997C12.2664 23.6407 12.4763 23.4309 12.7353 23.4309H22.6493L22.8093 23.2359C22.9738 23.0357 23.2692 23.0067 23.4694 23.1712C23.6696 23.3356 23.6986 23.6311 23.5342 23.8312L23.2335 24.1974C23.1442 24.306 23.0117 24.3685 22.8711 24.3685Z"
        fill="#635F76"
      />
      <path
        d="M27.2238 10.4941H26.0673C25.1713 10.4941 24.442 9.76483 24.442 8.86883C24.442 7.97283 25.1713 7.24353 26.0673 7.24353H27.2238C27.4828 7.24353 27.6926 7.45339 27.6926 7.71237V10.026C27.6926 10.2843 27.4828 10.4941 27.2238 10.4941ZM26.0673 8.18046C25.6878 8.18046 25.3797 8.4893 25.3797 8.86809C25.3797 9.24688 25.6885 9.55572 26.0673 9.55572H26.7549V8.18046H26.0673Z"
        fill="#635F76"
      />
      <path
        d="M24.0275 10.4939H21.7138C21.4549 10.4939 21.245 10.284 21.245 10.0251V7.71139C21.245 7.45241 21.4549 7.24255 21.7138 7.24255H24.0275C24.2865 7.24255 24.4963 7.45241 24.4963 7.71139V10.0251C24.4963 10.284 24.2865 10.4939 24.0275 10.4939ZM22.1834 9.55623H23.5594V8.18023H22.1834V9.55623Z"
        fill="#635F76"
      />
      <path
        d="M15.0637 15.5321H11.9486C11.6896 15.5321 11.4797 15.3222 11.4797 15.0633V12.9825C11.4797 12.7235 11.6896 12.5137 11.9486 12.5137H15.0637C15.3227 12.5137 15.5326 12.7235 15.5326 12.9825V15.0633C15.5326 15.3222 15.3227 15.5321 15.0637 15.5321ZM12.4167 14.5944H14.5942V13.4513H12.4167V14.5944Z"
        fill="#635F76"
      />
      <path
        d="M26.3792 26.4016C25.0195 26.4016 23.9107 25.2958 23.9077 23.9361C23.9077 23.8357 23.9888 23.7538 24.0893 23.7531C24.0893 23.7531 24.0893 23.7531 24.09 23.7531C24.1905 23.7531 24.2724 23.8342 24.2724 23.9346C24.2753 25.0941 25.2205 26.037 26.3792 26.037C27.5379 26.037 28.483 25.0941 28.4859 23.9346C28.4859 23.8342 28.5678 23.7531 28.6683 23.7531C28.6683 23.7531 28.6683 23.7531 28.669 23.7531C28.7695 23.7531 28.8513 23.8349 28.8506 23.9361C28.8476 25.2958 27.7388 26.4016 26.3792 26.4016Z"
        fill="#635F76"
      />
      <path
        d="M29.5613 24.082H23.1717C23.0712 24.082 22.9894 24.0001 22.9894 23.8997V23.3988C22.9894 21.5369 24.5045 20.0217 26.3665 20.0217C28.2284 20.0217 29.7436 21.5369 29.7436 23.3988V23.8997C29.7436 24.0009 29.6618 24.082 29.5613 24.082ZM23.354 23.7174H29.379V23.3988C29.379 21.7378 28.0275 20.3864 26.3665 20.3864C24.7055 20.3864 23.354 21.7378 23.354 23.3988V23.7174Z"
        fill="#635F76"
      />
      <path
        d="M26.3792 26.6881C24.8618 26.6881 23.6249 25.4535 23.6212 23.9361C23.6205 23.6772 23.8303 23.4666 24.0893 23.4666H24.0901C24.3483 23.4666 24.5581 23.6757 24.5589 23.9346C24.5611 24.9363 25.3775 25.7512 26.3792 25.7512C27.3808 25.7512 28.1972 24.9363 28.1994 23.9346C28.2002 23.6764 28.4101 23.4666 28.6683 23.4666H28.669C28.928 23.4673 29.1371 23.6772 29.1371 23.9361C29.1334 25.4535 27.8966 26.6881 26.3792 26.6881Z"
        fill="#635F76"
      />
      <path
        d="M29.5613 24.3685H23.1717C22.9127 24.3685 22.7029 24.1587 22.7029 23.8997V23.3989C22.7029 21.3791 24.346 19.7352 26.3665 19.7352C28.3862 19.7352 30.0301 21.3784 30.0301 23.3989V23.8997C30.0301 24.1587 29.8203 24.3685 29.5613 24.3685ZM23.6406 23.4309H29.0925V23.3989C29.0925 21.8956 27.8698 20.6729 26.3665 20.6729C24.8633 20.6729 23.6406 21.8956 23.6406 23.3989V23.4309Z"
        fill="#635F76"
      />
      <path
        d="M9.18471 26.6524C7.66732 26.6524 6.43048 25.4178 6.42676 23.9011C6.42602 23.6421 6.63588 23.4315 6.89485 23.4315H6.8956C7.15383 23.4315 7.36369 23.6406 7.36443 23.8996C7.36667 24.9013 8.18304 25.7154 9.18471 25.7154C10.1864 25.7154 11.0028 24.9005 11.005 23.8996C11.0057 23.6414 11.2156 23.4315 11.4738 23.4315H11.4746C11.7336 23.4323 11.9427 23.6421 11.9427 23.9011C11.9397 25.4178 10.7021 26.6524 9.18471 26.6524Z"
        fill="#635F76"
      />
      <path
        d="M12.3795 24.3685H5.98996C5.73098 24.3685 5.52112 24.1587 5.52112 23.8997V23.3989C5.52112 21.3791 7.16428 19.7352 9.18475 19.7352C11.2052 19.7352 12.8484 21.3784 12.8484 23.3989V23.8997C12.8484 24.1587 12.6385 24.3685 12.3795 24.3685ZM6.45879 23.4309H11.9107V23.3989C11.9107 21.8956 10.688 20.6729 9.18475 20.6729C7.68149 20.6729 6.45879 21.8956 6.45879 23.3989V23.4309Z"
        fill="#635F76"
      />
      <path
        d="M11.8794 15.5321H3.93002C3.67104 15.5321 3.46118 15.3222 3.46118 15.0632V11.6988C3.46118 9.25784 5.44741 7.27161 7.88834 7.27161H11.8794C12.1384 7.27161 12.3483 7.48147 12.3483 7.74044V15.0632C12.3483 15.3222 12.1384 15.5321 11.8794 15.5321ZM4.39886 14.5944H11.4106V8.20928H7.88834C5.96388 8.20928 4.39886 9.77505 4.39886 11.6988V14.5944Z"
        fill="#635F76"
      />
      <path
        d="M7.90179 11.4852H3.98886C3.72988 11.4852 3.52002 11.2753 3.52002 11.0163C3.52002 10.7573 3.72988 10.5475 3.98886 10.5475H7.90179C8.16076 10.5475 8.37062 10.7573 8.37062 11.0163C8.37062 11.2753 8.16076 11.4852 7.90179 11.4852Z"
        fill="#635F76"
      />
    </svg>
  );
};

export default Ciclomotor;
