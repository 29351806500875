import React from "react";

export const Map = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00016 6.00002C7.26378 6.00002 6.66683 6.59697 6.66683 7.33335C6.66683 8.06973 7.26378 8.66669 8.00016 8.66669C8.73654 8.66669 9.3335 8.06973 9.3335 7.33335C9.3335 6.59697 8.73654 6.00002 8.00016 6.00002ZM5.3335 7.33335C5.3335 5.86059 6.5274 4.66669 8.00016 4.66669C9.47292 4.66669 10.6668 5.86059 10.6668 7.33335C10.6668 8.80611 9.47292 10 8.00016 10C6.5274 10 5.3335 8.80611 5.3335 7.33335Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.66689C7.07703 2.66689 6.17479 2.94058 5.40737 3.45335C4.63994 3.96612 4.0418 4.69494 3.68859 5.54765C3.33537 6.40035 3.24294 7.33865 3.42299 8.24389C3.60303 9.1491 4.04743 9.98059 4.70002 10.6332M4.70002 10.6332L7.52917 13.4617C7.52923 13.4618 7.52911 13.4617 7.52917 13.4617C7.65416 13.5865 7.82373 13.6567 8.00034 13.6567C8.17703 13.6567 8.3465 13.5866 8.4715 13.4617L11.2999 10.6333C11.9525 9.98065 12.397 9.14913 12.577 8.24389C12.7571 7.33865 12.6646 6.40035 12.3114 5.54765C11.9582 4.69494 11.3601 3.96612 10.5926 3.45335C9.82522 2.94058 8.92297 2.66689 8 2.66689M12.2428 11.5761C13.0818 10.737 13.6533 9.66786 13.8847 8.50399C14.1162 7.34011 13.9974 6.13372 13.5433 5.03739C13.0891 3.94105 12.3201 3.00399 11.3334 2.34472C10.3467 1.68544 9.18668 1.33356 8 1.33356C6.81333 1.33356 5.6533 1.68544 4.66661 2.34472C3.67993 3.00399 2.91089 3.94105 2.45676 5.03739C2.00262 6.13372 1.88378 7.34011 2.11527 8.50399C2.34675 9.66786 2.91817 10.737 3.75725 11.5761L6.58665 14.4048C6.96167 14.7795 7.47025 14.9901 8.00034 14.9901C8.53042 14.9901 9.03882 14.7796 9.41384 14.405L12.2428 11.5761Z"
        fill="#635F76"
      />
    </svg>
  );
};
