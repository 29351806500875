import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlockTitle } from "../../Components/BlockTitle/BlockTitle";
import { CustomButton } from "../../Components/CustomButton/CustomButton";
import { CustomInput } from "../../Components/CustomInput/CustomInput";
import { Description } from "../../Components/Description/Description";
import { ModalAyuda } from "../../Components/ModalAyuda/ModalAyuda";
import { ModalEspera } from "../../Components/ModalEspera/ModalEspera";
import { Notification } from "../../Components/Notification/Notification";
import { SubBlockTitle } from "../../Components/SubBlockTitle/SubBlockTitle";
import { Subtitle } from "../../Components/Subtitle/Subtitle";
import { TipoItem } from "../../model/tipoItemEnum";
import { postRespuesta } from "../LayoutPregunta/PreguntasAPI";
import {
  getPreguntas,
  obtenerPreguntasStatus,
  selectBienes,
  selectId,
  selectPreguntas,
  selectStatusGetPreguntas,
  selectUploadStatus,
  validarCamposObligatorios
} from "../LayoutPregunta/preguntaSlice";
import "./LayoutContacto.scss";
import { ModalConexion } from "../../Components/ModalConexion/ModalConexion";

const LayoutContacto = () => {
  const dispatch = useDispatch();
  const preguntas = useSelector(selectPreguntas);
  const preguntaId: any = useSelector(selectId);
  const [openAyuda, setOpenAyuda] = useState(false);
  const [loading, setLoading] = useState(false);
  const statusSiguientePregunta = useSelector(selectStatusGetPreguntas);
  const [data, setData] = useState({movil: null, mail: null})
  const [noConnection, setNoConnection] = useState(false)
  const containerRef = useRef<any>();

  useEffect(() => {
    window.scrollTo(0, 0);
    containerRef?.current?.scrollTo(0, 0);
    //@ts-ignore
    dispatch(obtenerPreguntasStatus(preguntaId));
  }, [preguntas.id]);

  const handleSiguiente = async () => {
      setLoading(true)
      const response = await postRespuesta(preguntas);
      if (response.status === 200){
        // @ts-ignore
        dispatch(getPreguntas(preguntaId));
      }
      setLoading(false)
  };

  useEffect(() => {
    if (statusSiguientePregunta !== "loading") setLoading(false);
  }, [statusSiguientePregunta]);

  const handleMailChange = (e: any) => {
    setData((prevState: any) => {return {...prevState, mail: e}})
  }

  const handleMovilChange = (e: any) => {
    setData((prevState: any) => {return {...prevState, movil: e}})
  }

  const renderInputs = (e: any, index: number) => {
    switch (e.tipoItem) {
      case TipoItem.InputMovil: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <CustomInput
              type="tel"
              key={e.keyId}
              index={index}
              label={e.placeholder}
              prefetchedValue={e.valor}
              onChange={handleMovilChange}
            />
          </>
        );
      }
      case TipoItem.InputMail: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <CustomInput
              type="email"
              key={e.keyId}
              index={index}
              label={e.placeholder}
              prefetchedValue={e.valor}
              onChange={handleMailChange}
            />
          </>
        );
      }
    }
  }

  const renderContent = () => {
    return (
      <>
        <div>
          <div className="layout-pregunta-content-title">
            <BlockTitle
              text={preguntas.titulo}
              icon={preguntas.iconoPrincipal}
              ayuda={preguntas.ayuda ? preguntas.ayuda : null}
              fn={setOpenAyuda}
            />
          </div>
          {preguntas.subtitulo ? <Subtitle text={preguntas.subtitulo} /> : null}
        </div>
        {preguntas.titulo1 ? <SubBlockTitle text={preguntas.titulo1} /> : null}
        {preguntas.texto ? <Description text={preguntas.texto} /> : null}
        {preguntas.items?.map((e: any, index: number) => {
          return <Fragment key={e.id}>{renderInputs(e, index)}</Fragment>;
        })}
        {preguntas.tip ? (
          <Notification text={preguntas.tip} icon={preguntas.iconoTip} />
        ) : null}
      </>
    );
  };

  const disableInput = !data.mail && !data.movil

  const handleContinue = () => {
    const online = window.navigator.onLine
    if(!online) {
      setNoConnection(true);
      return; 
    }
    if (loading) {
      return () => null;
    } else {
      return handleSiguiente()
    }
  }

  return (
    <>
      <div className="layout-pregunta" ref={containerRef}>
        <div className="layout-pregunta-content">{renderContent()}</div>
      </div>
      <div className="layout-pregunta-content-actions">
        <CustomButton
          type="primary"
          text="Continuar"
          loading={loading}
          disable={disableInput}
          onClick={handleContinue}
        />
        {openAyuda ? (
          <ModalAyuda
            open={openAyuda}
            texto={preguntas.ayuda.texto}
            titulo={preguntas.ayuda.titulo}
            imagen={preguntas.ayuda.imagen}
            onClose={() => setOpenAyuda(false)}
          />
        ) : null}
        <ModalConexion open={noConnection} onClick={(e: boolean) => setNoConnection(e)} />
      </div>
    </>
  );
};

export default LayoutContacto;
