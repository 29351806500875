import React from "react";

const PuertaMetalica = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_994_1382)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.172119 31.0201C0.172119 30.7977 0.352407 30.6174 0.574803 30.6174H32.7895C33.0119 30.6174 33.1922 30.7977 33.1922 31.0201C33.1922 31.2425 33.0119 31.4228 32.7895 31.4228H0.574803C0.352407 31.4228 0.172119 31.2425 0.172119 31.0201Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.36682 3.56711C8.36682 3.34472 8.54711 3.16443 8.7695 3.16443H24.595C24.8174 3.16443 24.9977 3.34472 24.9977 3.56711V31.0201C24.9977 31.2425 24.8174 31.4227 24.595 31.4227H8.7695C8.54711 31.4227 8.36682 31.2425 8.36682 31.0201V3.56711ZM9.17219 3.9698V30.6174H24.1923V3.9698H9.17219Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.36682 17.2983C8.36682 17.0759 8.54711 16.8956 8.7695 16.8956H24.595C24.8174 16.8956 24.9977 17.0759 24.9977 17.2983V31.0198C24.9977 31.2421 24.8174 31.4224 24.595 31.4224H8.7695C8.54711 31.4224 8.36682 31.2421 8.36682 31.0198V17.2983ZM9.17219 17.701V30.6171H24.1923V17.701H9.17219Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.58484 1.82554C6.58484 1.60314 6.76513 1.42285 6.98752 1.42285H26.3767C26.5991 1.42285 26.7794 1.60314 26.7794 1.82554V31.0201C26.7794 31.2425 26.5991 31.4228 26.3767 31.4228H6.98752C6.76513 31.4228 6.58484 31.2425 6.58484 31.0201V1.82554ZM7.39021 2.22822V30.6174H25.974V2.22822H7.39021Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7036 14.7895C21.8493 14.6216 22.1037 14.6035 22.2716 14.7493L24.8589 16.9943C24.947 17.0708 24.9977 17.1818 24.9977 17.2985C24.9976 17.4152 24.947 17.5262 24.8588 17.6027L9.03331 31.3241C8.86528 31.4698 8.61096 31.4517 8.46527 31.2836C8.31957 31.1156 8.33768 30.8613 8.50572 30.7156L23.9804 17.2983L21.7438 15.3576C21.5758 15.2118 21.5578 14.9575 21.7036 14.7895Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.46531 3.30327C8.61103 3.13526 8.86536 3.1172 9.03336 3.26292L20.5702 13.2696C20.7382 13.4153 20.7563 13.6696 20.6106 13.8377C20.4649 14.0057 20.2105 14.0237 20.0425 13.878L8.50566 3.87132C8.33766 3.7256 8.31959 3.47127 8.46531 3.30327Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4719 20.5084C12.6176 20.3404 12.8719 20.3223 13.0399 20.468L24.8587 30.7163C25.0267 30.862 25.0448 31.1163 24.8991 31.2843C24.7534 31.4523 24.4991 31.4704 24.3311 31.3247L12.5123 21.0764C12.3443 20.9307 12.3262 20.6764 12.4719 20.5084Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.899 3.30304C25.0448 3.47093 25.0269 3.72527 24.859 3.87112L21.8691 6.46843L21.8682 6.46919L20.0976 7.99834L20.0964 7.99938L12.044 14.995L10.3326 16.4849L10.3308 16.4864L9.38531 17.3L11.2483 18.9174C11.4162 19.0632 11.4341 19.3175 11.2883 19.4854C11.1425 19.6534 10.8882 19.6713 10.7203 19.5255L8.50551 17.6027C8.41718 17.526 8.36656 17.4147 8.36682 17.2977C8.36708 17.1808 8.41819 17.0697 8.50686 16.9934L9.80375 15.8775L9.80551 15.8759L11.5152 14.3875L19.5688 7.39091L19.57 7.38986L21.341 5.86043L21.3418 5.85967L24.3309 3.26312C24.4988 3.11727 24.7531 3.13514 24.899 3.30304Z"
          fill="#635F76"
        />
        <path
          d="M23.5377 19.6745V14.9229H22.1686V19.6745H23.5377Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5376 14.5203C23.76 14.5203 23.9403 14.7006 23.9403 14.9229V19.6746C23.9403 19.897 23.76 20.0773 23.5376 20.0773H22.1685C21.9461 20.0773 21.7658 19.897 21.7658 19.6746V14.9229C21.7658 14.7006 21.9461 14.5203 22.1685 14.5203H23.5376ZM23.1349 15.3256H22.5712V19.2719H23.1349V15.3256Z"
          fill="#635F76"
        />
      </g>
      <defs>
        <clipPath id="clip0_994_1382">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.682129 0.422852)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PuertaMetalica;
