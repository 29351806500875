import React from "react";

const LocalVacio = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3647_10323)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.7294 2.05746C7.90178 1.98085 8.09854 1.98085 8.27092 2.05746L14.2709 4.72413C14.5117 4.83113 14.6668 5.06987 14.6668 5.33333V14C14.6668 14.3682 14.3684 14.6667 14.0002 14.6667C13.632 14.6667 13.3335 14.3682 13.3335 14V5.76658L8.00016 3.39621L2.66683 5.76658V14C2.66683 14.3682 2.36835 14.6667 2.00016 14.6667C1.63197 14.6667 1.3335 14.3682 1.3335 14V5.33333C1.3335 5.06987 1.48865 4.83113 1.7294 4.72413L7.7294 2.05746Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 8.66667C8 8.29848 8.29848 8 8.66667 8H11.3333C11.7015 8 12 8.29848 12 8.66667V14C12 14.3682 11.7015 14.6667 11.3333 14.6667H4.66667C4.29848 14.6667 4 14.3682 4 14V10C4 9.63181 4.29848 9.33333 4.66667 9.33333H8.66667C9.03486 9.33333 9.33333 9.63181 9.33333 10C9.33333 10.3682 9.03486 10.6667 8.66667 10.6667H5.33333V13.3333H10.6667V9.33333H8.66667C8.29848 9.33333 8 9.03486 8 8.66667Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.72402 7.05752C5.97407 6.80747 6.31321 6.66699 6.66683 6.66699H8.00016C8.35379 6.66699 8.69292 6.80747 8.94297 7.05752C9.19302 7.30756 9.3335 7.6467 9.3335 8.00033V14.0003C9.3335 14.3685 9.03502 14.667 8.66683 14.667C8.29864 14.667 8.00016 14.3685 8.00016 14.0003V8.00033L6.66683 8.00033L6.66683 10.0003C6.66683 10.3685 6.36835 10.667 6.00016 10.667C5.63197 10.667 5.3335 10.3685 5.3335 10.0003V8.00033C5.3335 7.6467 5.47397 7.30756 5.72402 7.05752Z"
          fill="#635F76"
        />
      </g>
      <defs>
        <clipPath id="clip0_3647_10323">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LocalVacio;
