import React, { useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { JsonAnimation } from "../../Components/JsonAnimation/JsonAnimation";
import "./CargaInvitacion.scss";
import { redirectToEvaluacion } from "../LayoutPregunta/PreguntasAPI";
import conf from "../../app/config";

const CargaInvitacion = () => {
  const [searchParams] = useSearchParams();
  const baseURL = conf('BASE_URL')
  const navigate = useNavigate()

  useEffect(() => {
    const params = searchParams.get("h");
    if (params) {
        const url = `${baseURL}invitacion/evaluacion?h=${params}`
        const redirectToUrl = async (url: string) => {
            const urlToRedirect = await redirectToEvaluacion(url);
            console.log(urlToRedirect);
            navigate(`/${urlToRedirect}`);
        }

        redirectToUrl(url)
    }
  }, []);

  return (
    <div className="carga-invitacion">
      <JsonAnimation
        animation="buttonLoaderBlue"
        width="137px"
        height="137px"
      />
      <p>Un momento, estamos preparando tu formulario inteligente.</p>
    </div>
  );
};

export default CargaInvitacion;
