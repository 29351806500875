import React from "react";

const ViviendaIndependiente = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.78087 25.7752C3.4274 25.7752 2.3277 24.6755 2.3277 23.322V17.9175C2.3277 16.5641 3.4274 15.4644 4.78087 15.4644C6.13434 15.4644 7.23404 16.5641 7.23404 17.9175V23.322C7.23404 24.6755 6.13434 25.7752 4.78087 25.7752ZM4.78087 16.4419C3.97254 16.4419 3.30521 17.0998 3.30521 17.9175V23.322C3.30521 24.1303 3.96315 24.7977 4.78087 24.7977C5.59859 24.7977 6.25653 24.1397 6.25653 23.322V17.9175C6.25653 17.1092 5.59859 16.4419 4.78087 16.4419Z"
        fill="#635F76"
      />
      <path
        d="M27.8745 25.7752C26.521 25.7752 25.4213 24.6755 25.4213 23.322V17.9175C25.4213 16.5641 26.521 15.4644 27.8745 15.4644C29.228 15.4644 30.3277 16.5641 30.3277 17.9175V23.322C30.3277 24.6755 29.228 25.7752 27.8745 25.7752ZM27.8745 16.4419C27.0662 16.4419 26.3988 17.0998 26.3988 17.9175V23.322C26.3988 24.1303 27.0568 24.7977 27.8745 24.7977C28.6922 24.7977 29.3502 24.1397 29.3502 23.322V17.9175C29.3502 17.1092 28.6922 16.4419 27.8745 16.4419Z"
        fill="#635F76"
      />
      <path
        d="M19.514 27.7396H3.30992C3.03739 27.7396 2.81647 27.9606 2.81647 28.2331C2.81647 28.5056 3.03739 28.7265 3.30992 28.7265H19.514C19.7865 28.7265 20.0075 28.5056 20.0075 28.2331C20.0075 27.9606 19.7865 27.7396 19.514 27.7396Z"
        fill="#635F76"
      />
      <path
        d="M21.9812 27.7396H20.9943V28.7265H21.9812V27.7396Z"
        fill="#635F76"
      />
      <path
        d="M29.8343 27.7396H23.4523C23.1797 27.7396 22.9588 27.9606 22.9588 28.2331C22.9588 28.5056 23.1797 28.7265 23.4523 28.7265H29.8343C30.1068 28.7265 30.3277 28.5056 30.3277 28.2331C30.3277 27.9606 30.1068 27.7396 29.8343 27.7396Z"
        fill="#635F76"
      />
      <path
        d="M5.27902 25.2864H4.29211V28.2377H5.27902V25.2864Z"
        fill="#635F76"
      />
      <path
        d="M28.3726 25.2864H27.3857V28.2377H28.3726V25.2864Z"
        fill="#635F76"
      />
      <path
        d="M27.3858 12.513C27.0192 12.513 26.6714 12.372 26.3895 12.1277L21.4831 7.63489L16.5768 12.1277C16.3042 12.3814 15.947 12.513 15.5805 12.513C14.7721 12.513 14.1048 11.8551 14.1048 11.0374C14.1048 10.6238 14.2834 10.229 14.5842 9.95647L21.4925 3.64966L28.3821 9.95647C28.6922 10.2384 28.8614 10.6332 28.8614 11.0374C28.8614 11.8457 28.2035 12.513 27.3858 12.513ZM21.4925 6.30021L27.0568 11.3945C27.1226 11.4509 27.2354 11.5261 27.3858 11.5261C27.6583 11.5261 27.8745 11.3099 27.8745 11.0374C27.8745 10.8964 27.8181 10.7742 27.7147 10.6802L21.4925 4.98433L15.2703 10.6802C15.1669 10.7742 15.1105 10.9058 15.1105 11.0374C15.1105 11.3099 15.3267 11.5261 15.5993 11.5261C15.7496 11.5261 15.8624 11.4509 15.9282 11.3945L21.4925 6.30021Z"
        fill="#635F76"
      />
      <path
        d="M12.1498 12.0243H4.42371L8.00477 5.8773C8.26795 5.42614 8.7567 5.14417 9.27365 5.14417H20.045V6.13107H9.28305C9.10447 6.13107 8.94468 6.22506 8.86009 6.37545L6.13435 11.0468H12.1592V12.0337L12.1498 12.0243Z"
        fill="#635F76"
      />
      <path
        d="M6.26596 11.5261H5.27905V16.2163H6.26596V11.5261Z"
        fill="#635F76"
      />
      <path
        d="M27.8839 12.0244H26.897V16.019H27.8839V12.0244Z"
        fill="#635F76"
      />
      <path
        d="M16.088 12.0244H15.1011V16.9402H16.088V12.0244Z"
        fill="#635F76"
      />
      <path
        d="M16.088 17.9176H15.1011V18.9045H16.088V17.9176Z"
        fill="#635F76"
      />
      <path d="M25.9101 19.882H6.7453V20.8689H25.9101V19.882Z" fill="#635F76" />
      <path
        d="M9.20786 14.4774H8.22095V16.4418H9.20786V14.4774Z"
        fill="#635F76"
      />
      <path
        d="M13.1367 14.4774H12.1498V16.4418H13.1367V14.4774Z"
        fill="#635F76"
      />
      <path
        d="M21.4925 20.3708H20.5056V14.9663H18.0525V20.3708H17.0656V14.9663C17.0656 14.4211 17.5073 13.9794 18.0525 13.9794H20.5056C21.0508 13.9794 21.4925 14.4211 21.4925 14.9663V20.3708Z"
        fill="#635F76"
      />
      <path
        d="M22.6334 20.201L21.713 20.5571L24.755 28.4202L25.6754 28.0641L22.6334 20.201Z"
        fill="#635F76"
      />
      <path
        d="M10.0142 20.2049L7.01599 28.0647L7.93809 28.4164L10.9363 20.5567L10.0142 20.2049Z"
        fill="#635F76"
      />
    </svg>
  );
};

export default ViviendaIndependiente;
