import React from "react";

const SinMascotas = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7693 20.7962C21.1613 22.3776 20.4916 23.828 20.0741 24.7314L19.9662 24.9651C19.7682 25.3945 19.3976 25.722 18.9496 25.8633C18.4786 26.0114 17.942 26.0993 17.3928 26.1275V23.348H17.5066C17.8973 23.348 18.2136 23.0317 18.2136 22.641C18.2136 22.2503 17.8973 21.934 17.5066 21.934H16.342C15.9513 21.934 15.635 22.2503 15.635 22.641C15.635 23.0317 15.9513 23.348 16.342 23.348H16.4998V26.1223C15.9848 26.0881 15.4847 26.001 15.0419 25.8611C14.5991 25.7205 14.2307 25.3975 14.0313 24.9763L13.9145 24.7285C12.9693 22.7303 10.7554 18.0501 10.742 15.1917C10.742 15.1694 10.7398 15.1471 10.7368 15.1255C10.7338 15.1061 10.4346 13.1601 10.3855 11.1701C10.3684 10.4862 10.3833 9.91767 10.419 9.44586L9.61232 8.63916C9.50739 9.33721 9.46795 10.1893 9.49325 11.1999C9.54162 13.1266 9.80804 14.9543 9.84897 15.2297C9.87948 18.2883 12.1396 23.066 13.107 25.111L13.2239 25.358C13.529 26.0033 14.0931 26.4966 14.7718 26.7125C15.4482 26.9268 16.2244 27.034 17.0006 27.034C17.7731 27.034 18.5448 26.9275 19.219 26.7154C19.9066 26.4981 20.4752 25.9966 20.7781 25.3394L20.886 25.1065C21.2767 24.2611 21.8825 22.9491 22.4592 21.486L21.7693 20.7962Z"
        fill="#635F76"
      />
      <path
        d="M10.5708 8.33483C10.7799 7.38599 11.1014 7.18506 11.2369 7.14339C11.245 7.14041 11.2636 7.13669 11.2927 7.13669C11.472 7.13669 12.0525 7.29818 13.1903 9.00311C13.9628 10.1611 14.5991 11.4411 14.7755 11.8065C14.8581 11.9769 15.0382 12.0766 15.2264 12.0558C16.3494 11.9322 17.5371 11.9322 18.6609 12.0558C18.8491 12.0759 19.0292 11.9769 19.1118 11.8065C19.2882 11.4418 19.9237 10.1611 20.6962 9.00311C22.0194 7.01985 22.5879 7.12478 22.6482 7.14339C22.87 7.21111 23.5874 7.70153 23.504 11.1605C23.4564 13.1452 23.1595 15.0861 23.1557 15.1062C23.152 15.13 23.1505 15.1531 23.1505 15.1769C23.1587 16.4986 22.6981 18.2146 22.1094 19.8749L22.806 20.5715C23.4839 18.7244 24.0443 16.7464 24.0443 15.2074C24.0867 14.9253 24.3502 13.1066 24.397 11.1895C24.4692 8.22097 23.9825 6.61799 22.9094 6.28906C22.073 6.03381 21.1338 6.73855 19.9535 8.50748C19.2904 9.50171 18.7263 10.5778 18.4458 11.1367C17.456 11.0474 16.429 11.0481 15.4408 11.1367C15.1609 10.5778 14.5961 9.50097 13.933 8.50748C12.7528 6.73855 11.8129 6.03455 10.9749 6.28981C10.4703 6.4446 10.0953 6.88292 9.84821 7.61371L10.5708 8.33483Z"
        fill="#635F76"
      />
      <path
        d="M28.9121 29.018C28.7975 29.018 28.6836 28.9741 28.5965 28.887L4.72899 5.0195C4.55485 4.84536 4.55485 4.56257 4.72899 4.38768C4.90313 4.21355 5.18592 4.21355 5.36006 4.38768L29.2276 28.256C29.4017 28.4301 29.4017 28.7129 29.2276 28.8878C29.1405 28.9748 29.0267 29.018 28.9121 29.018Z"
        fill="#635F76"
      />
    </svg>
  );
};

export default SinMascotas;
