import React from 'react'

export const Logo = () => {
  return (
    <svg width="24" height="36" viewBox="0 0 24 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.2822 21.3346H11.4546C9.76882 21.3337 8.11802 20.8305 6.6946 19.8839C5.27119 18.9372 4.13379 17.586 3.41497 15.9877C3.02327 15.1489 2.76361 14.2492 2.64607 13.3234C2.58125 12.8874 2.54974 12.4467 2.55187 12.0055C2.55011 11.5546 2.58161 11.1043 2.64607 10.6585C2.76889 9.73379 3.02829 8.83473 3.41497 7.99364C3.87005 7.00192 4.4858 6.10046 5.23483 5.32932C6.89733 3.62508 9.12916 2.66884 11.4546 2.66444H16.5532C16.7201 2.66452 16.8854 2.63011 17.0397 2.5632C17.194 2.49629 17.3341 2.39817 17.4522 2.27446C17.5703 2.15075 17.664 2.00387 17.7279 1.84221C17.7918 1.68055 17.8247 1.50727 17.8247 1.33228V1.33228C17.8247 1.1573 17.7918 0.98402 17.7279 0.822358C17.664 0.660696 17.5703 0.513815 17.4522 0.390106C17.3341 0.266396 17.194 0.168282 17.0397 0.101368C16.8854 0.0344547 16.7201 5.27948e-05 16.5532 0.000127769H11.4546C8.84073 -0.00663785 6.30513 0.934713 4.27807 2.66444C3.37928 3.4281 2.59237 4.32598 1.94252 5.32932C1.40889 6.15721 0.977104 7.05239 0.6574 7.99364C0.369999 8.85727 0.171564 9.75064 0.0654814 10.6585C0.0201265 11.1059 -0.00168812 11.5556 0.000136367 12.0055C-0.00194823 12.4458 0.0198686 12.8858 0.0654814 13.3234C0.168212 14.2317 0.366544 15.1252 0.656855 15.9877C0.976457 16.9347 1.40821 17.836 1.94198 18.6703C2.59413 19.6717 3.38075 20.5693 4.27752 21.3352C6.30688 23.0607 8.84094 24.0017 11.4541 24.0001H21.637C21.8041 24.0001 21.9696 23.9656 22.1239 23.8985C22.2783 23.8315 22.4186 23.7332 22.5367 23.6093C22.6548 23.4855 22.7485 23.3384 22.8124 23.1766C22.8763 23.0148 22.9091 22.8413 22.9091 22.6662V22.6662C22.9091 22.3126 22.775 21.9735 22.5365 21.7234C22.2979 21.4734 21.9744 21.3329 21.637 21.3329L15.2822 21.3346Z" fill="#0046CF"/>
        <path d="M20.7274 9.17946e-05V3.27282H22.9092V0.994811C22.9092 0.864124 22.8921 0.734719 22.8587 0.613988C22.8254 0.493258 22.7765 0.38357 22.7149 0.291193C22.6533 0.198816 22.5801 0.12556 22.4996 0.0756113C22.4191 0.0256628 22.3329 6.60203e-07 22.2458 9.17946e-05H20.7274Z" fill="#0046CF"/>
        <path d="M6.72267 8.06686C6.1545 8.83497 5.76154 9.72955 5.57398 10.6819C5.4973 11.118 5.45731 11.5603 5.45447 12.0037C5.45597 12.4377 5.49598 12.8707 5.57398 13.2969C5.75598 14.251 6.1495 15.1467 6.72267 15.9114C7.29631 16.7291 8.04528 17.3935 8.90897 17.8508C9.77266 18.3081 10.7268 18.5456 11.6941 18.5438C12.0215 18.5424 12.3351 18.4048 12.5661 18.1613C12.7972 17.9178 12.927 17.5881 12.9271 17.2444V17.2153C12.926 16.8696 12.7944 16.5384 12.5613 16.2942C12.3281 16.0501 12.0123 15.9128 11.6829 15.9125C10.912 15.9084 10.1609 15.6552 9.53169 15.1875C8.90249 14.7198 8.42564 14.0601 8.16588 13.2981C8.01712 12.8845 7.94171 12.4458 7.9434 12.0037C7.94118 11.5528 8.0158 11.1052 8.16375 10.6819C8.67861 9.17695 10.0663 8.06686 11.6851 8.06686H20.4179V18.5455H22.909V6.39164C22.909 6.14313 22.815 5.90479 22.6476 5.72907C22.4802 5.55334 22.2531 5.45462 22.0164 5.45462H11.6861C10.7226 5.45696 9.77282 5.69413 8.9115 6.14744C8.05019 6.60074 7.3009 7.2578 6.72267 8.06686Z" fill="#0046CF"/>
        <path d="M6.99646 30.1392C7.4231 30.5952 7.63643 31.3049 7.63643 32.2682V35.8982H5.71047V35.0158C5.60751 35.3167 5.41678 35.5709 5.16857 35.738C4.91481 35.9128 4.61151 36.0002 4.25867 36.0002C3.89113 36.0066 3.52743 35.9167 3.19712 35.738C2.89358 35.5753 2.63618 35.3234 2.45302 35.0096C2.27133 34.695 2.17711 34.3281 2.18207 33.9546C2.18207 33.5038 2.28772 33.1439 2.49902 32.875C2.71032 32.6061 3.04732 32.4122 3.51002 32.2934C3.97104 32.1739 4.59449 32.1141 5.38037 32.1141H5.69126V31.9606C5.69126 31.6457 5.62605 31.4265 5.49563 31.3028C5.36521 31.1792 5.13453 31.1174 4.8036 31.1174C4.49584 31.1257 4.19083 31.1838 3.89825 31.29C3.56471 31.4007 3.24209 31.5485 2.93527 31.7309L2.41612 30.235C2.74672 30.0138 3.16005 29.8286 3.65611 29.6792C4.10019 29.5383 4.5591 29.4629 5.02096 29.4551C5.91098 29.4554 6.56948 29.6835 6.99646 30.1392ZM5.42233 34.153C5.59892 33.9356 5.68721 33.6566 5.68721 33.3159V33.1624H5.50271C5.01102 33.1624 4.66122 33.2113 4.45329 33.3092C4.24536 33.4071 4.14156 33.5712 4.1419 33.8017C4.13912 33.8909 4.1525 33.9799 4.18124 34.0633C4.20997 34.1467 4.25349 34.2229 4.30922 34.2874C4.36528 34.3511 4.43274 34.401 4.50727 34.434C4.58179 34.467 4.66173 34.4824 4.74193 34.479C4.86996 34.4832 4.99724 34.4561 5.1148 34.3998C5.23236 34.3434 5.33734 34.2592 5.42233 34.153V34.153Z" fill="#0046CF"/>
        <path d="M9 26.1817H11.1818V28.3236H9V26.1817ZM9.04678 35.9999V29.4543H11.1345V35.9999H9.04678Z" fill="#0046CF"/>
        <path d="M17.4546 31.3548L16.3383 31.4841C15.8925 31.5355 15.5817 31.6692 15.4057 31.8851C15.2298 32.1011 15.1415 32.4074 15.1408 32.8041V36.0005H13.0909V29.6332H15.0363V30.7336C15.1542 30.3632 15.3824 30.0499 15.6823 29.847C15.9814 29.6443 16.346 29.5309 16.7763 29.5067L17.34 29.4551L17.4546 31.3548Z" fill="#0046CF"/>
        <path d="M23.3602 30.1392C23.7868 30.5952 24.0001 31.3049 24.0001 32.2682V35.8982H22.0743V35.0158C21.9711 35.3168 21.7803 35.571 21.5319 35.738C21.2789 35.9128 20.9756 36.0002 20.6221 36.0002C20.2546 36.0065 19.891 35.9167 19.5607 35.738C19.2573 35.5753 19.0001 35.3233 18.8171 35.0096C18.6353 34.6951 18.5409 34.3282 18.5457 33.9546C18.5457 33.5038 18.6515 33.1439 18.8631 32.875C19.0748 32.6061 19.4117 32.4122 19.874 32.2934C20.3357 32.1739 20.9591 32.1141 21.7442 32.1141H22.0495V31.9606C22.0495 31.6457 21.9841 31.4265 21.8534 31.3029C21.7227 31.1792 21.492 31.1174 21.1614 31.1174C20.8539 31.1257 20.5491 31.1838 20.2567 31.29C19.923 31.4009 19.6003 31.5486 19.2933 31.7309L18.7747 30.235C19.1052 30.0138 19.5184 29.8286 20.0141 29.6792C20.4581 29.5383 20.917 29.463 21.3788 29.4551C22.2728 29.4554 22.9332 29.6835 23.3602 30.1392ZM21.7857 34.153C21.9616 33.9356 22.0495 33.6566 22.0495 33.3159V33.1624H21.865C21.3731 33.1624 21.0233 33.2113 20.8157 33.3092C20.6081 33.4071 20.5043 33.5712 20.5043 33.8017C20.5016 33.8909 20.5149 33.9799 20.5437 34.0633C20.5724 34.1467 20.6159 34.223 20.6716 34.2874C20.7276 34.3511 20.795 34.401 20.8694 34.434C20.9438 34.467 21.0237 34.4824 21.1038 34.4791C21.2321 34.4835 21.3597 34.4566 21.4775 34.4002C21.5954 34.3439 21.7006 34.2595 21.7857 34.153Z" fill="#0046CF"/>
    </svg>
  )
}
