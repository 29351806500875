import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icons } from "../../Assets/Icons";
import { File } from "../../Assets/svgs/File";
import { Suitcase } from "../../Assets/svgs/Suitcase";
import { resetImagen } from "../../features/LayoutPregunta/PreguntasAPI";
import {
  handlePhotos,
  removePhoto,
  selectBienes,
  selectPreguntaId,
  selectPreguntas,
} from "../../features/LayoutPregunta/preguntaSlice";
import { AddPhoto } from "../AddPhoto/AddPhoto";
import { Notification } from "../Notification/Notification";
import "./ImageBlock.scss";
import { dataUrlToFile, toBase64 } from "../../utils";

export const ImageBlock = (props: any) => {
  const dispatch = useDispatch();
  const [photos, setPhotos] = useState<any>([]);
  const preguntas = useSelector(selectPreguntas);
  const icons = Icons();
  const bienes = useSelector(selectBienes);

  useEffect(() => {
    dispatch(handlePhotos(photos));
  }, [photos]);

  const handleChange = async (e: any, coords: any,imagenId: number, grupoImagenId: number) => {
    const base64img = await toBase64(e);
    setPhotos([
      ...photos,
      { imagen: base64img, coords: coords, imagenId, grupoImagenId, id: preguntas.items[0].id },
    ]);
  };

  const handleRemove = (id: number) => {
    setPhotos(photos.filter((p: any) => p.imagenId !== id));
    dispatch(removePhoto(id));
    resetImagen(id);
  };

  const toPreservedPhoto = (photoId: number) => {
    if(bienes && bienes.length) {
      return bienes.find((b: any) => b.imagenId === photoId)
    } else {
      return null;
    }
  }

  const renderInputs = () => {
    return props.items.gruposImagenes.map((g: any) => {
      return (
        <div key={g.nombre}>
          <p className="image-block-title">{g.nombre}</p>
          <div className="image-block-container">
            {g.imagenes.map((i: any) => {
              return (
                <div key={i.id} className="image-block-container-item">
                  <AddPhoto
                    removable
                    onChange={handleChange}
                    id={i.id}
                    defaultThumbnail={i.arn ? i.urlThumbnail : null}
                    data={i}
                    onDelete={handleRemove}
                    icono={i.tipoElemento}
                    iconos={props.iconos ? props.iconos : null}
                    fotoPreservada={toPreservedPhoto(i.id)}
                  />
                  <span>{i.descripcion}</span>
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="image-block">
      <div className={`${props.items.consigna ? "" : "image-block-title-container"}`}>
        <h1>
          {icons[props.items.iconoGrupoImagenes]}
          {props.items.tituloGrupoImagenes}
        </h1>
        {props.items.consigna ? (
          <p className="image-block-description">
            {props.items.consigna}
          </p>
        ) : null}
      </div>

      <div className="image-block-main">{renderInputs()}</div>
      {!props.isLast ? <div className="image-block-bottom-bar"></div> : null}
    </div>
  );
};
