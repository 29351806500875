import React from "react";

const HelpBlackCircle = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.49998 3.16732C6.00218 3.16732 3.16665 6.00285 3.16665 9.50065C3.16665 12.9985 6.00218 15.834 9.49998 15.834C12.9978 15.834 15.8333 12.9985 15.8333 9.50065C15.8333 6.00285 12.9978 3.16732 9.49998 3.16732ZM1.58331 9.50065C1.58331 5.1284 5.12773 1.58398 9.49998 1.58398C13.8722 1.58398 17.4166 5.1284 17.4166 9.50065C17.4166 13.8729 13.8722 17.4173 9.49998 17.4173C5.12773 17.4173 1.58331 13.8729 1.58331 9.50065Z"
        fill="#171717"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.49998 12.666C9.93721 12.666 10.2916 13.0205 10.2916 13.4577V13.4656C10.2916 13.9028 9.93721 14.2573 9.49998 14.2573C9.06275 14.2573 8.70831 13.9028 8.70831 13.4656V13.4577C8.70831 13.0205 9.06275 12.666 9.49998 12.666Z"
        fill="#0046CF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.29244 5.02216C8.6878 4.82654 9.12317 4.72535 9.56428 4.72657C10.0054 4.7278 10.4402 4.8314 10.8345 5.02922C11.2287 5.22703 11.5717 5.51367 11.8364 5.86656C12.101 6.21945 12.2802 6.62896 12.3597 7.06285C12.4392 7.49674 12.4169 7.94316 12.2946 8.36697C12.1722 8.79078 11.9532 9.18041 11.6547 9.50518C11.3562 9.82995 10.9864 10.081 10.5744 10.2386C10.5677 10.2411 10.561 10.2436 10.5542 10.246C10.4733 10.2744 10.4038 10.3284 10.3562 10.3998C10.3086 10.4712 10.2855 10.5561 10.2903 10.6418C10.3151 11.0783 9.98127 11.4523 9.54475 11.477C9.10822 11.5018 8.73428 11.168 8.70952 10.7314C8.68523 10.3031 8.80077 9.87849 9.03875 9.52153C9.27426 9.16827 9.61705 8.9002 10.0164 8.75675C10.1965 8.68656 10.3581 8.57607 10.489 8.43373C10.6216 8.28938 10.719 8.11622 10.7734 7.92786C10.8277 7.7395 10.8376 7.54109 10.8023 7.34825C10.767 7.15541 10.6873 6.9734 10.5697 6.81656C10.4521 6.65972 10.2996 6.53233 10.1244 6.44441C9.94919 6.35649 9.75594 6.31044 9.55989 6.3099C9.36384 6.30936 9.17034 6.35433 8.99463 6.44127C8.81891 6.52822 8.66577 6.65477 8.54728 6.81095C8.28301 7.15927 7.7864 7.22741 7.43808 6.96314C7.08976 6.69886 7.02163 6.20226 7.2859 5.85394C7.55252 5.50252 7.89707 5.21779 8.29244 5.02216Z"
        fill="#0046CF"
      />
    </svg>
  );
};

export default HelpBlackCircle;
