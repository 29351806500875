import { Autocomplete } from "@mui/material";
import * as R from "ramda";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { ChevronDown } from "../../Assets/svgs/ChevronDown";
import { Map } from "../../Assets/svgs/Map";
import { BlockTitle } from "../../Components/BlockTitle/BlockTitle";
import {
  CustomButton,
  debounce,
} from "../../Components/CustomButton/CustomButton";
import { CustomInput } from "../../Components/CustomInput/CustomInput";
import { Description } from "../../Components/Description/Description";
import { IncidenciaAlert } from "../../Components/IncidenciaAlert/IncidenciaAlert";
import {
  getCoordinates,
  LocationModal,
  locationModal,
} from "../../Components/LocationModal/LocationModal";
import { Notification } from "../../Components/Notification/Notification";
import { NumberInput } from "../../Components/NumberInput/NumberInput";
import { NumberInputAyuware } from "../../Components/NumberInput/NumberInputAyuware";
import { SubBlockTitle } from "../../Components/SubBlockTitle/SubBlockTitle";
import {
  obtenerCalles,
  obtenerEscaleras,
  obtenerPlantas,
  obtenerPuertas,
  postRespuesta,
  validarDireccion,
} from "../LayoutPregunta/PreguntasAPI";
import {
  changeNumeroDireccion,
  changeValue,
  clearTip,
  clearValue,
  getPreguntas,
  handleDireccionManual,
  selectId,
  selectPreguntas,
  updateCoordenadas,
  validarCamposObligatorios,
} from "../LayoutPregunta/preguntaSlice";
import "./DatosEmpresa.scss";
import { ModalConexion } from "../../Components/ModalConexion/ModalConexion";

export const DireccionFiscalAyuware = () => {
  const dispatch = useDispatch();
  const preguntas = useSelector(selectPreguntas);
  const preguntaId: any = useSelector(selectId);
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState([]);
  const [loader, setLoader] = useState(false);
  const obligatorios = useSelector(validarCamposObligatorios);
  const [invocarValidar, setInvocarValidar] = useState(false);
  const [geolocationModal, setGeolocationModal] = useState<locationModal>({
    open: false,
    msg: "",
  });
  const inspeccionId = useSelector(selectId);
  const [dataOptions, setDataOptions] = useState({
    bloques: [],
    puertas: [],
    escaleras: [],
    plantas: [],
  });
  const [callesOptions, setCallesOptions] = useState([]);
  const [isOpcionSelected, setIsOpcionSelected] = useState(false);
  const direccionInitialState = {
    calle: "",
    numero: null,
    cod_ine: "",
    cod_via: "",
    bloque: "",
    escalera: "",
    puerta: "",
    planta: "",
  };
  const [direccion, setDireccion] = useState(direccionInitialState);
  const [noConnection, setNoConnection] = useState(false)
  const [correctionMode, setCorrectionMode] = useState(false);
  //const disableInput = !direccion.calle.length || !direccion.numero;
  const hasDataOptions =
    dataOptions.bloques.length ||
    dataOptions.escaleras.length ||
    dataOptions.puertas.length ||
    dataOptions.plantas.length;

  const getOptionsDelayed = useCallback(
    debounce((texto: string, callback: any) => {
      setCallesOptions([]);
      obtenerCalles(texto, preguntaId).then((response: any) => {
        if (!R.isEmpty(response.data.direcciones)) {
          callback(response.data.direcciones);
        } else {
          callback([
            {
              cod_ine: "",
              cod_via: "",
              cp: "",
              dir: texto.replace(/[0-9]/g, "").trim(),
              dirSugerida: texto,
              numero_via: Number(texto.replace(/\D/g, "")),
              poblacion: "",
            },
          ]);
        }
      });
    }, 200),
    []
  );

  const toInputs = () => {
    const inputList = preguntas.items.map((e: any, index: number) => {
      return { data: e, index: index };
    });
    setInputs(inputList);
  };

  useEffect(() => {
    toInputs();
    if (preguntas.incidencias && preguntas.incidencias.length) {
      setOpen(true);
    }
  }, [preguntas]);

  useEffect(() => {
    getOptionsDelayed(direccion.calle, (filteredOptions: any) => {
      setCallesOptions(filteredOptions);
    });

  }, [direccion.calle, getOptionsDelayed]);

  useEffect(() => {
    getCoordinates(setGeolocationModal)
      .then((location: any) => {
        const data = {
          id: preguntaId,
          lat: `${location.coords.latitude}`,
          long: `${location.coords.longitude}`,
        };
        // @ts-ignore
        dispatch(updateCoordenadas(data));
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);

  useEffect(() => {
    if(hasDataOptions) {
      setInvocarValidar(false)
    }
  }, [dataOptions])

  useEffect(() => {
    const dataDireccion = preguntas.tip && JSON.parse(preguntas.tip);
    if (dataDireccion) {
      setDireccion({
        ...direccion,
        calle: dataDireccion.calle,
        numero: dataDireccion.numero,
        cod_ine: dataDireccion.cod_ine,
        cod_via: dataDireccion.cod_via,
      });
      setDataOptions({ ...dataDireccion });
    }    
  }, [preguntas]);

  const handleSiguiente = async () => {
    if ((invocarValidar && !hasDataOptions) || (preguntas.mostrarIncidencia && !hasDataOptions)) {
      setLoader(true);
      const results = await validarDireccion({ ...direccion, inspeccionId });
      if (results.data.code === 200) {
        if (
          results.data.vertical.bloques.length ||
          results.data.vertical.puertas.length ||
          results.data.vertical.plantas.length ||
          results.data.vertical.escaleras.length
        ) {
          setDataOptions(results.data.vertical);
          setInvocarValidar(false);
          setLoader(false);
        } else {
          setInvocarValidar(false);
          setCorrectionMode(false);
          const response = await postRespuesta(preguntas);
          if (response.status === 200) {
            //@ts-ignore
            dispatch(getPreguntas(preguntaId));
          }
          setLoader(false);
        }
      } else {
        const response = await postRespuesta(preguntas);
        if (response.status === 200) {
          //@ts-ignore
          dispatch(getPreguntas(preguntaId));
        }
        setLoader(false);
      }
    } else {
      setLoader(true);
      const response = await postRespuesta(preguntas);
      if (response.status === 200) {
        //@ts-ignore
        dispatch(getPreguntas(preguntaId));
        setLoader(false);
      } else {
        setLoader(false);
        console.log(
          response.status +
            "Ocurrio un error al intentar confirmar sus respuestas"
        );
      }
    }
  };

  const handleChangeCalle = (selected: any, index: number) => {
    dispatch(changeValue({ value: selected.dir, index: index }));
    if (selected.numero_via) {
      dispatch(changeNumeroDireccion(selected.numero_via.toString()));
    }
    setDireccion({
      ...direccion,
      calle: selected.dir,
      cod_ine: selected.cod_ine,
      cod_via: selected.cod_via,
      numero: selected.numero_via ? selected.numero_via : "",
    });

    setIsOpcionSelected(true);
  };

  const handleBloqueChange = async (bloque: string, index: number) => {
    dispatch(changeValue({ value: bloque, index: index }));
    dispatch(clearValue({ indexes: [3, 4, 5], key: "valor" }));
    setDireccion({
      ...direccion,
      bloque: bloque,
      escalera: "",
      planta: "",
      puerta: "",
    });
    if (bloque) {
      const results = await obtenerEscaleras({ inspeccionId, bloque });
      setDataOptions({ ...dataOptions, escaleras: results.data });
    }
  };

  const handleEscaleraChange = async (
    bloque: string,
    escalera: string,
    index: number
  ) => {
    dispatch(changeValue({ value: escalera, index: index }));
    dispatch(clearValue({ indexes: [4, 5], key: "valor" }));
    setDireccion({ ...direccion, escalera: escalera, planta: "", puerta: "" });
    if (escalera) {
      const results = await obtenerPlantas({ inspeccionId, bloque, escalera });
      setDataOptions({ ...dataOptions, plantas: results.data });
    }
  };

  const handlePlantaChange = async (
    bloque: string,
    escalera: string,
    planta: string,
    index: number
  ) => {
    dispatch(changeValue({ value: planta, index: index }));
    dispatch(clearValue({ indexes: [5], key: "valor" }));
    setDireccion({ ...direccion, planta: planta, puerta: "" });
    if (planta) {
      const results = await obtenerPuertas({
        inspeccionId,
        bloque,
        escalera,
        planta,
      });
      setDataOptions({ ...dataOptions, puertas: results.data });
    }
  };

  const handlePuertaChange = (puerta: string, index: number) => {
    dispatch(changeValue({ value: puerta, index: index }));
    setDireccion({ ...direccion, puerta: puerta });
  };

  const renderTopInputs = () => {
    return inputs.map((e: any, index: number) => {
      if (e.data.key === "calle") {
        return (
          <Autocomplete
            id="calle-combo-box"
            key="combo-calle"
            options={callesOptions}
            getOptionLabel={(option: any) => option.dirSugerida}
            isOptionEqualToValue={(option, value) => option.dir === value.dir}
            onChange={(e, v: any) => handleChangeCalle(v, index)}
            renderInput={(params: any) => {
              console.log(params)
              return (
                <div
                  className="dropdown-input-container"
                  ref={params.InputProps.ref}
                >
                  <input
                    {...params.inputProps}
                    className={`dropdown-input-input`}
                    type="text"
                    key="combo-calle-input"
                    autoComplete="off"
                    disabled={hasDataOptions || (!invocarValidar && !preguntas.mostrarIncidencia)}
                    value={direccion.calle}
                    onChange={(e) => {
                      setIsOpcionSelected(false);
                      setDireccion({ ...direccion, calle: e.target.value });
                    }}
                  />
                  <label className={(params.inputProps.value || hasDataOptions || (!invocarValidar && !preguntas.mostrarIncidencia) ) && "filled"}>
                    Calle
                  </label>
                </div>
                )
            }}
          />
        );
      }

      if (e.data.key === "numero") {
        return (
          <NumberInputAyuware
            type="number"
            index={index}
            label="Número"
            disable={hasDataOptions || (!invocarValidar && !preguntas.mostrarIncidencia)}
            key="num-input"
            onChange={(e: any) => setDireccion({ ...direccion, numero: e })}
            val={direccion.numero}
          />
        );
      }

      if (e.data.key === "bloque") {
        if (dataOptions.bloques?.length) {
          return (
            <Autocomplete
              id="bloque-combo-box"
              key="combo-bloque"
              options={dataOptions.bloques}
              value={direccion.bloque}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(o, v) => v === o || v === ""}
              onChange={(e, v: any) => handleBloqueChange(v, index)}
              renderInput={(params: any) => (
                <div
                  className="dropdown-input-container"
                  ref={params.InputProps.ref}
                >
                  <input
                    {...params.inputProps}
                    className={`dropdown-input-input`}
                    type="text"
                    key="combo-bloque-input"
                    autoComplete="none"
                  />
                  <div className="chevron-down">
                    <ChevronDown />
                  </div>
                  <label className={params.inputProps.value && "filled"}>
                    Bloque
                  </label>
                </div>
              )}
            />
          );
        }
      }

      if (e.data.key === "escalera") {
        if (dataOptions.escaleras?.length) {
          return (
            <Autocomplete
              id="escalera-combo-box"
              key="combo-escalera"
              options={dataOptions.escaleras}
              isOptionEqualToValue={(o, v) => v === o || v === ""}
              value={direccion.escalera}
              getOptionLabel={(option) => option}
              onChange={(e, v: any) =>
                handleEscaleraChange(direccion.bloque, v, index)
              }
              renderInput={(params: any) => (
                <div
                  className="dropdown-input-container"
                  ref={params.InputProps.ref}
                >
                  <input
                    {...params.inputProps}
                    className={`dropdown-input-input`}
                    type="text"
                    autoComplete="none"
                    key="combo-escalera-input"
                  />
                  <div className="chevron-down">
                    <ChevronDown />
                  </div>
                  <label className={params.inputProps.value && "filled"}>
                    Escalera
                  </label>
                </div>
              )}
            />
          );
        }
      }

      if (e.data.key === "planta") {
        if (dataOptions.plantas?.length) {
          return (
            <Autocomplete
              id="planta-combo-box"
              key="combo-planta"
              options={dataOptions.plantas}
              isOptionEqualToValue={(o, v) => v === o || v === ""}
              value={direccion.planta}
              getOptionLabel={(option) => option}
              onChange={(e, v: any) =>
                handlePlantaChange(
                  direccion.bloque,
                  direccion.escalera,
                  v,
                  index
                )
              }
              renderInput={(params: any) => (
                <div
                  className="dropdown-input-container"
                  ref={params.InputProps.ref}
                >
                  <input
                    {...params.inputProps}
                    className={`dropdown-input-input`}
                    type="text"
                    autoComplete="none"
                    key="combo-planta-input"
                  />
                  <div className="chevron-down">
                    <ChevronDown />
                  </div>
                  <label className={params.inputProps.value && "filled"}>
                    Planta
                  </label>
                </div>
              )}
            />
          );
        }
      }

      if (e.data.key === "puerta") {
        if (dataOptions.puertas?.length) {
          return (
            <Autocomplete
              id="puerta-combo-box"
              key="combo-puerta"
              options={dataOptions.puertas}
              isOptionEqualToValue={(o, v) => v === o || v === ""}
              value={direccion.puerta}
              getOptionLabel={(option) => option}
              onChange={(e, v: any) => handlePuertaChange(v, index)}
              renderInput={(params: any) => (
                <div
                  className="dropdown-input-container"
                  ref={params.InputProps.ref}
                >
                  <input
                    {...params.inputProps}
                    className={`dropdown-input-input`}
                    type="text"
                    key="combo-puerta-input"
                    autoComplete="none"
                  />
                  <div className="chevron-down">
                    <ChevronDown />
                  </div>
                  <label className={params.inputProps.value && "filled"}>
                    Puerta
                  </label>
                </div>
              )}
            />
          );
        }
      }
    });
  };

  const handleCorrection = () => {
    setCorrectionMode(true);
    setDataOptions({
      bloques: [],
      puertas: [],
      escaleras: [],
      plantas: [],
    });
    dispatch(clearTip());
    setInvocarValidar(true);
  };

  const handleContinue = () => {
    const online = window.navigator.onLine
    if(!online) {
      setNoConnection(true);
      return; 
    }
    if (loader) {
      return () => null;
    } else {
      return handleSiguiente()
    }
  }

  return (
    <>
      <div className="datos-empresa">
        <div className="datos-empresa-content">
          <BlockTitle icon={preguntas.iconoPrincipal} text={preguntas.titulo} />
          <div className="datos-empresa-text">
            <SubBlockTitle text={preguntas.titulo1} />
            <Description text={preguntas.texto} />
          </div>
          <div className="datos-empresa-inputs dropdown-input">
            {renderTopInputs()}
            {/* renderMiddleInputs() */}
          </div>
        </div>
        <div className="datos-empresa-buttons">
          <CustomButton
            type="secondary mb8"
            text="Corregir dirección"
            onClick={() => handleCorrection()}
          />
          <br />
          <CustomButton
            type="primary"
            text="Continuar"
            loading={loader}
            //disable={disableInput}
            onClick={handleContinue}
          />
        </div>
        <IncidenciaAlert
          open={open}
          items={preguntas.incidencias ? preguntas.incidencias[0] : {}}
        />
        <LocationModal
          geolocationModal={geolocationModal}
          setGeolocationModal={setGeolocationModal}
        />
        <ModalConexion open={noConnection} onClick={(e: boolean) => setNoConnection(e)} />
      </div>
    </>
  );
};
