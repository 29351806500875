import React from 'react'

export const ViviendaAsilada = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.3174 14.1002H29.0374L26.413 7.0343C26.1832 6.41566 25.5855 6 24.9255 6H6.39997C5.74008 6 5.14229 6.41566 4.9125 7.0343L2.28809 14.1002H2.00818C1.45225 14.1002 1 14.5525 1 15.1084V15.9184C1 16.4743 1.45225 16.9266 2.00818 16.9266H2.15721V26.3422C2.15721 26.5795 2.34949 26.7718 2.58679 26.7718C2.82408 26.7718 3.01636 26.5795 3.01636 26.3422V16.9266H9.76123C10.1387 16.9266 10.4936 16.7796 10.7605 16.5126L15.6628 11.6103L20.5651 16.5126C20.832 16.7795 21.1869 16.9265 21.5644 16.9265H28.3092V18.0654C28.3092 18.3026 28.5015 18.495 28.7388 18.495C28.9761 18.495 29.1684 18.3026 29.1684 18.0654V16.9265H29.3174C29.8733 16.9265 30.3256 16.4743 30.3256 15.9183V15.1083C30.3255 14.5525 29.8733 14.1002 29.3174 14.1002V14.1002ZM5.71786 7.3334C5.82325 7.04971 6.09737 6.85915 6.39997 6.85915H24.9255C25.2281 6.85915 25.5022 7.04976 25.6076 7.3334L28.121 14.1002H22.1497L16.662 8.61255C16.3951 8.34564 16.0403 8.19861 15.6628 8.19861C15.2853 8.19861 14.9304 8.34558 14.6635 8.61255L9.17581 14.1002H3.20451L5.71786 7.3334ZM29.4664 15.9184C29.4664 16.0006 29.3995 16.0674 29.3174 16.0674H21.5644C21.4164 16.0674 21.2772 16.0098 21.1726 15.9052L15.9665 10.6991C15.8859 10.6185 15.7767 10.5732 15.6627 10.5732C15.5487 10.5732 15.4396 10.6185 15.3589 10.6991L10.1529 15.9052C10.0482 16.0098 9.90906 16.0674 9.76106 16.0674H2.00818C1.92599 16.0674 1.85915 16.0006 1.85915 15.9184V15.1084C1.85915 15.0262 1.92599 14.9594 2.00818 14.9594H9.35377C9.46769 14.9594 9.57692 14.9141 9.65756 14.8335L15.2711 9.22002C15.3757 9.11538 15.5148 9.05776 15.6628 9.05776C15.8108 9.05776 15.95 9.11538 16.0545 9.22002L21.6681 14.8335C21.7487 14.9141 21.8579 14.9594 21.9719 14.9594H29.3175C29.3997 14.9594 29.4665 15.0262 29.4665 15.1084V15.9184H29.4664Z" fill="#635F76"/>
        <path d="M26.8292 28.8454C27.0665 28.8454 27.2588 28.6531 27.2588 28.4159V20.7786C27.2588 20.5413 27.0665 20.349 26.8292 20.349H21.0433C20.806 20.349 20.6138 20.5413 20.6138 20.7786V28.4159C20.6138 28.6531 20.806 28.8454 21.0433 28.8454H26.8292ZM26.3997 26.1348H24.3658V24.1011H26.3997V26.1348ZM23.5067 26.1348H21.4729V24.1011H23.5067V26.1348ZM26.3997 27.9863H21.4729V26.994H26.3997V27.9863ZM26.3997 23.2419H24.3658V21.2081H26.3997V23.2419ZM23.5067 21.2081V23.2419H21.4729V21.2081H23.5067Z" fill="#635F76"/>
        <path d="M10.2819 28.8454C10.5192 28.8454 10.7114 28.6531 10.7114 28.4159V20.7786C10.7114 20.5413 10.5192 20.349 10.2819 20.349H4.49598C4.25868 20.349 4.06641 20.5413 4.06641 20.7786V28.4159C4.06641 28.6531 4.25868 28.8454 4.49598 28.8454H10.2819ZM9.8523 26.1348H7.81853V24.1011H9.8523V26.1348ZM6.95938 26.1348H4.92555V24.1011H6.95938V26.1348ZM9.8523 27.9863H4.92555V26.994H9.8523V27.9863ZM9.8523 23.2419H7.81853V21.2081H9.8523V23.2419ZM6.95938 21.2081V23.2419H4.92555V21.2081H6.95938Z" fill="#635F76"/>
        <path d="M17.0512 22.2004H14.2739C14.0366 22.2004 13.8444 22.3928 13.8444 22.63V24.4815C13.8444 24.7187 14.0366 24.911 14.2739 24.911H17.0512C17.2885 24.911 17.4807 24.7187 17.4807 24.4815V22.63C17.4807 22.3928 17.2885 22.2004 17.0512 22.2004ZM16.6216 24.0519H14.7035V23.0596H16.6216V24.0519Z" fill="#635F76"/>
        <path d="M17.9438 16.7864C17.9438 15.5287 16.9205 14.5054 15.6627 14.5054C14.405 14.5054 13.3817 15.5287 13.3817 16.7864C13.3817 18.0441 14.405 19.0675 15.6627 19.0675C16.9205 19.0675 17.9438 18.0442 17.9438 16.7864V16.7864ZM14.2409 16.7864C14.2409 16.0024 14.8787 15.3645 15.6627 15.3645C16.4467 15.3645 17.0846 16.0024 17.0846 16.7864C17.0846 17.5704 16.4467 18.2084 15.6627 18.2084C14.8787 18.2084 14.2409 17.5705 14.2409 16.7864V16.7864Z" fill="#635F76"/>
        <path d="M29.896 29.4906H29.1684V20.0749C29.1684 19.8377 28.9761 19.6453 28.7388 19.6453C28.5015 19.6453 28.3092 19.8377 28.3092 20.0749V29.4906H19.3324V20.7785C19.3324 20.5413 19.1401 20.349 18.9028 20.349H12.4227C12.1854 20.349 11.9931 20.5413 11.9931 20.7785V29.4906H3.0163V28.3517C3.0163 28.1144 2.82403 27.9221 2.58673 27.9221C2.34943 27.9221 2.15716 28.1144 2.15716 28.3517V29.4906H1.42957C1.19228 29.4906 1 29.6829 1 29.9201V31.8874C1 32.1246 1.19228 32.3169 1.42957 32.3169H29.896C30.1333 32.3169 30.3255 32.1246 30.3255 31.8874V29.9201C30.3255 29.6829 30.1333 29.4906 29.896 29.4906ZM12.8523 26.3575H13.4642C13.7015 26.3575 13.8938 26.1652 13.8938 25.9279C13.8938 25.6907 13.7015 25.4983 13.4642 25.4983H12.8523V21.2081H18.4733V29.4906H12.8523V26.3575ZM29.4664 31.4578H1.85915V30.3497H29.4664V31.4578Z" fill="#635F76"/>
    </svg>
  )
}
