import React from 'react'

export const VigilanciaDeSeguridad = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.8751 27.9832C13.1421 27.0916 11.1184 26.3242 9.47213 25.2513C7.66513 24.0736 6.4576 22.5916 5.67205 20.5872C4.83256 18.4454 4.8177 16.4438 5.6222 13.8943C6.15406 12.2089 6.99832 10.3044 6.40464 8.53277C6.12777 7.70657 5.52255 6.94539 5.06066 6.20565C4.95571 6.03754 4.98962 5.822 5.14169 5.69083L6.97922 4.1057C7.12222 3.98231 7.33384 3.96513 7.49659 4.06375C9.94089 5.54421 13.2199 5.56662 15.6886 4.1279C15.8606 3.95467 16.1602 3.96113 16.324 4.14119C18.8696 5.58472 21.9999 5.55724 24.5214 4.0617C24.6863 3.9639 24.8992 3.98401 25.0409 4.11057L26.8639 5.73939C27.012 5.8717 27.043 6.08493 26.9384 6.25088C26.494 6.95539 25.9227 7.67334 25.6377 8.45482C25.0593 10.0408 25.7226 11.7828 26.2282 13.3013C26.8342 15.1251 27.3135 17.1506 26.7224 19.6007C26.2333 21.6279 25.0939 23.2839 23.239 24.6635C21.5902 25.8899 19.3295 26.9447 16.1237 27.9836C16.0556 28.0056 15.9432 28.0054 15.8751 27.9832ZM5.97849 6.07426C6.52763 6.95385 7.17802 7.81923 7.3757 8.84523C7.72303 10.6482 6.97959 12.4344 6.44414 14.1312C5.68836 16.5262 5.69759 18.3133 6.47601 20.2994C8.02861 24.2608 11.2566 25.6034 16.0002 27.1569C22.1812 25.1327 25.0491 22.8845 25.8858 19.4163C26.4002 17.2841 26.0675 15.5289 25.4099 13.5496C24.9033 12.0281 24.3329 10.4014 24.6588 8.78754C24.8593 7.79462 25.487 6.96252 26.0235 6.1118L24.6901 4.92042C21.9829 6.39713 18.7025 6.39395 15.9959 4.90821C14.6921 5.63575 13.1947 6.01888 11.6471 6.01893C11.6414 6.01893 11.636 6.01893 11.6302 6.01893C10.0956 6.01616 8.61161 5.63688 7.31737 4.91934L5.97849 6.07426Z" fill="#635F76"/>
        <path d="M18.6087 17.1543L16 15.827L13.3914 17.1543C13.0775 17.314 12.6881 17.0621 12.7458 16.7365L13.249 13.8971L11.1211 11.8897C10.877 11.6594 11.0232 11.2581 11.3691 11.2094L14.2916 10.7984L15.6027 8.22736C15.7573 7.92427 16.2427 7.92416 16.3973 8.22736L17.7084 10.7984L20.6309 11.2094C20.9767 11.2581 21.123 11.6593 20.8789 11.8897L18.751 13.8971L19.2542 16.7365C19.3116 17.0565 18.8797 17.2771 18.6087 17.1543ZM16.1841 14.9986L18.2426 16.046L17.8492 13.8261C17.8268 13.6998 17.8709 13.5713 17.9681 13.4797L19.6549 11.8884L17.3442 11.5634C17.1995 11.543 17.0754 11.4577 17.0132 11.3359L16 9.34891L14.9868 11.3358C14.9246 11.4576 14.8005 11.543 14.6558 11.5633L12.3451 11.8883L14.0319 13.4796C14.1291 13.5713 14.1731 13.6998 14.1508 13.826L13.7574 16.0459L15.8159 14.9985C15.9172 14.947 16.0829 14.947 16.1841 14.9986ZM18.5261 19.6994H13.4741C13.2317 19.6994 13.0352 19.5188 13.0352 19.2961C13.0352 19.0732 13.2317 18.8927 13.4741 18.8927H18.5261C18.7684 18.8927 18.9649 19.0733 18.9649 19.2961C18.9649 19.5188 18.7684 19.6994 18.5261 19.6994ZM18.5261 21.8497H13.4741C13.2317 21.8497 13.0352 21.6691 13.0352 21.4463C13.0352 21.2235 13.2317 21.043 13.4741 21.043H18.5261C18.7684 21.043 18.9649 21.2236 18.9649 21.4463C18.9649 21.6691 18.7684 21.8497 18.5261 21.8497ZM18.5261 24H13.4741C13.2317 24 13.0352 23.8194 13.0352 23.5966C13.0352 23.3739 13.2317 23.1932 13.4741 23.1932H18.5261C18.7684 23.1932 18.9649 23.3739 18.9649 23.5966C18.9649 23.8194 18.7684 24 18.5261 24Z" fill="#635F76"/>
        <path d="M15.8751 27.9832C13.1421 27.0916 11.1184 26.3242 9.47213 25.2513C7.66513 24.0736 6.4576 22.5916 5.67205 20.5872C4.83256 18.4454 4.8177 16.4438 5.6222 13.8943C6.15406 12.2089 6.99832 10.3044 6.40464 8.53277C6.12777 7.70657 5.52255 6.94539 5.06066 6.20565C4.95571 6.03754 4.98962 5.822 5.14169 5.69083L6.97922 4.1057C7.12222 3.98231 7.33384 3.96513 7.49659 4.06375C9.94089 5.54421 13.2199 5.56662 15.6886 4.1279C15.8606 3.95467 16.1602 3.96113 16.324 4.14119C18.8696 5.58472 21.9999 5.55724 24.5214 4.0617C24.6863 3.9639 24.8992 3.98401 25.0409 4.11057L26.8639 5.73939C27.012 5.8717 27.043 6.08493 26.9384 6.25088C26.494 6.95539 25.9227 7.67334 25.6377 8.45482C25.0593 10.0408 25.7226 11.7828 26.2282 13.3013C26.8342 15.1251 27.3135 17.1506 26.7224 19.6007C26.2333 21.6279 25.0939 23.2839 23.239 24.6635C21.5902 25.8899 19.3295 26.9447 16.1237 27.9836C16.0556 28.0056 15.9432 28.0054 15.8751 27.9832ZM5.97849 6.07426C6.52763 6.95385 7.17802 7.81923 7.3757 8.84523C7.72303 10.6482 6.97959 12.4344 6.44414 14.1312C5.68836 16.5262 5.69759 18.3133 6.47601 20.2994C8.02861 24.2608 11.2566 25.6034 16.0002 27.1569C22.1812 25.1327 25.0491 22.8845 25.8858 19.4163C26.4002 17.2841 26.0675 15.5289 25.4099 13.5496C24.9033 12.0281 24.3329 10.4014 24.6588 8.78754C24.8593 7.79462 25.487 6.96252 26.0235 6.1118L24.6901 4.92042C21.9829 6.39713 18.7025 6.39395 15.9959 4.90821C14.6921 5.63575 13.1947 6.01888 11.6471 6.01893C11.6414 6.01893 11.636 6.01893 11.6302 6.01893C10.0956 6.01616 8.61161 5.63688 7.31737 4.91934L5.97849 6.07426Z" stroke="#635F76" strokeWidth="0.2"/>
        <path d="M18.6087 17.1543L16 15.827L13.3914 17.1543C13.0775 17.314 12.6881 17.0621 12.7458 16.7365L13.249 13.8971L11.1211 11.8897C10.877 11.6594 11.0232 11.2581 11.3691 11.2094L14.2916 10.7984L15.6027 8.22736C15.7573 7.92427 16.2427 7.92416 16.3973 8.22736L17.7084 10.7984L20.6309 11.2094C20.9767 11.2581 21.123 11.6593 20.8789 11.8897L18.751 13.8971L19.2542 16.7365C19.3116 17.0565 18.8797 17.2771 18.6087 17.1543ZM16.1841 14.9986L18.2426 16.046L17.8492 13.8261C17.8268 13.6998 17.8709 13.5713 17.9681 13.4797L19.6549 11.8884L17.3442 11.5634C17.1995 11.543 17.0754 11.4577 17.0132 11.3359L16 9.34891L14.9868 11.3358C14.9246 11.4576 14.8005 11.543 14.6558 11.5633L12.3451 11.8883L14.0319 13.4796C14.1291 13.5713 14.1731 13.6998 14.1508 13.826L13.7574 16.0459L15.8159 14.9985C15.9172 14.947 16.0829 14.947 16.1841 14.9986ZM18.5261 19.6994H13.4741C13.2317 19.6994 13.0352 19.5188 13.0352 19.2961C13.0352 19.0732 13.2317 18.8927 13.4741 18.8927H18.5261C18.7684 18.8927 18.9649 19.0733 18.9649 19.2961C18.9649 19.5188 18.7684 19.6994 18.5261 19.6994ZM18.5261 21.8497H13.4741C13.2317 21.8497 13.0352 21.6691 13.0352 21.4463C13.0352 21.2235 13.2317 21.043 13.4741 21.043H18.5261C18.7684 21.043 18.9649 21.2236 18.9649 21.4463C18.9649 21.6691 18.7684 21.8497 18.5261 21.8497ZM18.5261 24H13.4741C13.2317 24 13.0352 23.8194 13.0352 23.5966C13.0352 23.3739 13.2317 23.1932 13.4741 23.1932H18.5261C18.7684 23.1932 18.9649 23.3739 18.9649 23.5966C18.9649 23.8194 18.7684 24 18.5261 24Z" stroke="#635F76" strokeWidth="0.2"/>
    </svg>
  )
}
