import React from 'react'

export const Timer = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11 3C11 2.44772 11.4477 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 8.73398 3.56678 5.83359 5.98552 4.01023C6.42653 3.67778 7.05355 3.76578 7.38601 4.2068C7.71846 4.64781 7.63046 5.27483 7.18945 5.60728C5.25042 7.06901 4 9.38832 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.92038 16.9463 4.55399 13 4.06189V7C13 7.55228 12.5523 8 12 8C11.4477 8 11 7.55228 11 7V3Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.29289 8.29289C7.68342 7.90237 8.31658 7.90237 8.70711 8.29289L12.7071 12.2929C13.0976 12.6834 13.0976 13.3166 12.7071 13.7071C12.3166 14.0976 11.6834 14.0976 11.2929 13.7071L7.29289 9.70711C6.90237 9.31658 6.90237 8.68342 7.29289 8.29289Z" fill="#89869B"/>
    </svg>
  )
}
