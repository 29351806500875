import React from 'react'

export const Home = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 12H3L12 3L21 12H19" stroke="#171717" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.99994 12V19C4.99994 19.5304 5.21065 20.0391 5.58573 20.4142C5.9608 20.7893 6.46951 21 6.99994 21H16.9999C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7892 20.0391 18.9999 19.5304 18.9999 19V12" stroke="#171717" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.0001 12H10.0001V16H14.0001V12Z" stroke="#171717" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
