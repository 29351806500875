import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { Id } from "../../Assets/svgs/Id";
import { Map } from "../../Assets/svgs/Map";
import { BlockTitle } from "../../Components/BlockTitle/BlockTitle";
import { CustomButton } from "../../Components/CustomButton/CustomButton";
import { CustomInput } from "../../Components/CustomInput/CustomInput";
import { Description } from "../../Components/Description/Description";
import { DropdownInput } from "../../Components/DropdownInput/DropdownInput";
import { IncidenciaAlert } from "../../Components/IncidenciaAlert/IncidenciaAlert";
import { Notification } from "../../Components/Notification/Notification";
import { NumberInput } from "../../Components/NumberInput/NumberInput";
import { RedDescription } from "../../Components/RedDescription/RedDescription";
import { SubBlockTitle } from "../../Components/SubBlockTitle/SubBlockTitle";
import { postRespuesta } from "../LayoutPregunta/PreguntasAPI";
import {
  fetchPreguntaAnterior,
  getPreguntas,
  selectId,
  selectPreguntas,
  validarCamposObligatorios,
} from "../LayoutPregunta/preguntaSlice";
import "./DatosEmpresa.scss";
import { ModalConexion } from "../../Components/ModalConexion/ModalConexion";

export const DireccionFiscalAutocomplete = () => {
  const dispatch = useDispatch();
  const preguntas = useSelector(selectPreguntas);
  const preguntaId: any = useSelector(selectId);
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState([]);
  const [loader, setLoader] = useState(false);
  const [noConnection, setNoConnection] = useState(false)
  const obligatorios = useSelector(validarCamposObligatorios);

  const toInputs = () => {
    const inputList = preguntas.items.map((e: any, index: number) => {
      return { data: e, index: index };
    });
    setInputs(inputList);
  };

  useEffect(() => {
    toInputs();
    if (preguntas.incidencias && preguntas.incidencias.length) {
      setOpen(true);
    }
  }, [preguntas]);

  const handleSiguiente = async () => {
    try { setLoader(true) } catch {}
    const response = await postRespuesta(preguntas);
    
    if (response.status === 200) {
      // @ts-ignore
      dispatch(getPreguntas(preguntaId));
      try { setLoader(false) } catch {}
    } else {
      try { setLoader(false) } catch {}
      console.log(
        response.status +
        "Ocurrio un error al intentar confirmar sus respuestas"
      );
    }
  };

  const renderTopInputs = () => {
    return inputs.map((e: any, index: number) => {
      if (e.data.key === "calle")
        return (
          <DropdownInput
            options={e.data.opciones.map((o: any) => o.titulo)}
            key={e.keyId}
            label={e.data.placeholder}
            index={index}
            type="text"
          />
        );
      if (e.data.key === "numero")
        return (
          <NumberInput
            key={e.keyId}
            label={e.data.placeholder}
            index={e.index}
            type="text"
            prefetchedValue={e.data.valor}
          />
        );
    });
  };

  const renderMiddleInputs = () => {
    return inputs.map((e: any) => {
      if (e.data.key === "planta")
        return (
          <NumberInput
            key={e.keyId}
            label={e.data.placeholder}
            index={e.index}
            type="text"
            prefetchedValue={e.data.valor}
          />
        );
      /* if (
        e.data.key === "bloque" ||
        e.data.key === "puerta" ||
        e.data.key === "escalera"
      ) {
        return <></>;
        return <CustomInput
          key={e.keyId}
          label={e.data.placeholder}
          index={e.index}
          type="text"
          prefetchedValue={e.data.valor}
        />
      }*/
    });
  };

  const renderBottomInputs = () => {
    return inputs.map((e: any) => {
      if (e.data.key === "codigoPostal") {
        return <></>;
        /* return <CustomInput
          key={e.keyId}
          label={e.data.placeholder}
          index={e.index}
          disable={e.data.key === "poblacion-" || e.data.key === "provincia-"}
          type="text"
          prefetchedValue={e.data.valor}
        />*/
      }
      if (e.data.key === "poblacion" || e.data.key === "provincia") {
        return (
          <CustomInput
            key={e.keyId}
            label={e.data.placeholder}
            index={e.index}
            disable={e.data.key === "poblacion-" || e.data.key === "provincia-"}
            type="text"
            prefetchedValue={e.data.valor}
          />
        );
      }
    });
  };

  const handleContinue = () => {
    const online = window.navigator.onLine
    if(!online) {
      setNoConnection(true);
      return; 
    }
    if (loader) {
      return () => null;
    } else {
      return handleSiguiente()
    }
  }

  return (
    <>
      <div className="datos-empresa">
        <div className="datos-empresa-content">
          <BlockTitle icon={preguntas.iconoPrincipal} text={preguntas.titulo} />
          <div className="datos-empresa-text">
            <SubBlockTitle text={preguntas.titulo1} />
            <RedDescription text={preguntas.subtitulo} />
            <Description text={preguntas.texto} />
          </div>
          <div className="datos-empresa-inputs">
            {renderTopInputs()}
            {renderMiddleInputs()}
            {/* <div className="datos-empresa-inputs-direccion">
            </div> */}
            {renderBottomInputs()}
          </div>
        </div>
        <div className="datos-empresa-buttons">
          <CustomButton
            type="primary"
            text="Continuar"
            loading={loader}
            disable={!obligatorios}
            onClick={handleContinue}
          />
        </div>
        <IncidenciaAlert
          open={open}
          items={preguntas.incidencias ? preguntas.incidencias[0] : {}}
        />
        <ModalConexion open={noConnection} onClick={(e: boolean) => setNoConnection(e)} />
      </div>
    </>
  );
};
