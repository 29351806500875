import dayjs from "dayjs";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Help } from "../../Assets/svgs/Help";
import { BienesList } from "../../Components/BienesList/BienesList";
import { BlockTitle } from "../../Components/BlockTitle/BlockTitle";
import { CustomButton } from "../../Components/CustomButton/CustomButton";
import { CustomCheckbox } from "../../Components/CustomCheckbox/CustomCheckbox";
import { CustomInput } from "../../Components/CustomInput/CustomInput";
import { DateInput } from "../../Components/DateInput/DateInput";
import { Description } from "../../Components/Description/Description";
import { ExclusiveToggleButtons } from "../../Components/ExclusiveToggleButtons/ExclusiveToggleButtons";
import { ImageBlock } from "../../Components/ImageBlock/ImageBlock";
import { InfoCard } from "../../Components/InfoCard/InfoCard";
import { InputCurrency } from "../../Components/InputCurrency/InputCurrency";
import { ModalAyuda } from "../../Components/ModalAyuda/ModalAyuda";
import { ModalEspera } from "../../Components/ModalEspera/ModalEspera";
import { MultipleToggleButtons } from "../../Components/MultipleToggleButtons/MultipleToggleButtons";
import { Notification } from "../../Components/Notification/Notification";
import { NumberInput } from "../../Components/NumberInput/NumberInput";
import { SubBlockTitle } from "../../Components/SubBlockTitle/SubBlockTitle";
import { Subtitle } from "../../Components/Subtitle/Subtitle";
import { YearDropdownInput } from "../../Components/YearDropdownInput/YearDropdownInput";
import { TipoItem } from "../../model/tipoItemEnum";
import {
  confirmarFormulario,
  postRespuesta,
  subirGrupoImagen
} from "../LayoutPregunta/PreguntasAPI";
import {
  changeValue,
  clearBienes,
  getPreguntas, selectBienes,
  selectId,
  selectPreguntas, validarCamposObligatorios
} from "../LayoutPregunta/preguntaSlice";
import "./LayoutFotografiasLibres.scss";
import TextArea from "../../Components/TextArea/TextArea";
import { useNavigate } from "react-router-dom";
import { ModalConexion } from "../../Components/ModalConexion/ModalConexion";
import { dataUrlToFile, purgeIndexedDbWithDelay } from "../../utils";
import useNetwork from "../../hooks/useNetwork";
import { IconsRamos } from "../../Assets/iconosRamos/IconsRamos";

const LayoutFotografiasLibres = () => {
  const dispatch = useDispatch();
  const preguntas = useSelector(selectPreguntas);
  const preguntaId: any = useSelector(selectId);
  const bienes = useSelector(selectBienes);
  const [openAyuda, setOpenAyuda] = useState(false);
  const [openEspera, setOpenEspera] = useState(false)
  const [loading, setLoading] = useState(false);
  const [noConnection, setNoConnection] = useState(false)
  const online = useNetwork()
  const obligatorios = useSelector(validarCamposObligatorios);
  const containerRef = useRef<any>()
  const navigator = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0);
    containerRef?.current?.scrollTo(0, 0)
  }, [preguntas.id]);

  const toBienesData = (data: any[], id: number) => {
    return data.reduce(
      (acc: any, d: any) => {
        return {
          ...acc,
          imagenes: [...acc.imagenes, d.imagen],
          detalles: [...acc.detalles, d.detalle],
          importes: [...acc.importes, d.importe],
        };
      },
      { id, imagenes: [], detalles: [], importes: [] }
    );
  };

  const toPhotosData = (data: any[], id: number) => {
    return data.reduce(
      (acc: any, d: any) => {
        return {
          ...acc,
          imagenes: [...acc.imagenes, d.imagen],
          ids: [...acc.ids, d.imagenId],
          idsGrupo: [...acc.idsGrupo, d.grupoImagenId],
        };
      },
      { id, imagenes: [], ids: [], idsGrupo: [] }
    );
  };

  const handleConfirm = async () => {
    const response: any = await confirmarFormulario(preguntaId);
    if (response.data.resultCode === 0) {
      navigator("/finalizado");
    }
  };

  const handleSiguiente = async () => {
    setLoading(true);
    setOpenEspera(true)

    const newBienes = bienes.filter((b: any) => !b.url)
    if (newBienes.length) {
      let results: any = [];
      const photos = await Promise.all(
        newBienes.map(async (b: any, index: number) => {
          const imagen = await dataUrlToFile(b.imagen, b.imagenId.toString())
          return {
            id: b.id,
            coords: JSON.stringify(b.coords),
            imagenes: [imagen],
            ids: [b.imagenId],
            idsGrupo: [b.grupoImagenId],
            idInspeccion: preguntas.inspeccionId,
            esUltima: index === bienes.length - 1,
          };
        })
      )

      for (let i = 0; i < photos.length; i++) {
        // @ts-ignore
        const response = await subirGrupoImagen(photos[i]);
        results = [...results, response];
      }

      console.log(results);

      const badStatus = results.some((r: any) => {
        return r.status !== 200;
      });

      if (!badStatus) {
        // @ts-ignore
        const response = await postRespuesta(preguntas);
        if (response.status === 200)
          // @ts-ignore
          dispatch(getPreguntas(preguntaId));
          dispatch(clearBienes())
          purgeIndexedDbWithDelay()
      }
    } else {
      const response = await postRespuesta(preguntas);
      if (response.status === 200)
        // @ts-ignore
        dispatch(getPreguntas(preguntaId));
    }
    setLoading(false);
    setOpenEspera(false)
  };

  useEffect(() => {
    if(!online && openEspera) {
      setOpenEspera(false)
      setLoading(false);
      setNoConnection(true);
    }
  }, [online])

  const handleNotaChange = (value: string, index: number) => {
    dispatch(changeValue({ value: value, index: index }))
  }

  const renderInputs = (e: any, index: number) => {
    switch (e.tipoItem) {
      case TipoItem.InputNumber: {
        return (
          <>
            {/* <InfoCard consigna={e.consigna} items={e.opciones} /> */}
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <NumberInput
              key={e.keyId}
              index={index}
              label={e.placeholder}
              prefetchedValue={e.valor}
            />
          </>
        );
      }
      case TipoItem.InputDate: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <DateInput
              key={e.keyId}
              index={index}
              label={e.placeholder}
              prefetchedValue={dayjs(e.valor).format("YYYY-MM-DD")}
            />
          </>
        );
      }
      case TipoItem.DropdownInputYear: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <YearDropdownInput
              prefetchedValue={e.valor}
              index={index}
              key={e.keyId}
            />
          </>
        );
      }
      case TipoItem.OptionMultipleChoice: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <ExclusiveToggleButtons items={e} path={[index]} key={e.keyId} />
          </>
        );
      }
      case TipoItem.SimpleOption: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <MultipleToggleButtons items={e} path={[index]} key={e.keyId} />
          </>
        );
      }
      case TipoItem.InputText: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <CustomInput
              type="text"
              key={e.keyId}
              index={index}
              label={e.placeholder}
              prefetchedValue={e.valor}
            />
          </>
        );
      }
      case TipoItem.Checkbox: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <CustomCheckbox
              label={e.placeholder}
              selected={e.valor}
              choiceIndex={index}
              singleCheckbox
              key={e.keyId}
            />
          </>
        );
      }
      case TipoItem.listaBienes: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <BienesList key={e.keyId} />
          </>
        );
      }
      case TipoItem.BloqueImagenes: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            <ImageBlock items={e} key={e.keyId} isLast={index === preguntas?.items.length - 1} iconos={IconsRamos}/>
          </>
        );
      }
      case TipoItem.CurrencyInput: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <InputCurrency
              key={e.keyId}
              index={index}
              label={e.placeholder}
              prefetchedValue={e.valor}
            />
          </>
        );
      }
      case TipoItem.CardSuperficie: {
        return <InfoCard consigna={e.consigna} items={e.opciones} />;
      }
      case TipoItem.TextArea: {
        return <TextArea consigna={e.consigna} etiqueta={e.etiqueta} onInputChange={handleNotaChange} index={index} defaultValue={e.valor}/>;
      }
    }
  };  

  const renderContent = () => {
    return (
      <>
        <div>
          <div className="layout-pregunta-content-title">
            <BlockTitle
              text={preguntas.titulo}
              icon={preguntas.iconoPrincipal}
              ayuda={preguntas.ayuda ? preguntas.ayuda : null}
              fn={setOpenAyuda}
            />
          </div>
          {preguntas.subtitulo ? <Subtitle text={preguntas.subtitulo} /> : null}
        </div>
        {preguntas.titulo1 ? <SubBlockTitle text={preguntas.titulo1} /> : null}
        {preguntas.texto ? <Description text={preguntas.texto} /> : null}
        {preguntas.tip ? (
          <Notification text={preguntas.tip} icon={preguntas.iconoTip} />
        ) : null}
        {preguntas.items?.map((e: any, index: number) => {
          return <Fragment key={e.id}>{renderInputs(e, index)}</Fragment>;
        })}
      </>
    );
  };

  const handleContinue = () => {
    if(!online) {
      setNoConnection(true);
      return; 
    }
    if (loading) {
      return () => null;
    } else {
      return handleSiguiente()
    }
  }

  return (
    <>
      <div className="layout-pregunta" ref={containerRef}>
        <div className="layout-pregunta-content">{renderContent()}</div>
      </div>
      <div className="layout-pregunta-content-actions">
        <CustomButton
          type="primary"
          text="Confirmar"
          loading={loading}
          disable={!obligatorios}
          onClick={handleContinue}
        />
        {openAyuda ? (
          <ModalAyuda
            open={openAyuda}
            texto={preguntas.ayuda.texto}
            titulo={preguntas.ayuda.titulo}
            imagen={preguntas.ayuda.imagen}
            onClose={() => setOpenAyuda(false)}
          />
        ) : null}
        <ModalEspera key={preguntas.keyId} open={openEspera} />
        <ModalConexion open={noConnection} onClick={(e: boolean) => setNoConnection(e)} />
      </div>
    </>
  );
};

export default LayoutFotografiasLibres;
