import React from "react";

const Adosado = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_981_1602)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.1074 23.8712C26.1074 23.584 25.8746 23.3512 25.5874 23.3512H0.395845C0.108628 23.3512 -0.124207 23.584 -0.124207 23.8712C-0.124207 24.1585 0.10863 24.3913 0.395846 24.3913H25.5874C25.8746 24.3913 26.1074 24.1585 26.1074 23.8712Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5193 26.2832C11.5193 25.996 11.2865 25.7632 10.9993 25.7632H8.59201C8.3048 25.7632 8.07196 25.996 8.07196 26.2832C8.07196 26.5705 8.3048 26.8033 8.59201 26.8033H10.9993C11.2865 26.8033 11.5193 26.5705 11.5193 26.2832Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.4532 26.2832C30.4532 25.996 30.2203 25.7632 29.9331 25.7632H27.5258C27.2386 25.7632 27.0058 25.996 27.0058 26.2832C27.0058 26.5705 27.2386 26.8033 27.5258 26.8033H29.9331C30.2203 26.8033 30.4532 26.5705 30.4532 26.2832Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.9862 26.2832C20.9862 25.996 20.7534 25.7632 20.4662 25.7632H18.0589C17.7717 25.7632 17.5389 25.996 17.5389 26.2832C17.5389 26.5705 17.7717 26.8033 18.0589 26.8033H20.4662C20.7534 26.8033 20.9862 26.5705 20.9862 26.2832Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1394 28.5636C16.1394 28.2764 15.9066 28.0436 15.6194 28.0436H13.2121C12.9249 28.0436 12.6921 28.2764 12.6921 28.5636C12.6921 28.8508 12.9249 29.0837 13.2121 29.0837H15.6194C15.9066 29.0837 16.1394 28.8508 16.1394 28.5636Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.0733 28.5636C35.0733 28.2764 34.8404 28.0436 34.5532 28.0436H32.146C31.8588 28.0436 31.6259 28.2764 31.6259 28.5636C31.6259 28.8508 31.8588 29.0837 32.146 29.0837H34.5532C34.8404 29.0837 35.0733 28.8508 35.0733 28.5636Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.6063 28.5636C25.6063 28.2764 25.3735 28.0436 25.0863 28.0436H22.679C22.3918 28.0436 22.159 28.2764 22.159 28.5636C22.159 28.8508 22.3918 29.0837 22.679 29.0837H25.0863C25.3735 29.0837 25.6063 28.8508 25.6063 28.5636Z"
          fill="#635F76"
        />
        <path
          d="M25.077 14.5211H24.2777V23.8713H25.077V14.5211Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.1573 14.4006H25.1975V23.9916H24.1573V14.4006ZM24.3981 14.6414V23.7508H24.9567V14.6414H24.3981Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.0575 23.8712C42.0575 23.584 41.8247 23.3512 41.5375 23.3512H21.4709C21.1836 23.3512 20.9508 23.584 20.9508 23.8712C20.9508 24.1585 21.1836 24.3913 21.4709 24.3913H41.5375C41.8247 24.3913 42.0575 24.1585 42.0575 23.8712Z"
          fill="#635F76"
        />
        <path
          d="M41.5746 15.8502C41.3701 15.8502 41.1842 15.7851 41.0355 15.6643L32.2988 8.17302L24.5079 14.7976L24.4171 13.9222L32.2988 7.12275L41.556 15.0602L42.3274 14.14C39.4555 11.7235 33.6 6.67662 32.2988 5.56129L24.5079 12.2966V11.0461L32.3081 4.49243L32.5683 4.7155C32.6427 4.78056 39.6321 10.8033 42.8572 13.5173C43.0338 13.666 43.1453 13.8798 43.1453 14.1028C43.1453 14.3259 43.0803 14.5025 42.9594 14.6512L42.188 15.5714C42.0393 15.7572 41.8162 15.8595 41.6025 15.8595L41.5746 15.8502Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.3087 4.33435L32.6476 4.62488C32.7214 4.68942 39.7102 10.7117 42.9347 13.4252C43.1346 13.5934 43.2657 13.8394 43.2657 14.1028C43.2657 14.3522 43.192 14.5559 43.0529 14.7271L43.0517 14.7286L42.2811 15.6477C42.1098 15.8612 41.8527 15.9799 41.6025 15.9799H41.5829L41.5545 15.9704C41.3315 15.9662 41.1258 15.8928 40.9596 15.7577L40.9571 15.7557L32.2983 8.33117L24.9517 14.6825L24.2755 13.8854L32.2993 6.9646L41.542 14.8895L42.158 14.1547C39.8417 12.2037 35.716 8.65707 33.4884 6.7421C32.9763 6.30187 32.5646 5.94787 32.2981 5.71932L24.9235 11.9394L24.2473 11.1424L32.3087 4.33435ZM24.5079 11.0461L24.9517 11.6005L32.2994 5.40325L32.3771 5.46987C32.6378 5.69332 33.0811 6.07435 33.6463 6.5603C35.904 8.50117 40.1085 12.1156 42.405 14.0479L42.4969 14.1253L41.5701 15.2308L32.2983 7.28088L24.6151 13.9135L24.9797 14.3433L32.2993 8.01484L41.1126 15.5718C41.2387 15.6738 41.397 15.7298 41.5746 15.7298H41.5941L41.6211 15.7388C41.7925 15.733 41.9724 15.6482 42.094 15.4961L42.0957 15.494L42.866 14.5753C42.9682 14.4492 43.025 14.2993 43.025 14.1028C43.025 13.9201 42.9331 13.7385 42.7797 13.6094C39.5601 10.9001 32.5904 4.89439 32.4895 4.80652L32.3075 4.65049L24.5079 11.0461Z"
          fill="#635F76"
        />
        <path
          d="M34.706 23.8713H33.9067V15.4506H30.7001V23.8713H29.9008V15.4506C29.9008 15.0044 30.2633 14.6512 30.7001 14.6512H33.9067C34.3528 14.6512 34.706 15.0137 34.706 15.4506V23.8713Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.7001 14.7715C30.3288 14.7715 30.0212 15.0717 30.0212 15.4505V23.7508H30.5797V15.3301H34.0271V23.7508H34.5856V15.4505C34.5856 15.0792 34.2854 14.7715 33.9067 14.7715H30.7001ZM29.7804 15.4505C29.7804 14.9369 30.1977 14.5308 30.7001 14.5308H33.9067C34.4202 14.5308 34.8264 14.9481 34.8264 15.4505V23.9916H33.7863V15.5709H30.8205V23.9916H29.7804V15.4505Z"
          fill="#635F76"
        />
        <path
          d="M32.2987 18.6569H31.4994V20.6645H32.2987V18.6569Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.3791 18.5366H32.4192V20.785H31.3791V18.5366ZM31.6199 18.7774V20.5442H32.1784V18.7774H31.6199Z"
          fill="#635F76"
        />
        <path
          d="M6.83074 15.7758C7.037 15.7758 7.2245 15.7101 7.37451 15.5883L16.1873 8.03173L24.5079 15.4228L24.6626 13.8896L16.1873 6.97232L6.84949 14.9789L6.07133 14.0507C8.96832 11.6131 14.8748 6.5223 16.1873 5.39726L23.8826 11.6712L24.1376 11.0412L16.178 4.31909L15.9154 4.5441C15.8404 4.60973 8.79019 10.685 5.53694 13.4226C5.35881 13.5726 5.2463 13.7882 5.2463 14.0132C5.2463 14.2382 5.31193 14.4163 5.43381 14.5663L6.21197 15.4945C6.36197 15.682 6.58698 15.7851 6.80261 15.7851L6.83074 15.7758Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1774 4.15955L15.8355 4.4526C15.7611 4.51771 8.71143 10.5924 5.45877 13.3295C5.25721 13.4993 5.12489 13.7474 5.12489 14.0131C5.12489 14.2646 5.19927 14.4701 5.33958 14.6428L5.34076 14.6443L6.11808 15.5714C6.29094 15.7868 6.55025 15.9065 6.80264 15.9065H6.82234L6.85103 15.8969C7.076 15.8927 7.28344 15.8186 7.45111 15.6824L7.45361 15.6804L16.1879 8.19117L23.9694 14.6317L24.6516 13.8277L16.1868 6.8127L6.86368 14.8066L6.24228 14.0655C8.57877 12.0975 12.7404 8.51991 14.9873 6.58826C15.5039 6.1442 15.9193 5.78712 16.188 5.55657L24.5079 12.2965V11.0459L16.1774 4.15955ZM23.9663 11.0554L23.8826 11.6712L16.1867 5.23775L16.1083 5.30495C15.8454 5.53035 15.3983 5.9147 14.8281 6.40488C12.5507 8.36265 8.3096 12.0086 5.99317 13.9577L5.90042 14.0357L6.83534 15.1509L16.1879 7.13173L24.309 13.856L23.9411 14.2896L16.1869 7.87209L7.29674 15.4949C7.16962 15.5977 7.00993 15.6542 6.83076 15.6542H6.81106L6.78388 15.6633C6.61098 15.6575 6.42953 15.5719 6.30682 15.4185L6.30507 15.4164L5.52809 14.4897C5.42499 14.3625 5.36777 14.2112 5.36777 14.0131C5.36777 13.8288 5.46048 13.6456 5.61516 13.5154C8.86285 10.7824 15.8932 4.72447 15.9949 4.63583L16.1786 4.47844L23.9663 11.0554Z"
          fill="#635F76"
        />
        <path
          d="M9.30579 10.413H10.1121V5.2566H12.1371V7.98482H12.9434V5.2566C12.9434 4.80658 12.5778 4.45032 12.1371 4.45032H10.1121C9.66205 4.45032 9.30579 4.81596 9.30579 5.2566V10.413Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1372 4.57174C12.5117 4.57174 12.822 4.87455 12.822 5.25658V7.86336H12.2586V5.13514H9.99065V10.2916H9.42725V5.25658C9.42725 4.88208 9.73006 4.57174 10.1121 4.57174H12.1372ZM9.30581 10.413H9.18437V5.25658C9.18437 4.74979 9.59409 4.32886 10.1121 4.32886H12.1372C12.644 4.32886 13.0649 4.73857 13.0649 5.25658V8.10624H12.0157V5.37802H10.2335V10.5345H9.30581V10.413Z"
          fill="#635F76"
        />
        <path
          d="M15.8587 23.8665H16.665V15.3724H19.8995V23.8665H20.7057V15.3724C20.7057 14.9224 20.3401 14.5662 19.8995 14.5662H16.665C16.215 14.5662 15.8587 14.9318 15.8587 15.3724V23.8665Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.8996 14.6877C20.274 14.6877 20.5844 14.9905 20.5844 15.3725V23.7452H20.021V15.2511H16.5436V23.7452H15.9802V15.3725C15.9802 14.9981 16.283 14.6877 16.6651 14.6877H19.8996ZM20.8273 15.3725C20.8273 14.8545 20.4063 14.4448 19.8996 14.4448H16.6651C16.1471 14.4448 15.7373 14.8658 15.7373 15.3725V23.988H16.7865V15.494H19.7781V23.988H20.8273V15.3725Z"
          fill="#635F76"
        />
        <path
          d="M11.5652 20.6322H12.7746C13.2246 20.6322 13.5809 20.2666 13.5809 19.8259V16.5914C13.5809 16.1414 13.2152 15.7852 12.7746 15.7852H11.5652C11.1152 15.7852 10.7589 16.1508 10.7589 16.5914V19.8259C10.7589 20.2759 11.1245 20.6322 11.5652 20.6322ZM12.7746 16.5821V19.8166H11.5558V16.5821H12.7652H12.7746Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7744 15.9063C13.1489 15.9063 13.4593 16.2091 13.4593 16.5912V19.8257C13.4593 20.2002 13.1565 20.5105 12.7744 20.5105H11.565C11.1905 20.5105 10.8802 20.2077 10.8802 19.8257V16.5912C10.8802 16.2167 11.183 15.9063 11.565 15.9063H12.7744ZM13.7021 16.5912C13.7021 16.0732 13.2812 15.6635 12.7744 15.6635H11.565C11.047 15.6635 10.6373 16.0844 10.6373 16.5912V19.8257C10.6373 20.3437 11.0582 20.7534 11.565 20.7534H12.7744C13.2924 20.7534 13.7021 20.3325 13.7021 19.8257V16.5912ZM12.8959 16.4604H11.4342V19.9377H12.8959V16.4604ZM12.653 16.7032V19.6949H11.6771V16.7032H12.653Z"
          fill="#635F76"
        />
        <path
          d="M18.287 18.6069H19.0933V20.632H18.287V18.6069Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.2147 18.4856H18.1656V20.7536H19.2147V18.4856ZM18.9718 18.7285V20.5107H18.4084V18.7285H18.9718Z"
          fill="#635F76"
        />
        <rect
          x="24.3277"
          y="10.1595"
          width="1"
          height="14"
          rx="0.5"
          fill="#635F76"
        />
        <rect
          x="7.3277"
          y="10.1595"
          width="1"
          height="14"
          rx="0.5"
          fill="#635F76"
        />
        <rect x="4.3277" y="8.15955" width="3" height="9" fill="white" />
        <path
          d="M-9.96644 15.7758C-9.76018 15.7758 -9.57268 15.7101 -9.42267 15.5883L-0.609849 8.03173L7.71071 15.4228L7.86547 13.8896L-0.609849 6.97232L-9.94769 14.9789L-10.7258 14.0507C-7.82886 11.6131 -1.9224 6.5223 -0.609849 5.39726L7.08545 11.6712L7.34045 11.0412L-0.619224 4.31909L-0.881733 4.5441C-0.956736 4.60973 -8.00699 10.685 -11.2602 13.4226C-11.4384 13.5726 -11.5509 13.7882 -11.5509 14.0132C-11.5509 14.2382 -11.4852 14.4163 -11.3634 14.5663L-10.5852 15.4945C-10.4352 15.682 -10.2102 15.7851 -9.99457 15.7851L-9.96644 15.7758Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.619781 4.15955L-0.961679 4.4526C-1.03609 4.51771 -8.08575 10.5924 -11.3384 13.3295C-11.54 13.4993 -11.6723 13.7474 -11.6723 14.0131C-11.6723 14.2646 -11.5979 14.4701 -11.4576 14.6428L-11.4564 14.6443L-10.6791 15.5714C-10.5062 15.7868 -10.2469 15.9065 -9.99454 15.9065H-9.97484L-9.94615 15.8969C-9.72118 15.8927 -9.51374 15.8186 -9.34607 15.6824L-9.34357 15.6804L-0.609323 8.19117L7.17219 14.6317L7.85437 13.8277L-0.610349 6.8127L-9.9335 14.8066L-10.5549 14.0655C-8.21841 12.0975 -4.05683 8.51991 -1.80985 6.58826C-1.29329 6.1442 -0.877923 5.78712 -0.609172 5.55657L7.71073 12.2965V11.0459L-0.619781 4.15955ZM7.16912 11.0554L7.08547 11.6712L-0.610456 5.23775L-0.688854 5.30495C-0.951814 5.53035 -1.39891 5.9147 -1.96911 6.40488C-4.24648 8.36265 -8.48758 12.0086 -10.804 13.9577L-10.8968 14.0357L-9.96184 15.1509L-0.609296 7.13173L7.51181 13.856L7.14396 14.2896L-0.610323 7.87209L-9.50044 15.4949C-9.62756 15.5977 -9.78725 15.6542 -9.96642 15.6542H-9.98612L-10.0133 15.6633C-10.1862 15.6575 -10.3677 15.5719 -10.4904 15.4185L-10.4921 15.4164L-11.2691 14.4897C-11.3722 14.3625 -11.4294 14.2112 -11.4294 14.0131C-11.4294 13.8288 -11.3367 13.6456 -11.182 13.5154C-7.93433 10.7824 -0.903942 4.72447 -0.80224 4.63583L-0.618614 4.47844L7.16912 11.0554Z"
          fill="#635F76"
        />
        <path
          d="M-0.938483 23.8665H-0.132205V15.3724H3.10229V23.8665H3.90857V15.3724C3.90857 14.9224 3.54293 14.5662 3.10229 14.5662H-0.132205C-0.582222 14.5662 -0.938483 14.9318 -0.938483 15.3724V23.8665Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.10237 14.6877C3.47686 14.6877 3.78721 14.9905 3.78721 15.3725V23.7452H3.22381V15.2511H-0.253564V23.7452H-0.816962V15.3725C-0.816962 14.9981 -0.514153 14.6877 -0.132124 14.6877H3.10237ZM4.03009 15.3725C4.03009 14.8545 3.60916 14.4448 3.10237 14.4448H-0.132124C-0.650128 14.4448 -1.05984 14.8658 -1.05984 15.3725V23.988H-0.0106835V15.494H2.98093V23.988H4.03009V15.3725Z"
          fill="#635F76"
        />
        <path
          d="M1.4898 18.6069H2.29608V20.632H1.4898V18.6069Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.41754 18.4856H1.36838V20.7536H2.41754V18.4856ZM2.17466 18.7285V20.5107H1.61126V18.7285H2.17466Z"
          fill="#635F76"
        />
      </g>
      <defs>
        <clipPath id="clip0_981_1602">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.327698 0.18811)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Adosado;
