import React from "react";
import "./Instruction.scss";

interface IProps {
  text: string;
}

export const Instruction = ({ text }: IProps) => {
  return (
    <div className="instruction">
      <p>{text}</p>
    </div>
  );
};
