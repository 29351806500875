import React from "react";

const Escuela = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3647_10260)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.75224 2.71467C7.91118 2.6511 8.08849 2.6511 8.24743 2.71467L14.9141 5.38134C15.1672 5.48258 15.3332 5.72772 15.3332 6.00033V10.0003C15.3332 10.3685 15.0347 10.667 14.6665 10.667C14.2983 10.667 13.9998 10.3685 13.9998 10.0003V6.98501L8.24743 9.28598C8.08849 9.34955 7.91118 9.34955 7.75224 9.28598L1.08558 6.61931C0.832471 6.51807 0.666504 6.27293 0.666504 6.00033C0.666504 5.72772 0.832471 5.48258 1.08558 5.38134L7.75224 2.71467ZM12.8714 6.00033L7.99984 7.94897L3.12823 6.00033L7.99984 4.05168L12.8714 6.00033Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.00016 6.40039C4.36835 6.40039 4.66683 6.69887 4.66683 7.06706V10.6671C4.66683 10.8171 4.80234 11.1512 5.46988 11.485C6.10622 11.8032 7.01611 12.0004 8.00016 12.0004C8.98421 12.0004 9.8941 11.8032 10.5304 11.485C11.198 11.1512 11.3335 10.8171 11.3335 10.6671V7.06706C11.3335 6.69887 11.632 6.40039 12.0002 6.40039C12.3684 6.40039 12.6668 6.69887 12.6668 7.06706V10.6671C12.6668 11.5779 11.9595 12.2612 11.1267 12.6776C10.2628 13.1095 9.13784 13.3337 8.00016 13.3337C6.86248 13.3337 5.73754 13.1095 4.87359 12.6776C4.04084 12.2612 3.3335 11.5779 3.3335 10.6671V7.06706C3.3335 6.69887 3.63197 6.40039 4.00016 6.40039Z"
          fill="#635F76"
        />
      </g>
      <defs>
        <clipPath id="clip0_3647_10260">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Escuela;
