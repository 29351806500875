import { handleBreakpoints } from "@mui/system";
import React, { useEffect, useState } from "react";
import { ErrorIcon } from "../../Assets/svgs/ErrorIcon";
import "./CustomInputAyuware.scss";
import { ItemControl } from '../../model/itemControl';
import { useDispatch } from "react-redux";
import { changeValue } from "../../features/LayoutPregunta/preguntaSlice";

interface IProps {
  id?: number;
  type: string;
  label: string;
  prefetchedValue?: any;
  disable?: boolean;
  error?: string;
  index?: number;
  length?: number;
  onChange?: any;
  defaultValue?: any;
  val?: any;
  isSuboption?: boolean;
}

export const CustomInputAyuware = ({
  index,
  type,
  label,
  disable,
  prefetchedValue,
  error,
  length,
  onChange,
  defaultValue = "",
  val,
  isSuboption
}: IProps) => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (prefetchedValue) {
      if(!isSuboption) dispatch(changeValue({value: prefetchedValue, index: index}))
      setValue(prefetchedValue);
    }
  }, []);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue])

  useEffect(() => {
      setValue(val);
  }, [val])

  const handleChange = (e: any) => {
    setValue(e);
    if(onChange) onChange(e, index)
    if(!isSuboption) dispatch(changeValue({value: e, index: index}))
  };

  return (
    <div className="custom-input">
      <input
        className={`custom-input-input ${error ? 'input-error' : ''}`}
        type={type}
        value={value ? value : defaultValue}
        onChange={(e) => handleChange(e.target.value)}
        disabled={disable}
        maxLength={length || 524288}
      />
      <label className={value && "filled"}>{label}</label>
      {error ? <span className="error-message-input"><ErrorIcon />{error}</span> : null}
    </div>
  );
};
