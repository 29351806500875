import React from "react";

const Cocina = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.7131 31.9653C2.18892 31.7594 2.00171 31.385 2.00171 30.8233C2.01107 27.0792 2.00171 23.3257 2.00171 19.5815C2.00171 18.8046 2.32932 18.477 3.09687 18.477C3.66786 18.477 4.2482 18.477 4.81918 18.4676C4.8379 18.4676 4.85662 18.4489 4.91279 18.4302C4.91279 18.346 4.91279 18.243 4.91279 18.14C4.91279 17.2882 4.91279 16.4458 4.91279 15.594C4.91279 14.9575 5.27784 14.6018 5.91435 14.6018C6.17644 14.6018 6.43853 14.6018 6.70062 14.6018C7.34648 14.6018 7.7209 14.9762 7.7209 15.6127C7.7209 16.4552 7.7209 17.2882 7.7209 18.1307C7.7209 18.2336 7.7209 18.346 7.7209 18.4864C7.88002 18.4864 8.01107 18.4864 8.15148 18.4864C15.6866 18.4864 23.2123 18.4864 30.7474 18.4864C31.2997 18.4864 31.7115 18.6455 31.9455 19.1603V31.2914C31.8145 31.6283 31.5805 31.8623 31.2435 31.9934H7.50561C7.32776 31.8343 7.178 31.6564 7.28096 31.3943C7.38392 31.1322 7.61793 31.1135 7.87066 31.1135C8.72246 31.1135 9.56489 31.1135 10.4167 31.1135C10.5196 31.1135 10.6226 31.1135 10.7162 31.1135V23.2695H2.9003V31.1135H3.19984C4.06099 31.1135 4.91279 31.1135 5.77394 31.1135C6.01731 31.1135 6.24196 31.1322 6.35428 31.4037C6.45725 31.6845 6.28876 31.8436 6.10155 31.9934H2.7131V31.9653ZM23.2498 31.0667H31.0657V23.2508H23.2498V31.0667ZM11.6148 31.0667H22.3418V23.2602H11.6148V31.0667ZM22.3418 22.3428V19.3756H11.6242V22.3428H22.3418ZM31.0563 22.3428V19.3756H23.2498V22.3428H31.0563ZM2.89094 22.3428H10.7069V19.385H2.9003V22.3428H2.89094ZM6.83166 18.4676V15.5004H5.81138V18.4676H6.83166Z"
        fill="#635F76"
      />
      <path
        d="M19.1499 1.96529C19.6647 2.17122 19.8613 2.555 19.8519 3.11662C19.8332 4.35219 19.8426 5.59712 19.8519 6.83269C19.8519 6.99181 19.9174 7.17902 20.0204 7.30071C20.685 8.12442 21.3777 8.93877 22.0516 9.76249C22.1733 9.91225 22.2388 10.1275 22.2575 10.3147C22.2856 10.7172 22.2669 11.1104 22.2575 11.5129C22.2575 12.1962 21.8925 12.5519 21.1998 12.5519C19.749 12.5519 18.2981 12.5519 16.8472 12.5519C15.4993 12.5519 14.1608 12.5519 12.8129 12.5519C12.0454 12.5519 11.7646 12.2055 11.6897 11.4474C11.5867 10.4177 11.8675 9.60336 12.607 8.88261C13.1031 8.40523 13.5149 7.83425 13.9362 7.29135C14.0298 7.16966 14.1046 6.98245 14.1046 6.82333C14.114 5.58776 14.1234 4.34283 14.1046 3.10726C14.0953 2.54564 14.2919 2.17122 14.8067 1.95593H19.1312L19.1499 1.96529ZM18.9533 6.81397V2.86389H15.022V6.81397H18.9533ZM20.8816 9.71568C20.348 9.06982 19.8519 8.48947 19.3652 7.89041C19.2435 7.74064 19.1218 7.7032 18.944 7.7032C17.9143 7.7032 16.8753 7.75937 15.8457 7.68448C15.0407 7.62832 14.4884 7.85297 14.0578 8.53628C13.7958 8.94813 13.4401 9.29447 13.0937 9.71568H20.8816ZM12.5976 11.6533H21.3589V10.6236H12.5976V11.6533Z"
        fill="#635F76"
      />
      <path
        d="M9.96739 1.96533C10.4916 2.18998 10.6601 2.60184 10.6507 3.16346C10.632 4.95129 10.6507 6.73912 10.6507 8.51759C10.6507 9.30387 10.3137 9.64084 9.53681 9.64084C7.39329 9.64084 5.24976 9.64084 3.10623 9.64084C2.3574 9.64084 2.02043 9.29451 2.02043 8.55504C2.00171 6.73912 2.02043 4.92321 2.00171 3.1073C2.00171 2.54568 2.18892 2.17126 2.7131 1.96533H9.96739ZM9.74274 8.7516V2.86393H2.90967V8.7516H9.73338H9.74274Z"
        fill="#635F76"
      />
      <path
        d="M31.9549 8.93881C31.749 9.46299 31.3652 9.6502 30.8129 9.6502C28.6788 9.63148 26.5446 9.6502 24.4105 9.6502C23.6803 9.6502 23.334 9.30387 23.3246 8.58312C23.3246 7.69388 23.3246 6.80465 23.3246 5.91541C23.3246 5.84989 23.3246 5.775 23.3246 5.70948C23.3527 5.44739 23.5212 5.28826 23.7552 5.28826C23.9892 5.28826 24.1671 5.45675 24.1858 5.71884C24.2045 5.98093 24.1858 6.24302 24.1858 6.50511C24.1858 7.15098 24.1858 7.79685 24.1858 8.44271V8.77032H31.047V2.86393H24.1858C24.1858 3.21026 24.1858 3.5566 24.1858 3.91229C24.1764 4.23054 23.9892 4.40839 23.7178 4.38031C23.4651 4.35223 23.334 4.1931 23.3246 3.94973C23.3153 3.56596 23.3153 3.19154 23.3246 2.80777C23.3434 2.37719 23.6335 2.14318 23.9892 1.96533H31.2435C31.5805 2.09638 31.8145 2.33039 31.9456 2.66736V8.93881H31.9549Z"
        fill="#635F76"
      />
      <path
        d="M31.9549 13.2165C31.8238 13.4599 31.6179 13.5348 31.3465 13.5254C30.9346 13.5161 30.5321 13.5254 30.1203 13.5254C30.1015 13.5254 30.0828 13.5348 30.0267 13.5535C30.0267 13.8905 30.0173 14.2368 30.036 14.5831C30.036 14.6487 30.1764 14.7142 30.2607 14.7704C30.8036 15.0792 31.0844 15.7064 30.9533 16.3148C30.8223 16.9139 30.2887 17.3632 29.6616 17.4006C29.0345 17.4381 28.4728 17.0636 28.2575 16.4646C28.0235 15.8 28.295 15.0792 28.9408 14.7516C29.0906 14.6767 29.1561 14.5925 29.1468 14.424C29.1374 14.1338 29.1468 13.8437 29.1468 13.5348H26.1515C26.1515 13.8811 26.1421 14.2275 26.1608 14.5738C26.1608 14.6393 26.3012 14.7142 26.3855 14.761C26.9284 15.0792 27.2185 15.7157 27.0781 16.3055C26.9284 16.9139 26.4323 17.3445 25.8145 17.3913C25.178 17.4381 24.6164 17.0824 24.3917 16.4927C24.139 15.8281 24.4011 15.098 25.0376 14.7704C25.2248 14.6674 25.2809 14.5644 25.2716 14.3679C25.2622 14.0964 25.2716 13.825 25.2716 13.5254C24.7848 13.5254 24.3449 13.5254 23.8956 13.5254C23.5306 13.5254 23.334 13.3663 23.334 13.0855C23.334 12.8047 23.5399 12.6549 23.905 12.6549C26.3855 12.6549 28.8753 12.6549 31.3558 12.6549C31.6179 12.6549 31.8051 12.7392 31.9549 12.9451V13.2353V13.2165ZM26.2263 16.0059C26.2263 15.7064 26.0017 15.463 25.7022 15.463C25.4213 15.463 25.178 15.697 25.178 15.9778C25.178 16.2587 25.3933 16.502 25.6741 16.5207C25.9736 16.5395 26.217 16.3148 26.2263 16.0153V16.0059ZM29.568 15.463C29.2872 15.463 29.0532 15.7064 29.0438 15.9872C29.0438 16.2774 29.2872 16.5207 29.5867 16.5207C29.8862 16.5207 30.1109 16.2774 30.1015 15.9685C30.0922 15.6783 29.8582 15.4537 29.568 15.463Z"
        fill="#635F76"
      />
      <path
        d="M27.6585 6.82333C27.8177 6.82333 27.9674 6.82333 28.1265 6.82333C28.3793 6.83269 28.5665 7.01053 28.5665 7.23518C28.5665 7.48791 28.4261 7.6564 28.1921 7.67512C27.8364 7.7032 27.4713 7.7032 27.1156 7.67512C26.8723 7.6564 26.7412 7.48791 26.7412 7.23518C26.7412 7.00117 26.9191 6.83269 27.1718 6.81396C27.3403 6.81396 27.4994 6.81396 27.6679 6.81396L27.6585 6.82333Z"
        fill="#635F76"
      />
      <path
        d="M4.81918 27.1634C4.81918 27.4723 4.81918 27.7906 4.81918 28.0995C4.81918 28.3803 4.64134 28.5581 4.40733 28.5675C4.1546 28.5768 3.95803 28.3896 3.94867 28.1088C3.94867 27.4723 3.94867 26.8452 3.94867 26.2087C3.94867 25.9372 4.13588 25.7594 4.37925 25.75C4.63198 25.75 4.80982 25.9278 4.81918 26.2274C4.81918 26.5363 4.81918 26.8545 4.81918 27.1634Z"
        fill="#635F76"
      />
      <path
        d="M25.1686 27.1729C25.1686 27.4724 25.1686 27.7719 25.1686 28.0808C25.1686 28.3803 24.9908 28.5676 24.7474 28.5769C24.4853 28.5769 24.2981 28.3897 24.2981 28.0808C24.2981 27.4817 24.2981 26.8733 24.2981 26.2743C24.2981 25.956 24.4759 25.7688 24.738 25.7688C25.0001 25.7688 25.1686 25.956 25.1686 26.2836C25.1686 26.5832 25.1686 26.8827 25.1686 27.1916V27.1729Z"
        fill="#635F76"
      />
      <path
        d="M16.9783 30.0277C15.8831 30.0277 14.7973 30.0277 13.7021 30.0277C13.0376 30.0277 12.6631 29.6626 12.6631 28.9981C12.6631 27.7719 12.6631 26.5363 12.6631 25.3101C12.6631 24.6642 13.0376 24.2898 13.6834 24.2898C15.8831 24.2898 18.0922 24.2898 20.2919 24.2898C20.9377 24.2898 21.3028 24.6642 21.3028 25.3101C21.3028 26.5363 21.3028 27.7719 21.3028 28.9981C21.3028 29.6439 20.9284 30.0183 20.2825 30.0183C19.178 30.0183 18.0828 30.0183 16.9783 30.0183V30.0277ZM13.5617 25.179V29.1291H20.3948V25.179H13.5617Z"
        fill="#635F76"
      />
      <path
        d="M16.9877 20.4147C17.4557 20.4147 17.671 20.5551 17.6616 20.864C17.6616 21.1541 17.437 21.2945 16.9877 21.2945C16.8941 21.2945 16.7911 21.2945 16.6975 21.2945C16.4541 21.2571 16.2763 21.0605 16.3044 20.8359C16.3325 20.5925 16.4729 20.4428 16.7256 20.4334C16.8098 20.4334 16.9034 20.4334 16.9877 20.4334V20.4147Z"
        fill="#635F76"
      />
      <path
        d="M13.3746 20.424C13.4588 20.424 13.5524 20.424 13.6366 20.424C13.8707 20.452 14.0017 20.5924 14.0204 20.8264C14.0391 21.0605 13.9175 21.2383 13.6834 21.2757C13.4682 21.3132 13.2342 21.3132 13.0189 21.2757C12.7942 21.2477 12.6632 21.0698 12.6725 20.8358C12.6819 20.5924 12.8223 20.4427 13.0844 20.424C13.178 20.424 13.281 20.424 13.3746 20.424Z"
        fill="#635F76"
      />
      <path
        d="M20.6288 20.4147C21.0781 20.4147 21.2934 20.5457 21.3028 20.8359C21.3122 21.1354 21.0875 21.2945 20.6288 21.2945C20.5352 21.2945 20.4323 21.2945 20.3387 21.2945C20.0953 21.2571 19.9268 21.0605 19.9549 20.8265C19.983 20.5832 20.1234 20.4428 20.3761 20.424C20.4604 20.424 20.554 20.424 20.6382 20.424L20.6288 20.4147Z"
        fill="#635F76"
      />
      <path
        d="M27.1717 20.4146C27.3402 20.4146 27.4993 20.4146 27.6678 20.4146C27.9206 20.4334 28.061 20.5831 28.0797 20.8265C28.0891 21.0699 27.9486 21.2477 27.7053 21.2664C27.3496 21.2945 26.9845 21.2945 26.6288 21.2664C26.3855 21.2477 26.2544 21.0699 26.2451 20.8265C26.2451 20.5925 26.4229 20.424 26.685 20.4053C26.8535 20.3959 27.0126 20.4053 27.1811 20.4053L27.1717 20.4146Z"
        fill="#635F76"
      />
      <path
        d="M6.83168 20.4147C7.00017 20.4147 7.1593 20.4053 7.32778 20.4147C7.58051 20.4334 7.72092 20.6019 7.72092 20.8359C7.72092 21.0699 7.58987 21.2478 7.36522 21.2665C7.00017 21.2946 6.62575 21.2946 6.2607 21.2665C6.01733 21.2478 5.87692 21.0605 5.89565 20.8172C5.91437 20.5738 6.05477 20.424 6.3075 20.4147C6.48535 20.4147 6.65384 20.4147 6.83168 20.4147Z"
        fill="#635F76"
      />
      <path
        d="M6.34491 6.82337C6.50404 6.82337 6.6538 6.82337 6.81293 6.82337C7.06566 6.83273 7.21543 6.97314 7.23415 7.21651C7.25287 7.46924 7.12182 7.65645 6.87845 7.67517C6.50404 7.70325 6.12026 7.70325 5.74585 7.67517C5.5212 7.65645 5.39015 7.46924 5.40887 7.23523C5.42759 6.99186 5.568 6.84209 5.82073 6.82337C5.99858 6.81401 6.16706 6.82337 6.34491 6.82337Z"
        fill="#635F76"
      />
      <path
        d="M16.9596 27.1167C16.3605 27.1167 15.7521 27.1167 15.153 27.1167C14.8161 27.1167 14.6101 26.9482 14.6195 26.6768C14.6195 26.4053 14.8254 26.2368 15.1624 26.2368C16.3793 26.2368 17.5961 26.2368 18.813 26.2368C19.1499 26.2368 19.3559 26.4053 19.3652 26.6674C19.3652 26.9482 19.1687 27.1167 18.8036 27.1167C18.1858 27.1167 17.5774 27.1167 16.9596 27.1167Z"
        fill="#635F76"
      />
    </svg>
  );
};

export default Cocina;
