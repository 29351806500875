import React from 'react'

export const Estanteria = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.304 16H7.70363C7.61123 16 7.51973 16.0202 7.43436 16.0593C7.34899 16.0985 7.27143 16.156 7.20609 16.2284C7.14075 16.3008 7.08892 16.3867 7.05356 16.4813C7.0182 16.5759 7 16.6773 7 16.7797V23.2288C7.00201 23.4341 7.07703 23.6303 7.20877 23.7746C7.34051 23.919 7.51833 24 7.70363 24H14.304C14.4886 24 14.6656 23.9187 14.7961 23.7741C14.9266 23.6295 15 23.4333 15 23.2288V16.7797C15.001 16.6777 14.9837 16.5765 14.9492 16.4819C14.9147 16.3874 14.8636 16.3014 14.7988 16.2289C14.7341 16.1564 14.657 16.0988 14.5721 16.0595C14.4872 16.0202 14.396 16 14.304 16ZM10.2658 16.8475H11.7418V18.3814L11.2141 17.9831C11.1497 17.9391 11.0756 17.9157 11 17.9153C10.9271 17.918 10.856 17.9413 10.7935 17.9831L10.2658 18.3814V16.8475ZM14.2657 23.2034H7.74187V16.8475H9.52389V19.1356C9.52268 19.2099 9.53986 19.2831 9.57357 19.3474C9.60728 19.4116 9.65622 19.4644 9.71509 19.5C9.77322 19.5372 9.83917 19.5568 9.90629 19.5568C9.97342 19.5568 10.0394 19.5372 10.0975 19.5L11 18.8305L11.9101 19.5C11.9701 19.5476 12.0429 19.5715 12.1166 19.5678C12.1748 19.5796 12.2344 19.5796 12.2925 19.5678C12.3512 19.5312 12.4004 19.4785 12.4353 19.4146C12.4701 19.3508 12.4894 19.278 12.4914 19.2034V16.822H14.2657V23.2034Z" fill="#635F76"/>
        <path d="M24.2964 16H17.7036C17.6112 16 17.5197 16.0202 17.4344 16.0593C17.349 16.0985 17.2714 16.156 17.2061 16.2284C17.1408 16.3008 17.0889 16.3867 17.0536 16.4813C17.0182 16.5759 17 16.6773 17 16.7797V23.2288C17.002 23.4341 17.077 23.6303 17.2088 23.7746C17.3405 23.919 17.5183 24 17.7036 24H24.2964C24.4817 24 24.6595 23.919 24.7912 23.7746C24.923 23.6303 24.998 23.4341 25 23.2288V16.7797C25 16.5729 24.9259 16.3746 24.7939 16.2284C24.662 16.0821 24.483 16 24.2964 16ZM20.2581 16.8475H21.7342V18.3814L21.2065 17.9831C21.1449 17.9392 21.0732 17.916 21 17.916C20.9268 17.916 20.8551 17.9392 20.7935 17.9831L20.2581 18.3814V16.8475ZM24.2581 23.2034H17.7419V16.8475H19.5163V19.1356C19.5167 19.2095 19.5345 19.2821 19.5681 19.346C19.6016 19.4099 19.6497 19.463 19.7075 19.5C19.7656 19.5372 19.8315 19.5568 19.8987 19.5568C19.9658 19.5568 20.0317 19.5372 20.0899 19.5L21 18.8305L21.9025 19.5C21.9634 19.5455 22.0355 19.5691 22.109 19.5678C22.1671 19.5806 22.2268 19.5806 22.2849 19.5678C22.3446 19.5326 22.3947 19.4801 22.4297 19.416C22.4647 19.3518 22.4834 19.2784 22.4837 19.2034V16.822H24.2581V23.2034Z" fill="#635F76"/>
        <path d="M14.304 5H7.70363C7.51702 5 7.33804 5.08214 7.20609 5.22836C7.07413 5.37457 7 5.57288 7 5.77966V12.2203C7 12.4271 7.07413 12.6254 7.20609 12.7716C7.33804 12.9179 7.51702 13 7.70363 13H14.304C14.396 13 14.4872 12.9798 14.5721 12.9405C14.657 12.9012 14.7341 12.8436 14.7988 12.7711C14.8636 12.6986 14.9147 12.6126 14.9492 12.5181C14.9837 12.4235 15.001 12.3223 15 12.2203V5.77966C15.001 5.67768 14.9837 5.57648 14.9492 5.48194C14.9147 5.38739 14.8636 5.30137 14.7988 5.22886C14.7341 5.15635 14.657 5.09879 14.5721 5.0595C14.4872 5.02022 14.396 4.99999 14.304 5ZM10.2658 5.84746H11.7418V7.35593L11.2141 6.95763C11.1549 6.89832 11.0796 6.86255 11 6.85593C10.9258 6.85764 10.8538 6.88423 10.7935 6.9322L10.2658 7.33051V5.84746ZM14.2657 12.2034H7.74187V5.82203H9.52389V8.11864C9.52202 8.19354 9.53885 8.26755 9.57254 8.33251C9.60622 8.39748 9.65544 8.45087 9.71476 8.48681C9.77409 8.52275 9.84123 8.53983 9.90876 8.53618C9.9763 8.53252 10.0416 8.50827 10.0975 8.4661L11 7.78814L11.9101 8.4661C11.9717 8.51016 12.0433 8.53369 12.1166 8.5339C12.1781 8.5332 12.2385 8.51574 12.2925 8.48305C12.3517 8.44716 12.4013 8.39454 12.4363 8.33054C12.4712 8.26653 12.4902 8.19343 12.4914 8.11864V5.82203H14.2657V12.2034Z" fill="#635F76"/>
        <path d="M24.2451 6.00005H17.7549C17.6562 5.99894 17.5582 6.01683 17.4667 6.05269C17.3751 6.08856 17.2918 6.14168 17.2216 6.20897C17.1514 6.27627 17.0957 6.35641 17.0576 6.44475C17.0196 6.53309 17 6.62787 17 6.7236V12.2764C17.0023 12.4691 17.0828 12.6531 17.2241 12.7886C17.3655 12.9241 17.5562 13 17.7549 13H24.2451C24.4431 12.9979 24.6326 12.9212 24.7734 12.7862C24.9143 12.6512 24.9955 12.4684 25 12.2764V6.7236C24.9977 6.53093 24.9172 6.34691 24.7759 6.21145C24.6345 6.076 24.4438 6.00004 24.2451 6.00005ZM20.3232 6.84139H21.6768V8.03609L21.2343 7.74162C21.1644 7.69813 21.0831 7.67502 21 7.67502C20.9169 7.67502 20.8356 7.69813 20.7657 7.74162L20.3232 8.03609V6.84139ZM24.1497 12.2091H17.8416V6.81615H19.4816V8.7933C19.481 8.86792 19.5018 8.94124 19.5416 9.0051C19.5815 9.06896 19.6388 9.12087 19.7072 9.15507C19.7721 9.19466 19.8473 9.21566 19.9241 9.21566C20.0008 9.21566 20.076 9.19466 20.141 9.15507L21.0087 8.59138L21.8764 9.15507C21.9468 9.19758 22.0277 9.22084 22.1106 9.22238C22.1806 9.2228 22.2494 9.20539 22.3102 9.1719C22.3774 9.13627 22.4336 9.08403 22.4733 9.02049C22.5129 8.95695 22.5345 8.88437 22.5358 8.81013V6.81615H24.167L24.1497 12.2091Z" fill="#635F76"/>
        <line x1="5.6" y1="28.6" x2="5.6" y2="4.4" stroke="#635F76" strokeWidth="0.8" strokeLinecap="round"/>
        <line x1="26.6" y1="28.6" x2="26.6" y2="4.4" stroke="#635F76" strokeWidth="0.8" strokeLinecap="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28 14.4C28 14.621 27.8209 14.8 27.6 14.8L4.4 14.8001C4.17908 14.8001 4 14.621 4 14.4001C4 14.1791 4.17908 14.0001 4.4 14.0001L27.6 14C27.8209 14 28 14.1791 28 14.4Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28 25.4C28 25.621 27.8209 25.8 27.6 25.8L4.4 25.8001C4.17908 25.8001 4 25.621 4 25.4001C4 25.1791 4.17908 25.0001 4.4 25.0001L27.6 25C27.8209 25 28 25.1791 28 25.4Z" fill="#635F76"/>
    </svg>
  )
}
