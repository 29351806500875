import React from "react";
import "./RedDescription.scss";

interface IProps {
  text: string;
}

export const RedDescription = ({ text }: IProps) => {
  return (
    <div className="redDescription">
      <p>{text}</p>
    </div>
  );
};

