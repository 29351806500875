import { persistor } from "../app/store"

export const formatSiNo = (text: string) => {
    if (text === "SI") return "Sí"
    if (text === "NO") return "No"
    return text
}

export const neutralizeBack = (callback: () => void) => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, "", window.location.href)
      callback()
    }
  }

export const toBase64 = async (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

const getBase64FileExtension = (base64String: string) => {
  switch (base64String.charAt(0)) {
    case '/':
      return 'jpeg';
    case 'i':
      return 'png';
    case 'R':
      return 'gif';
    case 'U':
      return 'webp';
    case 'J':
      return 'pdf';
    default:
      return 'jpeg';
  }
}

export const dataUrlToFile = async (dataUrl: any, fileName: string): Promise<File> => {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  const base64Str = dataUrl.split(',')[1]
  const fileExtension = getBase64FileExtension(base64Str)
  return new File([blob], `${fileName}.${fileExtension}`);
}

const purgeIndexedDb = async () => {
  await persistor.purge();
}

export const purgeIndexedDbWithDelay = () => {
  setTimeout(() => purgeIndexedDb(), 200);
}