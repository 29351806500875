import Lottie from 'lottie-web'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import * as animationData from '../../Assets/jsonAnimations/inspeccion-finalizada.json'
import { OkCircle } from '../../Assets/svgs/OkCircle'
import { CustomButton } from '../../Components/CustomButton/CustomButton'
import { backToLocal, codigoTest, fetchPreguntaAnterior, selectId, selectPreguntas } from '../LayoutPregunta/preguntaSlice'
import { selectLogo } from '../Registro/userSlice'
import './SuccessConfirmar.scss'

export const SuccessConfirmar = () => {
    const preguntas = useSelector(selectPreguntas)
    const inspeccionId = useSelector(selectId)
    const logo = useSelector(selectLogo)
    const codigo = useSelector(codigoTest)
    const dispatch = useDispatch()
    const lottieInstance = useRef<any>()
    const divRef = useRef<any>()
    const navigator = useNavigate()

    useEffect(() => {
        if (divRef.current) {
            lottieInstance.current = Lottie.loadAnimation({
                animationData,
                container: divRef.current,
            })
        }
    }, [])

    const handleAnterior = () => {
        navigator('/form')
        //@ts-ignore
        dispatch(fetchPreguntaAnterior(preguntas.id))
    }

    const handleVolverLocal = () => {
        //@ts-ignore
        dispatch(backToLocal(inspeccionId))
        navigator('/form')
    }

    const renderBackButtons = () => {
        if (codigo === "010101") {
            return (
                <div style={{display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '3rem'}}>
                    <CustomButton
                        type="secondary"
                        text="Pregunta Anterior"
                        onClick={handleAnterior}
                    />
                    <CustomButton
                        type="secondary"
                        text="Volver a Local"
                        onClick={handleVolverLocal}
                    />
                </div>
            )
        } else return null;
    }

    return (
        <div className="success-confirmar">
            <div className="success-confirmar-tag">
                <div className="success-confirmar-tag-icon">
                    <OkCircle />
                </div>
                <span>Evaluación enviada!</span>
            </div>
            {inspeccionId ? <img className="success-confirmar-logo" src={logo} alt="Logo aseguradora" /> : null}
            <div className="success-confirmar-content">
                <div className="success-confirmar-svg" ref={divRef}>
                </div>
                <h1>¡Felicitaciones!<br />Tu evaluación ha sido enviada exitosamente.</h1>
                <p>Hemos enviado la evaluación a tu asesor de seguros. Éste la revisará y se pondrá en contacto contigo lo antes posible.</p>
            </div>
            {renderBackButtons()}
        </div>
    )
}
