import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Back } from "../../Assets/svgs/Back";
import {
  fetchPreguntaAnterior,
  obtenerPreguntasStatus,
  selectId,
  selectPreguntaId,
  selectPreguntas,
  selectPreguntasStatus,
} from "../../features/LayoutPregunta/preguntaSlice";
import { TipoRamos } from "../../model/ramos";
import { Stepper } from "../Stepper/Stepper";
import "./Header.scss";

interface PreguntasStatus {
  totalBloques: number;
  bloqueActual: number;
  porcentajeAvanceBloqueActual: number;
}

export const Header = () => {
  const dispatch = useDispatch();
  const id: number = useSelector(selectId);
  const idPregunta = useSelector(selectPreguntaId);
  const preguntas = useSelector(selectPreguntas);
  const preguntasStatus: PreguntasStatus = useSelector(selectPreguntasStatus);
  const [ramo, setRamo] = useState("");

  useEffect(() => {
    const ramoCustom = localStorage.getItem("ramoCustom");
    if(ramoCustom)
      setRamo(ramoCustom.toUpperCase())
  }, []);

  useEffect(() => {
    //@ts-ignore
    dispatch(obtenerPreguntasStatus(id));
  }, [idPregunta]);

  const handleAnterior = () => {
    //@ts-ignore
    dispatch(fetchPreguntaAnterior(preguntas.id));
  };

  return (
    <div className="header">
      <div className="header-main">
        <div
          className="header-arrow"
          style={{
            cursor: "pointer",
            visibility:
              preguntas.titulo1 === "Datos identificativos"
                ? "hidden"
                : "visible",
          }}
          onClick={
            preguntas && preguntas.titulo1 === "Datos identificativos"
              ? () => null
              : handleAnterior
          }
        >
          <Back />
        </div>
        <span>EVALUACIÓN {ramo}</span>
      </div>
      <div className="header-progressbar">
        <Stepper
          steps={(preguntasStatus && preguntasStatus.totalBloques) || 9}
          current={preguntasStatus && preguntasStatus.bloqueActual}
          progress={
            preguntasStatus && preguntasStatus.porcentajeAvanceBloqueActual
          }
        />
      </div>
    </div>
  );
};
