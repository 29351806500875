import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as R from "ramda";
import { RootState } from "../../app/store";
import { ItemDto, OpcionDto, OpcionSubItemDto, SubItemDto, SubopcionDto } from "../../model/PreguntasDto";
import {
  cambiarCoordenadas,
  empezarInspeccion,
  getPregunta,
  getPreguntaAnterior,
  obtenerPregunta,
  obtenerPreguntaStatus,
  subirGrupoImagen,
  subirImagen,
  volverLocal
} from "./PreguntasAPI";

const initialState: any = { preguntasStatus: {}, preguntas: {}, inspeccion: {}, bienes: [], archivos: [], statusImagen: "idle", codigoTest: "" };

export const getPreguntas = createAsyncThunk(
  "pregunta/fetchPregunta",
  async (id) => {
    let response;
    try {
      response = await getPregunta(id);
    } catch (error) {
      console.log("Error al obtener la siguiente pregunta");
    }

    return response;
  }
);

export const fetchPreguntaAnterior = createAsyncThunk(
  "pregunta/fetchPreguntaAnterior",
  async (id) => {
    let response;
    try {
      response = await getPreguntaAnterior(id);
    } catch (error) {
      console.log("Error al obtener la siguiente pregunta");
    }

    return response;
  }
);

export const obtenerPreguntas = createAsyncThunk(
  "pregunta/obtenerPregunta",
  async (id) => {
    let response;
    try {
      response = await obtenerPregunta(id);
    } catch (error) {
      console.log("Error al obtener la siguiente pregunta");
    }

    return response;
  }
);

export const obtenerPreguntasStatus = createAsyncThunk(
  "pregunta/obtenerPreguntaStatus",
  async (id) => {
    let response;
    try {
      response = await obtenerPreguntaStatus(id);
    } catch (error) {
      console.log("Error al obtener el estado de la pregunta");
    }

    return response;
  }
);

export const comenzarInspeccion = createAsyncThunk(
  "pregunta/empezarInspeccion",
  async (data) => {
    let response;
    try {
      response = await empezarInspeccion(data);
    } catch (error) {
      console.log(error);
      alert(JSON.stringify(error));
    }
    return response;
  }
);

export const updateCoordenadas = createAsyncThunk(
  "pregunta/newcoords",
  async (data) => {
    let response;
    try {
      response = await cambiarCoordenadas(data);
    } catch (error) {
      console.log(error);
      alert(JSON.stringify(error));
    }
    console.log("la response al cambiar las coordenadas es",{response})
    return response;
  }
);

export const postImagenes = createAsyncThunk(
  "imagenes/postImagenes",
  async (data) => {
    let response;
    try {
      response = await subirImagen(data);
    } catch (error) {
      console.log(error);
    }
    return response;
  }
);

export const postGrupoImagenes = createAsyncThunk(
  "imagenes/postGrupoImagenes",
  async (data) => {
    let response;
    try {
      response = await subirGrupoImagen(data);
    } catch (error) {
      console.log(error);
    }
    return response;
  }
);

export const backToLocal = createAsyncThunk(
  "preguntas/backToLocal",
  async (id) => {
    let response;
    try {
      response = await volverLocal(id);
    } catch (error) {
      console.log(error);
    }
    return response;
  }
);


export const preguntaSlice = createSlice({
  name: "pregunta",
  initialState,
  reducers: {
    changeCodigoTest: (state, action) => {
      if(action.payload === "010101") {
        state.codigoTest = action.payload;
      }
    },
    saveInitialCoords: (state, action) => {
      state.inspeccion.coordenadas = {lat: action.payload.lat, lng: action.payload.lng}
    },
    handleInspeccion: (state, action) => {
      state.inspeccion = action.payload;
    },
    changeValue: (state, action) => {
      state.preguntas.items[action.payload.index] = {
        ...state.preguntas.items[action.payload.index],
        valor: action.payload.value,
      };
    },
    clearValue: (state, action) => {
      const key = action.payload.key
      action.payload.indexes.forEach((i :number) => {
        state.preguntas.items[i] = {...state.preguntas.items[i], [key]: ""}
      })
    },
    clearToggleButtonValues: (state, action) => {
      const itemIndex = action.payload.itemIndex
      const optionToKeepIndex = action.payload.optionIndex
      const optionToKeep = state.preguntas.items[itemIndex].opciones.find((o: OpcionDto, i: number) => i === optionToKeepIndex)
      const optionsToClear = state.preguntas.items[itemIndex].opciones.filter((o: OpcionDto, i: number) => i !== optionToKeepIndex)
      const clearedOptions = optionsToClear.map((o: OpcionDto) => o.selected = false)
      state.preguntas.items[itemIndex].options = [optionToKeep, ...clearedOptions]
    },
    handleValue: (state, action) => {
      state.preguntas.items = R.assocPath(
        action.payload.path,
        action.payload.value,
        state.preguntas.items
      );
    },
    handleExclusiveValue: (state, action) => {
      const pathToOptions = action.payload.path.slice(
        0,
        action.payload.path.length - 2
      );
      const options: any = R.path(pathToOptions, state.preguntas.items);
      const indexToUpdate = action.payload.path[action.payload.path.length - 2];
      const result = options.map((o: any, i: number) => {
        if (i === indexToUpdate) {
          return R.assoc(
            action.payload.path[action.payload.path.length - 1],
            action.payload.value,
            o
          );
        } else {
          return R.assoc(
            action.payload.path[action.payload.path.length - 1],
            null,
            o
          );
        }
      });

      if(action.payload.multipleChoice && action.payload.subpregunta) {
        state.preguntas.items[action.payload.path[0]].opciones[action.payload.path[2]].subpreguntas[action.payload.path[4]].opciones = result;
      } else if (action.payload.multipleChoice) {
        state.preguntas.items[action.payload.path[0]].opciones[action.payload.path[2]].subopciones = result;
      } else {
        state.preguntas.items[action.payload.path[0]].opciones = result;
      }
    },
    handleActivity: (state, action) => {
      const selectedCategory = state.preguntas.items[0].opciones.map(
        (o: any) => {
          const suboptions = o.subopciones.map((s: any) => {
            if (s.id === action.payload.subOptionId) {
              return { ...s, selected: true };
            } else {
              return { ...s, selected: null };
            }
          });
          if (o.id === action.payload.categoryId) {
            return { ...o, selected: true, subopciones: suboptions };
          } else {
            return { ...o, selected: null, subopciones: suboptions };
          }
        }
      );
      state.preguntas.items[0].opciones = selectedCategory;
    },
    handleSecondaryActivity: (state, action) => {
      const selectedCategory = state.preguntas.items[0].opciones.map(
        (o: any) => {
          const suboptions = o.subopciones.map((s: any) => {
            if (s.id === action.payload.subOptionId) {
              return { ...s, selected: true };
            } else {
              return s
            }
          });
          if (o.id === action.payload.categoryId) {
            return { ...o, selected: true, subopciones: suboptions };
          } else {
            return o
          }
        }
      );
      state.preguntas.items[0].opciones = selectedCategory;
    },
    removeSecondaryActivity: (state, action) => {
      const selectedCategory = state.preguntas.items[0].opciones.map(
        (o: any) => {
          const suboptions = o.subopciones.map((s: any) => {
            if (s.id === action.payload.subOptionId) {
              return { ...s, selected: null };
            } else {
              return s;
            }
          });
          if (!suboptions.find((s: any) => s.selected)) {
            return { ...o, selected: null, subopciones: suboptions };
          } else {
            return { ...o, subopciones: suboptions }
          }
        }
      );
      state.preguntas.items[0].opciones = selectedCategory;
    },
    handleBienes: (state, action) => {
      state.bienes = action.payload;
    },
    handleArchivos: (state, action) => {
      state.archivos = [...state.archivos, action.payload];
    },
    removeArchivos: (state, action) => {
      const newArchivos = state.archivos.filter((a: any) => a.id !== action.payload)
      state.archivos = newArchivos;
    },
    editArchivos: (state, action) => {
      state.archivos = R.update(action.payload.index, { id: action.payload.id, file: action.payload.file }, state.archivos)
    },
    clearArchivos: (state) => {
      state.archivos = [];
    },
    handlePhotos: (state, action) => {
      if(state.bienes.length) {
        state.bienes = R.uniq([...state.bienes, ...action.payload])
      } else {
        state.bienes = [...action.payload]
      }
    },
    removePhoto: (state, action) => {
      state.bienes = state.bienes.filter((b:any) => b.imagenId !== action.payload)
    },
    clearBienes: (state) => {
      state.bienes = []
    },
    handleDireccionManual: (state, action) => {
      const itemCalle = state.preguntas.items.find((i: ItemDto) => i.key === 'calle')
      state.preguntas.items[0] = {...itemCalle, tipoDeDatoValor: action.payload}
    },
    changeNumeroDireccion: (state, action) => {
      const itemNumero = state.preguntas.items.find((i: ItemDto) => i.key === 'numero')
      state.preguntas.items[1] = {...itemNumero, valor: action.payload}
    },
    clearTip: (state) => {
      state.preguntas = {...state.preguntas, tip: ""}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPreguntas.fulfilled, (state, { payload }) => {
      state.preguntas = payload?.data;
      state.preguntaId = payload?.data.id;
      state.statusGetPreguntas = "success";
    });
    builder.addCase(getPreguntas.pending, (state, { payload }) => {
      state.statusGetPreguntas = "loading";
    });
    builder.addCase(getPreguntas.rejected, (state, { payload }) => {
      state.statusGetPreguntas = "failed";
    });
    builder.addCase(comenzarInspeccion.fulfilled, (state, { payload }) => {
      state.preguntas = payload?.data;
      state.preguntaId = payload?.data.id;
      state.status = "success";
    });
    builder.addCase(comenzarInspeccion.pending, (state, { payload }) => {
      state.status = "loading";
    });
    builder.addCase(comenzarInspeccion.rejected, (state, { payload }) => {
      state.status = "failed";
    });
    builder.addCase(fetchPreguntaAnterior.fulfilled, (state, { payload }) => {
      state.preguntas = payload?.data;
      state.status = "success";
    });
    builder.addCase(fetchPreguntaAnterior.pending, (state, { payload }) => {
      state.status = "loading";
    });
    builder.addCase(fetchPreguntaAnterior.rejected, (state, { payload }) => {
      state.status = "failed";
    });
    builder.addCase(obtenerPreguntas.fulfilled, (state, { payload }) => {
      state.preguntas = payload?.data;
      state.status = "success";
    });
    builder.addCase(obtenerPreguntas.pending, (state, { payload }) => {
      state.status = "loading";
    });
    builder.addCase(obtenerPreguntas.rejected, (state, { payload }) => {
      state.status = "failed";
    });
    builder.addCase(postGrupoImagenes.fulfilled, (state, { payload }) => {
      state.statusImagen = "success";
    });
    builder.addCase(postGrupoImagenes.pending, (state, { payload }) => {
      state.statusImagen = "loading";
    });
    builder.addCase(postGrupoImagenes.rejected, (state, { payload }) => {
      state.statusImagen = "failed";
    });
    builder.addCase(postImagenes.fulfilled, (state, { payload }) => {
      state.statusImagen = "success";
    });
    builder.addCase(postImagenes.pending, (state, { payload }) => {
      state.statusImagen = "loading";
    });
    builder.addCase(postImagenes.rejected, (state, { payload }) => {
      state.statusImagen = "failed";
    });
    builder.addCase(obtenerPreguntasStatus.fulfilled, (state, { payload }) => {
      state.preguntasStatus = payload?.data;
      state.status = "success";
    });
    builder.addCase(obtenerPreguntasStatus.pending, (state, { payload }) => {
      state.status = "loading";
    });
    builder.addCase(obtenerPreguntasStatus.rejected, (state, { payload }) => {
      state.status = "failed";
    });
    builder.addCase(backToLocal.fulfilled, (state, { payload }) => {
      state.preguntas = payload?.data;
      state.status = "success";
    });
    builder.addCase(backToLocal.pending, (state, { payload }) => {
      state.status = "loading";
    });
    builder.addCase(backToLocal.rejected, (state, { payload }) => {
      state.status = "failed";
    });
  },
});

export const {
  changeCodigoTest,
  changeValue,
  clearValue,
  handleExclusiveValue,
  handleValue,
  handleActivity,
  handleSecondaryActivity,
  removeSecondaryActivity,
  handleBienes,
  handlePhotos,
  removePhoto,
  handleDireccionManual,
  changeNumeroDireccion,
  handleInspeccion,
  clearBienes,
  clearToggleButtonValues,
  saveInitialCoords,
  clearTip,
  handleArchivos,
  clearArchivos,
  removeArchivos,
  editArchivos
} = preguntaSlice.actions;

export const selectPreguntas = (state: RootState) => state.preguntas.preguntas;
export const selectCoords = (state: RootState) => state.preguntas.inspeccion.coordenadas;
export const selectPreguntasStatus = (state: RootState) => state.preguntas.preguntasStatus;
export const selectBienes = (state: RootState) => state.preguntas.bienes;
export const selectArchivos = (state: RootState) => state.preguntas.archivos;
export const selectId = (state: RootState) => state.preguntas.inspeccion.id;
export const selectPreguntaId = (state: RootState) => state.preguntas.preguntaId;
export const selectUploadStatus = (state: RootState) => state.preguntas.statusImagen;
export const selectStatusGetPreguntas = (state: RootState) => state.preguntas.statusGetPreguntas;
export const codigoTest = (state: RootState) => state.preguntas.codigoTest;

const esComentarioValido = (opcion: OpcionDto) => {
  return opcion.selected &&
         opcion.tieneComentarios &&
         opcion.comentarios &&
         opcion.comentarios !== ""
}

const opcionesValidas = (opciones: OpcionSubItemDto[] | SubopcionDto[]) => {
  //SUBOPCIONES DE LAS OPCIONES/OPCIONES DE LAS SUBPREGUNTAS
  if(!opciones.length) {
    return true
  }
  return opciones.some((opcion: OpcionSubItemDto | SubopcionDto) => opcion.selected === true || opcion.tipoDeDatoValor === 1)
}

const subpreguntasValidas = (subpreguntas: SubItemDto[]) => {
  if(!subpreguntas.length) {
    return true
  }

  return subpreguntas.every(s => opcionesValidas(s.opciones))
}

const subopcionesValidas = (subopciones: SubopcionDto[]) => {
  return opcionesValidas(subopciones)
}

const opcionDeItemValido = (opcion: OpcionDto) => {
  /* if(opcion.tieneComentarios) {
    return esComentarioValido(opcion)
  } */
  
  return opcion.selected &&
         /* !opcion.tieneComentarios && */
         (subopcionesValidas(opcion.subopciones) &&
         subpreguntasValidas(opcion.subpreguntas))
}

const opcionesDeItemValidas = (opciones: OpcionDto[]) => {
  const opcionesSeleccionadas = opciones.filter((o: OpcionDto) => o.selected)

  return opcionesSeleccionadas.length && opcionesSeleccionadas.every(opcionDeItemValido)
}

export const validarCamposObligatorios = (state: RootState) => {
  const esValido = (i: ItemDto) => {
    if(i.obligatorio) {
      return (i.valor !== "" && i.valor != null && !i.opciones.length) || (i.valor !== "" && i.valor != null && i.tipoItem === 16)
       || opcionesDeItemValidas(i.opciones)
    }
    return true
  }

  return state.preguntas.preguntas?.items?.reduce((acc: any, e: any) => {
    return acc && esValido(e)
  }, true)
}

export default preguntaSlice.reducer;
