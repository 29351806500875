import React from "react";

const IconIndustria = () => {
  return (
    <svg
      width="106"
      height="106"
      viewBox="0 0 106 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="5" y="5" width="96" height="96" rx="8" fill="#EBF1FE" />
      <path
        d="M67.6854 23.0962C67.6826 23.1503 67.6812 23.2049 67.6812 23.2598C67.6812 24.9826 66.2847 26.379 64.562 26.379C62.8392 26.379 61.4428 24.9826 61.4428 23.2598C61.4428 21.537 60.0466 20.1406 58.3238 20.1406C56.601 20.1406 55.2046 21.537 55.2046 23.2598C55.2046 24.9826 53.8082 26.379 52.0854 26.379C50.3627 26.379 48.9663 24.9826 48.9663 23.2598C48.9663 21.537 47.5698 20.1406 45.8471 20.1406C44.1243 20.1406 42.7279 21.537 42.7279 23.2598C42.7279 24.9826 41.3315 26.379 39.6087 26.379C37.8859 26.379 36.4895 24.9826 36.4895 23.2598C36.4895 21.537 35.0931 20.1406 33.3703 20.1406C31.6476 20.1406 30.2511 21.537 30.2511 23.2598L30.2513 23.378M26.9678 85.2782V67.5663H35.1746V85.2782"
        stroke="#D0DFFB"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2007 85.2774H84.7817V46.6746L68.8864 62.5699V46.6746L52.9913 62.5699V46.6746L37.096 62.5699L34.8253 30.7793H25.7422L21.2007 62.5699V85.2774Z"
        stroke="#D0DFFB"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.9408 74.8735H40.5913V68.4336H56.9408V74.8735ZM78.7399 74.8735H62.3905V68.4336H78.7399V74.8735Z"
        stroke="#D0DFFB"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clip-path="url(#clip0_4227_28108)">
        <path
          d="M53 48.334V57.6673"
          stroke="#0046CF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.3335 53H57.6668"
          stroke="#0046CF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4227_28108">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(45 45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconIndustria;
