import React, { useEffect, useState } from "react";
import { DropdownInput } from "../DropdownInput/DropdownInput";

export const YearDropdownInput = (props: any) => {
  const getYears = () => {
    let years = [];
    const maxYear = new Date().getFullYear();
    const minYear = maxYear - 100;

    for (let i = maxYear; i >= minYear; i--) {
      years.push(i.toString());
    }
    return years;
  };

  const options = getYears()

  return (
    <DropdownInput
      label={props.label}
      index={props.index}
      type="number"
      disable={props.disable}
      prefetchedValue={props.prefetchedValue}
      error={props.error}
      options={options}
    />
  );
};
