import React from "react";

const Aerotermia = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.7554 15.316L25.8634 10.7135V3.57157C25.8634 3.27298 25.6207 3.03031 25.3221 3.03031H21.0994C20.8008 3.03031 20.5581 3.27298 20.5581 3.57157V5.72218L16.3733 1.78451C16.1649 1.58876 15.8401 1.58876 15.6317 1.78451L3.67442 13.0301C3.66269 13.0409 3.65006 13.0517 3.63923 13.0635L1.24416 15.316C1.02675 15.5208 1.01593 15.8636 1.2207 16.081C1.32715 16.1947 1.47059 16.2515 1.61492 16.2515C1.74753 16.2515 1.88104 16.2028 1.98569 16.1044L3.48678 14.6927V31.0964C3.48678 31.395 3.72944 31.6377 4.02804 31.6377H27.968C28.2666 31.6377 28.5092 31.395 28.5092 31.0964V14.6881L30.0148 16.1044C30.2322 16.3092 30.575 16.2984 30.7798 16.081C30.9837 15.8627 30.9738 15.5208 30.7554 15.316ZM21.6415 4.11283H24.7817V9.69502L21.6415 6.74065V4.11283ZM27.4258 30.5552H4.5693V13.6742L16.0025 2.92206L27.4258 13.6697V30.5552Z"
        fill="#635F76"
      />
      <path
        d="M17.2627 15.2061C17.2627 14.9075 17.02 14.6648 16.7214 14.6648C16.4228 14.6648 16.1802 14.9075 16.1802 15.2061C16.1802 15.9304 15.9132 16.2678 15.5749 16.6945C15.2014 17.1663 14.7368 17.7545 14.7368 18.855C14.7368 19.9556 15.2014 20.5438 15.5749 21.0156C15.9132 21.4432 16.1802 21.7806 16.1802 22.5049C16.1802 23.2293 15.9132 23.5667 15.5749 23.9943C15.2014 24.4661 14.7368 25.0543 14.7368 26.1548C14.7368 26.4534 14.9795 26.6961 15.2781 26.6961C15.5767 26.6961 15.8193 26.4534 15.8193 26.1548C15.8193 25.4305 16.0864 25.0931 16.4246 24.6655C16.7981 24.1937 17.2627 23.6055 17.2627 22.5049C17.2627 21.4044 16.7981 20.8162 16.4246 20.3444C16.0864 19.9168 15.8193 19.5794 15.8193 18.855C15.8193 18.1307 16.0864 17.7933 16.4246 17.3666C16.7981 16.8939 17.2627 16.3066 17.2627 15.2061Z"
        fill="#635F76"
      />
      <path
        d="M12.9146 15.2061C12.9146 14.9075 12.6719 14.6648 12.3733 14.6648C12.0747 14.6648 11.832 14.9075 11.832 15.2061C11.832 15.9304 11.565 16.2678 11.2267 16.6945C10.8533 17.1663 10.3887 17.7545 10.3887 18.855C10.3887 19.9556 10.8533 20.5438 11.2267 21.0156C11.565 21.4432 11.832 21.7806 11.832 22.5049C11.832 23.2293 11.565 23.5667 11.2267 23.9943C10.8533 24.4661 10.3887 25.0543 10.3887 26.1548C10.3887 26.4534 10.6313 26.6961 10.9299 26.6961C11.2285 26.6961 11.4712 26.4534 11.4712 26.1548C11.4712 25.4305 11.7382 25.0931 12.0765 24.6655C12.45 24.1937 12.9146 23.6055 12.9146 22.5049C12.9146 21.4044 12.45 20.8162 12.0765 20.3444C11.7382 19.9168 11.4712 19.5794 11.4712 18.855C11.4712 18.1307 11.7382 17.7933 12.0765 17.3666C12.4491 16.8939 12.9146 16.3066 12.9146 15.2061Z"
        fill="#635F76"
      />
      <path
        d="M20.1676 18.855C20.1676 18.1307 20.4346 17.7933 20.7729 17.3666C21.1464 16.8948 21.611 16.3066 21.611 15.2061C21.611 14.9075 21.3683 14.6648 21.0697 14.6648C20.7711 14.6648 20.5284 14.9075 20.5284 15.2061C20.5284 15.9304 20.2614 16.2678 19.9231 16.6945C19.5497 17.1663 19.0851 17.7545 19.0851 18.855C19.0851 19.9556 19.5497 20.5438 19.9231 21.0156C20.2614 21.4432 20.5284 21.7806 20.5284 22.5049C20.5284 23.2293 20.2614 23.5667 19.9231 23.9943C19.5497 24.4661 19.0851 25.0543 19.0851 26.1548C19.0851 26.4534 19.3277 26.6961 19.6263 26.6961C19.9249 26.6961 20.1676 26.4534 20.1676 26.1548C20.1676 25.4305 20.4346 25.0931 20.7729 24.6655C21.1464 24.1937 21.611 23.6055 21.611 22.5049C21.611 21.4044 21.1464 20.8162 20.7729 20.3444C20.4346 19.9168 20.1676 19.5794 20.1676 18.855Z"
        fill="#635F76"
      />
    </svg>
  );
};

export default Aerotermia;
