import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BienesList } from "../../Components/BienesList/BienesList";
import { BlockTitle } from "../../Components/BlockTitle/BlockTitle";
import { ChoiceCard } from "../../Components/ChoiceCard/ChoiceCard";
import { CustomButton } from "../../Components/CustomButton/CustomButton";
import { CustomCheckbox } from "../../Components/CustomCheckbox/CustomCheckbox";
import { CustomInput } from "../../Components/CustomInput/CustomInput";
import { DateInput } from "../../Components/DateInput/DateInput";
import { Description } from "../../Components/Description/Description";
import { ExclusiveToggleButtons } from "../../Components/ExclusiveToggleButtons/ExclusiveToggleButtons";
import { ImageBlock } from "../../Components/ImageBlock/ImageBlock";
import { InfoCard } from "../../Components/InfoCard/InfoCard";
import { InputCurrency } from "../../Components/InputCurrency/InputCurrency";
import { ModalAyuda } from "../../Components/ModalAyuda/ModalAyuda";
import { ModalEspera } from "../../Components/ModalEspera/ModalEspera";
import { MultipleToggleButtons } from "../../Components/MultipleToggleButtons/MultipleToggleButtons";
import { Notification } from "../../Components/Notification/Notification";
import { NumberInput } from "../../Components/NumberInput/NumberInput";
import { SubBlockTitle } from "../../Components/SubBlockTitle/SubBlockTitle";
import { Subtitle } from "../../Components/Subtitle/Subtitle";
import { YearDropdownInput } from "../../Components/YearDropdownInput/YearDropdownInput";
import { TipoItem } from "../../model/tipoItemEnum";
import "./LayoutBienes.scss";
import { postRespuesta, subirImagen } from "../LayoutPregunta/PreguntasAPI";
import {
  clearBienes,
  getPreguntas,
  obtenerPreguntasStatus,
  postGrupoImagenes, selectBienes,
  selectId,
  selectPreguntas,
  selectStatusGetPreguntas,
  selectUploadStatus,
  validarCamposObligatorios
} from "../LayoutPregunta/preguntaSlice";
import { BienesListHogar } from "../../Components/BienesList/BienesListHogar";
import { ModalConexion } from "../../Components/ModalConexion/ModalConexion";
import { dataUrlToFile, purgeIndexedDbWithDelay } from "../../utils";
import { persistor } from "../../app/store";
import useNetwork from "../../hooks/useNetwork";

const LayoutBienes = () => {
  const dispatch = useDispatch();
  const preguntas = useSelector(selectPreguntas);
  const preguntaId: any = useSelector(selectId);
  const bienes = useSelector(selectBienes);
  const status = useSelector(selectUploadStatus);
  const [openAyuda, setOpenAyuda] = useState(false);
  const [openEspera, setOpenEspera] = useState(false)
  const [loading, setLoading] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [hasUnconfirmedCards, setHasUnconfirmedCards] = useState(false)
  const [noConnection, setNoConnection] = useState(false)
  const statusSiguientePregunta = useSelector(selectStatusGetPreguntas);
  const obligatorios = useSelector(validarCamposObligatorios);
  const online = useNetwork()
  const containerRef = useRef<any>()
  const [errorText, setErrorText] = useState("Ocurrió un error inesperado. Reintente nuevamente más tarde.");

  useEffect(() => {
    window.scrollTo(0, 0);
    containerRef?.current?.scrollTo(0, 0)
    //@ts-ignore
    dispatch(obtenerPreguntasStatus(preguntaId))
  }, [preguntas.id]);

  const toBienesData = (data: any[], id: number) => {
    return data.reduce(
      (acc: any, d: any) => {
        return {
          ...acc,
          imagenes: [...acc.imagenes, d.imagen],
          detalles: [...acc.detalles, d.detalle],
          importes: [...acc.importes, d.importe],
        };
      },
      { id, imagenes: [], detalles: [], importes: [] }
    );
  };

  const handleSiguiente = async () => {
      setLoading(true);
      setOpenEspera(true)
      setUploadError(false);

      const newBienes = bienes.filter((b: any) => !b.url)
      if (newBienes.length) {
        let results: any = [];

        const photos = await Promise.all(newBienes.map(async (b: any, index: number) => {
          const imagen = await dataUrlToFile(b.imagen, b.id.toString())
          return {
            id: preguntas.items[0].id,
            imagenes: [imagen],
            detalles: [b.detalle],
            importes: [b.importe],
            tipoElemento: [b.tipoElemento] || [],
            esUltima: index === bienes.length - 1,
          };
        }));

        for (let i = 0; i < photos.length; i++) {
          // @ts-ignore
          try {
            const response = await subirImagen(photos[i]);
            results = [...results, response];
            
          }
          catch(error) {
            console.log(error)
            
          }
        }

        const badRequests = results.filter((r: any) =>  r.status !== 200);

        const badStatus = badRequests.length > 0;

        console.log(badRequests);


        
        //Si todas las imagenes dieron OK, el postRespuesta(preguntas) YA lo hizo la API de imagenes
        if (!badStatus) {
          const response = await postRespuesta(preguntas);
        if (response.status === 200) {
          // @ts-ignore
            dispatch(getPreguntas(preguntaId));
            dispatch(clearBienes())
            purgeIndexedDbWithDelay()
        }
        } else {
          //Alguna imagen fallo y no se proceso postPregunta
          if(badRequests.some((r: any) => r.message === "Network Error")){
            setErrorText("Error de conexión. Porfavor conéctese a internet e intente nuevamente.");
          }
          else{
            setErrorText("La imagen que se ha intentado subir es muy grande, por favor intente reduciendo la calidad de imagen desde la configuración de su cámara. Máximo permitido: 10 MB por imagen.")
          }
          setUploadError(true);
          setLoading(false)
        }
      } else {
        const response = await postRespuesta(preguntas);
        if (response.status === 200)
        // @ts-ignore
          dispatch(getPreguntas(preguntaId));
          purgeIndexedDbWithDelay()
      }
      setOpenEspera(false);
  };

  useEffect(() => {
    if(statusSiguientePregunta !== "loading")
      setLoading(false)
  }, [statusSiguientePregunta])

  useEffect(() => {
    if(!online && openEspera) {
      setOpenEspera(false)
      setLoading(false);
      setNoConnection(true);
    }
  }, [online])

  const renderInputs = (e: any, index: number) => {
    switch (e.tipoItem) {
      case TipoItem.listaBienes: {
        return (
          <>
            {uploadError 
              ? <Notification text={errorText} icon="warning" />
              : null
            }
            <BienesList key={e.keyId} buttonText={e.etiqueta} confirmarText={e.consigna} hasUnconfirmedCards={setHasUnconfirmedCards} />
          </>
        );
      }
      case TipoItem.BienesAltoValorHogar: {
        return (
          <>
            {uploadError 
              ? <Notification text={errorText} icon="warning" />
              : null
            }
            <BienesListHogar key={e.keyId} buttonText={e.etiqueta} confirmarText={e.consigna} hasUnconfirmedCards={setHasUnconfirmedCards} />
          </>
        );
      }
    }
  };

  const renderContent = () => {
    return (
      <>
        <div>
          <div className="layout-pregunta-content-title">
            <BlockTitle
              text={preguntas.titulo}
              icon={preguntas.iconoPrincipal}
              ayuda={preguntas.ayuda ? preguntas.ayuda : null}
              fn={setOpenAyuda}
            />
          </div>
          {preguntas.subtitulo ? <Subtitle text={preguntas.subtitulo} /> : null}
        </div>
        {preguntas.titulo1 ? <SubBlockTitle text={preguntas.titulo1} /> : null}
        {preguntas.texto ? <Description text={preguntas.texto} /> : null}
        {preguntas.items?.map((e: any, index: number) => {
          return <>{renderInputs(e, index)}</>;
        })}
        {preguntas.tip ? (
          <Notification text={preguntas.tip} icon={preguntas.iconoTip} />
        ) : null}
      </>
    );
  };

  const handleContinue = () => {
    if(!online) {
      setNoConnection(true);
      return; 
    }
    if (loading) {
      return () => null;
    } else {
      return handleSiguiente()
    }
  }

  return (
    <>
      <div className={`${hasUnconfirmedCards ? "extend-layout-height" : ""} layout-pregunta`} ref={containerRef}>
        <div className="layout-pregunta-content">{renderContent()}</div>
      </div>
      <div className={`${hasUnconfirmedCards ? 'hide-button' : ""} layout-pregunta-content-actions`}>
        <CustomButton
          type="primary"
          text="Continuar"
          loading={loading}
          disable={!obligatorios}
          onClick={handleContinue}
        />
        {openAyuda ? (
          <ModalAyuda
            open={openAyuda}
            texto={preguntas.ayuda.texto}
            titulo={preguntas.ayuda.titulo}
            imagen={preguntas.ayuda.imagen}
            onClose={() => setOpenAyuda(false)}
          />
        ) : null}
        <ModalEspera key={preguntas.keyId} open={openEspera} />
        <ModalConexion open={noConnection} onClick={(e: boolean) => setNoConnection(e)} />
      </div>
    </>
  );
};

export default LayoutBienes;
