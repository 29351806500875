import { Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ChevronDown } from "../../Assets/svgs/ChevronDown";
import { ErrorIcon } from "../../Assets/svgs/ErrorIcon";
import { changeValue } from "../../features/LayoutPregunta/preguntaSlice";
import { ItemControl } from "../../model/itemControl";
import "./DropdownInput.scss";

interface IProps {
  index: number;
  type: string;
  label: string;
  options: any;
  prefetchedValue?: string;
  disable?: boolean;
  error?: string;
}

export const DropdownInput = ({
  index,
  type,
  label,
  disable,
  options,
  prefetchedValue,
  error,
}: IProps) => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (prefetchedValue) setValue(prefetchedValue);
  }, []);

  const handleChange = (e: any) => {
    dispatch(changeValue({value: e, index: index}))
    setValue(e);
  };

  return (
    <div className="dropdown-input">
      <Autocomplete
        id="custom-combo-box"
        options={options}
        onChange={(e, v) => {
          handleChange(v);
        }}
        renderInput={(params: any) => (
          <div className="dropdown-input-container" ref={params.InputProps.ref}>
            <input
              {...params.inputProps}
              type={type}
              className={`dropdown-input-input ${error ? "input-error" : ""}`}
              disabled={disable}
              autoComplete="none"
              value={params.inputProps.value || prefetchedValue}
            />
            <div className="chevron-down">
              <ChevronDown />
            </div>
            <label className={value && "filled"}>{label}</label>
            {error ? (
              <span className="error-message-input">
                <ErrorIcon />
                {error}
              </span>
            ) : null}
          </div>
        )}
      />
    </div>
  );
};
