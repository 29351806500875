import React from 'react'

export const Construction = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_457_2230)">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.5 16C2.67157 16 2 16.6716 2 17.5C2 18.3284 2.67157 19 3.5 19C4.32843 19 5 18.3284 5 17.5C5 16.6716 4.32843 16 3.5 16ZM0 17.5C0 15.567 1.567 14 3.5 14C5.433 14 7 15.567 7 17.5C7 19.433 5.433 21 3.5 21C1.567 21 0 19.433 0 17.5Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.5 16C11.6716 16 11 16.6716 11 17.5C11 18.3284 11.6716 19 12.5 19C13.3284 19 14 18.3284 14 17.5C14 16.6716 13.3284 16 12.5 16ZM9 17.5C9 15.567 10.567 14 12.5 14C14.433 14 16 15.567 16 17.5C16 19.433 14.433 21 12.5 21C10.567 21 9 19.433 9 17.5Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M2 20C2 19.4477 2.44772 19 3 19H13C13.5523 19 14 19.4477 14 20C14 20.5523 13.5523 21 13 21H3C2.44772 21 2 20.5523 2 20Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M2 15C2 14.4477 2.44772 14 3 14H13C13.5523 14 14 14.4477 14 15C14 15.5523 13.5523 16 13 16H3C2.44772 16 2 15.5523 2 15Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7 7C7 6.44772 7.44772 6 8 6H10C11.0609 6 12.0783 6.42143 12.8284 7.17157C13.5786 7.92172 14 8.93913 14 10V15C14 15.5523 13.5523 16 13 16C12.4477 16 12 15.5523 12 15V10C12 9.46957 11.7893 8.96086 11.4142 8.58579C11.0391 8.21071 10.5304 8 10 8H9V12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12V7Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5 13.0354C5.02038 13.0204 5.0634 13 5.125 13H13C13.5523 13 14 12.5523 14 12C14 11.4477 13.5523 11 13 11H5.125C4.59695 11 4.07025 11.1854 3.66514 11.5455C3.2562 11.909 3 12.429 3 13V15C3 15.5523 3.44772 16 4 16C4.55228 16 5 15.5523 5 15V13.0354Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.6478 3.00564C18.9622 3.03913 19.2422 3.2193 19.4029 3.49152L22.8611 9.34752C23.1419 9.82308 22.984 10.4362 22.5085 10.7171C22.0329 10.9979 21.4198 10.84 21.1389 10.3645L18.3644 5.66603L13.7346 10.6785C13.3599 11.0842 12.7272 11.1093 12.3215 10.7346C11.9158 10.3599 11.8907 9.72721 12.2654 9.3215L17.8073 3.3215C18.0218 3.08927 18.3335 2.97214 18.6478 3.00564Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.8169 13.9288C19.0307 13.975 19.2552 13.9998 19.4857 14C20.0195 13.9995 20.5301 13.8667 20.9515 13.6319C21.3727 13.3972 21.6704 13.081 21.837 12.746C22.0013 12.4153 22.0396 12.0659 21.9599 11.7315C21.934 11.6232 21.8954 11.5148 21.8434 11.408L18.8169 13.9288ZM21.9713 10L21.3313 9.23163L16.36 13.3723C16.132 13.5622 16.0001 13.8436 16 14.1403C15.9999 14.4371 16.1316 14.7186 16.3595 14.9086C17.2142 15.6213 18.3403 15.9992 19.4849 16H19.4864C20.3427 15.9994 21.1908 15.788 21.9249 15.379C22.6595 14.9698 23.2625 14.3713 23.6279 13.6363C23.9955 12.8969 24.0965 12.069 23.9053 11.2675C23.715 10.4695 23.252 9.76594 22.6118 9.23202L21.9713 10Z" fill="#89869B"/>
        </g>
        <defs>
        <clipPath id="clip0_457_2230">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
    </svg>
  )
}
