
import { CustomInput } from "../CustomInput/CustomInput";


export const NumberInput = (props: any) => {
  return (
    <CustomInput
      type="number"
      index={props.index}
      label={props.label}
      disable={props.disable}
      prefetchedValue={props.prefetchedValue}
      length={props.length}
      onChange={props.onChange}
      defaultValue={props.defaultValue}
      isSuboption={props.isSuboption}
      indexSubpregunta={props.indexSubpregunta}
      tworows = {props.tworows}
    />
  );
};
