import React from 'react'

export const Add = () => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5 3.33337V12.6667" stroke="#0046CF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.83203 8H13.1654" stroke="#0046CF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
