import React from 'react'

export const Pistas = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.3333 4.66669H4.66667C3.19391 4.66669 2 5.86059 2 7.33335V24.6667C2 26.1394 3.19391 27.3334 4.66667 27.3334H27.3333C28.8061 27.3334 30 26.1394 30 24.6667V7.33335C30 5.86059 28.8061 4.66669 27.3333 4.66669Z" stroke="#635F76" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16 4V5.33333" stroke="#635F76" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16 8.88934V24.8893" stroke="#635F76" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="1.78 2.67"/>
        <path d="M16 26.6667V28" stroke="#635F76" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.66663 8.66669V23.3334" stroke="#635F76" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M23.3334 8.66669V23.3334" stroke="#635F76" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22.6667 16H9.33337" stroke="#635F76" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M29.3333 8.66669H2.66663" stroke="#635F76" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M29.3333 23.3333H2.66663" stroke="#635F76" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
