import React from "react";

const NoPosee = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9782 30.2265C9.48429 30.2265 3.3894 24.1316 3.3894 16.6377C3.3894 9.14371 9.48429 3.04883 16.9782 3.04883C24.4722 3.04883 30.5671 9.14371 30.5671 16.6377C30.5671 24.1316 24.4722 30.2265 16.9782 30.2265ZM16.9782 3.94185C9.97545 3.94185 4.28243 9.63488 4.28243 16.6377C4.28243 23.6405 9.97545 29.3335 16.9782 29.3335C23.981 29.3335 29.6741 23.6405 29.6741 16.6377C29.6741 9.63488 23.981 3.94185 16.9782 3.94185Z"
        fill="#635F76"
      />
      <path
        d="M11.9937 21.687C11.8201 21.5133 11.8201 21.2292 11.9937 21.0555L20.9868 12.0624C21.1605 11.8888 21.4446 11.8888 21.6183 12.0624C21.7919 12.2361 21.7919 12.5202 21.6183 12.6939L12.6252 21.687C12.4515 21.8606 12.1674 21.8606 11.9937 21.687Z"
        fill="#635F76"
      />
      <path
        d="M20.9869 21.6817L11.9938 12.6886C11.8201 12.515 11.8201 12.2308 11.9938 12.0572C12.1674 11.8835 12.4516 11.8835 12.6252 12.0572L21.6183 21.0503C21.792 21.2239 21.792 21.5081 21.6183 21.6817C21.4447 21.8554 21.1605 21.8554 20.9869 21.6817Z"
        fill="#635F76"
      />
    </svg>
  );
};

export default NoPosee;
