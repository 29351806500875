import React from "react";

const FingerPrint = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1071_1277)">
        <path
          d="M17.4553 0.484985C18.1078 0.567846 18.7707 0.629991 19.4232 0.723208C21.474 1.01322 23.4108 1.66574 25.2752 2.55649C25.5238 2.67042 25.7723 2.79471 26.0106 2.919C26.4352 3.14687 26.6113 3.61296 26.4145 4.00654C26.2177 4.41049 25.762 4.56585 25.3166 4.36906C24.7884 4.13084 24.2809 3.8719 23.7526 3.63367C21.0182 2.41149 18.1388 1.95575 15.1662 2.13183C12.6597 2.27684 10.2671 2.90865 8.01954 4.03762C7.79168 4.15155 7.57417 4.27584 7.34631 4.37942C6.932 4.56585 6.45556 4.40013 6.26912 4.0169C6.08269 3.63367 6.21733 3.14687 6.63164 2.93972C7.42917 2.54613 8.2267 2.15255 9.0553 1.82111C10.9714 1.07536 12.9704 0.650706 15.0419 0.526415C15.1352 0.526415 15.2284 0.495343 15.3216 0.484985C16.0363 0.484985 16.7509 0.484985 17.4656 0.484985H17.4553Z"
          fill="#635F76"
        />
        <path
          d="M4.68445 21.2726C4.68445 22.9402 5.05732 24.7838 5.69949 26.5653C5.80307 26.845 5.83414 27.1143 5.67878 27.3836C5.51305 27.6632 5.27483 27.8186 4.95375 27.7875C4.60159 27.7565 4.3323 27.5804 4.22872 27.2282C3.95943 26.3375 3.6487 25.4571 3.45191 24.5456C2.97546 22.3395 2.88224 20.1126 3.38976 17.8961C3.93871 15.5139 5.19197 13.5252 6.97347 11.8784C9.22105 9.80686 11.8829 8.61574 14.928 8.34645C18.9986 7.97358 22.5926 9.15433 25.5859 11.9405C28.1028 14.2813 29.4597 17.2021 29.5322 20.6615C29.584 22.8781 28.2996 24.6699 26.1763 25.3846C23.618 26.2546 20.7904 24.9288 19.8893 22.4016C19.7132 21.8941 19.6304 21.3348 19.6096 20.7962C19.5475 19.0768 17.9524 17.7097 16.1813 17.7614C14.6484 17.8029 13.3123 18.7765 13.0119 20.1644C12.8773 20.7962 12.898 21.4798 12.9808 22.1323C13.4262 25.5814 15.2491 28.1397 18.1803 29.9315C19.0503 30.4701 20.0136 30.8016 20.9976 31.0916C21.2047 31.1537 21.4119 31.2573 21.5569 31.4023C21.8262 31.6612 21.8469 32.003 21.6604 32.3241C21.4844 32.6245 21.1426 32.7799 20.8008 32.697C19.0711 32.2413 17.4553 31.5473 16.0363 30.4287C14.4102 29.134 13.1155 27.57 12.2869 25.6539C11.5619 23.9863 11.2097 22.2359 11.3236 20.413C11.4376 18.6418 12.8151 17.0157 14.6795 16.4046C17.0617 15.6174 19.6822 16.705 20.7801 18.9422C21.0493 19.4911 21.2047 20.0815 21.2151 20.703C21.2254 22.1737 21.9297 23.2199 23.2659 23.7688C24.5916 24.3178 25.8552 24.0795 26.9324 23.1266C27.6574 22.4845 27.9785 21.6455 27.9371 20.6512C27.875 19.3669 27.6471 18.1343 27.1499 16.9536C26.3731 15.0685 25.1613 13.5356 23.5455 12.3134C22.1162 11.2258 20.5004 10.5008 18.7396 10.1487C13.7784 9.13362 8.5996 11.2258 5.99986 15.6174C5.07804 17.1814 4.62231 18.994 4.65338 21.283L4.68445 21.2726Z"
          fill="#635F76"
        />
        <path
          d="M16.72 12.1995C19.7547 12.3238 22.4787 13.7117 24.3017 16.6739C25.0785 17.9375 25.4306 19.3462 25.4617 20.8376C25.4617 21.2934 25.1406 21.6662 24.7056 21.6973C24.2913 21.7284 23.9184 21.3969 23.8563 20.9412C23.7838 20.3716 23.7631 19.7812 23.6181 19.2322C23.0795 17.14 21.7744 15.6278 19.8686 14.6646C16.9582 13.1938 13.5505 13.6392 11.1269 15.8039C9.5629 17.2021 8.78609 18.9629 8.81716 21.0759C8.83787 23.1474 9.24182 25.136 10.2361 26.9797C10.9508 28.3054 11.8001 29.538 12.8773 30.6048C13.0016 30.7291 13.1259 30.8637 13.2502 30.988C13.592 31.3712 13.592 31.8373 13.2502 32.1688C12.9291 32.4795 12.4423 32.4588 12.1005 32.1066C11.2201 31.2159 10.4019 30.2837 9.70791 29.2376C8.56858 27.5079 7.75033 25.6539 7.42925 23.6031C7.21174 22.1841 7.07709 20.7651 7.36711 19.3462C7.86427 16.8707 9.24182 14.9856 11.3651 13.6392C12.8773 12.6759 14.5552 12.2305 16.7407 12.2202L16.72 12.1995Z"
          fill="#635F76"
        />
        <path
          d="M16.3885 4.42102C19.5579 4.44174 22.5719 5.16676 25.3581 6.77218C27.4711 7.98401 29.2318 9.59978 30.6612 11.5781C30.9408 11.9613 30.8683 12.4688 30.5162 12.7278C30.1537 12.9971 29.6565 12.9142 29.3561 12.5102C28.3307 11.1223 27.15 9.90015 25.731 8.91619C23.6491 7.45578 21.3394 6.58574 18.8225 6.22323C16.0778 5.82964 13.3848 6.06787 10.754 6.97933C7.91605 7.96329 5.57525 9.65157 3.72125 12.0234C3.57624 12.2099 3.4416 12.417 3.28623 12.6035C2.97551 12.9867 2.46799 13.0488 2.11583 12.7588C1.79475 12.4999 1.7326 12.0027 2.0019 11.6299C3.59696 9.40299 5.59596 7.65257 8.02998 6.39931C10.6401 5.05283 13.4366 4.44174 16.3885 4.43138V4.42102Z"
          fill="#635F76"
        />
        <path
          d="M24.2395 29.4449C21.7019 29.3931 19.4958 28.5749 17.7661 26.6898C16.2953 25.0947 15.4667 23.2097 15.4356 21.0139C15.4356 20.496 15.736 20.1335 16.1813 20.092C16.6164 20.061 16.9996 20.4028 17.0307 20.9103C17.0825 21.946 17.2689 22.9507 17.7557 23.8725C18.9779 26.1719 20.8422 27.5391 23.473 27.7359C24.2395 27.798 25.0163 27.6945 25.7931 27.6427C26.0831 27.622 26.3628 27.622 26.5907 27.8188C26.8185 28.0156 26.9428 28.2641 26.86 28.5749C26.7771 28.9063 26.5803 29.1549 26.2385 29.2067C25.5756 29.2999 24.9127 29.362 24.2395 29.4449Z"
          fill="#635F76"
        />
      </g>
      <defs>
        <clipPath id="clip0_1071_1277">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.332581 0.602051)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FingerPrint;
