import React from 'react'

export const CajaFuerte = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.57143 5H25.4286C26.2667 5 27 5.73333 27 6.57143V25.4286C27 26.2667 26.2667 27 25.4286 27H6.57143C5.73333 27 5 26.2667 5 25.4286V6.57143C5 5.73333 5.73333 5 6.57143 5Z" stroke="#635F76" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z" stroke="#635F76" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.0625 8V10.1333H7V13.3333H8.0625V18.6667H7V21.8667H8.0625V24H24V8H8.0625Z" stroke="#635F76" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16 18C17.1046 18 18 17.1046 18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18Z" stroke="#635F76" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 15L19 13" stroke="#635F76" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
