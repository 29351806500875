import React, { useEffect, useState } from 'react'
import './TextArea.scss'
import { useDispatch } from 'react-redux';
import { changeValue } from '../../features/LayoutPregunta/preguntaSlice';

interface ITextAreaProps {
    consigna: string;
    etiqueta: string;
    onInputChange?: (e: string, i: number) => void;
    index: number;
    defaultValue: string;
}

const TextArea  = ({consigna, etiqueta, onInputChange, index, defaultValue}: ITextAreaProps) => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch()

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    }
  }, [])

  const handleChange = (e: any) => {
    setValue(e.target.value)
    onInputChange ? onInputChange(e.target.value, index) : dispatch(changeValue({ value: e.target.value, index: index }));
  }

  return (
    <div className="textarea">
        <h2>{etiqueta}</h2>
        <p>{consigna}</p>
        <textarea maxLength={200} onChange={handleChange} value={value}></textarea>
        <span className="textarea-chars">{`${value.length}/200`}</span>
    </div>
  )
}

export default TextArea