import React from "react";
import "./Description.scss";

interface IProps {
  text: string;
}

export const Description = ({ text }: IProps) => {
  return (
    <div className="description">
      <p>{text}</p>
    </div>
  );
};

