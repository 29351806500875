import React from 'react'

export const MenosM = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.32843 19.5352L4.14645 16.3532C3.95118 16.158 3.95118 15.8414 4.14645 15.6461L7.32843 12.4641C7.52369 12.2689 7.84027 12.2689 8.03553 12.4641C8.2308 12.6594 8.2308 12.976 8.03553 13.1712L5.70711 15.4997L22.5 15.4997C22.7761 15.4997 23 15.7235 23 15.9997C23 16.2758 22.7761 16.4997 22.5 16.4997L5.70711 16.4997L8.03553 18.8281C8.2308 19.0234 8.2308 19.3399 8.03553 19.5352C7.84027 19.7305 7.52369 19.7305 7.32843 19.5352Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M27.5 10C27.7761 10 28 10.2442 28 10.5455V21.4545C28 21.7558 27.7761 22 27.5 22C27.2239 22 27 21.7558 27 21.4545V10.5455C27 10.2442 27.2239 10 27.5 10Z" fill="#635F76"/>
    </svg>
  )
}
