import React from 'react'

export const Ventanas = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.6 25.2H25.6V7.6C25.918 7.59905 26.2226 7.47232 26.4475 7.24748C26.6723 7.02264 26.7991 6.71797 26.8 6.4V4.4C26.8 4.29391 26.7579 4.19217 26.6828 4.11716C26.6078 4.04214 26.5061 4 26.4 4H5.6C5.49391 4 5.39217 4.04214 5.31716 4.11716C5.24214 4.19217 5.2 4.29391 5.2 4.4V6.4C5.20095 6.71797 5.32768 7.02264 5.55252 7.24748C5.77736 7.47232 6.08203 7.59905 6.4 7.6V25.2H4.4C4.29391 25.2 4.19217 25.2421 4.11716 25.3172C4.04214 25.3922 4 25.4939 4 25.6V27.6C4 27.7061 4.04214 27.8078 4.11716 27.8828C4.19217 27.9579 4.29391 28 4.4 28H27.6C27.7061 28 27.8078 27.9579 27.8828 27.8828C27.9579 27.8078 28 27.7061 28 27.6V25.6C28 25.4939 27.9579 25.3922 27.8828 25.3172C27.8078 25.2421 27.7061 25.2 27.6 25.2ZM6 6.4V4.8H26V6.4C26 6.50609 25.9579 6.60783 25.8828 6.68284C25.8078 6.75786 25.7061 6.8 25.6 6.8H6.4C6.29391 6.8 6.19217 6.75786 6.11716 6.68284C6.04214 6.60783 6 6.50609 6 6.4ZM24.8 7.6V25.2H7.2V7.6H24.8ZM27.2 27.2H4.8V26H27.2V27.2Z" fill="#635F76"/>
        <path d="M24 8H7.99998C7.89389 8 7.79215 8.04214 7.71713 8.11716C7.64212 8.19217 7.59998 8.29391 7.59998 8.4V24.4C7.59998 24.5061 7.64212 24.6078 7.71713 24.6828C7.79215 24.7579 7.89389 24.8 7.99998 24.8H24C24.1061 24.8 24.2078 24.7579 24.2828 24.6828C24.3578 24.6078 24.4 24.5061 24.4 24.4V8.4C24.4 8.29391 24.3578 8.19217 24.2828 8.11716C24.2078 8.04214 24.1061 8 24 8ZM15.6 24H8.39998V16.4H15.6V24ZM15.6 15.6H8.39998V8.8H15.6V15.6ZM23.6 24H16.4V16.4H23.6V24ZM23.6 15.6H16.4V8.8H23.6V15.6Z" fill="#635F76"/>
        <path d="M10.9173 9.7171L9.31726 11.3171L9.88295 11.8828L11.483 10.2828L10.9173 9.7171Z" fill="#635F76"/>
        <path d="M12.9172 9.71712L9.31714 13.3171L9.88282 13.8828L13.4828 10.2828L12.9172 9.71712Z" fill="#635F76"/>
    </svg>
  )
}
