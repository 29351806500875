import React from "react";

const Gallina = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8019 8.69652C20.4439 8.69652 20.1016 8.57521 19.821 8.34675C19.498 8.08405 19.2971 7.71121 19.2547 7.2967C19.2123 6.88219 19.3336 6.47661 19.5963 6.15363C19.859 5.83065 20.2318 5.62972 20.6463 5.58731C20.6992 5.58135 20.752 5.57912 20.8048 5.57912C21.0817 4.87586 21.7343 4.36684 22.5098 4.28721C23.2859 4.20684 24.0264 4.57372 24.4409 5.20628C24.493 5.19586 24.5459 5.18768 24.5987 5.18247C25.0125 5.14005 25.4188 5.26135 25.741 5.52405C26.064 5.78675 26.2649 6.15958 26.3073 6.5741C26.3498 6.98786 26.2292 7.39345 25.9665 7.71717C25.7038 8.04014 25.331 8.24182 24.9165 8.28424C24.8733 8.28945 24.8219 8.29242 24.7661 8.29242C24.5198 8.29242 24.3196 8.09224 24.3196 7.84591C24.3196 7.59958 24.5198 7.3994 24.7661 7.3994C24.787 7.3994 24.8033 7.39865 24.8182 7.39717C25.002 7.37782 25.1613 7.29224 25.2737 7.15382C25.386 7.01614 25.4374 6.84275 25.4195 6.66563C25.4017 6.48777 25.3153 6.32926 25.1777 6.21689C25.04 6.10452 24.8666 6.05317 24.6895 6.07103C24.6002 6.07996 24.5161 6.106 24.4387 6.14842C24.3256 6.21093 24.1909 6.22061 24.0696 6.1767C23.9483 6.13205 23.8523 6.03754 23.8061 5.91698C23.6186 5.42731 23.123 5.12293 22.6006 5.17652C22.0774 5.2301 21.6532 5.62898 21.5699 6.14619C21.549 6.27344 21.4746 6.38582 21.3652 6.45354C21.2558 6.522 21.1226 6.53912 20.9991 6.50191C20.9135 6.47586 20.8257 6.46768 20.7386 6.47661C20.56 6.49447 20.4007 6.58079 20.2891 6.71847C20.1767 6.85614 20.1254 7.02954 20.1432 7.20665C20.1619 7.38377 20.2474 7.54303 20.3859 7.6554C20.5235 7.76777 20.6969 7.81912 20.8733 7.80126C20.9112 7.79754 20.9432 7.79158 20.9715 7.7834C21.2097 7.71717 21.4552 7.85633 21.5215 8.09372C21.5877 8.33112 21.4486 8.57745 21.2112 8.64368C21.1352 8.66452 21.0556 8.6794 20.9671 8.68907C20.9105 8.69354 20.8562 8.69652 20.8019 8.69652Z"
        fill="#635F76"
      />
      <path
        d="M24.5868 15.8095C24.342 15.8095 24.1425 15.6123 24.1403 15.3667C24.1381 15.1204 24.3368 14.9187 24.5831 14.9165C24.6471 14.9157 24.7133 14.9098 24.7795 14.8971C25.0534 14.845 25.2923 14.6887 25.4501 14.4566C25.6078 14.2244 25.6659 13.9453 25.6138 13.6692C25.526 13.2056 24.4543 11.9985 23.7221 11.2885C23.5449 11.1166 23.5405 10.8346 23.7116 10.6575C23.8828 10.4804 24.1656 10.4759 24.3427 10.6471C24.6709 10.9648 26.3185 12.5976 26.4904 13.5025C26.5872 14.0123 26.48 14.5295 26.1875 14.9581C25.8951 15.3875 25.4538 15.677 24.9433 15.7738C24.8242 15.7961 24.7059 15.8072 24.5898 15.808C24.589 15.8095 24.5875 15.8095 24.5868 15.8095Z"
        fill="#635F76"
      />
      <path
        d="M20.8859 29.0001C20.8554 29.0001 20.8242 28.9971 20.7929 28.9904L17.3392 28.2589C17.133 28.215 16.9849 28.0327 16.9849 27.8221V24.529C16.9849 24.2827 17.1851 24.0825 17.4314 24.0825C17.6778 24.0825 17.8779 24.2827 17.8779 24.529V27.4604L20.9775 28.1168C21.2186 28.1681 21.3726 28.4048 21.322 28.6459C21.2781 28.8565 21.0928 29.0001 20.8859 29.0001Z"
        fill="#635F76"
      />
      <path
        d="M17.4329 28.192C17.2558 28.192 17.0884 28.0863 17.0184 27.9114C16.9269 27.6822 17.0378 27.4225 17.2662 27.331L20.72 25.9453C20.9485 25.8538 21.2089 25.9647 21.3005 26.1931C21.392 26.4216 21.2811 26.6821 21.0527 26.7736L17.5989 28.1593C17.5446 28.1816 17.488 28.192 17.4329 28.192Z"
        fill="#635F76"
      />
      <path
        d="M14.5753 25.3812C12.7647 25.3812 11.1989 24.8215 9.78867 23.6703C8.24821 22.4126 6.37584 20.6682 6.20616 19.5028C6.18533 19.3577 6.23667 19.2119 6.34309 19.1121C6.44951 19.0124 6.59984 18.9715 6.74198 19.002L7.95128 19.2639L7.55016 18.292C7.48021 18.1231 7.52114 17.9281 7.65212 17.8009C7.78384 17.6736 7.97956 17.6394 8.14626 17.7146C8.1656 17.7235 10.2143 18.6128 12.6121 17.7518L17.3265 15.7804C18.8045 15.0578 19.7771 13.6751 19.8054 12.2545V12.2381C19.827 11.1761 19.853 9.85521 20.2735 8.78358C20.7982 7.44553 21.8244 6.76758 23.3224 6.76758C24.8175 6.76758 25.52 8.01855 25.7373 8.506C26.3758 8.80814 27.763 9.57018 27.7533 10.4833C27.7518 10.6083 27.6983 10.7274 27.6052 10.8107C27.5122 10.8941 27.3887 10.935 27.2637 10.9231C26.6125 10.8591 25.2462 11.1374 24.4797 11.3183C24.4983 12.3683 24.6419 13.4794 24.909 14.6247C25.3102 16.3475 24.9842 18.2526 23.99 19.988C23.0404 21.6453 21.5729 23.0176 19.8568 23.8511L18.0283 24.7389C18.0201 24.7426 18.0119 24.7464 18.0037 24.7501C17.0184 25.1572 15.801 25.3812 14.5753 25.3812ZM7.41547 20.0617C7.9163 20.7977 8.95891 21.8396 10.3535 22.9789C11.6149 24.0089 12.9559 24.4881 14.5753 24.4881C15.6811 24.4881 16.7729 24.2894 17.6517 23.9285L19.4676 23.0466C22.7353 21.4586 24.7438 17.8478 24.0398 14.8264C23.7362 13.5218 23.5829 12.2552 23.5851 11.0623V10.9678C23.5851 10.7646 23.7228 10.5875 23.9193 10.5361C23.9944 10.5168 25.4099 10.1514 26.5343 10.0479C26.2225 9.78376 25.7254 9.47493 25.2097 9.24646C25.0958 9.19586 25.0088 9.1006 24.9686 8.98302C24.95 8.92869 24.496 7.6606 23.3232 7.6606C20.9812 7.6606 20.749 9.74209 20.6992 12.2559V12.2723C20.6642 14.0286 19.4891 15.7231 17.7068 16.5893C17.6994 16.5931 17.6919 16.5968 17.6837 16.5998L12.947 18.5808C12.9403 18.5838 12.9336 18.586 12.9262 18.589C11.2138 19.2066 9.67928 19.0504 8.73937 18.8331L9.10179 19.7112C9.16505 19.8645 9.13826 20.0409 9.03184 20.1681C8.92542 20.2954 8.75649 20.3534 8.595 20.3185L7.41547 20.0617Z"
        fill="#635F76"
      />
      <path
        d="M16.237 22.6656C15.8486 22.6656 15.4616 22.6076 15.0873 22.4908C14.1302 22.1916 13.3704 21.5441 12.9492 20.6682C12.842 20.4457 12.9358 20.1793 13.1576 20.0721C13.3801 19.9657 13.6465 20.0588 13.7537 20.2813C14.067 20.9324 14.6348 21.4139 15.3537 21.6387C16.083 21.8664 16.8785 21.8024 17.5937 21.4586L18.1786 21.1773C18.8938 20.8334 19.44 20.2515 19.7168 19.5393C19.9899 18.8375 19.9676 18.0934 19.6543 17.4422C19.5472 17.2197 19.6409 16.9533 19.8627 16.8461C20.0852 16.7389 20.3516 16.8327 20.4588 17.0552C20.8807 17.9311 20.9127 18.9283 20.5488 19.863C20.1894 20.7866 19.4854 21.5397 18.5656 21.9817L17.9806 22.263C17.4247 22.5302 16.8294 22.6649 16.237 22.6656Z"
        fill="#635F76"
      />
    </svg>
  );
};

export default Gallina;
