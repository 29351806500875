import LinearProgress from "@mui/material/LinearProgress";
import "./Stepper.scss"

interface StepperProps {
  steps: number;
  current: number;
  progress: number
}

export const Stepper = (props: StepperProps) => {
    const { steps, current, progress } = props;
  
    const ProgressBar = ({ current, steps, progress }: StepperProps) => {
      let value = 0;
      if(current-1 === steps) {
        value = progress
      } else if(current-1 >= steps) {
        value = 100
      }
      
      return <LinearProgress variant="determinate" value={value} className={`${steps === current - 1 ? "linearProgressActive": "linearProgress"}`} />
    }
    
    const renderStep = (key: number) => {
      return (
        <li className="step" key={key}>
          <ProgressBar current={current} steps={key} progress={progress} />
        </li>
      )
    }
  
    return (
      <ul className="stepper">
        {(() => {
          let aux = []
          for (let i = 0; i < steps; i++){
            aux.push(renderStep(i));
          }
          return aux;
        })()}
      </ul>
    )
  }