import React from "react";

const AcristalamientoSimple = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29915 1.5892C7.37676 1.53099 7.47549 1.50859 7.57063 1.52762L11.8332 2.38013C11.9651 2.40651 12.0691 2.50793 12.0989 2.63908C12.1286 2.77024 12.0785 2.90663 11.9709 2.98732L8.56086 5.54485C8.48325 5.60306 8.38452 5.62546 8.28939 5.60643L4.02683 4.75392C3.89495 4.72755 3.7909 4.62612 3.76115 4.49497C3.7314 4.36382 3.78151 4.22743 3.8891 4.14673L7.29915 1.5892ZM7.58638 2.22629L4.90846 4.23473L8.27363 4.90777L10.9516 2.89932L7.58638 2.22629Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75937 26.8421C3.79631 26.6574 3.97596 26.5376 4.16063 26.5746L8.42319 27.4271C8.60786 27.464 8.72763 27.6437 8.6907 27.8283C8.65376 28.013 8.47411 28.1328 8.28944 28.0958L4.02688 27.2433C3.84221 27.2064 3.72244 27.0267 3.75937 26.8421Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4645 28.4179C29.5775 28.5686 29.5469 28.7823 29.3963 28.8953L25.9862 31.4529C25.9086 31.5111 25.8099 31.5335 25.7147 31.5144L21.4522 30.6619C21.2675 30.625 21.1477 30.4453 21.1847 30.2607C21.2216 30.076 21.4013 29.9562 21.5859 29.9932L25.699 30.8158L28.987 28.3497C29.1377 28.2367 29.3515 28.2673 29.4645 28.4179Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5947 22.6308C24.6316 22.4461 24.8113 22.3263 24.996 22.3633L29.2585 23.2158C29.3904 23.2421 29.4945 23.3436 29.5242 23.4747C29.554 23.6059 29.5038 23.7423 29.3963 23.823L25.9862 26.3805C25.9086 26.4387 25.8099 26.4611 25.7147 26.4421L21.4522 25.5896C21.2675 25.5526 21.1477 25.373 21.1847 25.1883C21.2216 25.0036 21.4013 24.8839 21.5859 24.9208L25.699 25.7434L28.3769 23.735L24.8622 23.032C24.6775 22.9951 24.5578 22.8154 24.5947 22.6308Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0391 19.9864C12.1521 20.1371 12.1216 20.3508 11.9709 20.4638L8.56089 23.0213C8.41022 23.1343 8.19648 23.1038 8.08348 22.9531C7.97048 22.8025 8.00102 22.5887 8.15168 22.4757L11.5617 19.9182C11.7124 19.8052 11.9261 19.8357 12.0391 19.9864Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7664 2.37354C11.9548 2.37354 12.1074 2.52621 12.1074 2.71454V20.191C12.1074 20.3794 11.9548 20.532 11.7664 20.532C11.5781 20.532 11.4254 20.3794 11.4254 20.191V2.71454C11.4254 2.52621 11.5781 2.37354 11.7664 2.37354Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.94635 5.80356C10.0819 5.755 10.2334 5.79683 10.3248 5.90807L25.1926 23.9984C25.2837 24.1093 25.2957 24.2653 25.2225 24.3888C25.1493 24.5123 25.0066 24.5767 24.8656 24.5499L10.3985 21.8048C10.2942 21.785 10.2101 21.7198 10.1628 21.6335C10.1308 21.6434 10.0967 21.6488 10.0613 21.6488C9.87301 21.6488 9.72034 21.4962 9.72034 21.3078V6.12459C9.72034 5.9806 9.81079 5.85212 9.94635 5.80356ZM10.4023 21.134C10.442 21.1269 10.4836 21.1268 10.5256 21.1348L24.0682 23.7044L10.4023 7.07657V21.134Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.09381 4.07849C4.28214 4.07849 4.43482 4.23116 4.43482 4.4195V26.9088C4.43482 27.0971 4.28214 27.2498 4.09381 27.2498C3.90548 27.2498 3.75281 27.0971 3.75281 26.9088V4.4195C3.75281 4.23116 3.90548 4.07849 4.09381 4.07849Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.35639 4.93103C8.54472 4.93103 8.69739 5.0837 8.69739 5.27203V22.7741C8.69739 22.9624 8.54472 23.1151 8.35639 23.1151C8.16805 23.1151 8.01538 22.9624 8.01538 22.7741V5.27203C8.01538 5.0837 8.16805 4.93103 8.35639 4.93103Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0213 22.6849C8.05643 22.4999 8.23489 22.3784 8.41992 22.4135L25.8453 25.7213C26.0303 25.7564 26.1518 25.9349 26.1167 26.1199C26.0816 26.3049 25.9031 26.4264 25.7181 26.3913L8.29273 23.0836C8.1077 23.0484 7.98618 22.87 8.0213 22.6849Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4313 20.1105C11.4663 19.9255 11.6448 19.8039 11.8298 19.8389L29.2551 23.1381C29.4402 23.1732 29.5618 23.3516 29.5267 23.5366C29.4917 23.7217 29.3133 23.8433 29.1283 23.8082L11.7029 20.509C11.5179 20.474 11.3963 20.2956 11.4313 20.1105Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7817 26.0819C25.97 26.0819 26.1227 26.2346 26.1227 26.4229V31.1799C26.1227 31.2819 26.077 31.3785 25.9982 31.4433C25.9195 31.5081 25.8158 31.5342 25.7158 31.5145L4.02787 27.2434C3.84308 27.207 3.72279 27.0277 3.75918 26.843C3.79557 26.6582 3.97486 26.5379 4.15964 26.5743L25.4406 30.7652V26.4229C25.4406 26.2346 25.5933 26.0819 25.7817 26.0819Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1918 23.3541C29.3802 23.3541 29.5328 23.5068 29.5328 23.6951V28.6226C29.5328 28.811 29.3802 28.9637 29.1918 28.9637C29.0035 28.9637 28.8508 28.811 28.8508 28.6226V23.6951C28.8508 23.5068 29.0035 23.3541 29.1918 23.3541Z"
        fill="#635F76"
      />
    </svg>
  );
};

export default AcristalamientoSimple;
