import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

const initialState: any = { aseguradoraLogo: "", aceptoToS: false, aceptoToSAseguradora: false };

export const userSlice = createSlice({
  name: "usuario",
  initialState,
  reducers: {
    changeLogo: (state, action) => {
      if(action.payload) {
        state.aseguradoraLogo = action.payload;
      } else {
        state.aseguradoraLogo = 'https://aira-system.com/wp-content/uploads/2022/04/Logo-Aira-firma.png';
      }
    },
    aceptoToS: (state, action) => {
      state.aceptoToS = action.payload
    },
    aceptoToSAseguradora: (state, action) => {
      state.aceptoToSAseguradora = action.payload
    }
  },
});

export const {
    changeLogo,
    aceptoToS,
    aceptoToSAseguradora
} = userSlice.actions;

export const selectLogo = (state: RootState) => state.usuario.aseguradoraLogo;

export const selectToS = (state: RootState) => state.usuario.aceptoToS

export const selectToSAseguradora = (state: RootState) => state.usuario.aceptoToSAseguradora


export default userSlice.reducer;
