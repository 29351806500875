import React from "react";

const StarBlackCircle = () => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.125 16.125C12.06 16.125 15.25 12.935 15.25 9C15.25 5.06497 12.06 1.875 8.125 1.875C4.18997 1.875 1 5.06497 1 9C1 12.935 4.18997 16.125 8.125 16.125Z"
        stroke="#00163F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25819 6.02824L9.09645 7.64468C9.13755 7.72593 9.21844 7.78272 9.31257 7.79642L11.1888 8.05654C11.265 8.0665 11.3337 8.10481 11.3799 8.16297C11.426 8.22112 11.4456 8.29429 11.4344 8.3662C11.425 8.42531 11.395 8.47978 11.3493 8.52103L9.99038 9.78446C9.9217 9.84672 9.89091 9.93758 9.90852 10.026L10.236 11.8066C10.2597 11.9533 10.1548 12.0909 10.0002 12.1162C9.93644 12.1257 9.87114 12.1159 9.81356 12.0884L8.14031 11.2492C8.05672 11.2056 7.95539 11.2056 7.8718 11.2492L6.18872 12.0945C6.04752 12.1631 5.87429 12.1121 5.79906 11.98C5.76973 11.9267 5.7594 11.8659 5.76959 11.8066L6.09704 10.026C6.11288 9.93769 6.08235 9.84761 6.01518 9.78446L4.64645 8.52103C4.53452 8.41372 4.53452 8.24127 4.64645 8.13395C4.69213 8.09395 4.74893 8.0671 4.81017 8.05654L6.68644 7.79642C6.78012 7.78172 6.86058 7.72524 6.90256 7.64468L7.74082 6.02824C7.77394 5.9627 7.83365 5.91258 7.9064 5.88926C7.97915 5.86594 8.05877 5.87139 8.12721 5.90437C8.18367 5.93146 8.22955 5.97484 8.25819 6.02824Z"
        stroke="#0046CF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StarBlackCircle;
