import React from 'react'

export const Graficas = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.87868 8.87868C3.44129 8.31607 4.20435 8 5 8H19C19.7957 8 20.5587 8.31607 21.1213 8.87868C21.6839 9.44129 22 10.2043 22 11V15C22 15.7957 21.6839 16.5587 21.1213 17.1213C20.5587 17.6839 19.7957 18 19 18H17C16.4477 18 16 17.5523 16 17C16 16.4477 16.4477 16 17 16H19C19.2652 16 19.5196 15.8946 19.7071 15.7071C19.8946 15.5196 20 15.2652 20 15V11C20 10.7348 19.8946 10.4804 19.7071 10.2929C19.5196 10.1054 19.2652 10 19 10H5C4.73478 10 4.48043 10.1054 4.29289 10.2929C4.10536 10.4804 4 10.7348 4 11V15C4 15.2652 4.10536 15.5196 4.29289 15.7071C4.48043 15.8946 4.73478 16 5 16H7C7.55228 16 8 16.4477 8 17C8 17.5523 7.55228 18 7 18H5C4.20435 18 3.44129 17.6839 2.87868 17.1213C2.31607 16.5587 2 15.7956 2 15V11C2 10.2044 2.31607 9.44129 2.87868 8.87868Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M6.87868 2.87868C7.44129 2.31607 8.20435 2 9 2H15C15.7956 2 16.5587 2.31607 17.1213 2.87868C17.6839 3.44129 18 4.20435 18 5V9C18 9.55228 17.5523 10 17 10C16.4477 10 16 9.55228 16 9V5C16 4.73478 15.8946 4.48043 15.7071 4.29289C15.5196 4.10536 15.2652 4 15 4H9C8.73478 4 8.48043 4.10536 8.29289 4.29289C8.10536 4.48043 8 4.73478 8 5V9C8 9.55228 7.55228 10 7 10C6.44772 10 6 9.55228 6 9V5C6 4.20435 6.31607 3.44129 6.87868 2.87868Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9 14C8.44772 14 8 14.4477 8 15V19C8 19.5523 8.44772 20 9 20H15C15.5523 20 16 19.5523 16 19V15C16 14.4477 15.5523 14 15 14H9ZM6 15C6 13.3431 7.34315 12 9 12H15C16.6569 12 18 13.3431 18 15V19C18 20.6569 16.6569 22 15 22H9C7.34315 22 6 20.6569 6 19V15Z" fill="#89869B"/>
    </svg>
  )
}
