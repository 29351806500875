import React from "react";
import { Icons } from "../../Assets/Icons";
import "./Notification.scss";

interface IProps {
  text: string;
  icon?: string;
  iconComponent?: JSX.Element
}

export const Notification = ({ text, icon, iconComponent }: IProps) => {
  const icons = Icons()

  return (
    <div className="notification">
      <div className="notification-icon">{icon ? icons[icon] : iconComponent}</div>
      <p>{text}</p>
    </div>
  );
};
