import React from "react";

export const VideoCamara = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.23355 21.7402C5.03387 21.7402 5.73877 22.3359 5.73877 23.125V27.4143C5.73877 28.2034 5.03384 28.8002 4.23355 28.8002H3.12125C3.00659 28.8002 2.89664 28.7547 2.81557 28.6736C2.7345 28.5926 2.68895 28.4826 2.68893 28.3679V22.1725C2.68895 22.0579 2.7345 21.948 2.81557 21.8669C2.89664 21.7858 3.00659 21.7402 3.12125 21.7402H4.23355ZM4.23355 22.6049H3.55356V27.9356H4.23355C4.62144 27.9356 4.87414 27.6813 4.87414 27.4143V23.125C4.87414 22.8579 4.62141 22.6049 4.23355 22.6049Z"
        fill="#635F76"
      />
      <path
        d="M9.17811 23.4314C9.75061 23.4158 9.66132 24.2409 9.17811 24.296H5.54172C4.9571 24.3041 4.9571 23.4231 5.54172 23.4314H9.17811ZM9.17811 26.2437C9.61829 26.4586 9.75227 27.1327 9.17811 27.1083H5.54172C4.9571 27.1164 4.9571 26.2354 5.54172 26.2437H9.17811Z"
        fill="#635F76"
      />
      <path
        d="M11.9025 21.811C13.833 21.811 15.415 23.3604 15.415 25.2695C15.415 27.1786 13.833 28.728 11.9025 28.728C9.97195 28.728 8.38994 27.1786 8.38995 25.2695C8.38994 23.3604 9.97195 21.811 11.9025 21.811ZM11.9025 22.6757C10.4311 22.6757 9.25457 23.8359 9.25457 25.2695C9.25457 26.7031 10.4311 27.8634 11.9025 27.8634C13.3739 27.8634 14.5504 26.7031 14.5504 25.2695C14.5504 23.8359 13.3739 22.6757 11.9025 22.6757Z"
        fill="#635F76"
      />
      <path
        d="M11.9022 23.3354C12.9758 23.3354 13.8623 24.2025 13.8623 25.2696C13.8623 26.3367 12.9758 27.2037 11.9022 27.2037C10.8286 27.2037 9.94107 26.3367 9.94106 25.2696C9.94107 24.2025 10.8286 23.3354 11.9022 23.3354ZM11.9022 24.2001C11.2878 24.2001 10.8057 24.678 10.8057 25.2696C10.8057 25.8611 11.2878 26.3391 11.9022 26.3391C12.5167 26.3391 12.9977 25.8611 12.9977 25.2696C12.9977 24.678 12.5167 24.2001 11.9022 24.2001ZM10.8001 6.05082C10.8687 6.04986 10.9367 6.06527 10.9982 6.09579L27.2223 14.1465C27.3248 14.1976 27.4028 14.2871 27.4393 14.3956C27.4758 14.5041 27.4678 14.6226 27.4171 14.7252C26.8873 15.7988 26.282 16.6124 25.6113 17.1446C24.9405 17.6767 24.1773 17.923 23.434 17.7829C23.3953 17.7757 23.3578 17.7634 23.3225 17.746L8.79386 10.5645C8.7429 10.5394 8.69738 10.5045 8.6599 10.4618C8.62242 10.4192 8.59371 10.3696 8.57541 10.3158C8.55712 10.262 8.54959 10.2052 8.55327 10.1485C8.55695 10.0918 8.57175 10.0364 8.59684 9.98548L10.4184 6.29284C10.4535 6.22122 10.5077 6.1607 10.5751 6.11799C10.6425 6.07527 10.7203 6.05204 10.8001 6.05082ZM11.0027 7.06402L9.56392 9.98101L23.6276 16.9307C24.0834 17.0065 24.5567 16.8788 25.0743 16.468C25.5269 16.1089 25.9866 15.4926 26.4185 14.7139L11.0027 7.06402Z"
        fill="#635F76"
      />
      <path
        d="M9.05521 10.2231C9.12662 10.2234 9.19687 10.2412 9.25968 10.2752C9.3225 10.3092 9.37591 10.3582 9.41516 10.4179C9.45441 10.4775 9.47827 10.546 9.48461 10.6171C9.49095 10.6882 9.47958 10.7599 9.4515 10.8255C8.98894 11.9553 8.79243 12.7351 8.77601 13.2674C8.76025 13.7781 8.89107 14.0468 9.12951 14.3313L20.7119 20.0572C20.9584 20.0451 21.3286 19.8925 21.7814 19.4571C22.2534 19.0033 22.7871 18.2948 23.3362 17.3586C23.3943 17.2596 23.4893 17.1878 23.6003 17.1588C23.7114 17.1299 23.8294 17.1463 23.9283 17.2044C24.0273 17.2624 24.0992 17.3575 24.1281 17.4685C24.157 17.5796 24.1407 17.6976 24.0826 17.7965C23.5067 18.7786 22.939 19.5425 22.3804 20.0797C21.8217 20.6169 21.2586 20.9526 20.6601 20.9477C20.5949 20.9471 20.5306 20.9317 20.4721 20.9027L8.62627 15.0485C8.57522 15.0231 8.52969 14.9879 8.4923 14.9449C8.11957 14.5174 7.8895 13.9509 7.91138 13.2416C7.93326 12.5322 8.1692 11.6749 8.65104 10.4979C8.68284 10.4167 8.73846 10.347 8.81059 10.298C8.88272 10.2489 8.96799 10.2229 9.05521 10.2232V10.2231Z"
        fill="#635F76"
      />
      <path
        d="M26.441 15.7734C26.5234 15.7729 26.6043 15.796 26.6741 15.8399L27.4689 16.3228C27.5494 16.3719 27.6116 16.4459 27.6461 16.5335C27.6807 16.6212 27.6857 16.7177 27.6603 16.8084C27.6349 16.8992 27.5806 16.9792 27.5056 17.0362C27.4306 17.0932 27.3391 17.1242 27.2449 17.1244H27.488L25.036 21.1672C24.9775 21.2639 24.8835 21.3338 24.774 21.362C24.6646 21.3902 24.5485 21.3744 24.4506 21.3181L22.1607 20.0054C22.1097 19.9781 22.0646 19.9408 22.0283 19.8958C21.9919 19.8508 21.965 19.799 21.949 19.7434C21.933 19.6877 21.9284 19.6295 21.9354 19.5721C21.9423 19.5147 21.9608 19.4592 21.9896 19.409C22.0183 19.3588 22.0569 19.3149 22.103 19.2799C22.149 19.2449 22.2017 19.2195 22.2577 19.2052C22.3138 19.1909 22.3721 19.188 22.4293 19.1966C22.4865 19.2053 22.5414 19.2253 22.5907 19.2556L24.5147 20.3589L26.6515 16.8373L26.226 16.5784C26.1446 16.5305 26.0811 16.4571 26.0453 16.3697C26.0094 16.2823 26.0033 16.1855 26.0276 16.0942C26.052 16.0029 26.1057 15.9221 26.1803 15.8642C26.255 15.8063 26.3465 15.7744 26.441 15.7734Z"
        fill="#635F76"
      />
      <path
        d="M28.6107 18.5542L26.8376 21.4869C26.7136 21.692 26.4465 21.7572 26.242 21.6321L24.8527 20.7844C24.3603 20.4842 24.8107 19.7456 25.3031 20.0459L26.3219 20.6673L27.7742 18.2671L26.734 17.6321C26.3558 17.4096 26.5114 16.8303 26.9501 16.8272C27.0326 16.8266 27.1134 16.8497 27.1832 16.8936L28.5916 17.7526C28.8049 17.8825 28.8437 18.1259 28.7561 18.3104L28.6107 18.5542Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7308 18.7951L18.1553 19.5483C16.8299 20.2957 15.756 21.7378 14.7677 23.4335L14.0202 22.9978C15.0365 21.2539 16.1708 19.6748 17.7308 18.7951ZM13.706 16.7349C14.0113 18.5361 13.4575 20.4393 12.6951 22.3504L11.8924 22.0307C12.6338 20.1724 13.1125 18.4066 12.8538 16.8801L13.706 16.7349Z"
        fill="#635F76"
      />
      <path
        d="M13.0169 4.76949C13.3817 4.64919 13.7915 4.6675 14.1585 4.85276C14.8925 5.22329 15.1917 6.13033 14.8216 6.86463L14.1822 8.13339C14.1566 8.18411 14.1213 8.22931 14.0782 8.26638C14.0352 8.30345 13.9853 8.33167 13.9314 8.34944C13.8774 8.36722 13.8205 8.37421 13.7639 8.36999C13.7072 8.36577 13.652 8.35043 13.6012 8.32485C13.5505 8.29928 13.5053 8.26396 13.4683 8.22092C13.4312 8.17789 13.403 8.12799 13.3852 8.07404C13.3674 8.02009 13.3605 7.96315 13.3647 7.90651C13.3689 7.84986 13.3843 7.79464 13.4099 7.74393L14.0493 6.4751C14.2106 6.15502 14.0886 5.78532 13.769 5.62398C13.4495 5.46276 13.0814 5.5842 12.9201 5.90435L12.2807 7.17311C12.256 7.2252 12.2212 7.27181 12.1782 7.31025C12.1352 7.34868 12.085 7.37815 12.0305 7.3969C11.976 7.41564 11.9183 7.42329 11.8608 7.41941C11.8033 7.41554 11.7471 7.40021 11.6957 7.37431C11.6442 7.34841 11.5984 7.31248 11.561 7.26863C11.5236 7.22477 11.4953 7.1739 11.4778 7.11896C11.4604 7.06403 11.4541 7.00615 11.4593 6.94875C11.4646 6.89135 11.4812 6.83557 11.5083 6.7847L12.1478 5.51591C12.3329 5.1489 12.6521 4.88979 13.0169 4.76949Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.95834 3.20025C6.01512 3.20113 6.07116 3.2132 6.12328 3.23575C6.17539 3.25829 6.22255 3.29088 6.26207 3.33165C6.30159 3.37243 6.33269 3.4206 6.35359 3.4734C6.37449 3.5262 6.38479 3.58261 6.3839 3.63939V12.1438H9.20069C9.25798 12.143 9.31485 12.1535 9.36801 12.1749C9.42118 12.1963 9.46956 12.228 9.51036 12.2682C9.55115 12.3084 9.58355 12.3564 9.60566 12.4092C9.62777 12.4621 9.63916 12.5188 9.63916 12.5761C9.63916 12.6334 9.62777 12.6901 9.60566 12.7429C9.58355 12.7958 9.55115 12.8437 9.51036 12.884C9.46956 12.9242 9.42118 12.9559 9.36801 12.9773C9.31485 12.9986 9.25798 13.0092 9.20069 13.0084H5.51927V3.63939C5.51836 3.58147 5.52909 3.52395 5.55083 3.47027C5.57258 3.41658 5.60488 3.36783 5.64584 3.32687C5.68679 3.28591 5.73556 3.25359 5.78924 3.23184C5.84292 3.21009 5.90043 3.19934 5.95834 3.20025Z"
        fill="#635F76"
      />
    </svg>
  );
};
