import React from 'react'

export const Alimentacion = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.78211 2.78905C9.7765 2.27442 10.8798 2.00583 11.9995 2.00583C13.1192 2.00583 14.2225 2.27442 15.2169 2.78905C16.2113 3.30369 17.0677 4.04935 17.7143 4.96345C18.3609 5.87756 18.7788 6.93343 18.9329 8.04245C19.087 9.15147 18.9728 10.2813 18.5999 11.337C18.416 11.8578 17.8447 12.1308 17.324 11.9469C16.8032 11.763 16.5302 11.1917 16.7141 10.671C16.9804 9.91686 17.062 9.10986 16.9519 8.3177C16.8419 7.52555 16.5434 6.77135 16.0815 6.11842C15.6197 5.46549 15.0079 4.93287 14.2977 4.56527C13.5874 4.19768 12.7993 4.00583 11.9995 4.00583C11.1997 4.00583 10.4116 4.19768 9.70137 4.56527C8.99109 4.93287 8.37935 5.46549 7.9175 6.11842C7.45565 6.77135 7.15716 7.52555 7.04709 8.3177C6.93702 9.10986 7.01858 9.91686 7.28493 10.671C7.46886 11.1917 7.1958 11.763 6.67504 11.9469C6.15428 12.1308 5.58302 11.8578 5.3991 11.337C5.02621 10.2813 4.91203 9.15147 5.06612 8.04245C5.22022 6.93343 5.6381 5.87756 6.28469 4.96345C6.93128 4.04935 7.78771 3.30369 8.78211 2.78905Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.48849 10.479C5.67016 10.1814 5.99372 9.99988 6.3424 10L17.6574 10.004C18.006 10.0041 18.3293 10.1857 18.5108 10.4833C18.6923 10.7809 18.7057 11.1515 18.5463 11.4615L12.8893 22.4575C12.7179 22.7906 12.3746 23 12 23C11.6254 23 11.2821 22.7906 11.1108 22.4574L5.45278 11.4574C5.2933 11.1473 5.30683 10.7766 5.48849 10.479ZM7.98123 12.0006L12.0001 19.8139L16.0183 12.0034L7.98123 12.0006Z" fill="#89869B"/>
    </svg>
  )
}
