import React from "react";

const Drone2 = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1048_1372)">
        <path
          d="M13.6276 17.6947C13.5081 17.4474 13.2102 17.3428 12.9629 17.4623C10.6037 18.6 9.13908 20.8621 9.13908 23.3666V24.052H8.53745C8.26278 24.052 8.03955 24.2752 8.03955 24.5499C8.03955 24.8246 8.26278 25.0478 8.53745 25.0478H9.63532C9.63615 25.0478 9.63615 25.0478 9.63698 25.0478C9.63781 25.0478 9.63781 25.0478 9.63864 25.0478H10.7365C11.0112 25.0478 11.2344 24.8246 11.2344 24.5499C11.2344 24.2752 11.0112 24.052 10.7365 24.052H10.1349V23.3666C10.1349 21.248 11.3846 19.3286 13.3961 18.3585C13.6434 18.2399 13.7471 17.9419 13.6276 17.6947Z"
          fill="#635F76"
        />
        <path
          d="M23.7284 24.052H23.1268V23.3666C23.1268 21.5924 22.3957 19.9253 21.0672 18.6731C20.5477 18.1843 19.9535 17.7769 19.3029 17.4623C19.0557 17.3428 18.7577 17.4466 18.6382 17.6939C18.5188 17.9412 18.6225 18.2391 18.8698 18.3586C19.4291 18.6291 19.9394 18.9784 20.3842 19.3975C21.5103 20.4597 22.131 21.8688 22.131 23.3666V24.052H21.5294C21.2547 24.052 21.0315 24.2753 21.0315 24.5499C21.0315 24.8246 21.2547 25.0478 21.5294 25.0478H22.6273C22.6281 25.0478 22.6281 25.0478 22.6289 25.0478C22.6297 25.0478 22.6297 25.0478 22.6306 25.0478H23.7284C24.0031 25.0478 24.2263 24.8246 24.2263 24.5499C24.2263 24.2753 24.0031 24.052 23.7284 24.052Z"
          fill="#635F76"
        />
        <path
          d="M27.7033 9.38059C27.7033 9.10592 27.4801 8.88269 27.2054 8.88269C26.9308 8.88269 26.7075 9.10592 26.7075 9.38059V12.9646H22.6828C22.0646 12.9646 21.5244 12.5821 21.3393 12.012C20.6008 9.74157 18.4773 9.42872 16.2923 9.42872H15.9736C13.7887 9.42872 11.6643 9.74157 10.9266 12.012C10.7416 12.5821 10.2013 12.9646 9.58311 12.9646H5.55842V9.38059C5.55842 9.10592 5.3352 8.88269 5.06052 8.88269C4.78585 8.88269 4.56262 9.10592 4.56262 9.38059V13.4625C4.56262 13.4642 4.56262 13.4659 4.56262 13.4675V14.055C4.56262 15.4981 5.73684 16.6723 7.17991 16.6723H10.6818C10.8046 17.6507 11.6411 18.4092 12.6518 18.4092H19.6141C20.6249 18.4092 21.4613 17.6499 21.5841 16.6723H25.086C26.5291 16.6723 27.7033 15.4981 27.7033 14.055V13.4675C27.7033 13.4659 27.7033 13.4642 27.7033 13.4625V9.38059ZM26.7075 14.0542C26.7075 14.9479 25.9798 15.6757 25.086 15.6757H21.102C20.8273 15.6757 20.6041 15.8989 20.6041 16.1736V16.4225C20.6041 16.9686 20.1602 17.4125 19.6141 17.4125H12.6518C12.1058 17.4125 11.6618 16.9686 11.6618 16.4225V16.1736C11.6618 15.8989 11.4386 15.6757 11.1639 15.6757H7.17991C6.28618 15.6757 5.55842 14.9479 5.55842 14.0542V13.9596H9.58228C10.6337 13.9596 11.554 13.3007 11.8734 12.319C12.3863 10.7407 13.8227 10.4237 15.9736 10.4237H16.2923C18.4432 10.4237 19.8797 10.7407 20.3925 12.319C20.7112 13.2999 21.6323 13.9596 22.6837 13.9596H26.7075V14.0542Z"
          fill="#635F76"
        />
        <path
          d="M9.4893 11.1514H0.630834C0.35616 11.1514 0.132935 10.9282 0.132935 10.6535C0.132935 10.3789 0.35616 10.1556 0.630834 10.1556H9.4893C9.76397 10.1556 9.9872 10.3789 9.9872 10.6535C9.9872 10.9282 9.76397 11.1514 9.4893 11.1514Z"
          fill="#635F76"
        />
        <path
          d="M31.635 11.1822H22.7766C22.5019 11.1822 22.2787 10.959 22.2787 10.6843C22.2787 10.4096 22.5019 10.1864 22.7766 10.1864H31.635C31.9097 10.1864 32.1329 10.4096 32.1329 10.6843C32.1329 10.959 31.9097 11.1822 31.635 11.1822Z"
          fill="#635F76"
        />
      </g>
      <defs>
        <clipPath id="clip0_1048_1372">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.132935 0.965332)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Drone2;
