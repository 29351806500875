import React from "react";

const Plus2 = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9782 30.2265C9.48429 30.2265 3.3894 24.1316 3.3894 16.6377C3.3894 9.14371 9.48429 3.04883 16.9782 3.04883C24.4722 3.04883 30.5671 9.14371 30.5671 16.6377C30.5671 24.1316 24.4722 30.2265 16.9782 30.2265ZM16.9782 3.94185C9.97545 3.94185 4.28243 9.63488 4.28243 16.6377C4.28243 23.6405 9.97545 29.3335 16.9782 29.3335C23.981 29.3335 29.6741 23.6405 29.6741 16.6377C29.6741 9.63488 23.981 3.94185 16.9782 3.94185Z"
        fill="#635F76"
      />
      <path
        d="M16.8071 23.6777C16.5615 23.6777 16.3606 23.4768 16.3606 23.2312V10.513C16.3606 10.2675 16.5615 10.0665 16.8071 10.0665C17.0527 10.0665 17.2536 10.2675 17.2536 10.513V23.2312C17.2536 23.4768 17.0527 23.6777 16.8071 23.6777Z"
        fill="#635F76"
      />
      <path
        d="M23.1625 17.3149H10.4443C10.1987 17.3149 9.9978 17.114 9.9978 16.8684C9.9978 16.6228 10.1987 16.4219 10.4443 16.4219H23.1625C23.408 16.4219 23.609 16.6228 23.609 16.8684C23.609 17.114 23.408 17.3149 23.1625 17.3149Z"
        fill="#635F76"
      />
    </svg>
  );
};

export default Plus2;
