import React from "react";
import { ClipLoader } from "react-spinners";
import "./CustomButton.scss";

interface IProps {
  text: any;
  onClick: () => void;
  type: string;
  disable?: boolean;
  children?: any;
  loading?: boolean;
}

export function debounce<T extends unknown[]>(func: (...args: T) => void, delay: number): (...args: T) => void {
  let timer: any | null = null;
  return (...args: T) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
          func.call(null, ...args);
      }, delay);
  };
}

export const CustomButton = ({ text, onClick, type, disable, loading }: IProps) => {

  const renderLoader = () => {
    try {
      return <>{loading ? <div className="button-loader"><ClipLoader size={25} color={"#ffffff"} loading /> </div> : <div></div>}</>
    } catch {
      return <></>
    }
  }

  return (
    <button className={type} disabled={disable} onClick={debounce(onClick, 300)}>
      {text && !loading ? text : null}
      {renderLoader()}
    </button>
  );
};
