import React from 'react'

export const EdificioMixto = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.7832 30.0667H9.24985C8.98308 30.0667 8.76654 30.2832 8.76654 30.55V31.5167C8.76654 31.7835 8.98308 32 9.24985 32C9.51663 32 9.73317 31.7835 9.73317 31.5167V31.0334H22.7832C23.0499 31.0334 23.2665 30.8169 23.2665 30.5501C23.2665 30.2833 23.05 30.0667 22.7832 30.0667Z" fill="#635F76"/>
        <path d="M12.6333 15.0833C12.6333 15.35 12.8498 15.5666 13.1166 15.5666H15.05C15.3168 15.5666 15.5333 15.35 15.5333 15.0833V12.1833C15.5333 11.9165 15.3168 11.7 15.05 11.7H13.1167C12.8499 11.7 12.6334 11.9165 12.6334 12.1833V15.0833H12.6333ZM13.6 12.6666H14.5667V14.6H13.6V12.6666Z" fill="#635F76"/>
        <path d="M8.28331 8.80005H2.48331C2.21654 8.80005 2 9.01659 2 9.28336V18.9501C2 19.2168 2.21654 19.4334 2.48331 19.4334C2.75009 19.4334 2.96663 19.2168 2.96663 18.9501V9.76673H8.28331C8.55009 9.76673 8.76663 9.5502 8.76663 9.28342C8.76663 9.01664 8.55015 8.80005 8.28331 8.80005Z" fill="#635F76"/>
        <path d="M25.2001 26.6834C25.2001 26.9501 25.4166 27.1667 25.6834 27.1667H27.6167C27.8835 27.1667 28.1 26.9501 28.1 26.6834V23.7834C28.1 23.5166 27.8835 23.3 27.6167 23.3H25.6834C25.4166 23.3 25.2001 23.5166 25.2001 23.7834V26.6834ZM26.1668 24.2667H27.1334V26.2001H26.1668V24.2667Z" fill="#635F76"/>
        <path d="M4.41669 20.4C2.02753 20.4 2 26.6205 2 26.6833C2 27.8506 2.83182 28.8264 3.93331 29.0512V31.5167C3.93331 31.7835 4.14985 32 4.41663 32C4.68341 32 4.89994 31.7835 4.89994 31.5167V29.0512C6.00143 28.8264 6.83326 27.8506 6.83326 26.6833C6.83331 26.6205 6.80579 20.4 4.41669 20.4ZM4.41669 28.1333C3.61726 28.1333 2.96669 27.4828 2.96669 26.6833C2.96669 24.2754 3.61341 21.3667 4.41669 21.3667C5.21996 21.3667 5.86669 24.2754 5.86669 26.6833C5.86669 27.4828 5.21611 28.1333 4.41669 28.1333Z" fill="#635F76"/>
        <path d="M7.31677 17.5H5.3834C5.11662 17.5 4.90009 17.7165 4.90009 17.9833V18.95C4.90009 19.2168 5.11662 19.4333 5.3834 19.4333C5.65018 19.4333 5.86671 19.2168 5.86671 18.95V18.4667H6.8334V20.8834C6.8334 21.1501 7.04994 21.3667 7.31671 21.3667C7.58349 21.3667 7.80003 21.1501 7.80003 20.8834V17.9834C7.80008 17.7165 7.58355 17.5 7.31677 17.5Z" fill="#635F76"/>
        <path d="M25.6834 15.5666H27.6167C27.8835 15.5666 28.1 15.35 28.1 15.0833V12.1833C28.1 11.9165 27.8835 11.7 27.6167 11.7H25.6834C25.4166 11.7 25.2001 11.9165 25.2001 12.1833V15.0833C25.2001 15.35 25.4166 15.5666 25.6834 15.5666ZM26.1668 12.6666H27.1334V14.5999H26.1668V12.6666Z" fill="#635F76"/>
        <path d="M7.31677 11.7H5.3834C5.11662 11.7 4.90009 11.9165 4.90009 12.1833V15.0833C4.90009 15.35 5.11662 15.5666 5.3834 15.5666H7.31671C7.58349 15.5666 7.80003 15.35 7.80003 15.0833V12.1833C7.80008 11.9165 7.58355 11.7 7.31677 11.7ZM6.8334 14.6H5.86677V12.6666H6.83346V14.6H6.8334Z" fill="#635F76"/>
        <path d="M17.4667 15.0833C17.4667 15.35 17.6833 15.5666 17.9501 15.5666H19.8834C20.1501 15.5666 20.3667 15.35 20.3667 15.0833V12.1833C20.3667 11.9165 20.1501 11.7 19.8834 11.7H17.9501C17.6833 11.7 17.4667 11.9165 17.4667 12.1833V15.0833ZM18.4334 12.6666H19.4001V14.6H18.4334V12.6666Z" fill="#635F76"/>
        <path d="M30.5167 8.80005H24.7167C24.4499 8.80005 24.2334 9.01659 24.2334 9.28336C24.2334 9.55014 24.4499 9.76668 24.7167 9.76668H30.0334V29.189C29.8817 29.1348 29.7207 29.1 29.5501 29.1C29.4665 29.1 29.3833 29.1073 29.3017 29.1218C28.9682 28.521 28.3336 28.1334 27.6168 28.1334C26.9 28.1334 26.2654 28.521 25.9319 29.1218C25.8502 29.1073 25.7671 29.1 25.6835 29.1C24.884 29.1 24.2335 29.7506 24.2335 30.55C24.2335 31.3495 24.884 32 25.6835 32H29.55C30.3495 32 31 31.3495 31 30.55V9.28336C31 9.01659 30.7835 8.80005 30.5167 8.80005ZM29.55 31.0334H25.6833C25.417 31.0334 25.2 30.8164 25.2 30.55C25.2 30.2837 25.417 30.0667 25.6833 30.0667C25.7761 30.0667 25.8641 30.0933 25.9453 30.1455C26.0777 30.2306 26.2431 30.2461 26.389 30.1871C26.535 30.1281 26.6428 30.0015 26.6781 29.8483C26.7801 29.4079 27.1663 29.1 27.6167 29.1C28.0672 29.1 28.4534 29.4079 28.5554 29.8483C28.5907 30.0015 28.6984 30.1281 28.8444 30.1871C28.9899 30.2461 29.1556 30.2306 29.2881 30.1455C29.6028 29.9425 30.0334 30.1909 30.0334 30.55C30.0333 30.8164 29.8164 31.0334 29.55 31.0334Z" fill="#635F76"/>
        <path d="M17.9501 9.76653H19.8834C20.1501 9.76653 20.3667 9.54999 20.3667 9.28322V6.38322C20.3667 6.11644 20.1501 5.8999 19.8834 5.8999H17.9501C17.6833 5.8999 17.4667 6.11644 17.4667 6.38322V9.28322C17.4667 9.54999 17.6833 9.76653 17.9501 9.76653ZM18.4334 6.86653H19.4001V8.79985H18.4334V6.86653Z" fill="#635F76"/>
        <path d="M17.4667 20.8833C17.4667 21.1501 17.6833 21.3666 17.9501 21.3666H19.8834C20.1501 21.3666 20.3667 21.1501 20.3667 20.8833V17.9833C20.3667 17.7165 20.1501 17.5 19.8834 17.5H17.9501C17.6833 17.5 17.4667 17.7165 17.4667 17.9833V20.8833ZM18.4334 18.4667H19.4001V20.4H18.4334V18.4667Z" fill="#635F76"/>
        <path d="M13.1167 9.76653H15.0501C15.3168 9.76653 15.5334 9.54999 15.5334 9.28322V6.38322C15.5334 6.11644 15.3168 5.8999 15.0501 5.8999H13.1167C12.85 5.8999 12.6334 6.11644 12.6334 6.38322V9.28322C12.6334 9.54999 12.8499 9.76653 13.1167 9.76653ZM13.6001 6.86653H14.5667V8.79985H13.6001V6.86653Z" fill="#635F76"/>
        <path d="M16.1568 26.3402C16.0698 26.432 16.0166 26.5577 16.0166 26.6834C16.0166 26.8091 16.0698 26.9347 16.1568 27.0266C16.2486 27.1136 16.3743 27.1668 16.5 27.1668C16.6257 27.1668 16.7513 27.1136 16.8432 27.0266C16.9302 26.9348 16.9833 26.8091 16.9833 26.6834C16.9833 26.5577 16.9302 26.4321 16.8432 26.3402C16.6595 26.1613 16.3405 26.1613 16.1568 26.3402Z" fill="#635F76"/>
        <path d="M12.6333 20.8833C12.6333 21.1501 12.8498 21.3666 13.1166 21.3666H15.05C15.3168 21.3666 15.5333 21.1501 15.5333 20.8833V17.9833C15.5333 17.7165 15.3168 17.5 15.05 17.5H13.1167C12.8499 17.5 12.6334 17.7165 12.6334 17.9833V20.8833H12.6333ZM13.6 18.4667H14.5667V20.4H13.6V18.4667Z" fill="#635F76"/>
        <path d="M11.6666 23.7834C11.6666 24.0501 11.8832 24.2667 12.1499 24.2667H13.5999V28.6167C13.5999 28.8835 13.8165 29.1 14.0833 29.1C14.35 29.1 14.5666 28.8835 14.5666 28.6167V24.2667H18.4333V28.6167C18.4333 28.8835 18.6498 29.1 18.9166 29.1C19.1833 29.1 19.3999 28.8835 19.3999 28.6167V24.2667H20.8499C21.1167 24.2667 21.3332 24.0501 21.3332 23.7834C21.3332 23.5166 21.1167 23.3 20.8499 23.3H12.1499C11.8832 23.3 11.6666 23.5166 11.6666 23.7834Z" fill="#635F76"/>
        <path d="M25.2001 20.8833C25.2001 21.1501 25.4166 21.3666 25.6834 21.3666H27.6167C27.8835 21.3666 28.1 21.1501 28.1 20.8833V17.9833C28.1 17.7165 27.8835 17.5 27.6167 17.5H25.6834C25.4166 17.5 25.2001 17.7165 25.2001 17.9833V20.8833ZM26.1668 18.4667H27.1334V20.4H26.1668V18.4667Z" fill="#635F76"/>
        <path d="M22.7833 3H10.2167C9.94994 3 9.7334 3.21654 9.7334 3.48331V28.6166C9.7334 28.8834 9.94994 29.0999 10.2167 29.0999C10.4835 29.0999 10.7 28.8834 10.7 28.6166V3.96663H22.3V28.6166C22.3 28.8834 22.5166 29.0999 22.7833 29.0999C23.0501 29.0999 23.2667 28.8834 23.2667 28.6166V3.48331C23.2667 3.21654 23.0502 3 22.7833 3Z" fill="#635F76"/>
    </svg>
  )
}
