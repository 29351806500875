import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import conf from "../../app/config";
import { AiraLogo } from "../../Assets/svgs/AiraLogo";
import { AlertTriangle } from "../../Assets/svgs/AlertTriangle";
import { CustomButton } from "../../Components/CustomButton/CustomButton";
import { SmsInput } from "../../Components/SmsInput/SmsInput";
import { getInspeccion, validarCodigo } from "../LayoutPregunta/PreguntasAPI";
import {
  changeCodigoTest,
  handleInspeccion,
  selectId,
} from "../LayoutPregunta/preguntaSlice";
import { DataError } from "./DataError";
import "./Registro.scss";
import { selectLogo } from "./userSlice";
import { INSPECT_MAX_BYTES } from "buffer";
import { persistor } from "../../app/store";
import { purgeIndexedDbWithDelay } from "../../utils";

const VERSION = conf("ENV") + "-" + conf("VERSION");

interface CodeData {
  id: number;
  codigo: string;
}

export const Registro = () => {
  const [code, setCode] = useState("");
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [response, setResponse] = useState<any>({});
  const [origen, setOrigen] = useState<any>();
  const logo = useSelector(selectLogo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation().search;
  let nombreUsuarioDecoded: any;
  const id = useSelector(selectId);

  const validateCode = async (data: CodeData) => {
    const response = await validarCodigo(data);
    setResponse(response.data);
    console.log(response);
    if (response.data.data && response.data.resultCode === 0) {
      localStorage.setItem("email", response.data.message);
      localStorage.setItem("ramoId", response.data.ramoId);
      localStorage.setItem("ramoCustom", response.data.ramoCustom);
      localStorage.setItem("iconoRamoCustom", response.data.iconoRamoCustom);
      navigate("/login");
    } else if (response.data && response.data.resultCode === 2) {
      navigate("/completada")
    };
  }

  const handleClick = async () => {
    dispatch(changeCodigoTest(code));
    await validateCode({ id: id, codigo: code })
  };

  useEffect(() => {
    const urlDecoded = decodeURIComponent(location);
    nombreUsuarioDecoded = new URLSearchParams(urlDecoded).get("nombre");
    setNombreUsuario(nombreUsuarioDecoded);
    const idInspeccion: any = new URLSearchParams(urlDecoded).get(
      "idInspeccion"
    );

    const previousId = localStorage.getItem("idInspeccion");
    
    if(previousId && previousId !== idInspeccion) {
      purgeIndexedDbWithDelay()
    }

    const origenInspeccion: any = new URLSearchParams(urlDecoded).get("o");
    setOrigen(origenInspeccion);
    localStorage.setItem("origen", origenInspeccion);
    localStorage.setItem("idInspeccion", idInspeccion);
    localStorage.setItem("userName", nombreUsuarioDecoded);
    dispatch(
      handleInspeccion({ id: idInspeccion, user: nombreUsuarioDecoded })
    );
  });

  useEffect(() => {
    if (origen === "2") {
      validateCode({ id: id, codigo: "1111" });
    }
  }, [origen]);

  console.log(origen);

  const renderContent = () => {
    if (response.resultCode === 1) {
      return (
        <DataError
          title={response.title}
          message={response.message}
          tip={response.tip}
          tipIcon={response.tipIcon}
          logo={logo}
        />
      );
    }
    if (id && nombreUsuario && nombreUsuario.length > 0) {
      return (
        <>
          <div className="registro-info">
            <div className="registro-info-logo">
              <img src={logo} alt="Logo aseguradora" />
            </div>
            <div className="registro-info-content">
              <div>
                <h1>¡{nombreUsuario.trim()}!</h1>
                <p>
                  Para comenzar con tu evaluación, por favor ingresa el código
                  de 4 dígitos incluído en el SMS que te hemos enviado.
                </p>
              </div>
              <ReactCodeInput
                fields={4}
                onChange={(c: string) => setCode(c)}
                className="registro-sms-input"
                fieldHeight={48}
                fieldWidth={42}
              />
            </div>
          </div>
          <div>
            <div className="version">{VERSION}</div>
            <div className="registro-button">
              <CustomButton
                type="primary"
                text="Comenzar"
                disable={code.length < 4}
                onClick={handleClick}
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <DataError
          title="Lo sentimos, pero no encontramos datos asociados a este link"
          tip="Comunícate con tu asesor de seguros para obtener un nuevo link"
          tipIcon={<AlertTriangle />}
          logo={logo}
        />
      );
    }
  };
  return <div className="registro">{(origen === "0" || origen === "1") ? renderContent() : null}</div>;
};
