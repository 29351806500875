import React from "react";

const Vidrio = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33997 5.90364C5.33997 5.71773 5.49068 5.56702 5.67659 5.56702H27.3014C27.4873 5.56702 27.638 5.71773 27.638 5.90364V27.5285C27.638 27.7144 27.4873 27.8651 27.3014 27.8651H5.67659C5.49068 27.8651 5.33997 27.7144 5.33997 27.5285V5.90364ZM6.01322 6.24027V27.1918H26.9648V6.24027H6.01322Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1465 5.66576C24.2778 5.7973 24.2777 6.01044 24.1462 6.14182L16.7225 13.5566C16.5909 13.6879 16.3778 13.6878 16.2464 13.5563C16.115 13.4247 16.1151 13.2116 16.2467 13.0802L23.6704 5.66547C23.8019 5.53409 24.0151 5.53422 24.1465 5.66576Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2452 19.499C19.3766 19.6305 19.3765 19.8437 19.2449 19.9751L11.8212 27.3898C11.6897 27.5212 11.4765 27.5211 11.3452 27.3895C11.2138 27.258 11.2139 27.0448 11.3454 26.9135L18.7692 19.4987C18.9007 19.3673 19.1138 19.3675 19.2452 19.499Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3726 5.66583C26.5039 5.7974 26.5038 6.01054 26.3722 6.14189L21.3722 11.1329C21.2406 11.2643 21.0275 11.2641 20.8961 11.1325C20.7648 11.0009 20.765 10.7878 20.8965 10.6564L25.8965 5.6654C26.0281 5.53406 26.2413 5.53425 26.3726 5.66583Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7654 21.9133C14.897 22.0447 14.8972 22.2578 14.7659 22.3894L9.77482 27.3894C9.64348 27.521 9.43034 27.5212 9.29876 27.3898C9.16718 27.2585 9.16699 27.0453 9.29833 26.9138L14.2894 21.9138C14.4207 21.7822 14.6338 21.782 14.7654 21.9133Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.78251 7.97732L3.45347 7.90625L3.2111 9.02833L3.21031 9.03209C3.14753 9.33974 2.90974 9.57753 2.60209 9.64032L1.47625 9.88348C1.3213 9.91695 1.21069 10.054 1.21069 10.2125C1.21069 10.371 1.3213 10.5081 1.47625 10.5416L2.59833 10.7839L2.60209 10.7847C2.90974 10.8475 3.14753 11.0853 3.21031 11.3929L3.45347 12.5188C3.48694 12.6737 3.62399 12.7843 3.78251 12.7843C3.94104 12.7843 4.07808 12.6737 4.11155 12.5188L4.35392 11.3967L4.35471 11.3929C4.41749 11.0853 4.65528 10.8475 4.96293 10.7847L6.08878 10.5416C6.24373 10.5081 6.35433 10.371 6.35433 10.2125C6.35433 10.054 6.24373 9.91695 6.08878 9.88348L4.96669 9.64111L4.96293 9.64032C4.65528 9.57753 4.41749 9.33974 4.35471 9.03209L4.11155 7.90625L3.78251 7.97732ZM3.78251 9.44757C3.63578 9.79176 3.36176 10.0658 3.01757 10.2125C3.36176 10.3592 3.63578 10.6333 3.78251 10.9775C3.92924 10.6333 4.20327 10.3592 4.54746 10.2125C4.20327 10.0658 3.92924 9.79176 3.78251 9.44757Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6388 21.1865C28.7974 21.1865 28.9344 21.2971 28.9679 21.4521L29.2111 22.5779C29.2738 22.8856 29.5116 23.1234 29.8193 23.1861L29.823 23.1869L30.9451 23.4293C31.1 23.4628 31.2107 23.5998 31.2107 23.7583C31.2107 23.9169 31.1 24.0539 30.9451 24.0874L29.8193 24.3306C29.5116 24.3933 29.2738 24.6311 29.211 24.9388L29.2103 24.9425L28.9679 26.0646C28.9344 26.2196 28.7974 26.3302 28.6388 26.3302C28.4803 26.3302 28.3433 26.2196 28.3098 26.0646L28.0666 24.9388C28.0038 24.6311 27.7661 24.3933 27.4584 24.3305L27.4546 24.3298L26.3326 24.0874C26.1776 24.0539 26.067 23.9169 26.067 23.7583C26.067 23.5998 26.1776 23.4628 26.3326 23.4293L27.4584 23.1861C27.7661 23.1233 28.0039 22.8856 28.0666 22.5779L28.0674 22.5742L28.3098 21.4521C28.3433 21.2971 28.4803 21.1865 28.6388 21.1865ZM28.6388 22.9934C28.4921 23.3376 28.2181 23.6116 27.8739 23.7583C28.2181 23.9051 28.4921 24.1791 28.6388 24.5233C28.7856 24.1791 29.0596 23.9051 29.4038 23.7583C29.0596 23.6116 28.7856 23.3376 28.6388 22.9934Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3987 20.4191C24.4335 20.5777 24.5574 20.7017 24.716 20.7364C24.7161 20.7365 24.7162 20.7365 24.7163 20.7365L25.4607 20.898C25.6155 20.9315 25.726 21.0685 25.726 21.2269C25.726 21.3854 25.6155 21.5223 25.4607 21.5559L24.7163 21.7174C24.7162 21.7174 24.7161 21.7174 24.716 21.7174C24.5574 21.7522 24.4335 21.8761 24.3987 22.0347C24.3987 22.0348 24.3987 22.0349 24.3987 22.035L24.2372 22.7794C24.2036 22.9343 24.0667 23.0447 23.9082 23.0447C23.7498 23.0447 23.6128 22.9343 23.5793 22.7794L23.4178 22.035C23.4178 22.0349 23.4178 22.0348 23.4177 22.0347C23.3829 21.8761 23.259 21.7522 23.1004 21.7174C23.1004 21.7174 23.1003 21.7174 23.1002 21.7174L22.3557 21.5559C22.2009 21.5223 22.0905 21.3854 22.0905 21.2269C22.0905 21.0685 22.2009 20.9315 22.3557 20.898L23.1002 20.7365C23.1003 20.7365 23.1004 20.7365 23.1005 20.7365C23.259 20.7017 23.383 20.5777 23.4177 20.4192C23.4178 20.4191 23.4178 20.419 23.4178 20.4189L23.5793 19.6744L23.9082 19.7458L24.2372 19.6744L24.3987 20.4189C24.3987 20.419 24.3987 20.4191 24.3987 20.4191ZM23.6296 21.2269C23.7386 21.3021 23.8331 21.3966 23.9082 21.5056C23.9834 21.3966 24.0779 21.3021 24.1869 21.2269C24.0779 21.1518 23.9834 21.0573 23.9082 20.9483C23.8331 21.0573 23.7386 21.1518 23.6296 21.2269Z"
        fill="#635F76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53653 7.79346C9.69495 7.79346 9.83193 7.90392 9.8655 8.05874L10.027 8.80321C10.027 8.80328 10.027 8.80335 10.027 8.80342C10.0618 8.96201 10.1857 9.08595 10.3443 9.12075C10.3444 9.12077 10.3445 9.12079 10.3446 9.1208L11.089 9.28226C11.2438 9.31583 11.3543 9.45281 11.3543 9.61123C11.3543 9.76965 11.2438 9.90664 11.089 9.94021L10.3446 10.1017C10.3445 10.1017 10.3444 10.1017 10.3443 10.1017C10.1857 10.1365 10.0618 10.2605 10.027 10.419C10.027 10.4191 10.027 10.4192 10.027 10.4193L9.8655 11.1637C9.83193 11.3186 9.69495 11.429 9.53653 11.429C9.37811 11.429 9.24112 11.3186 9.20755 11.1637L9.0461 10.4193C9.04608 10.4192 9.04606 10.4191 9.04605 10.419C9.01127 10.2604 8.88736 10.1365 8.72878 10.1017C8.72869 10.1017 8.7286 10.1017 8.7285 10.1017L7.98403 9.94021C7.82921 9.90664 7.71875 9.76965 7.71875 9.61123C7.71875 9.45281 7.82921 9.31583 7.98403 9.28226L8.7285 9.1208C8.72859 9.12079 8.72867 9.12077 8.72875 9.12075C8.88735 9.08596 9.01127 8.96202 9.04605 8.80343C9.04607 8.80336 9.04608 8.80328 9.0461 8.80321L9.20755 8.05874C9.24112 7.90392 9.37811 7.79346 9.53653 7.79346ZM9.53653 9.33257C9.46135 9.44157 9.36686 9.53606 9.25786 9.61123C9.36686 9.68641 9.46135 9.7809 9.53653 9.8899C9.6117 9.7809 9.70619 9.68641 9.8152 9.61123C9.70619 9.53606 9.6117 9.44157 9.53653 9.33257Z"
        fill="#635F76"
      />
    </svg>
  );
};

export default Vidrio;
