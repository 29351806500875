import React from "react";

const SistemaSismico = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.25"
        y="0.25"
        width="22.5"
        height="22.5"
        rx="2.75"
        stroke="#635F76"
        stroke-width="0.5"
      />
      <rect
        x="2.25"
        y="2.25"
        width="18.5"
        height="18.5"
        rx="1.75"
        stroke="#635F76"
        stroke-width="0.5"
      />
      <circle cx="18" cy="5" r="0.75" stroke="#635F76" stroke-width="0.5" />
      <circle cx="5" cy="18" r="0.75" stroke="#635F76" stroke-width="0.5" />
      <rect
        x="7.25"
        y="10.25"
        width="8.5"
        height="2.5"
        rx="1.25"
        stroke="#635F76"
        stroke-width="0.5"
      />
    </svg>
  );
};

export default SistemaSismico;
