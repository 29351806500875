import React from "react";

const Pvc = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.58545 18.131C1.58545 13.871 1.58545 9.61103 1.58545 5.35103C1.58545 4.69103 1.72545 4.55103 2.39545 4.55103C7.43545 4.55103 12.4654 4.55103 17.5054 4.55103C18.1454 4.55103 18.2954 4.69103 18.2954 5.33103C18.2954 13.861 18.2954 22.391 18.2954 30.921C18.2954 31.581 18.1754 31.711 17.5254 31.711C12.4754 31.711 7.42545 31.711 2.38545 31.711C1.72545 31.711 1.59545 31.581 1.59545 30.941C1.58545 26.671 1.58545 22.401 1.58545 18.131ZM13.0454 26.491V5.63103H2.65545V26.491H13.0454ZM14.1454 5.63103C14.1454 5.75103 14.1254 5.85103 14.1254 5.95103C14.1254 12.811 14.1254 19.671 14.1254 26.531C14.1254 26.701 14.2154 26.901 14.3354 27.021C15.2054 27.911 16.0954 28.791 16.9754 29.671C17.0454 29.741 17.1154 29.791 17.2254 29.871V5.63103H14.1354H14.1454ZM16.4754 30.661C16.3454 30.521 16.2654 30.431 16.1754 30.341C15.3354 29.501 14.4954 28.671 13.6654 27.821C13.4754 27.621 13.2754 27.541 12.9954 27.541C9.69545 27.541 6.39545 27.541 3.08545 27.541C2.94545 27.541 2.81545 27.551 2.65545 27.561V30.651H16.4654L16.4754 30.661Z"
        fill="#635F76"
      />
      <path
        d="M21.9455 12.8311C21.9455 13.2011 21.9455 13.5011 21.9455 13.8111C21.9455 14.1711 21.7355 14.4111 21.4355 14.4011C21.0955 14.3911 20.9155 14.1911 20.9155 13.8611C20.9155 12.8311 20.9055 11.7911 20.9155 10.7611C20.9155 10.4111 21.1355 10.2311 21.4855 10.2311C21.9955 10.2311 22.5055 10.2211 23.0155 10.2311C23.5955 10.2511 23.9855 10.6511 24.0255 11.2211C24.0355 11.4211 24.0355 11.6311 24.0255 11.8311C23.9855 12.4211 23.5755 12.8111 22.9755 12.8311C22.6455 12.8311 22.3155 12.8311 21.9455 12.8311ZM22.9655 11.7811V11.3111H21.9655V11.7811H22.9655Z"
        fill="#635F76"
      />
      <path
        d="M26.1254 12.211C26.3154 11.641 26.4654 11.181 26.6154 10.731C26.7554 10.331 27.0054 10.151 27.3054 10.241C27.6254 10.341 27.7454 10.641 27.6054 11.051C27.2954 12.001 26.9754 12.961 26.6554 13.911C26.5654 14.171 26.4254 14.381 26.1254 14.381C25.8054 14.381 25.6654 14.181 25.5754 13.901C25.2654 12.941 24.9354 11.991 24.6254 11.041C24.4954 10.641 24.5954 10.361 24.9054 10.251C25.2054 10.141 25.4754 10.311 25.6154 10.701C25.7754 11.171 25.9354 11.641 26.1254 12.211Z"
        fill="#635F76"
      />
      <path
        d="M29.2955 13.3611C29.6555 13.3611 29.9655 13.3611 30.2855 13.3611C30.6255 13.3711 30.8355 13.5811 30.8255 13.8811C30.8155 14.2011 30.6255 14.3811 30.3155 14.3911C29.9355 14.4111 29.5555 14.4111 29.1755 14.3811C28.6355 14.3311 28.2455 13.9411 28.2355 13.4011C28.2155 12.6811 28.2155 11.9511 28.2355 11.2211C28.2455 10.6711 28.6655 10.2511 29.2055 10.2211C29.5755 10.2011 29.9455 10.2011 30.3155 10.2211C30.6255 10.2311 30.8155 10.4011 30.8355 10.7211C30.8455 11.0411 30.6555 11.2311 30.3555 11.2511C30.0155 11.2811 29.6755 11.2511 29.3155 11.2511V13.3511L29.2955 13.3611Z"
        fill="#635F76"
      />
      <path
        d="M24.0454 1.72107C24.5454 1.73107 24.9454 1.93107 25.2154 2.35107C25.4954 2.79107 25.7554 3.24107 26.0054 3.70107C26.1654 4.00107 26.0954 4.29107 25.8354 4.43107C25.5354 4.58107 25.2854 4.50107 25.1154 4.22107C24.8854 3.84107 24.6754 3.45107 24.4454 3.07107C24.2054 2.67107 23.9054 2.67107 23.6654 3.07107C23.4454 3.43107 23.2454 3.80107 23.0354 4.16107C22.8354 4.50107 22.5454 4.59107 22.2754 4.42107C22.0154 4.26107 21.9554 3.97107 22.1354 3.65107C22.3754 3.23107 22.6154 2.81107 22.8654 2.40107C23.1454 1.95107 23.5554 1.74107 24.0754 1.74107L24.0454 1.72107Z"
        fill="#635F76"
      />
      <path
        d="M22.2955 8.6311C22.0455 8.6311 21.7855 8.6511 21.5355 8.6311C20.5655 8.5411 19.9955 7.5411 20.3955 6.6511C20.5355 6.3511 20.7055 6.0711 20.8855 5.7811C21.0555 5.5111 21.3055 5.4611 21.5855 5.5911C21.8455 5.7111 21.9255 6.0211 21.7755 6.3111C21.6655 6.5411 21.5155 6.7511 21.3955 6.9811C21.2155 7.3311 21.3655 7.5911 21.7655 7.6011C22.1555 7.6111 22.5555 7.6011 22.9555 7.6011C23.3155 7.6011 23.5455 7.8211 23.5455 8.1111C23.5455 8.4111 23.3155 8.6111 22.9555 8.6311C22.7355 8.6311 22.5255 8.6311 22.3055 8.6311C22.3055 8.6311 22.3055 8.6311 22.3055 8.6211L22.2955 8.6311Z"
        fill="#635F76"
      />
      <path
        d="M25.7755 8.65107C25.5555 8.65107 25.3455 8.65107 25.1255 8.65107C24.7655 8.63107 24.5255 8.41107 24.5655 8.11107C24.6055 7.79107 24.7955 7.61107 25.1355 7.61107C25.5155 7.61107 25.8955 7.61107 26.2855 7.61107C26.7455 7.60107 26.8955 7.34108 26.6655 6.93108C26.5455 6.72108 26.4155 6.51107 26.3055 6.30107C26.1555 6.02107 26.2255 5.76108 26.4855 5.61108C26.7455 5.45108 27.0155 5.51107 27.1855 5.78107C27.3755 6.08107 27.5655 6.39107 27.7055 6.71107C28.0755 7.59107 27.4855 8.55108 26.5355 8.64108C26.2855 8.66108 26.0255 8.64108 25.7755 8.64108C25.7755 8.64108 25.7755 8.64107 25.7755 8.65107Z"
        fill="#635F76"
      />
      <path
        d="M29.5155 21.181C30.0255 21.811 30.0955 22.041 29.8555 22.291C29.5955 22.561 29.3755 22.501 28.7355 21.961C28.4055 22.201 28.1255 22.751 27.6155 22.271C27.3555 22.031 27.4455 21.791 27.9755 21.201C27.7455 20.861 27.1455 20.561 27.6755 20.081C28.1355 19.661 28.4055 20.181 28.7355 20.431C29.0755 20.201 29.3755 19.631 29.8455 20.121C30.2855 20.581 29.7455 20.881 29.5155 21.201V21.181Z"
        fill="#635F76"
      />
      <path
        d="M23.2554 18.3811C22.9454 18.3811 22.6754 18.1211 22.6654 17.8011C22.6654 17.4811 22.9154 17.2111 23.2254 17.2011C23.5454 17.1911 23.8454 17.4711 23.8354 17.8011C23.8354 18.1111 23.5554 18.3811 23.2454 18.3811H23.2554Z"
        fill="#635F76"
      />
    </svg>
  );
};

export default Pvc;
