import React from 'react'

export const OtrosMobile = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.1915 29H2.80846C2.15567 29 1.57119 28.6681 1.2448 28.1124C0.918401 27.5567 0.918401 26.8939 1.2448 26.3382L14.4363 3.88758C14.7627 3.33191 15.3472 3 16 3C16.6528 3 17.2373 3.33191 17.5637 3.88758L30.7552 26.3382C31.0816 26.8939 31.0816 27.5577 30.7552 28.1124C30.4288 28.669 29.8443 29 29.1915 29ZM16 4.11973C15.8842 4.11973 15.5958 4.15143 15.4222 4.44791L2.23063 26.8976C2.05699 27.1931 2.17275 27.4551 2.23063 27.553C2.28851 27.6518 2.46119 27.8812 2.80846 27.8812H29.1906C29.5388 27.8812 29.7105 27.6518 29.7684 27.553C29.8263 27.4542 29.9421 27.1931 29.7684 26.8976L16.5778 4.44698C16.4042 4.15143 16.1158 4.11973 16 4.11973Z" fill="#635F76"/>
        <path d="M16.2517 20.6234H15.7473C14.7591 20.6234 13.948 19.8505 13.8995 18.8632L13.6357 13.466C13.6077 12.8899 13.8109 12.3417 14.209 11.924C14.6071 11.5063 15.1441 11.2761 15.7212 11.2761H16.2769C16.854 11.2761 17.391 11.5063 17.7891 11.924C18.1872 12.3417 18.3904 12.8899 18.3625 13.466L18.0986 18.8632C18.052 19.8505 17.24 20.6234 16.2517 20.6234ZM15.7222 12.3948C15.4546 12.3948 15.2047 12.502 15.0201 12.696C14.8355 12.8899 14.7404 13.1444 14.7535 13.412L15.0173 18.8091C15.036 19.1988 15.3576 19.5046 15.7473 19.5046H16.2517C16.6423 19.5046 16.9631 19.1988 16.9817 18.8091L17.2455 13.412C17.2586 13.1444 17.1644 12.8899 16.9789 12.696C16.7943 12.502 16.5454 12.3948 16.2769 12.3948H15.7222Z" fill="#635F76"/>
        <path d="M16 26.0065C14.8766 26.0065 13.9629 25.0928 13.9629 23.9694C13.9629 22.846 14.8766 21.9323 16 21.9323C17.1234 21.9323 18.0371 22.846 18.0371 23.9694C18.0371 25.0928 17.1234 26.0065 16 26.0065ZM16 23.0511C15.4937 23.0511 15.0817 23.4632 15.0817 23.9694C15.0817 24.4756 15.4937 24.8877 16 24.8877C16.5062 24.8877 16.9183 24.4756 16.9183 23.9694C16.9183 23.4632 16.5062 23.0511 16 23.0511Z" fill="#635F76"/>
    </svg>
  )
}
