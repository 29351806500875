import React from "react";

const AcristalamientoMultiple = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.35564 5.60995C8.35564 5.60995 8.31302 5.60995 8.28745 5.60995L4.02577 4.74909C3.88939 4.72352 3.78711 4.62124 3.76154 4.49339C3.72745 4.36554 3.77859 4.22917 3.88939 4.14393L7.29874 1.58692C7.37545 1.52726 7.47773 1.51021 7.57149 1.52726L11.8332 2.3796C11.9695 2.40517 12.0718 2.50745 12.0974 2.6353C12.1315 2.76315 12.0803 2.89952 11.9695 2.98476L8.5602 5.54177C8.50053 5.58438 8.43235 5.60995 8.35564 5.60995ZM4.9122 4.22917L8.27892 4.90251L10.9553 2.891L7.58853 2.21765L4.9122 4.22917Z"
        fill="#635F76"
      />
      <path
        d="M8.35566 28.0946C8.35566 28.0946 8.31305 28.0946 8.28747 28.0946L4.02579 27.2338C3.83828 27.1997 3.71895 27.0207 3.76157 26.8332C3.80418 26.6457 3.97465 26.5263 4.16217 26.5689L8.42385 27.4213C8.61136 27.4554 8.73069 27.6344 8.68807 27.8219C8.65398 27.9838 8.50908 28.0946 8.35566 28.0946Z"
        fill="#635F76"
      />
      <path
        d="M25.7774 31.5125C25.7774 31.5125 25.7348 31.5125 25.7092 31.5125L21.4475 30.6602C21.26 30.6261 21.1407 30.4471 21.1833 30.2596C21.2174 30.0721 21.3964 29.9527 21.5839 29.9953L25.7007 30.8136L28.9907 28.3503C29.1441 28.2395 29.3572 28.2651 29.468 28.4185C29.5788 28.5719 29.5533 28.785 29.3998 28.8958L25.9905 31.4528C25.9308 31.4955 25.8626 31.521 25.7859 31.521L25.7774 31.5125Z"
        fill="#635F76"
      />
      <path
        d="M25.7774 26.4411C25.7774 26.4411 25.7348 26.4411 25.7092 26.4411L21.4475 25.5887C21.26 25.5547 21.1407 25.3757 21.1833 25.1881C21.2174 25.0006 21.3964 24.8813 21.5839 24.9239L25.7007 25.7422L28.377 23.7307L24.8654 23.0317C24.6779 22.9976 24.5586 22.8187 24.6012 22.6311C24.6353 22.4436 24.8143 22.3243 25.0018 22.3669L29.2635 23.2193C29.3998 23.2448 29.5021 23.3471 29.5277 23.475C29.5618 23.6028 29.5106 23.7392 29.3998 23.8244L25.9905 26.3814C25.9308 26.424 25.8626 26.4496 25.7859 26.4496L25.7774 26.4411Z"
        fill="#635F76"
      />
      <path
        d="M8.35562 23.0828C8.25334 23.0828 8.15106 23.0316 8.08287 22.9464C7.97207 22.793 7.99764 22.5799 8.15106 22.4691L11.5604 19.9121C11.7138 19.8013 11.9269 19.8269 12.0377 19.9803C12.1485 20.1337 12.1229 20.3468 11.9695 20.4576L8.56018 23.0146C8.50052 23.0572 8.42381 23.0828 8.35562 23.0828Z"
        fill="#635F76"
      />
      <path
        d="M11.765 20.5259C11.5775 20.5259 11.4241 20.3724 11.4241 20.1849V2.71203C11.4241 2.52451 11.5775 2.37109 11.765 2.37109C11.9525 2.37109 12.1059 2.52451 12.1059 2.71203V20.1849C12.1059 20.3724 11.9525 20.5259 11.765 20.5259Z"
        fill="#635F76"
      />
      <path
        d="M23.2203 25.8275C23.2203 25.8275 23.1777 25.8275 23.1607 25.8275L8.69652 23.083C8.58571 23.0659 8.50048 22.9977 8.45786 22.904C8.2533 22.9722 8.01465 22.8017 8.01465 22.5801V7.39998C8.01465 7.25508 8.10841 7.12723 8.24478 7.07609C8.38115 7.02495 8.53457 7.07609 8.61981 7.17837L23.4846 25.2735C23.5783 25.3843 23.5868 25.5377 23.5187 25.6656C23.459 25.7678 23.3482 25.836 23.2289 25.836L23.2203 25.8275ZM8.75618 22.4011C8.75618 22.4011 8.7988 22.4011 8.81584 22.4011L22.3595 24.9666L8.69652 8.34607V22.4096C8.69652 22.4096 8.73913 22.4096 8.75618 22.4096V22.4011Z"
        fill="#635F76"
      />
      <path
        d="M24.8484 24.4636C24.8484 24.4636 24.8058 24.4636 24.7887 24.4636L10.3246 21.7191C10.2138 21.702 10.1285 21.6338 10.0859 21.5401C9.88135 21.5997 9.6427 21.4378 9.6427 21.2162V6.02754C9.6427 5.88265 9.73646 5.7548 9.87283 5.70366C10.0092 5.65252 10.1626 5.70366 10.2479 5.80594L25.1126 23.9096C25.2064 24.0204 25.2149 24.1738 25.1467 24.3016C25.087 24.4039 24.9762 24.4721 24.8569 24.4721L24.8484 24.4636ZM10.3842 21.0372C10.3842 21.0372 10.4268 21.0372 10.4439 21.0372L23.9875 23.6027L10.3246 6.98216V21.0457C10.3246 21.0457 10.3672 21.0457 10.3842 21.0457V21.0372Z"
        fill="#635F76"
      />
      <path
        d="M26.6298 23.4067C26.6298 23.4067 26.5871 23.4067 26.5701 23.4067L12.1059 20.6622C11.9951 20.6451 11.9099 20.5769 11.8673 20.4832C11.6627 20.5514 11.4241 20.3809 11.4241 20.1593V4.97066C11.4241 4.82576 11.5178 4.69791 11.6542 4.64677C11.7906 4.59563 11.944 4.64677 12.0292 4.74905L26.894 22.8442C26.9877 22.955 26.9963 23.1084 26.9281 23.2362C26.8684 23.3385 26.7576 23.4067 26.6383 23.4067H26.6298ZM12.1656 19.9803C12.1656 19.9803 12.2082 19.9803 12.2253 19.9803L25.7689 22.5458L12.1059 5.92528V19.9803C12.1059 19.9803 12.1486 19.9803 12.1656 19.9803Z"
        fill="#635F76"
      />
      <path
        d="M4.09399 27.2422C3.90647 27.2422 3.75305 27.0888 3.75305 26.9013V4.41662C3.75305 4.2291 3.90647 4.07568 4.09399 4.07568C4.2815 4.07568 4.43492 4.2291 4.43492 4.41662V26.9013C4.43492 27.0888 4.2815 27.2422 4.09399 27.2422Z"
        fill="#635F76"
      />
      <path
        d="M8.35558 23.1084C8.16807 23.1084 8.01465 22.955 8.01465 22.7675V5.26904C8.01465 5.08152 8.16807 4.9281 8.35558 4.9281C8.5431 4.9281 8.69652 5.08152 8.69652 5.26904V22.7675C8.69652 22.955 8.5431 23.1084 8.35558 23.1084Z"
        fill="#635F76"
      />
      <path
        d="M25.7774 26.3899C25.7774 26.3899 25.7348 26.3899 25.7178 26.3899L8.29601 23.0828C8.10849 23.0487 7.98917 22.8698 8.02326 22.6822C8.05735 22.4947 8.23634 22.3754 8.42386 22.4095L25.8456 25.7166C26.0331 25.7506 26.1525 25.9296 26.1184 26.1172C26.0843 26.2791 25.9479 26.3984 25.786 26.3984L25.7774 26.3899Z"
        fill="#635F76"
      />
      <path
        d="M29.1867 23.8073C29.1867 23.8073 29.1441 23.8073 29.1271 23.8073L11.7053 20.5002C11.5178 20.4661 11.3985 20.2871 11.4326 20.0996C11.4667 19.9121 11.6542 19.7928 11.8332 19.8268L29.2549 23.1339C29.4424 23.168 29.5618 23.347 29.5277 23.5345C29.4936 23.6965 29.3572 23.8158 29.1953 23.8158L29.1867 23.8073Z"
        fill="#635F76"
      />
      <path
        d="M25.7774 31.5125C25.7774 31.5125 25.7348 31.5125 25.7092 31.5125L4.02575 27.2338C3.83823 27.1997 3.7189 27.0207 3.753 26.8332C3.78709 26.6457 3.96608 26.5263 4.1536 26.5604L25.4279 30.7539V26.4155C25.4279 26.228 25.5813 26.0746 25.7689 26.0746C25.9564 26.0746 26.1098 26.228 26.1098 26.4155V31.1716C26.1098 31.2738 26.0672 31.3676 25.9819 31.4358C25.9223 31.4869 25.8456 31.5125 25.7689 31.5125H25.7774Z"
        fill="#635F76"
      />
      <path
        d="M29.1866 28.9555C28.9991 28.9555 28.8457 28.8021 28.8457 28.6146V23.6881C28.8457 23.5006 28.9991 23.3472 29.1866 23.3472C29.3742 23.3472 29.5276 23.5006 29.5276 23.6881V28.6146C29.5276 28.8021 29.3742 28.9555 29.1866 28.9555Z"
        fill="#635F76"
      />
    </svg>
  );
};

export default AcristalamientoMultiple;
