import React from "react";

const Magnetico = () => {
  return (
    <svg
      width="54"
      height="82"
      viewBox="0 0 54 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0278 74.6146H11.4567C10.4217 74.6147 9.42844 74.2041 8.6932 73.4721C7.95795 72.7402 7.54017 71.7462 7.53076 70.7064V32.3776C7.54007 31.3345 7.95669 30.3367 8.69095 29.5991C9.4252 28.8615 10.4184 28.4429 11.4567 28.4336H12.0278C13.0661 28.4429 14.0593 28.8615 14.7936 29.5991C15.5278 30.3367 15.9445 31.3345 15.9538 32.3776V70.7423C15.9351 71.7758 15.5132 72.7607 14.7789 73.4849C14.0447 74.2091 13.0568 74.6148 12.0278 74.6146ZM11.4567 31.1227C11.1254 31.1227 10.8077 31.2549 10.5734 31.4903C10.3392 31.7256 10.2076 32.0448 10.2076 32.3776V70.7423C10.2169 71.0689 10.3526 71.3789 10.5858 71.6066C10.8191 71.8342 11.1315 71.9615 11.4567 71.9614H12.0278C12.353 71.9615 12.6654 71.8342 12.8987 71.6066C13.132 71.3789 13.2677 71.0689 13.277 70.7423V32.3776C13.277 32.0448 13.1454 31.7256 12.9111 31.4903C12.6768 31.2549 12.3591 31.1227 12.0278 31.1227H11.4567Z"
        fill="#D0DFFB"
      />
      <path
        d="M11.7781 21.7278C10.9028 21.7349 10.0451 21.481 9.31345 20.9984C8.58178 20.5158 8.009 19.826 7.66754 19.0164C7.32608 18.2067 7.23128 17.3135 7.39512 16.4497C7.55896 15.5859 7.97409 14.7904 8.588 14.1636C9.20192 13.5368 9.98706 13.107 10.8441 12.9285C11.7012 12.75 12.5917 12.8308 13.4031 13.1608C14.2144 13.4907 14.9102 14.0549 15.4023 14.7821C15.8945 15.5092 16.161 16.3666 16.168 17.2459C16.1727 17.8298 16.0629 18.4089 15.8449 18.9501C15.6268 19.4913 15.3047 19.9841 14.8971 20.4003C14.4894 20.8165 14.0042 21.1479 13.469 21.3757C12.9339 21.6035 12.3593 21.7231 11.7781 21.7278ZM11.7781 15.5249C11.4293 15.5178 11.0863 15.6157 10.7933 15.806C10.5003 15.9963 10.2706 16.2703 10.1339 16.5927C9.99711 16.9152 9.95947 17.2714 10.0258 17.6155C10.0921 17.9596 10.2594 18.2759 10.5061 18.5237C10.7528 18.7716 11.0677 18.9396 11.4102 19.0063C11.7528 19.0729 12.1073 19.0351 12.4283 18.8977C12.7493 18.7603 13.022 18.5296 13.2114 18.2353C13.4008 17.9409 13.4983 17.5964 13.4912 17.2459C13.4821 16.7924 13.2987 16.3599 12.9794 16.0391C12.66 15.7183 12.2296 15.5341 11.7781 15.5249Z"
        fill="#D0DFFB"
      />
      <path
        d="M17.0245 82H5.99603C4.40579 82 2.88067 81.3654 1.7562 80.2357C0.631723 79.1061 0 77.5739 0 75.9764V5.70092C0 4.18894 0.597881 2.73889 1.66212 1.66976C2.72635 0.600631 4.16976 0 5.67482 0H17.3457C18.8507 0 20.2941 0.600631 21.3584 1.66976C22.4226 2.73889 23.0205 4.18894 23.0205 5.70092V75.9764C23.0205 77.5739 22.3888 79.1061 21.2643 80.2357C20.1398 81.3654 18.6147 82 17.0245 82ZM5.67482 2.90424C4.88916 2.90424 4.13568 3.21778 3.58014 3.77588C3.02459 4.33398 2.71249 5.09092 2.71249 5.88019V76.1557C2.71249 77.0305 3.05843 77.8695 3.67422 78.4882C4.29 79.1068 5.12519 79.4543 5.99603 79.4543H17.0245C17.4557 79.4543 17.8826 79.369 18.281 79.2032C18.6794 79.0374 19.0414 78.7945 19.3463 78.4882C19.6512 78.1818 19.893 77.8182 20.0581 77.418C20.2231 77.0178 20.308 76.5888 20.308 76.1557V5.70092C20.2625 4.94312 19.9304 4.23146 19.3797 3.71157C18.829 3.19167 18.1014 2.90286 17.3457 2.90424H5.67482Z"
        fill="#D0DFFB"
      />
      <path
        d="M48.004 75.5097H43.0073C41.417 75.5097 39.8919 74.8751 38.7674 73.7455C37.643 72.6158 37.0112 71.0837 37.0112 69.4861V19.3969C37.0112 17.8816 37.6079 16.4279 38.6712 15.353C39.7344 14.2782 41.1777 13.6696 42.686 13.6602H48.3252C49.8335 13.6696 51.2768 14.2782 52.3401 15.353C53.4033 16.4279 54 17.8816 54 19.3969V69.5937C53.9719 71.1725 53.3278 72.6772 52.2064 73.7837C51.0849 74.8901 49.5758 75.51 48.004 75.5097ZM42.686 16.421C41.9004 16.421 41.1469 16.7345 40.5914 17.2926C40.0358 17.8507 39.7237 18.6077 39.7237 19.3969V69.5937C39.7237 70.4685 40.0697 71.3076 40.6854 71.9262C41.3012 72.5448 42.1364 72.8923 43.0073 72.8923H48.004C48.8748 72.8923 49.71 72.5448 50.3258 71.9262C50.9416 71.3076 51.2875 70.4685 51.2875 69.5937V19.3969C51.2875 18.6077 50.9754 17.8507 50.4199 17.2926C49.8643 16.7345 49.1108 16.421 48.3252 16.421H42.686Z"
        fill="#D0DFFB"
      />
      <path
        d="M29.6234 34.5645H29.4093C29.2116 34.4812 29.0422 34.3419 28.9218 34.1637C28.8015 33.9855 28.7352 33.7759 28.7312 33.5606V30.5129H27.0894C26.8847 30.5249 26.6806 30.4812 26.4985 30.3866C26.3163 30.292 26.1629 30.1498 26.0544 29.9751C25.9755 29.8132 25.9346 29.6354 25.9346 29.4552C25.9346 29.275 25.9755 29.0972 26.0544 28.9353L29.9803 22.7683C30.1063 22.5981 30.2754 22.4651 30.47 22.3831C30.6647 22.3011 30.8777 22.2731 31.0867 22.3022C31.2819 22.3891 31.4488 22.5293 31.5687 22.7067C31.6886 22.8842 31.7565 23.0918 31.7649 23.3061V26.3538H33.4423C33.6228 26.366 33.7978 26.4211 33.953 26.5146C34.1081 26.6082 34.2389 26.7373 34.3346 26.8916C34.4134 27.0534 34.4544 27.2313 34.4544 27.4115C34.4544 27.5917 34.4134 27.7695 34.3346 27.9314L30.4086 34.0984C30.32 34.2294 30.2036 34.3391 30.0679 34.4196C29.9322 34.5002 29.7804 34.5497 29.6234 34.5645ZM28.3743 28.7202H29.3022C29.5956 28.7202 29.8771 28.8373 30.0846 29.0457C30.2921 29.2542 30.4086 29.5369 30.4086 29.8317V30.7639L32.0861 28.0031H31.1581C30.8647 28.0031 30.5833 27.886 30.3758 27.6775C30.1683 27.4691 30.0517 27.1864 30.0517 26.8916V26.1028L28.3743 28.7202Z"
        fill="#D0DFFB"
      />
      <path
        d="M29.6233 64.3245H29.4091C29.2168 64.2437 29.0511 64.11 28.9311 63.9387C28.8112 63.7674 28.7418 63.5656 28.731 63.3564V60.2729H27.0892C26.9026 60.2663 26.7204 60.2138 26.5586 60.1201C26.3968 60.0263 26.2604 59.8941 26.1613 59.7351C26.0825 59.5732 26.0415 59.3954 26.0415 59.2152C26.0415 59.035 26.0825 58.8572 26.1613 58.6953L30.0873 52.5283C30.1985 52.3462 30.3665 52.2062 30.5651 52.13C30.7638 52.0539 30.9819 52.0459 31.1856 52.1072C31.3892 52.1686 31.567 52.2959 31.6911 52.4694C31.8152 52.6428 31.8787 52.8526 31.8718 53.0661V56.1496H33.5493C33.7272 56.155 33.9013 56.2034 34.0566 56.2908C34.2119 56.3782 34.344 56.502 34.4415 56.6516C34.5209 56.8197 34.5621 57.0034 34.5621 57.1894C34.5621 57.3754 34.5209 57.5591 34.4415 57.7272L30.4085 63.8584C30.315 63.9846 30.1974 64.0909 30.0626 64.1709C29.9278 64.2509 29.7784 64.3031 29.6233 64.3245ZM28.4812 58.4443H29.4091C29.5575 58.4442 29.7043 58.4741 29.841 58.5322C29.9776 58.5903 30.1012 58.6754 30.2044 58.7824C30.3076 58.8895 30.3883 59.0163 30.4418 59.1553C30.4953 59.2943 30.5203 59.4427 30.5156 59.5917V60.488L32.193 57.9065H31.2651C31.1167 57.9066 30.9699 57.8767 30.8332 57.8186C30.6966 57.7605 30.573 57.6754 30.4698 57.5684C30.3666 57.4613 30.2859 57.3345 30.2324 57.1955C30.1789 57.0565 30.1539 56.9081 30.1586 56.7591V55.8628L28.4812 58.4443Z"
        fill="#D0DFFB"
      />
    </svg>
  );
};

export default Magnetico;
