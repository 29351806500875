import React from "react";

const PiedraNatural = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3407 3.927C16.6207 4.107 16.9307 4.257 17.1707 4.477C18.5607 5.737 19.9307 7.027 21.3207 8.287C21.6707 8.607 21.8407 8.987 21.8307 9.467C21.8207 10.557 21.8307 11.637 21.8307 12.727C21.8307 13.557 21.3207 14.057 20.4907 14.067C19.7507 14.067 19.0107 14.067 18.2707 14.067C17.9207 14.067 17.6107 13.967 17.3207 13.767C16.1407 12.937 14.9707 12.107 13.7807 11.287C13.6207 11.177 13.4107 11.117 13.2107 11.107C12.7107 11.087 12.2107 11.107 11.7007 11.107C11.0807 11.107 10.6507 10.797 10.4607 10.207C9.97074 8.667 9.49074 7.137 9.01074 5.597C8.77074 4.837 9.09074 4.227 9.85074 3.967C9.87074 3.967 9.89074 3.937 9.91074 3.927C12.0507 3.927 14.2007 3.927 16.3407 3.927V3.927ZM13.1307 4.767C12.1707 4.767 11.2007 4.767 10.2407 4.767C9.85074 4.767 9.70074 4.977 9.82074 5.347C10.3007 6.867 10.7807 8.387 11.2607 9.917C11.3307 10.157 11.4807 10.267 11.7307 10.267C12.2107 10.267 12.6907 10.277 13.1607 10.267C13.5907 10.247 13.9607 10.377 14.3107 10.627C15.4607 11.447 16.6207 12.257 17.7907 13.067C17.9307 13.167 18.1207 13.217 18.2907 13.227C19.0107 13.247 19.7407 13.227 20.4707 13.227C20.8807 13.227 21.0007 13.107 21.0007 12.687C21.0007 11.607 21.0007 10.527 21.0007 9.447C21.0007 9.217 20.9207 9.057 20.7607 8.907C19.3307 7.597 17.9007 6.267 16.4607 4.957C16.3507 4.857 16.1607 4.777 16.0007 4.777C15.0407 4.757 14.0907 4.777 13.1307 4.777V4.767Z"
        fill="#635F76"
      />
      <path
        d="M3.68078 25.1971C3.81078 24.7871 4.09078 24.5071 4.43078 24.2571C5.58078 23.4071 6.73078 22.5471 7.87078 21.6771C8.24078 21.3971 8.63078 21.3171 9.08078 21.4371C9.61078 21.5771 10.1508 21.7071 10.6808 21.8371C11.1608 21.9471 11.4808 22.2371 11.6408 22.6971C11.8408 23.2371 12.0408 23.7771 12.2408 24.3171C12.4108 24.7571 12.3608 25.1671 12.1008 25.5571C11.4108 26.5871 10.7308 27.6071 10.0508 28.6371C9.77078 29.0571 9.39078 29.2571 8.88078 29.2471C7.64078 29.2471 6.41078 29.2471 5.17078 29.2471C4.30078 29.2471 4.00078 29.0571 3.67078 28.2571V25.1871L3.68078 25.1971ZM6.94078 28.4071C7.58078 28.4071 8.23078 28.4071 8.87078 28.4071C9.09078 28.4071 9.24078 28.3371 9.36078 28.1471C10.0308 27.1271 10.7108 26.1171 11.3908 25.1071C11.5108 24.9371 11.5208 24.7671 11.4508 24.5771C11.2508 24.0571 11.0508 23.5371 10.8608 23.0071C10.7908 22.8071 10.6708 22.6971 10.4608 22.6471C9.92078 22.5171 9.39078 22.3671 8.85078 22.2571C8.71078 22.2271 8.51078 22.2571 8.40078 22.3471C7.18078 23.2471 5.97078 24.1571 4.76078 25.0671C4.59078 25.1971 4.52078 25.3371 4.52078 25.5471C4.52078 26.3371 4.52078 27.1271 4.52078 27.9171C4.52078 28.2771 4.65078 28.4071 5.01078 28.4071C5.65078 28.4071 6.30078 28.4071 6.94078 28.4071Z"
        fill="#635F76"
      />
      <path
        d="M3.68079 12.9269C3.83079 12.4869 4.08079 12.1369 4.55079 11.9969C5.25079 11.7869 5.93079 12.2069 6.10079 12.9469C6.30079 13.7969 6.49079 14.6469 6.68079 15.4969C7.00079 16.9269 7.33079 18.3569 7.65079 19.7869C7.78079 20.3469 7.62079 20.8069 7.15079 21.1569C6.66079 21.5269 6.18079 21.8969 5.69079 22.2569C4.92079 22.8169 4.03079 22.5369 3.72079 21.6269C3.71079 21.5969 3.69079 21.5669 3.68079 21.5369C3.68079 18.6669 3.68079 15.7969 3.68079 12.9269V12.9269ZM4.52079 17.2269C4.52079 18.5469 4.52079 19.8669 4.52079 21.1769C4.52079 21.3669 4.54079 21.5469 4.73079 21.6469C4.93079 21.7469 5.09079 21.6469 5.25079 21.5369C5.70079 21.1969 6.14079 20.8569 6.60079 20.5369C6.84079 20.3669 6.89079 20.1769 6.83079 19.8969C6.43079 18.1869 6.05079 16.4769 5.66079 14.7769C5.53079 14.2169 5.40079 13.6569 5.27079 13.0969C5.23079 12.9169 5.10079 12.7869 4.92079 12.8069C4.79079 12.8269 4.66079 12.9369 4.56079 13.0469C4.51079 13.1069 4.52079 13.2369 4.52079 13.3369C4.52079 14.6369 4.52079 15.9369 4.52079 17.2369V17.2269Z"
        fill="#635F76"
      />
      <path
        d="M6.94079 3.927C7.51079 4.107 7.79079 4.527 7.96079 5.087C8.39079 6.517 8.86079 7.937 9.31079 9.367C9.62079 10.357 9.08079 11.107 8.05079 11.107C7.09079 11.107 6.14079 11.107 5.18079 11.107C4.30079 11.107 4.01079 10.917 3.68079 10.117V4.917C3.83079 4.407 4.16079 4.077 4.67079 3.927H6.94079ZM6.53079 10.257C7.07079 10.257 7.60079 10.257 8.14079 10.257C8.46079 10.257 8.63079 10.037 8.53079 9.737C8.04079 8.187 7.55079 6.627 7.06079 5.077C6.99079 4.867 6.85079 4.767 6.64079 4.767C6.08079 4.767 5.52079 4.767 4.96079 4.767C4.66079 4.767 4.52079 4.907 4.52079 5.207C4.52079 6.737 4.52079 8.267 4.52079 9.807C4.52079 10.117 4.66079 10.257 4.98079 10.257C5.50079 10.257 6.02079 10.257 6.54079 10.257H6.53079Z"
        fill="#635F76"
      />
      <path
        d="M28.0208 3.927C28.4908 4.087 28.8508 4.357 28.9708 4.867C29.1308 5.547 28.8008 6.197 28.1408 6.397C26.4308 6.907 24.7208 7.407 23.0108 7.907C22.5808 8.037 22.1808 7.917 21.8408 7.617C21.2908 7.107 20.7408 6.607 20.1908 6.087C19.4308 5.367 19.6908 4.287 20.6908 3.967C20.7108 3.967 20.7308 3.937 20.7508 3.927C23.1708 3.927 25.6008 3.927 28.0208 3.927V3.927ZM24.4108 4.767C23.3108 4.767 22.2008 4.767 21.1008 4.767C20.9008 4.767 20.7208 4.797 20.6308 5.007C20.5408 5.217 20.6508 5.367 20.8008 5.507C21.3208 5.977 21.8308 6.457 22.3408 6.937C22.5108 7.097 22.6808 7.137 22.9008 7.067C24.5208 6.587 26.1508 6.107 27.7808 5.627C28.0808 5.537 28.2008 5.377 28.1608 5.117C28.1208 4.877 27.9708 4.777 27.6508 4.777C26.5708 4.777 25.4908 4.777 24.4108 4.777V4.767Z"
        fill="#635F76"
      />
      <path
        d="M15.9308 24.607C15.3408 24.607 14.7608 24.607 14.1708 24.607C13.5608 24.607 13.1508 24.327 12.9308 23.767C12.7608 23.327 12.5908 22.877 12.4308 22.427C12.2608 21.967 12.3308 21.547 12.6408 21.177C14.2408 19.237 15.8308 17.297 17.4308 15.357C17.6708 15.057 17.9908 14.917 18.3808 14.917C19.7808 14.917 21.1808 14.917 22.5808 14.917C23.4108 14.917 23.9808 15.657 23.7608 16.457C23.4508 17.597 23.1208 18.737 22.7808 19.877C22.7208 20.087 22.5808 20.297 22.4208 20.457C21.1808 21.717 19.9208 22.967 18.6708 24.227C18.3908 24.507 18.0708 24.637 17.6708 24.637C17.0808 24.637 16.5008 24.637 15.9108 24.637L15.9308 24.607ZM15.9308 23.767C16.5008 23.767 17.0708 23.767 17.6408 23.767C17.8508 23.767 18.0008 23.707 18.1408 23.557C19.3608 22.327 20.5908 21.107 21.8108 19.877C21.9108 19.777 21.9908 19.637 22.0308 19.507C22.3408 18.447 22.6408 17.387 22.9408 16.327C23.0508 15.927 22.9108 15.747 22.5008 15.747C21.1608 15.747 19.8308 15.747 18.4908 15.747C18.2908 15.747 18.1508 15.817 18.0208 15.967C16.4608 17.867 14.9008 19.757 13.3408 21.657C13.2008 21.827 13.1808 21.997 13.2608 22.207C13.4208 22.617 13.5808 23.017 13.7208 23.427C13.8108 23.677 13.9708 23.767 14.2208 23.767C14.7908 23.757 15.3608 23.767 15.9308 23.767Z"
        fill="#635F76"
      />
      <path
        d="M10.5808 11.947C11.3008 11.947 12.0108 11.947 12.7308 11.947C13.0708 11.947 13.3808 12.027 13.6608 12.227C14.3908 12.747 15.1308 13.257 15.8608 13.777C16.5208 14.247 16.6308 14.997 16.1208 15.627C14.7808 17.277 13.4208 18.907 12.0708 20.547C11.7208 20.967 11.2808 21.117 10.7408 20.987C10.3008 20.877 9.86079 20.767 9.42079 20.657C8.93079 20.527 8.62079 20.217 8.50079 19.727C8.02079 17.637 7.54079 15.537 7.08079 13.447C6.90079 12.647 7.47079 11.967 8.30079 11.957C9.06079 11.957 9.82079 11.957 10.5708 11.957L10.5808 11.947ZM10.5808 12.787C9.86079 12.787 9.15079 12.787 8.43079 12.787C7.98079 12.787 7.84079 12.957 7.94079 13.377C8.40079 15.407 8.86079 17.437 9.32079 19.467C9.37079 19.677 9.48079 19.797 9.69079 19.847C10.1008 19.947 10.5108 20.037 10.9108 20.147C11.1408 20.207 11.3008 20.147 11.4508 19.967C12.7808 18.347 14.1108 16.737 15.4408 15.117C15.6808 14.827 15.6508 14.637 15.3408 14.417C14.6308 13.917 13.9108 13.407 13.1908 12.917C13.0608 12.827 12.8908 12.777 12.7308 12.777C12.0108 12.767 11.3008 12.777 10.5808 12.777V12.787Z"
        fill="#635F76"
      />
      <path
        d="M23.9307 29.257C22.6907 29.257 21.4607 29.257 20.2207 29.257C19.3807 29.257 18.8807 28.757 18.8807 27.917C18.8807 27.217 18.8807 26.517 18.8807 25.817C18.8807 25.407 19.0207 25.067 19.3107 24.777C20.2607 23.837 21.2007 22.887 22.1407 21.947C22.7507 21.337 23.4507 21.337 24.0507 21.947C25.5607 23.457 27.0707 24.967 28.5807 26.477C28.8707 26.767 29.0207 27.107 29.0107 27.517C29.0107 27.647 29.0107 27.777 29.0107 27.917C29.0107 28.777 28.5207 29.277 27.6607 29.277C26.4207 29.277 25.1707 29.277 23.9307 29.277V29.257ZM23.9407 28.417H27.3507C28.1507 28.417 28.1607 28.407 28.1707 27.607C28.1707 27.397 28.1407 27.217 27.9707 27.047C26.4607 25.547 24.9507 24.037 23.4407 22.527C23.1807 22.267 23.0107 22.277 22.7507 22.527C21.8107 23.467 20.8607 24.407 19.9207 25.367C19.8107 25.487 19.7307 25.677 19.7207 25.837C19.7007 26.507 19.7207 27.187 19.7207 27.867C19.7207 28.297 19.8407 28.407 20.2607 28.407C21.4907 28.407 22.7107 28.407 23.9407 28.407V28.417Z"
        fill="#635F76"
      />
      <path
        d="M29.0108 19.3169C29.0108 20.4269 29.0108 21.5469 29.0108 22.6569C29.0108 23.2669 28.7508 23.6969 28.2408 23.8869C27.7008 24.0869 27.2508 23.9469 26.8508 23.5469C25.9608 22.6469 25.0708 21.7569 24.1808 20.8669C23.7808 20.4669 23.6508 20.0269 23.8108 19.4769C24.1708 18.2469 24.5108 17.0069 24.8708 15.7769C25.0408 15.1769 25.4308 14.8669 26.0708 14.8069C26.5608 14.7669 27.0608 14.7169 27.5508 14.6769C28.4708 14.5969 29.0308 15.1069 29.0308 16.0369C29.0308 17.1369 29.0308 18.2269 29.0308 19.3269L29.0108 19.3169ZM28.1708 19.3169C28.1708 18.2169 28.1708 17.1069 28.1708 16.0069C28.1708 15.6069 28.0208 15.4769 27.6208 15.5069C27.1308 15.5469 26.6408 15.6069 26.1408 15.6369C25.8808 15.6569 25.7308 15.7669 25.6608 16.0169C25.3108 17.2469 24.9708 18.4669 24.6108 19.6969C24.5408 19.9369 24.6008 20.1069 24.7708 20.2769C25.6608 21.1569 26.5408 22.0469 27.4308 22.9369C27.5708 23.0769 27.7208 23.1969 27.9308 23.1069C28.1508 23.0169 28.1708 22.8269 28.1708 22.6169C28.1708 21.5169 28.1708 20.4269 28.1708 19.3269V19.3169Z"
        fill="#635F76"
      />
      <path
        d="M29.0108 10.6071C29.0108 11.2371 29.0108 11.8771 29.0108 12.5071C29.0108 13.2171 28.5808 13.7271 27.8708 13.7871C26.5908 13.8971 25.3108 13.9871 24.0308 14.0471C23.1808 14.0871 22.6808 13.5571 22.6808 12.6971C22.6808 11.7571 22.6808 10.8171 22.6808 9.87709C22.6808 9.21709 23.0008 8.78709 23.6308 8.59709C24.8808 8.22709 26.1208 7.85709 27.3708 7.48709C28.2608 7.22709 29.0108 7.78709 29.0108 8.71709C29.0108 9.34709 29.0108 9.96709 29.0108 10.5971V10.6071ZM28.1708 10.6271C28.1708 10.0071 28.1708 9.38709 28.1708 8.77709C28.1708 8.35709 27.9808 8.20709 27.5708 8.32709C26.3508 8.68709 25.1408 9.04709 23.9208 9.40709C23.5808 9.50709 23.5208 9.58709 23.5208 9.95709C23.5208 10.8571 23.5208 11.7571 23.5208 12.6571C23.5208 13.1171 23.6308 13.2471 24.0808 13.2271C25.3108 13.1571 26.5308 13.0671 27.7508 12.9771C28.0408 12.9571 28.1608 12.8071 28.1608 12.5171C28.1608 11.8871 28.1608 11.2671 28.1608 10.6371L28.1708 10.6271Z"
        fill="#635F76"
      />
      <path
        d="M14.8708 29.257C14.2508 29.257 13.6308 29.257 13.0208 29.257C12.5208 29.257 12.1208 29.057 11.8808 28.617C11.6408 28.177 11.6908 27.727 11.9608 27.307C12.2408 26.887 12.5308 26.457 12.8108 26.037C13.0708 25.637 13.4508 25.457 13.9208 25.457C14.8708 25.457 15.8108 25.457 16.7608 25.457C17.5208 25.457 18.0308 25.977 18.0308 26.737C18.0308 27.167 18.0308 27.597 18.0308 28.017C18.0308 28.727 17.5108 29.247 16.8008 29.257C16.1608 29.257 15.5208 29.257 14.8708 29.257ZM14.8708 28.417C15.5008 28.417 16.1208 28.417 16.7508 28.417C17.0608 28.417 17.2008 28.277 17.2008 27.967C17.2008 27.567 17.2008 27.157 17.2008 26.757C17.2008 26.447 17.0608 26.307 16.7408 26.307C15.8108 26.307 14.8808 26.307 13.9508 26.307C13.7508 26.307 13.6108 26.377 13.5008 26.547C13.2408 26.947 12.9608 27.337 12.7108 27.747C12.6308 27.877 12.6008 28.077 12.6408 28.217C12.6908 28.407 12.8908 28.427 13.0708 28.427C13.6708 28.427 14.2708 28.427 14.8708 28.427V28.417Z"
        fill="#635F76"
      />
    </svg>
  );
};

export default PiedraNatural;
