import React from 'react'

export const Download = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.9999 18C19.9281 18 20.8184 17.6313 21.4747 16.9749C22.1311 16.3185 22.4999 15.4283 22.4999 14.5C22.4999 13.5718 22.1311 12.6815 21.4747 12.0251C20.8184 11.3688 19.9281 11 18.9999 11H17.9999C18.1458 10.35 18.148 9.68042 18.0064 9.02961C17.8648 8.37879 17.5822 7.75945 17.1746 7.20694C16.7671 6.65443 16.2426 6.17958 15.6312 5.80949C15.0197 5.43941 14.3332 5.18133 13.611 5.05001C12.8887 4.91869 12.1448 4.91669 11.4216 5.04412C10.6985 5.17156 10.0104 5.42594 9.39645 5.79273C8.15663 6.5335 7.29455 7.68719 6.99987 9.00001C5.93382 8.95731 4.88541 9.27041 4.03414 9.88571C3.18287 10.501 2.58169 11.3802 2.33354 12.3728C2.08539 13.3654 2.2057 14.4097 2.67387 15.3268C3.14204 16.2439 3.92895 16.9768 4.89987 17.4" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 13V22" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 19L12 22L15 19" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
