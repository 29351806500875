import React, { useEffect, useState } from 'react'

const useNetwork = () => {
  const [networkStatus, setNetworkStatus] = useState<boolean>(true);

  useEffect(() => {
    const handleStatusChange = () => {
      setNetworkStatus(navigator.onLine)
    }

    window.addEventListener('online', handleStatusChange)
    window.addEventListener('offline', handleStatusChange)
    return () => {
      window.removeEventListener('online', handleStatusChange)
      window.removeEventListener('offline', handleStatusChange)
    }
  }, [])

  return networkStatus
}

export default useNetwork