import Lottie from "lottie-web";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as animationData from "../../Assets/jsonAnimations/animatedSearch.json";
import { CustomButton } from "../../Components/CustomButton/CustomButton";
import {
  getPreguntas,
  selectId,
  selectStatusGetPreguntas,
} from "../LayoutPregunta/preguntaSlice";
import "./LayoutCarga.scss";

export const LayoutCarga = () => {
  const lottieInstance = useRef<any>();
  const divRef = useRef<any>();
  const dispatch = useDispatch();
  const preguntaId = useSelector(selectId);
  const [status, setStatus] = useState("success");
  const statusFetch = useSelector(selectStatusGetPreguntas);
  const refreshTime = 10000;

  const getSiguientePregunta = async () => {
    setStatus("loading");
    //@ts-ignore
    const response: any = await dispatch(getPreguntas(preguntaId));

    if (response.payload.status === 200) {
      setStatus("success");
      console.log("LOADER: " + JSON.stringify(response));
      return response;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      console.log(statusFetch);
      console.log(status);
      console.log(preguntaId);

      if (statusFetch !== "loading") {
        getSiguientePregunta();
      } else {
        console.log(status);
      }
    }, refreshTime);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (divRef.current) {
      lottieInstance.current = Lottie.loadAnimation({
        animationData,
        container: divRef.current,
      });
    }
  }, []);

  return (
    <div className="layout-carga">
      <div className="layout-carga-text">
        <div className="layout-carga-svg" ref={divRef}></div>
        <p className="layout-carga-msg1">
          Estamos intentando localizar los datos catastrales a partir de la
          información que nos has dado.
        </p>
        <p className="layout-carga-msg2">
          Este proceso puede tardar unos segundos. Por favor, espera.
        </p>
      </div>
    </div>
  );
};
