import { persistReducer, persistStore } from 'redux-persist';
import {
  Action, combineReducers, configureStore,
  ThunkAction
} from "@reduxjs/toolkit";
import preguntaSlice from "../features/LayoutPregunta/preguntaSlice";
import userSlice from "../features/Registro/userSlice";
//@ts-ignore
import storage from 'redux-persist-indexeddb-storage';

const persistConfig = {
  key: 'preguntas',
  storage: storage('MyDB'),
  whitelist: ["bienes"]
}

const reducers = combineReducers({
  preguntas: persistReducer(persistConfig, preguntaSlice),
  usuario: userSlice
})

export const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const persistor = persistStore(store);