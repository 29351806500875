import React from "react";

const Velero = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.49146 23.7461C5.26671 23.7461 5.06802 23.5839 5.02932 23.3547C4.98616 23.0994 5.15881 22.8576 5.41406 22.8151L27.4985 19.1128C27.7538 19.0697 27.9956 19.2423 28.0381 19.4976C28.0805 19.7528 27.9086 19.9947 27.6533 20.0371L5.56885 23.7394C5.54281 23.7439 5.51676 23.7461 5.49146 23.7461Z"
        fill="#635F76"
      />
      <path
        d="M22.4834 27.7744C22.3324 27.7744 22.1843 27.7015 22.0935 27.5668C21.9491 27.3517 22.0072 27.0607 22.2222 26.9164C24.7778 25.204 26.5616 22.4967 27.1153 19.4894C27.2209 18.9164 27.2834 18.33 27.3006 17.7406L5.15061 21.454C5.35824 22.001 5.60903 22.5339 5.89926 23.0451C6.77517 24.5886 8.01871 25.9274 9.49517 26.9164C9.71024 27.0607 9.76754 27.3517 9.62392 27.5668C9.48029 27.7819 9.18857 27.8392 8.9735 27.6955C7.37647 26.6254 6.03099 25.178 5.08438 23.5087C4.6721 22.7839 4.3335 22.0167 4.07824 21.2293C4.03582 21.0998 4.05294 20.9577 4.12364 20.8416C4.19433 20.7247 4.31266 20.6451 4.44661 20.6228L27.695 16.7255C27.8304 16.7024 27.9688 16.7404 28.0738 16.8282C28.1787 16.916 28.2405 17.0455 28.2412 17.1832L28.242 17.2524C28.2427 17.3022 28.2434 17.3521 28.2434 17.402C28.2434 18.1618 28.1742 18.9223 28.0381 19.6606C27.4382 22.9149 25.5093 25.844 22.7447 27.6963C22.6635 27.7491 22.5728 27.7744 22.4834 27.7744Z"
        fill="#635F76"
      />
      <path
        d="M9.90072 20.3534C9.64174 20.3534 9.43188 20.1435 9.43188 19.8845V5.07077C9.43188 4.81179 9.64174 4.60193 9.90072 4.60193C10.1597 4.60193 10.3696 4.81179 10.3696 5.07077V19.8845C10.3696 20.1435 10.1597 20.3534 9.90072 20.3534Z"
        fill="#635F76"
      />
      <path
        d="M4.22628 19.6322C4.1154 19.6322 4.00749 19.5928 3.92191 19.5206C3.81698 19.4313 3.75745 19.3011 3.75745 19.1634C3.75745 8.80136 7.46796 5.78369 7.62573 5.66015C7.76712 5.55001 7.95912 5.52992 8.11987 5.60806C8.28061 5.6862 8.38331 5.84992 8.38331 6.02927V18.449C8.38331 18.5048 8.37363 18.5599 8.35428 18.612C8.26424 18.8538 8.06182 18.897 7.91298 18.929C7.84898 18.9424 7.75521 18.9625 7.63838 18.987C7.4047 19.0362 7.07875 19.1039 6.70666 19.179C6.02721 19.316 5.05754 19.505 4.3007 19.627C4.27614 19.63 4.25084 19.6322 4.22628 19.6322ZM7.44563 7.27727C7.15242 7.69104 6.80414 8.26555 6.45735 9.0328C5.68266 10.7467 4.76507 13.7807 4.69884 18.6068C5.69307 18.4304 6.86294 18.19 7.44563 18.068V7.27727Z"
        fill="#635F76"
      />
      <path
        d="M11.8899 18.1432C11.7627 18.1432 11.6183 18.1075 11.5059 17.9527C11.4025 17.8098 11.3891 17.6208 11.4694 17.4637C12.9459 14.6046 12.8685 11.5028 12.544 9.4042C12.1883 7.10392 11.465 5.46671 11.4583 5.45033C11.3913 5.30001 11.4077 5.12587 11.5022 4.99117C11.596 4.85647 11.7545 4.78057 11.9182 4.79173C12.0239 4.79917 14.54 4.98447 17.0881 6.4721C19.459 7.85703 22.2854 10.6656 22.2854 16.1837C22.2854 16.4167 22.115 16.6139 21.8851 16.6474C18.8168 17.0983 15.3228 17.6304 13.446 17.9162C12.9854 17.9861 12.614 18.0427 12.3565 18.0821C12.2278 18.1015 12.1273 18.1164 12.0596 18.1268C12.0112 18.135 11.9524 18.1432 11.8899 18.1432ZM12.6118 5.8321C12.87 6.55917 13.2361 7.7588 13.4661 9.23303C13.9148 12.1048 13.6425 14.7988 12.6802 17.0849C12.8663 17.0567 13.0761 17.0247 13.3039 16.9904C15.0996 16.7173 18.374 16.218 21.3418 15.7804C21.2399 11.9255 19.66 9.07378 16.6438 7.29964C15.0884 6.38354 13.5122 5.99285 12.6118 5.8321Z"
        fill="#635F76"
      />
      <path
        d="M22.5116 27.7743H9.23385C8.97488 27.7743 8.76501 27.5645 8.76501 27.3055C8.76501 27.0465 8.97488 26.8367 9.23385 26.8367H22.5116C22.7706 26.8367 22.9805 27.0465 22.9805 27.3055C22.9805 27.5645 22.7706 27.7743 22.5116 27.7743Z"
        fill="#635F76"
      />
    </svg>
  );
};

export default Velero;
