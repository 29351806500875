export const Inmueble = () => {
  return (
    <svg width="76" height="64" viewBox="0 0 76 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M43.9883 61.8169V34.9235H61.9878V61.8169M14.1776 49.8644V34.7227H38.0827V49.8644H14.1776Z" stroke="#D0DFFB" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M67.9643 26.5575V61.8177H8.20138V26.5575M16.5681 19.3859C16.5681 23.3466 13.3575 26.5575 9.39679 26.5575C5.43612 26.5575 2.22522 23.3466 2.22522 19.3859L8.20138 2.05469H67.9643L73.9405 19.3859C73.9405 23.3466 70.7296 26.5575 66.7689 26.5575C62.8082 26.5575 59.5975 23.3466 59.5975 19.3859" stroke="#D0DFFB" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M45.2546 19.3867C45.2546 23.3474 42.0437 26.5583 38.0831 26.5583C34.1224 26.5583 30.9115 23.3474 30.9115 19.3867" stroke="#D0DFFB" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20.1541 2.05469L16.5684 19.3859C16.5684 23.3466 19.7793 26.5575 23.7399 26.5575C27.7006 26.5575 30.9115 23.3466 30.9115 19.3859L32.1067 2.05469M44.0594 2.05469L45.2546 19.3859C45.2546 23.3466 48.4655 26.5575 52.4262 26.5575C56.3869 26.5575 59.5978 23.3466 59.5978 19.3859L56.012 2.05469H44.0594Z" stroke="#D0DFFB" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}
