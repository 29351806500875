import React from "react";

const CierreMetalico = () => {
  return (
    <svg
      width="26"
      height="23"
      viewBox="0 0 26 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1590_2862)">
        <path
          d="M25.3973 0.225098H0.602782C0.39271 0.225098 0.222412 0.397486 0.222412 0.610137V2.83555C0.222412 3.0482 0.39271 3.22059 0.602782 3.22059H25.3973C25.6074 3.22059 25.7777 3.0482 25.7777 2.83555V0.610137C25.7777 0.397486 25.6074 0.225098 25.3973 0.225098Z"
          stroke="#878598"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M25.707 3.20117H0.392334L0.838245 5.10765H25.335L25.707 3.20117Z"
          stroke="#878598"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M2.48181 5.12634H0.770264V22.7751H2.48181V5.12634Z"
          stroke="#878598"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M25.1862 5.11047H23.4746V22.7593H25.1862V5.11047Z"
          stroke="#878598"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M2.44678 6.38739H23.3697"
          stroke="#878598"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M2.44678 7.90765H23.3697"
          stroke="#878598"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M2.44678 9.39282H23.3697"
          stroke="#878598"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M2.44678 10.9133H23.3697"
          stroke="#878598"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M2.44678 12.363H23.3697"
          stroke="#878598"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M2.44678 13.8479H23.3697"
          stroke="#878598"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M2.44678 15.3684H23.3697"
          stroke="#878598"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M2.44678 16.7474H23.3697"
          stroke="#878598"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M2.44678 18.1971H23.3697"
          stroke="#878598"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M2.44678 19.682H23.3697"
          stroke="#878598"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M2.44678 21.2025H23.3697"
          stroke="#878598"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M1.67673 7.80621C1.64159 7.80621 1.6068 7.81322 1.57434 7.82683C1.54188 7.84044 1.51239 7.86039 1.48754 7.88554C1.4627 7.91069 1.44299 7.94054 1.42955 7.9734C1.4161 8.00626 1.40918 8.04148 1.40918 8.07704C1.40912 8.11266 1.41599 8.14794 1.42941 8.18087C1.44282 8.21379 1.46252 8.24372 1.48736 8.26894C1.51221 8.29416 1.54173 8.31417 1.57422 8.32784C1.60671 8.3415 1.64154 8.34855 1.67673 8.34859C1.74771 8.34852 1.81576 8.31994 1.86593 8.26911C1.9161 8.21828 1.94428 8.14937 1.94428 8.07752C1.94434 8.04191 1.93746 8.00664 1.92404 7.97373C1.91062 7.94082 1.89093 7.9109 1.86608 7.88571C1.84123 7.86051 1.81171 7.84051 1.77922 7.82687C1.74673 7.81323 1.7119 7.80621 1.67673 7.80621Z"
          fill="#878598"
        />
        <path
          d="M1.67673 19.7642C1.60575 19.7643 1.5377 19.7929 1.48753 19.8437C1.43736 19.8945 1.40918 19.9634 1.40918 20.0353C1.40918 20.1071 1.43737 20.176 1.48754 20.2268C1.53772 20.2776 1.60577 20.3061 1.67673 20.3061C1.71186 20.3061 1.74665 20.2991 1.77911 20.2855C1.81157 20.2719 1.84107 20.2519 1.86591 20.2268C1.89076 20.2016 1.91046 20.1718 1.92391 20.1389C1.93735 20.1061 1.94428 20.0709 1.94428 20.0353C1.94428 19.9634 1.91609 19.8945 1.86593 19.8437C1.81576 19.7929 1.74771 19.7643 1.67673 19.7642Z"
          fill="#878598"
        />
        <path
          d="M24.2749 7.80621C24.2039 7.80628 24.1359 7.83483 24.0858 7.88561C24.0356 7.93638 24.0074 8.00523 24.0073 8.07704C24.0073 8.11266 24.0141 8.14794 24.0276 8.18087C24.041 8.21379 24.0607 8.24372 24.0855 8.26894C24.1104 8.29416 24.1399 8.31417 24.1724 8.32784C24.2049 8.3415 24.2397 8.34855 24.2749 8.34859C24.3459 8.34852 24.4139 8.31994 24.4641 8.26911C24.5142 8.21828 24.5424 8.14937 24.5424 8.07752C24.5424 8.04195 24.5355 8.00673 24.5221 7.97388C24.5086 7.94102 24.4889 7.91116 24.4641 7.88601C24.4392 7.86086 24.4097 7.84091 24.3773 7.8273C24.3448 7.81369 24.31 7.80669 24.2749 7.80669V7.80621Z"
          fill="#878598"
        />
        <path
          d="M24.2749 19.7642C24.2039 19.7643 24.1358 19.7929 24.0857 19.8437C24.0355 19.8945 24.0073 19.9634 24.0073 20.0353C24.0074 20.1071 24.0356 20.176 24.0858 20.2268C24.136 20.2776 24.2041 20.3061 24.2751 20.3061C24.3461 20.3061 24.4141 20.2776 24.4643 20.2268C24.5145 20.176 24.5427 20.1071 24.5427 20.0353C24.5427 19.9634 24.5145 19.8945 24.4643 19.8437C24.4141 19.7929 24.3461 19.7643 24.2751 19.7642H24.2749Z"
          fill="#878598"
        />
        <path
          d="M12.9846 20.2908C12.9136 20.2908 12.8456 20.3193 12.7954 20.3701C12.7452 20.4209 12.717 20.4898 12.717 20.5616C12.717 20.5972 12.7239 20.6324 12.7373 20.6653C12.7508 20.6982 12.7705 20.7281 12.7953 20.7533C12.8202 20.7784 12.8497 20.7984 12.8821 20.812C12.9146 20.8257 12.9494 20.8327 12.9846 20.8327C13.0556 20.8326 13.1236 20.804 13.1738 20.7532C13.224 20.7024 13.2521 20.6335 13.2521 20.5616C13.2521 20.4898 13.2239 20.4209 13.1738 20.3701C13.1236 20.3193 13.0555 20.2908 12.9846 20.2908Z"
          fill="#878598"
        />
      </g>
      <defs>
        <clipPath id="clip0_1590_2862">
          <rect width="26" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CierreMetalico;
