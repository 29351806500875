import React from "react";

const PuertaHueca = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_978_1413)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.282104 30.9228C0.282104 30.7019 0.461191 30.5228 0.682104 30.5228H32.6821C32.903 30.5228 33.0821 30.7019 33.0821 30.9228C33.0821 31.1437 32.903 31.3228 32.6821 31.3228H0.682104C0.461191 31.3228 0.282104 31.1437 0.282104 30.9228Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.42212 3.65281C8.42212 3.43189 8.60121 3.25281 8.82212 3.25281H24.5421C24.763 3.25281 24.9421 3.43189 24.9421 3.65281V30.9228C24.9421 31.1437 24.763 31.3228 24.5421 31.3228H8.82212C8.60121 31.3228 8.42212 31.1437 8.42212 30.9228V3.65281ZM9.22212 4.05281V30.5228H24.1421V4.05281H9.22212Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.6521 1.92283C6.6521 1.70191 6.83119 1.52283 7.0521 1.52283H26.3121C26.533 1.52283 26.7121 1.70191 26.7121 1.92283V30.9228C26.7121 31.1437 26.533 31.3228 26.3121 31.3228H7.0521C6.83119 31.3228 6.6521 31.1437 6.6521 30.9228V1.92283ZM7.4521 2.32283V30.5228H25.9121V2.32283H7.4521Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.3021 17.0129C20.3021 16.792 20.4812 16.6129 20.7021 16.6129H22.6221C22.843 16.6129 23.0221 16.792 23.0221 17.0129V18.9329C23.0221 19.1538 22.843 19.3329 22.6221 19.3329H20.7021C20.4812 19.3329 20.3021 19.1538 20.3021 18.9329V17.0129ZM21.1021 17.4129V18.5329H22.2221V17.4129H21.1021Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1621 17.9729C19.1621 17.752 19.3412 17.5729 19.5621 17.5729H20.5321C20.753 17.5729 20.9321 17.752 20.9321 17.9729C20.9321 18.1938 20.753 18.3729 20.5321 18.3729H19.5621C19.3412 18.3729 19.1621 18.1938 19.1621 17.9729Z"
          fill="#635F76"
        />
      </g>
      <defs>
        <clipPath id="clip0_978_1413">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.682129 0.422852)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PuertaHueca;
