import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import { Header } from "./Components/Header/Header";
import { ActividadEmpresarial } from "./features/ActividadEmpresarial/ActividadEmpresarial";
import { DatosIdentificativos } from "./features/DatosEmpresa/DatosIdentificativos";
import { DireccionFiscal } from "./features/DatosEmpresa/DireccionFiscal";
import { DireccionFiscalAutocomplete } from "./features/DatosEmpresa/DireccionFiscalAutocomplete";
import { DireccionFiscalAyuware } from "./features/DatosEmpresa/DireccionFiscalAyuware";
import { LayoutCarga } from "./features/LayoutCarga/LayoutCarga";
import { LayoutCargarSimple } from "./features/LayoutCarga/LayoutCargarSimple";
import { LayoutConfirmar } from "./features/LayoutConfirmar/LayoutConfirmar";
import { SuccessConfirmar } from "./features/LayoutConfirmar/SuccessConfirmar";
import LayoutFotografias from "./features/LayoutFotografias/LayoutFotografias";
import { LayoutMensaje } from "./features/LayoutMensaje/LayoutMensaje";
import LayoutPregunta from "./features/LayoutPregunta/LayoutPregunta";
import { fetchPreguntaAnterior, handleInspeccion, selectId, selectPreguntas } from "./features/LayoutPregunta/preguntaSlice";
import { LayoutRelleno } from "./features/LayoutRelleno/LayoutRelleno";
import { Login } from "./features/Login/Login";
import { Registro } from "./features/Registro/Registro";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import conf from "./app/config";
import { DatosIniciales } from "./features/DatosEmpresa/DatosIniciales";
import { FechaInicioDesconozco } from "./features/FechaInicioDesconozco/FechaInicioDesconozco";
import LayoutBienes from "./features/LayoutBienes/LayoutBienes";
import { changeLogo } from "./features/Registro/userSlice";
import { getLogo } from "./features/Registro/usuarioAPI";
import { ActividadSecundaria } from "./features/ActividadEmpresarial/ActividadSecundaria";
import { neutralizeBack } from "./utils";
import DireccionMaps from "./features/DatosEmpresa/DireccionMaps";
import CargaInvitacion from "./features/CargaInvitacion/CargaInvitacion";
import LayoutFotografiasLibres from "./features/LayoutFotografiasLibres/LayoutFotografiasLibres";
import LayoutContacto from "./features/LayoutContacto/LayoutContacto";
import LayoutMobiliario from "./features/LayoutMobiliario/LayoutMobiliario";
import InspeccionCompletada from "./features/InspeccionCompletada/InspeccionCompletada";
import LayoutCargaArchivo from "./features/LayoutCargaArchivos/LayoutCargaArchivo";
import LayoutPreguntaEscrita from "./features/LayoutsPreguntasLibres/LayoutPreguntaEscrita";
import LayoutPreguntaSeleccionUnica from "./features/LayoutsPreguntasLibres/LayoutPreguntaSeleccionUnica";
import LayoutPreguntaSeleccionMultiple from "./features/LayoutsPreguntasLibres/LayoutPreguntaSeleccionMultiple";
import LayoutPreguntaSeleccionDesplegable from "./features/LayoutsPreguntasLibres/LayoutPreguntaSeleccionDesplegable";

const ENV = conf('ENV');

if (true || ENV === 'Test' || ENV === 'Staging' || ENV === 'P') {
  Sentry.init({
    dsn: "https://cfa3f814936e4a849c4e7f9cd537d151@o1379367.ingest.sentry.io/6692024",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const App = () => {
  const preguntas = useSelector(selectPreguntas);
  const idInspeccion = useSelector(selectId)
  const dispatch = useDispatch()
  const [preguntaId, setPreguntaId] = useState(1)
  const location = useLocation()

  const handleAnterior = () => {
    //@ts-ignore
    dispatch(fetchPreguntaAnterior(preguntas.id));
  };

  useEffect(() => {
    const id = localStorage.getItem('idInspeccion')
    const user = localStorage.getItem('userName')
    dispatch(handleInspeccion({ id, user }))
  }, [])

  useEffect(() => {
    if(location.pathname === "/form" && preguntas.titulo1 !== "Datos identificativos") {
      neutralizeBack(handleAnterior)
    }
  }, [location.pathname, preguntas])

  useEffect(() => {
    if (idInspeccion) {
      const getAseguradoraLogo = async (id: number) => {
        const results = await getLogo(id)
        dispatch(changeLogo(results))
      }
  
      getAseguradoraLogo(idInspeccion)
    }
  }, [idInspeccion])

  const renderLayout = () => {
    switch (preguntas.templateLayout) {
      case "layout_default": return <LayoutPregunta />
      case "layout_cif": return <DatosIdentificativos />
      case "layout_datos_iniciales": return <DatosIniciales />
      case "layout_direccion": return <DireccionFiscal />
      case "layout_direccion_autocomplete": return <DireccionFiscalAutocomplete />
      case "layout_direccion_ayuware": return <DireccionFiscalAyuware />
      case "layout_direccion_google_maps": return <DireccionMaps />
      case "layout_actividad": return <ActividadEmpresarial />
      case "layout_fecha": return <FechaInicioDesconozco />
      case "layout_confirmar": return <LayoutConfirmar />
      case "layout_cargar": return <LayoutCarga />
      case "layout_cargar_simple": return <LayoutCargarSimple />
      case "layout_mensaje": return <LayoutMensaje />
      case "layout_fotografias": return <LayoutFotografias />
      case "layout_fotografias_libres": return <LayoutFotografiasLibres />
      case "layout_bienes": return <LayoutBienes />
      case "layout_actividad_secundaria": return <ActividadSecundaria />
      case "layout_contacto": return <LayoutContacto />
      case "layout_mobiliario": return <LayoutMobiliario />
      case "layout_carga_archivo": return <LayoutCargaArchivo />
      case "layout_escrita": return <LayoutPreguntaEscrita />
      case "layout_seleccion_unica": return <LayoutPreguntaSeleccionUnica />
      case "layout_seleccion_multiple": return <LayoutPreguntaSeleccionMultiple />
      case "layout_seleccion_desplegable": return <LayoutPreguntaSeleccionDesplegable />
      default: return <LayoutRelleno />
    }
  }

  return (
    <div className="App">
       {location.pathname === "/form" ? <Header /> : null}
      < Routes >
        <Route path="/" element={<Registro />} />
        <Route path="/login" element={<Login />} />
        <Route path="/form" element={renderLayout()} />
        <Route path="/finalizado" element={<SuccessConfirmar />} />
        <Route path="/completada" element={<InspeccionCompletada />} />
        <Route path="/invitacion" element={<CargaInvitacion />} />
      </Routes>
      {/*@ts-ignore*/}
      {/* <button onClick={() => dispatch(obtenerPreguntas(preguntaId))} >Obtener pregunta</button>
      <input type="text" onChange={(e: any) => setPreguntaId(e.target.value)} /> */}
    </div >
  );
};

export default App;
