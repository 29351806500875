import React from "react";

const PuertaAcorazada = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_992_1344)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.172119 31.0201C0.172119 30.7977 0.352407 30.6174 0.574803 30.6174H32.7895C33.0119 30.6174 33.1922 30.7977 33.1922 31.0201C33.1922 31.2425 33.0119 31.4228 32.7895 31.4228H0.574803C0.352407 31.4228 0.172119 31.2425 0.172119 31.0201Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.36682 3.56711C8.36682 3.34472 8.54711 3.16443 8.7695 3.16443H24.595C24.8174 3.16443 24.9977 3.34472 24.9977 3.56711V31.0201C24.9977 31.2425 24.8174 31.4227 24.595 31.4227H8.7695C8.54711 31.4227 8.36682 31.2425 8.36682 31.0201V3.56711ZM9.17219 3.9698V30.6174H24.1923V3.9698H9.17219Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.36682 17.2983C8.36682 17.0759 8.54711 16.8956 8.7695 16.8956H24.595C24.8174 16.8956 24.9977 17.0759 24.9977 17.2983V31.0198C24.9977 31.2421 24.8174 31.4224 24.595 31.4224H8.7695C8.54711 31.4224 8.36682 31.2421 8.36682 31.0198V17.2983ZM9.17219 17.701V30.6171H24.1923V17.701H9.17219Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.58484 1.82554C6.58484 1.60314 6.76513 1.42285 6.98752 1.42285H26.3767C26.5991 1.42285 26.7794 1.60314 26.7794 1.82554V31.0201C26.7794 31.2425 26.5991 31.4228 26.3767 31.4228H6.98752C6.76513 31.4228 6.58484 31.2425 6.58484 31.0201V1.82554ZM7.39021 2.22822V30.6174H25.974V2.22822H7.39021Z"
          fill="#635F76"
        />
        <path
          d="M23.5377 19.6745V14.9229H22.1686V19.6745H23.5377Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5376 14.5203C23.76 14.5203 23.9403 14.7006 23.9403 14.9229V19.6746C23.9403 19.897 23.76 20.0773 23.5376 20.0773H22.1685C21.9461 20.0773 21.7658 19.897 21.7658 19.6746V14.9229C21.7658 14.7006 21.9461 14.5203 22.1685 14.5203H23.5376ZM23.1349 15.3256H22.5712V19.2719H23.1349V15.3256Z"
          fill="#635F76"
        />
        <path
          d="M22.7162 14.5751V5.38306H10.7004V15.4162H21.9352"
          stroke="#635F76"
          strokeWidth="0.8"
          strokeLinejoin="round"
          strokeDasharray="0.3 2"
        />
        <path
          d="M10.7004 29.1757V19.1426H21.9473L22.7162 19.7999V29.1757H10.7004Z"
          stroke="#635F76"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="0.3 2"
        />
      </g>
      <defs>
        <clipPath id="clip0_992_1344">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.682129 0.422852)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PuertaAcorazada;
