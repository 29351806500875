import React from "react";

const CasaFincaRustica = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.11983 8.4633C8.34573 8.35035 8.59674 8.2876 8.87284 8.2876C9.81411 8.2876 10.5797 9.05316 10.5797 9.99442V11.9272C10.5797 12.8684 9.81411 13.634 8.87284 13.634C7.93158 13.634 7.16602 12.8684 7.16602 11.9272V11.5506"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.8728 13.634V16.6837"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3783 3.16724C12.9767 3.16724 12.5877 3.31784 12.3116 3.60649C12.0355 3.89515 11.8723 4.27165 11.8723 4.67326C11.8723 5.51412 12.5375 6.17928 13.3783 6.17928C13.7799 6.17928 14.1564 6.02868 14.4451 5.74003C14.7212 5.45137 14.8844 5.07487 14.8844 4.67326C14.8844 3.8324 14.2192 3.16724 13.3783 3.16724ZM13.3783 5.55177C13.1524 5.55177 12.9265 5.45137 12.7634 5.28822C12.5877 5.11252 12.4998 4.88661 12.4998 4.66071C12.5124 4.19635 12.914 3.8073 13.3783 3.8073C13.8427 3.8073 14.2443 4.22145 14.2443 4.68581C14.2443 5.15017 13.8427 5.55177 13.3783 5.56432V5.55177Z"
        fill="#635F76"
      />
      <path
        d="M18.9883 6.53074C18.9005 6.44289 18.8126 6.35504 18.7499 6.26719L18.6997 6.17934L18.7499 6.09149C18.8126 5.99109 18.9005 5.90323 18.9883 5.82793C19.2519 5.56438 19.3146 5.46398 19.1138 5.25062C18.9632 5.10002 18.8754 5.12512 18.8377 5.13767C18.7373 5.17532 18.6369 5.27572 18.5365 5.37613C18.4487 5.46398 18.3608 5.55183 18.273 5.61458L18.1851 5.66478L18.0972 5.61458C17.9968 5.55183 17.9215 5.46398 17.8337 5.37613C17.7082 5.25062 17.5952 5.12512 17.4823 5.12512C17.4195 5.12512 17.3442 5.16277 17.2689 5.25062C17.1936 5.33848 17.1434 5.41378 17.1434 5.47653C17.1434 5.58948 17.2689 5.71498 17.3944 5.84048C17.4823 5.91578 17.5576 6.00364 17.6203 6.10404L17.6831 6.19189L17.6203 6.27974C17.5576 6.38014 17.4697 6.45544 17.3944 6.54329C17.2689 6.6688 17.1434 6.7943 17.1434 6.8947C17.1434 6.95745 17.1811 7.03275 17.2689 7.1206C17.3568 7.20845 17.4195 7.2461 17.4823 7.2461C17.5952 7.2461 17.7207 7.1206 17.8337 6.9951C17.9215 6.90725 17.9968 6.83195 18.0972 6.7692L18.1851 6.70645L18.273 6.7692C18.3734 6.83195 18.4487 6.9198 18.5365 6.9951C18.6369 7.0955 18.7373 7.1959 18.8377 7.23355C18.8754 7.2461 18.9632 7.28376 19.1138 7.1206C19.2017 7.03275 19.2393 6.97 19.2393 6.90725C19.2393 6.7943 19.1138 6.6688 18.9883 6.55584V6.53074Z"
        fill="#635F76"
      />
      <path
        d="M28.8151 16.7842H3.8277V29.2089H28.8151V16.7842Z"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.8658 12.9564V16.7842"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.8658 12.7556H25.8784C26.821 12.7556 27.5852 11.9914 27.5852 11.0488V5.21295C27.5852 4.2703 26.821 3.50613 25.8784 3.50613H25.8658C24.9232 3.50613 24.159 4.2703 24.159 5.21295V11.0488C24.159 11.9914 24.9232 12.7556 25.8658 12.7556Z"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.42561 4.39709H6.41306C5.47041 4.39709 4.70624 5.16127 4.70624 6.10392V10.0196C4.70624 10.9622 5.47041 11.7264 6.41306 11.7264H6.42561C7.36827 11.7264 8.13244 10.9622 8.13244 10.0196V6.10392C8.13244 5.16127 7.36827 4.39709 6.42561 4.39709Z"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.41302 11.7264V16.6838"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.8663 13.0568H16.428V16.7716H22.8663V13.0568Z"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4406 13.0568H11.9351V16.7716H16.4406V13.0568Z"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.8663 13.0567H16.4281L14.1439 10.4839H20.5822L22.8663 13.0567Z"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1564 10.4839L11.8723 13.0567H16.428"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0476 14.1987H13.5917V16.7966H15.0476V14.1987Z"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.775 14.1987H17.7835V15.4914H18.775V14.1987Z"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2599 14.1987H20.2684V15.4914H21.2599V14.1987Z"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.8277 16.7842C3.8277 16.7842 7.3794 19.9092 8.17006 28.9328"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.8277 16.7842C5.17057 16.7842 11.4959 19.2566 14.144 28.9328"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.8277 16.7842C7.16605 16.7842 16.2147 18.0894 20.0174 28.9328"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.8277 16.7842C7.2037 16.7967 15.0476 16.7967 18.9883 18.8926"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.537 21.001C15.537 21.001 20.0175 17.2736 28.8277 16.7842"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1851 24.992C18.1851 24.992 20.1429 18.6416 28.8151 16.7842"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4397 29.2089C22.4397 29.2089 22.1636 21.0136 28.8152 16.7842"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5852 29.2089C27.5852 29.2089 26.4306 23.988 28.8276 16.7842"
        stroke="#635F76"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CasaFincaRustica;
