export const formatDecimalDot = (n: string) => {
    const numWithoutDots =  n.replace(/\./g, '')
    const numWithDecimalDot = numWithoutDots.replace(',', '.')
    return numWithDecimalDot
}
  
export const formatDecimalComma = (n: string) => {
const numWithoutComma =  n.replace(/,/g, '')
const numWithDecimalComma = numWithoutComma.replace('.', ',')
return numWithDecimalComma
}

export const formatStringMoney = (num: string) => {
    const formatter = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    });
  
  
    const number = (num == '' || Number.isNaN(num)) ? "-" : formatter.format(Number(num)).trim();
  
    return number
};