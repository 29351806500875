import React from 'react'

export const PuertaMaciza = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.61536 28V7.4428V5.92004H23.3846V26.4773V28" stroke="#635F76" strokeMiterlimit="10" strokeLinejoin="round"/>
        <path d="M24.9354 28V5.54839H26.1539V4H24.9354H23.3108H8.68927H7.06465H5.84619V5.54839H7.06465V28" stroke="#635F76" strokeMiterlimit="10" strokeLinejoin="round"/>
        <path d="M20.6155 17.44C21.1253 17.44 21.5385 17.0102 21.5385 16.48C21.5385 15.9498 21.1253 15.52 20.6155 15.52C20.1057 15.52 19.6924 15.9498 19.6924 16.48C19.6924 17.0102 20.1057 17.44 20.6155 17.44Z" stroke="#635F76" strokeMiterlimit="10" strokeLinejoin="round"/>
        <path d="M4 28H7.2H8.8H23.2H24.8H28" stroke="#635F76" strokeMiterlimit="10" strokeLinejoin="round"/>
        <path d="M8.61536 7.83997H20.6154" stroke="#635F76" strokeMiterlimit="10" strokeLinejoin="round"/>
        <path d="M11.3846 26.08H23.3846" stroke="#635F76" strokeMiterlimit="10" strokeLinejoin="round"/>
    </svg>
  )
}
