import React from "react";

const Car = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3646_10308)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.6665 10.6663C4.29831 10.6663 3.99984 10.9648 3.99984 11.333C3.99984 11.7012 4.29831 11.9997 4.6665 11.9997C5.03469 11.9997 5.33317 11.7012 5.33317 11.333C5.33317 10.9648 5.03469 10.6663 4.6665 10.6663ZM2.6665 11.333C2.6665 10.2284 3.56193 9.33301 4.6665 9.33301C5.77107 9.33301 6.6665 10.2284 6.6665 11.333C6.6665 12.4376 5.77107 13.333 4.6665 13.333C3.56193 13.333 2.6665 12.4376 2.6665 11.333Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3335 10.6663C10.9653 10.6663 10.6668 10.9648 10.6668 11.333C10.6668 11.7012 10.9653 11.9997 11.3335 11.9997C11.7017 11.9997 12.0002 11.7012 12.0002 11.333C12.0002 10.9648 11.7017 10.6663 11.3335 10.6663ZM9.3335 11.333C9.3335 10.2284 10.2289 9.33301 11.3335 9.33301C12.4381 9.33301 13.3335 10.2284 13.3335 11.333C13.3335 12.4376 12.4381 13.333 11.3335 13.333C10.2289 13.333 9.3335 12.4376 9.3335 11.333Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.71451 3.75208C2.81575 3.49898 3.06089 3.33301 3.3335 3.33301H9.3335C9.53602 3.33301 9.72756 3.42507 9.85407 3.58321L12.3206 6.66634H12.6668C13.1973 6.66634 13.706 6.87706 14.081 7.25213C14.4561 7.6272 14.6668 8.13591 14.6668 8.66634V11.333C14.6668 11.7012 14.3684 11.9997 14.0002 11.9997H12.6668C12.2986 11.9997 12.0002 11.7012 12.0002 11.333C12.0002 10.9648 12.2986 10.6663 12.6668 10.6663H13.3335V8.66634C13.3335 8.48953 13.2633 8.31996 13.1382 8.19494C13.0132 8.06991 12.8436 7.99967 12.6668 7.99967H2.66683V10.6663H3.3335C3.70169 10.6663 4.00016 10.9648 4.00016 11.333C4.00016 11.7012 3.70169 11.9997 3.3335 11.9997H2.00016C1.63197 11.9997 1.3335 11.7012 1.3335 11.333V7.33301C1.3335 7.24014 1.35249 7.1517 1.38679 7.07137L2.71451 3.75208ZM2.98485 6.66634L3.78485 4.66634H7.3335V6.66634H2.98485ZM8.66683 6.66634H10.6131L9.01308 4.66634H8.66683V6.66634ZM5.3335 11.333C5.3335 10.9648 5.63197 10.6663 6.00016 10.6663H10.0002C10.3684 10.6663 10.6668 10.9648 10.6668 11.333C10.6668 11.7012 10.3684 11.9997 10.0002 11.9997H6.00016C5.63197 11.9997 5.3335 11.7012 5.3335 11.333Z"
          fill="#635F76"
        />
      </g>
      <defs>
        <clipPath id="clip0_3646_10308">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Car;
