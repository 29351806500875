import React from "react";

const BarreraElectronica = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.25"
        y="0.25"
        width="1.5"
        height="5.5"
        rx="0.75"
        stroke="#635F76"
        stroke-width="0.5"
      />
      <rect
        x="0.25"
        y="8.25"
        width="1.5"
        height="6.5"
        rx="0.75"
        stroke="#635F76"
        stroke-width="0.5"
      />
      <rect
        x="0.25"
        y="17.25"
        width="1.5"
        height="5.5"
        rx="0.75"
        stroke="#635F76"
        stroke-width="0.5"
      />
      <rect
        x="21.25"
        y="0.25"
        width="1.5"
        height="5.5"
        rx="0.75"
        stroke="#635F76"
        stroke-width="0.5"
      />
      <rect
        x="21.25"
        y="8.25"
        width="1.5"
        height="6.5"
        rx="0.75"
        stroke="#635F76"
        stroke-width="0.5"
      />
      <rect
        x="21.25"
        y="17.25"
        width="1.5"
        height="5.5"
        rx="0.75"
        stroke="#635F76"
        stroke-width="0.5"
      />
      <line
        x1="5.25"
        y1="1.75"
        x2="20.75"
        y2="1.75"
        stroke="#635F76"
        stroke-width="0.5"
        stroke-linecap="round"
        stroke-dasharray="1 1"
      />
      <line
        x1="4.25"
        y1="3.75"
        x2="20.75"
        y2="3.75"
        stroke="#635F76"
        stroke-width="0.5"
        stroke-linecap="round"
        stroke-dasharray="1 1"
      />
      <line
        x1="2.25"
        y1="10.75"
        x2="17.75"
        y2="10.75"
        stroke="#635F76"
        stroke-width="0.5"
        stroke-linecap="round"
        stroke-dasharray="1 1"
      />
      <line
        x1="2.25"
        y1="12.75"
        x2="17.75"
        y2="12.75"
        stroke="#635F76"
        stroke-width="0.5"
        stroke-linecap="round"
        stroke-dasharray="1 1"
      />
      <path
        d="M4.29892 4H4.22352C4.1539 3.97284 4.09427 3.92746 4.05188 3.86937C4.00949 3.81128 3.98616 3.743 3.98474 3.67281V2.67956H3.40663C3.33456 2.68345 3.26268 2.66923 3.19856 2.63839C3.13443 2.60755 3.08041 2.56123 3.04218 2.50428C3.01443 2.45153 3 2.39357 3 2.33485C3 2.27612 3.01443 2.21816 3.04218 2.16541L4.4246 0.155539C4.46894 0.100084 4.52849 0.0567273 4.59703 0.0300035C4.66557 0.00327968 4.74057 -0.00582928 4.81419 0.00363017C4.88289 0.0319694 4.94169 0.0776458 4.98389 0.135478C5.0261 0.19331 5.05003 0.260982 5.05297 0.330819V1.32407H5.64363C5.70719 1.32806 5.76882 1.34603 5.82344 1.37651C5.87807 1.40698 5.92412 1.44908 5.95782 1.49935C5.98557 1.5521 6 1.61006 6 1.66878C6 1.72751 5.98557 1.78547 5.95782 1.83822L4.57541 3.84809C4.54419 3.89078 4.50322 3.92653 4.45543 3.95279C4.40763 3.97905 4.35419 3.99517 4.29892 4ZM3.85906 2.0953H4.18581C4.28914 2.0953 4.38823 2.13346 4.4613 2.2014C4.53436 2.26933 4.57541 2.36147 4.57541 2.45754V2.76136L5.16607 1.86159H4.83932C4.736 1.86159 4.6369 1.82343 4.56384 1.75549C4.49078 1.68756 4.44973 1.59542 4.44973 1.49935V1.24227L3.85906 2.0953Z"
        fill="#626074"
      />
      <line
        x1="5.25"
        y1="18.75"
        x2="20.75"
        y2="18.75"
        stroke="#635F76"
        stroke-width="0.5"
        stroke-linecap="round"
        stroke-dasharray="1 1"
      />
      <line
        x1="4.25"
        y1="20.75"
        x2="20.75"
        y2="20.75"
        stroke="#635F76"
        stroke-width="0.5"
        stroke-linecap="round"
        stroke-dasharray="1 1"
      />
      <path
        d="M4.29892 21H4.22352C4.1539 20.9728 4.09427 20.9275 4.05188 20.8694C4.00949 20.8113 3.98616 20.743 3.98474 20.6728V19.6796H3.40663C3.33456 19.6835 3.26268 19.6692 3.19856 19.6384C3.13443 19.6076 3.08041 19.5612 3.04218 19.5043C3.01443 19.4515 3 19.3936 3 19.3348C3 19.2761 3.01443 19.2182 3.04218 19.1654L4.4246 17.1555C4.46894 17.1001 4.52849 17.0567 4.59703 17.03C4.66557 17.0033 4.74057 16.9942 4.81419 17.0036C4.88289 17.032 4.94169 17.0776 4.98389 17.1355C5.0261 17.1933 5.05003 17.261 5.05297 17.3308V18.3241H5.64363C5.70719 18.3281 5.76882 18.346 5.82344 18.3765C5.87807 18.407 5.92412 18.4491 5.95782 18.4993C5.98557 18.5521 6 18.6101 6 18.6688C6 18.7275 5.98557 18.7855 5.95782 18.8382L4.57541 20.8481C4.54419 20.8908 4.50322 20.9265 4.45543 20.9528C4.40763 20.979 4.35419 20.9952 4.29892 21ZM3.85906 19.0953H4.18581C4.28914 19.0953 4.38823 19.1335 4.4613 19.2014C4.53436 19.2693 4.57541 19.3615 4.57541 19.4575V19.7614L5.16607 18.8616H4.83932C4.736 18.8616 4.6369 18.8234 4.56384 18.7555C4.49078 18.6876 4.44973 18.5954 4.44973 18.4993V18.2423L3.85906 19.0953Z"
        fill="#626074"
      />
      <path
        d="M18.2989 14H18.2235C18.1539 13.9728 18.0943 13.9275 18.0519 13.8694C18.0095 13.8113 17.9862 13.743 17.9847 13.6728V12.6796H17.4066C17.3346 12.6835 17.2627 12.6692 17.1986 12.6384C17.1344 12.6076 17.0804 12.5612 17.0422 12.5043C17.0144 12.4515 17 12.3936 17 12.3348C17 12.2761 17.0144 12.2182 17.0422 12.1654L18.4246 10.1555C18.4689 10.1001 18.5285 10.0567 18.597 10.03C18.6656 10.0033 18.7406 9.99417 18.8142 10.0036C18.8829 10.032 18.9417 10.0776 18.9839 10.1355C19.0261 10.1933 19.05 10.261 19.053 10.3308V11.3241H19.6436C19.7072 11.3281 19.7688 11.346 19.8234 11.3765C19.8781 11.407 19.9241 11.4491 19.9578 11.4993C19.9856 11.5521 20 11.6101 20 11.6688C20 11.7275 19.9856 11.7855 19.9578 11.8382L18.5754 13.8481C18.5442 13.8908 18.5032 13.9265 18.4554 13.9528C18.4076 13.979 18.3542 13.9952 18.2989 14ZM17.8591 12.0953H18.1858C18.2891 12.0953 18.3882 12.1335 18.4613 12.2014C18.5344 12.2693 18.5754 12.3615 18.5754 12.4575V12.7614L19.1661 11.8616H18.8393C18.736 11.8616 18.6369 11.8234 18.5638 11.7555C18.4908 11.6876 18.4497 11.5954 18.4497 11.4993V11.2423L17.8591 12.0953Z"
        fill="#626074"
      />
    </svg>
  );
};

export default BarreraElectronica;
