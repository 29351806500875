import { AiraLogo } from "../../Assets/svgs/AiraLogo";
import { CustomButton } from "../../Components/CustomButton/CustomButton";
import { JsonAnimation } from "../../Components/JsonAnimation/JsonAnimation";
import { Notification } from "../../Components/Notification/Notification";
import "./DataError.scss";

export const DataError = (props: any) => {
  const renderTip = () => {
    if (props.tip) {
      return (
        <div className="data-error-tip">
          <Notification text={props.tip} icon={props.tipIcon} />
        </div>
      );
    }
  };

  return (
    <div className="data-error">
      <div className="data-error-logo">
        {props.logo ? (
          <img src={props.logo} alt="Logo aseguradora" />
        ) : (
          <AiraLogo />
        )}
      </div>
      <div className="data-error-content">
        <JsonAnimation width="72px" height="72px" animation="error" />
        <p className="data-error-content-title">{props.title}</p>
        <span className="data-error-content-msg">{props.message}</span>
        {props.message ? (
          <CustomButton
            type="primary"
            text="Intentarlo nuevamente"
            onClick={() => window.location.reload()}
          />
        ) : null}
        {renderTip()}
      </div>
    </div>
  );
};
