import React from 'react'
import './InspeccionCompletada.scss'
import { Info } from '../../Assets/svgs/Info'
import { Alert } from '@mui/material'
import { AlertTriangle } from '../../Assets/svgs/AlertTriangle'

const InspeccionCompletada = () => {
  return (
    <div className="ev-completada">
        <div className="ev-completada-icon">
            <AlertTriangle />
        </div>
        Esta evaluación ya ha sido completada.
    </div>
  )
}

export default InspeccionCompletada