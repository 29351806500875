import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { handleExclusiveValue, handleValue } from "../../features/LayoutPregunta/preguntaSlice";
import { CustomCheckbox } from "../CustomCheckbox/CustomCheckbox";
import { ExclusiveMultipleChoice } from "../ExclusiveMultipleChoice/ExclusiveMultipleChoice";
import { InputCurrency } from "../InputCurrency/InputCurrency";
import { MultipleChoice } from "../MultipleChoice/MultipleChoice";
import { NumberInput } from "../NumberInput/NumberInput";
import { ToggleButtonList } from "../ToggleButtonList/ToggleButtonList";
import { ToggleButtons } from "../ToggleButtons/ToggleButtons";

export const ExclusiveToggleButtons = (props :any) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState([]);

  const handleChange = (v: any, index:number) => {
    setValue(v);
    dispatch(handleExclusiveValue({path: [...props.path, "opciones", index, "selected"], value: true}))
  }

  const handleInputComentarioChange = (value: any, index: number) => {
    dispatch(handleValue({ path: [...props.path, "opciones", index, "comentarios"], value: value}))
  }

  const onChangeInput = (value: any, index: number, indexSub: number, indexOpcion: number) => {
    dispatch(handleValue({ path: [...props.path, "opciones", index, "subpreguntas", indexSub, "opciones", indexOpcion, "valor"], value: value}))
  }

  return (
    <ToggleButtonList value={value} exclusive onChange={handleChange}>
      {props.items.opciones.map((o: any, index: number) => {
        return <ToggleButtons key={o + index} selected={o.selected} index={index} value={o.titulo} icon={o.icono} subtitle={o.subtitulo} backgroundColor={`${o.tipoDeDatoValor === 7 ? "#e5f1fb" : "#ffffff"}`}>
          {o.subopciones?.length && o.tipoDeDatoValor === 4 
          ? <ExclusiveMultipleChoice key={o.id + index} items={o.subopciones} id={o.id} titulo={o.consigna ? o.consigna : o.tituloSubopciones} type="subopcion" path={[...props.path, "opciones", index]} />
          : null}
          {o.subopciones?.length && o.tipoDeDatoValor !== 4 
          ? <MultipleChoice key={o.id + index} items={o.subopciones} titulo={o.tituloSubopciones} path={[...props.path, "opciones", index]} /> 
          : null}
          {o.subpreguntas?.length ? o.subpreguntas.map((s: any, indexSub: number) => {
              if(s.tipoItem === 1) {
                return (
                  <div key={s.id + index} style={{marginBottom: "16px", display: "flex", flexDirection: "column", gap: "16px"}}>
                    <span style={{fontSize: "14px"}}>{s.consigna}</span>
                    {s.opciones.map((op: any, indexOpcion: number) => {
                      if(op.tipoDeDatoValor === 1) {
                        return (
                          <InputCurrency
                          key={op.keyId}
                          index={index}
                          indexSubpregunta={{indexSub, indexOpcion}}
                          label={s.placeholder}
                          prefetchedValue={op.valor}
                          onChange={onChangeInput}
                        />
                        )
                      }
                      if(op.tipoDeDatoValor === 5) {
                        return (
                          <CustomCheckbox
                            label={op.subtitulo}
                            selected={op.valor}
                            choiceIndex={index}
                            indexSubpregunta={{indexSub, indexOpcion}}
                            singleCheckbox
                            key={op.keyId}
                            index={index}
                            onChange={onChangeInput}
                          />
                        )
                      }
                    })}

                  </div>
                )
              }
             if(s.tipoItem === 5) {
               return <ExclusiveMultipleChoice key={s.id + index} items={s.opciones} id={s.id} titulo={s.consigna} type="subpregunta" subpregunta path={[...props.path, "opciones", index, 'subpreguntas', indexSub]}/>
             } else {
               return <MultipleChoice key={s.id + index} items={s.opciones} id={s.id} titulo={s.consigna} subpregunta path={[...props.path, "opciones", index, 'subpreguntas', indexSub]}/>
             }
          }): null}
          {!o.subopciones?.length && !o.subpreguntas?.length && o.tieneComentarios && o.tipoInputComentarios === 1 
          ? <InputCurrency label={o.comentariosPlaceholder} isSuboption prefetchedValue={o.comentarios} onChange={handleInputComentarioChange} index={index} />
          : null}
          {!o.subopciones?.length && !o.subpreguntas?.length && o.tieneComentarios && o.tipoInputComentarios === 2 
          ? <NumberInput label={o.comentariosPlaceholder} isSuboption prefetchedValue={o.comentarios} onChange={handleInputComentarioChange} index={index} />
          : null}
        </ToggleButtons>;
      })}
    </ToggleButtonList>
  );
};