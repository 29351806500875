import React from 'react'

export const Agricultura = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7 12C5.34314 12 4 13.3431 4 15C4 16.6569 5.34314 18 7 18C8.65685 18 10 16.6569 10 15C10 13.3431 8.65685 12 7 12ZM2 15C2 12.2386 4.23858 10 7 10C9.76142 10 12 12.2386 12 15C12 17.7614 9.76142 20 7 20C4.23858 20 2 17.7614 2 15Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7 14C7.55228 14 8 14.4477 8 15V15.01C8 15.5623 7.55228 16.01 7 16.01C6.44772 16.01 6 15.5623 6 15.01V15C6 14.4477 6.44772 14 7 14Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M19 16C18.4477 16 18 16.4477 18 17C18 17.5523 18.4477 18 19 18C19.5523 18 20 17.5523 20 17C20 16.4477 19.5523 16 19 16ZM16 17C16 15.3431 17.3431 14 19 14C20.6569 14 22 15.3431 22 17C22 18.6569 20.6569 20 19 20C17.3431 20 16 18.6569 16 17Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.5 17C9.5 16.4477 9.94772 16 10.5 16H17C17.5523 16 18 16.4477 18 17C18 17.5523 17.5523 18 17 18H10.5C9.94772 18 9.5 17.5523 9.5 17Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4 5C4 4.44772 4.44772 4 5 4H11C11.4089 4 11.7766 4.24895 11.9285 4.62861L13.677 9H19C19.5304 9 20.0391 9.21071 20.4142 9.58579C20.7893 9.96086 21 10.4696 21 11V15.2C21 15.7523 20.5523 16.2 20 16.2C19.4477 16.2 19 15.7523 19 15.2V11H13C12.5911 11 12.2234 10.751 12.0715 10.3714L10.323 6H6V11.5C6 12.0523 5.55228 12.5 5 12.5C4.44772 12.5 4 12.0523 4 11.5V5Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.5858 4.58579C15.9609 4.21071 16.4696 4 17 4H18C18.5523 4 19 4.44772 19 5C19 5.55228 18.5523 6 18 6H17L17 10C17 10.5523 16.5523 11 16 11C15.4477 11 15 10.5523 15 10V6C15 5.46957 15.2107 4.96086 15.5858 4.58579Z" fill="#89869B"/>
    </svg>
  )
}
