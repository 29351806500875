import React from 'react'

export const PlusFilters = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z" fill="#0046CF"/>
        <path d="M10.528 11.944V13.18H8.224V15.484H6.976V13.18H4.672V11.944H6.976V9.628H8.224V11.944H10.528ZM16.1513 14.5H17.9753V16H12.4553V14.5H14.2793V9.616L12.8393 10.456L12.1313 9.112L14.8193 7.54H16.1513V14.5Z" fill="white"/>
    </svg>
  )
}
