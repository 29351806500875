import React from 'react'
import './InfoCard.scss'

export const InfoCard = (props: any) => {

    const renderItems = () => {
        return props.items.map((i: any) => {
            return <li key={i.titulo} className="info-card-item">{i.titulo}</li>
        })
    }

    return (
        <div className="info-card">
            <p>{props.consigna}</p>
            <ul>
                {renderItems()}
            </ul>
        </div>
    )
}
