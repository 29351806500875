import React from 'react'
import Acero from './svgs/Acero'
import AcristalamientoDoble from './svgs/AcristalamientoDoble'
import AcristalamientoMultiple from './svgs/AcristalamientoMultiple'
import AcristalamientoSimple from './svgs/AcristalamientoSimple'
import { Add } from './svgs/Add'
import Adosado from './svgs/Adosado'
import Aeronave from './svgs/Aeronave'
import Aerotermia from './svgs/Aerotermia'
import { Agricultura } from './svgs/Agricultura'
import AireAcondicionado from './svgs/AireAcondicionado'
import { Alarma } from './svgs/Alarma'
import { AlarmaElectronica } from './svgs/AlarmaElectronica'
import { Alert } from './svgs/Alert'
import { AlertTriangle } from './svgs/AlertTriangle'
import { Alimentacion } from './svgs/Alimentacion'
import Aluminio from './svgs/Aluminio'
import { Apilado } from './svgs/Apilado'
import { Archive } from './svgs/Archive'
import { Asalariado } from './svgs/Asalariado'
import Aseo from './svgs/Aseo'
import AutoCaravana from './svgs/AutoCaravana'
import { AutofitLeft } from './svgs/AutofitLeft'
import { AutofitRight } from './svgs/AutofitRight'
import Autonomo from './svgs/Autonomo'
import Avion from './svgs/Avion'
import Avioneta from './svgs/Avioneta'
import Azotea from './svgs/Azotea'
import Azulejos from './svgs/Azulejos'
import { Back } from './svgs/Back'
import { Bar } from './svgs/Bar'
import BarreraElectronica from './svgs/BarreraElectronica'
import { Bed } from './svgs/Bed'
import { Bell } from './svgs/Bell'
import Bicicleta from './svgs/Bicicleta'
import BicicletaElectrica from './svgs/BicicletaElectrica'
import { BIES } from './svgs/BIES'
import Bote from './svgs/Bote'
import { Breadcrumbs } from './svgs/Breadcrumbs'
import { Business } from './svgs/Business'
import Caballo from './svgs/Caballo'
import { CajaFuerte } from './svgs/CajaFuerte'
import CalefaccionPrivativa from './svgs/CalefaccionPrivativa'
import { Calendar } from './svgs/Calendar'
import { CalendarOff } from './svgs/CalendarOff'
import { Camera } from './svgs/Camera'
import { Cancel } from './svgs/Cancel'
import Car from './svgs/Car'
import Caravana from './svgs/Caravana'
import CasaFincaRustica from './svgs/CasaFincaRustica'
import { Centralita } from './svgs/Centralita'
import { CentroComercial } from './svgs/CentroComercial'
import CerramientoPerimetral from './svgs/CerramientoPerimetral'
import ChapaMetalica from './svgs/ChapaMetalica'
import { ChevronDoubleLeft } from './svgs/ChevronDoubleLeft'
import { ChevronDoubleRight } from './svgs/ChevronDoubleRight'
import { ChevronDown } from './svgs/ChevronDown'
import { ChevronLeft } from './svgs/ChevronLeft'
import { ChevronRight } from './svgs/ChevronRight'
import { ChevronUp } from './svgs/ChevronUp'
import Ciclomotor from './svgs/Ciclomotor'
import CierreMetalico from './svgs/CierreMetalico'
import { Clientes } from './svgs/Clientes'
import { Close } from './svgs/Close'
import Cocina from './svgs/Cocina'
import { ComercialName } from './svgs/ComercialName'
import { Config } from './svgs/Config'
import { Connect } from './svgs/Connect'
import { Construction } from './svgs/Construction'
import { Content } from './svgs/Content'
import CuadroElectrico from './svgs/CuadroElectrico'
import CuartoDeBaño from './svgs/CuartoDeBaño'
import CubiertaVegetal from './svgs/CubiertaVegetal'
import DepositoCombustible from './svgs/DepositoCombustible'
import { Desconozco } from './svgs/Desconozco'
import { Desktop } from './svgs/Desktop'
import { Despoblado } from './svgs/Despoblado'
import Domotica from './svgs/Domotica'
import DormitorioPrincipal from './svgs/DormitorioPrincipal'
import { Download } from './svgs/Download'
import Drone from './svgs/Drone'
import Drone2 from './svgs/Drone2'
import Duplex from './svgs/Duplex'
import { EdificioMixto } from './svgs/EdificioMixto'
import { Edit } from './svgs/Edit'
import { EditSquare } from './svgs/EditSquare'
import { Electricidad } from './svgs/Electricidad'
import Embarcacion from './svgs/Embarcacion'
import Empresario from './svgs/Empresario'
import { Entre } from './svgs/Entre'
import { EntreM } from './svgs/EntreM'
import Eolica from './svgs/Eolica'
import { Equipos } from './svgs/Equipos'
import { Error } from './svgs/Error'
import { ErrorIcon } from './svgs/ErrorIcon'
import { Escaparate } from './svgs/Escaparate'
import Escuela from './svgs/Escuela'
import { Estanteria } from './svgs/Estanteria'
import Estudio from './svgs/Estudio'
import EtiquetaBIES from './svgs/EtiquetaBIES'
import EtiquetaExtintor from './svgs/EtiquetaExtintor'
import { Extintor } from './svgs/Extintor'
import { File } from './svgs/File'
import { FileComplete } from './svgs/FileComplete'
import { Filters } from './svgs/Filters'
import FingerPrint from './svgs/FingerPrint'
import FingerPrintVerify from './svgs/FingerPrintVerify'
import { Fire } from './svgs/Fire'
import { FireMobile } from './svgs/FireMobile'
import { Flash } from './svgs/Flash'
import Furgoneta from './svgs/Furgoneta'
import Gallina from './svgs/Gallina'
import Garage from './svgs/Garage'
import Gato from './svgs/Gato'
import Geotermia from './svgs/Geotermia'
import { Gimnasio } from './svgs/Gimnasio'
import { GPS } from './svgs/GPS'
import { Graficas } from './svgs/Graficas'
import { Granel } from './svgs/Granel'
import Helicoptero from './svgs/Helicoptero'
import { Help } from './svgs/Help'
import { HelpCircle } from './svgs/HelpCircle'
import { Hidrante } from './svgs/Hidrante'
import { History } from './svgs/History'
import { Home } from './svgs/Home'
import Hormigon from './svgs/Hormigon'
import { Id } from './svgs/Id'
import { Industrial } from './svgs/Industrial'
import { Info } from './svgs/Info'
import { Inmueble } from './svgs/Inmueble'
import { Inquilino } from './svgs/Inquilino'
import { Inspecciones } from './svgs/Inspecciones'
import Invernadero from './svgs/Invernadero'
import Kayak from './svgs/Kayak'
import Ladrillo from './svgs/Ladrillo'
import Lancha from './svgs/Lancha'
import { Local } from './svgs/Local'
import { LocalMobile } from './svgs/LocalMobile'
import LocalVacio from './svgs/LocalVacio'
import Loft from './svgs/Loft'
import { Logic } from './svgs/Logic'
import { Logo } from './svgs/Logo'
import Madera from './svgs/Madera'
import MaderaNatural from './svgs/MaderaNatural'
import Magnetico from './svgs/Magnetico'
import { Map } from './svgs/Map'
import { Maquinarias } from './svgs/Maquinarias'
import { Mas } from './svgs/Mas'
import { MasM } from './svgs/MasM'
import { Menos } from './svgs/Menos'
import { MenosM } from './svgs/MenosM'
import { Menu } from './svgs/Menu'
import { Mercado } from './svgs/Mercado'
import { Mercancias } from './svgs/Mercancias'
import Metal from './svgs/Metal'
import { Minus } from './svgs/Minus'
import { Modelos } from './svgs/Modelos'
import Motocicleta from './svgs/Motocicleta'
import MotoDeAgua from './svgs/MotoDeAgua'
import { Movil } from './svgs/Movil'
import NingunAnexo from './svgs/NingunAnexo'
import NoInfo from './svgs/NoInfo'
import NoInfo2 from './svgs/NoInfo2'
import NoInfo3 from './svgs/NoInfo3'
import NoPosee from './svgs/NoPosee'
import { NumberOne } from './svgs/NumberOne'
import { Office } from './svgs/Office'
import { Oficina } from './svgs/Oficina'
import { OficinaBienes } from './svgs/OficinaBienes'
import { Oficinas } from './svgs/Oficinas'
import { Ok } from './svgs/Ok'
import { OkCircle } from './svgs/OkCircle'
import { Options } from './svgs/Options'
import { Order } from './svgs/Order'
import { Otros } from './svgs/Otros'
import { OtrosMobile } from './svgs/OtrosMobile'
import OtrosServicios from './svgs/OtrosServicios'
import Oveja from './svgs/Oveja'
import { Paletizado } from './svgs/Paletizado'
import PanelSandwich from './svgs/PanelSandwich'
import Peligroso from './svgs/Peligroso'
import Perro from './svgs/Perro'
import { PhotoVideoMaximise } from './svgs/PhotoVideoMaximise'
import PiedraNatural from './svgs/PiedraNatural'
import Pintura from './svgs/Pintura'
import { Piscina } from './svgs/Piscina'
import Piso from './svgs/Piso'
import PistaDeportiva from './svgs/PistaDeportiva'
import { Pistas } from './svgs/Pistas'
import PlacasSolares from './svgs/PlacasSolares'
import { Placeholder } from './svgs/Placeholder'
import { Plus } from './svgs/Plus'
import Plus2 from './svgs/Plus2'
import { PlusCircle } from './svgs/PlusCircle'
import { PlusFilters } from './svgs/PlusFilters'
import PreguntaBIES from './svgs/PreguntaBIES'
import { Propietario } from './svgs/Propietario'
import PuertaAcorazada from './svgs/PuertaAcorazada'
import { PuertaAcristalada } from './svgs/PuertaAcristalada'
import PuertaBlindada from './svgs/PuertaBlindada'
import PuertaHueca from './svgs/PuertaHueca'
import { PuertaMaciza } from './svgs/PuertaMaciza'
import PuertaMetalica from './svgs/PuertaMetalica'
import PulsadorManual from './svgs/PulsadorManual'
import { PulsadorManualIncendios } from './svgs/pulsadorManualIncendios'
import Pvc from './svgs/Pvc'
import Pvc2 from './svgs/Pvc2'
import Quad from './svgs/Quad'
import { Refrigeracion } from './svgs/Refrigeracion'
import Remolque from './svgs/Remolque'
import { Remove } from './svgs/Remove'
import { RemoveImage } from './svgs/RemoveImage'
import ResidenciaHabitual from './svgs/ResidenciaHabitual'
import Restauracion from './svgs/Restauracion'
import { Restaurant } from './svgs/Restaurant'
import { Robo } from './svgs/Robo'
import { Rociador } from './svgs/Rociador'
import { Rural } from './svgs/Rural'
import Salon from './svgs/Salon'
import SalonAzul from './svgs/SalonAzul'
import Salud from './svgs/Salud'
import { Search } from './svgs/Search'
import { SearchIcon } from './svgs/SearchIcon'
import SegundaResidencia from './svgs/SegundaResidencia'
import { Send } from './svgs/Send'
import { SendEmail } from './svgs/SendEmail'
import SimilMadera from './svgs/SimilMadera'
import SinMascotas from './svgs/SinMascotas'
import SistemaSismico from './svgs/SistemaSismico'
import { Star } from './svgs/Star'
import { SubUrbana } from './svgs/SubUrbana'
import { Suitcase } from './svgs/Suitcase'
import Tejas from './svgs/Tejas'
import { Template } from './svgs/Template'
import Textil from './svgs/Textil'
import { Time } from './svgs/Time'
import { Timer } from './svgs/Timer'
import { Trash } from './svgs/Trash'
import Trastero from './svgs/Trastero'
import Trastero2 from './svgs/Trastero2'
import { Turismo } from './svgs/Turismo'
import TurismoVehiculo from './svgs/TurismoVehiculo'
import { Unlink } from './svgs/Unlink'
import { Urbana } from './svgs/Urbana'
import { Urgent } from './svgs/Urgent'
import { User } from './svgs/User'
import Vaca from './svgs/Vaca'
import { Vehiculo } from './svgs/Vehiculo'
import VehiculoTerrestre from './svgs/VehiculoTerrestre'
import Velero from './svgs/Velero'
import Velero2 from './svgs/Velero2'
import { Ventanas } from './svgs/Ventanas'
import VerifyActive from './svgs/VerifyActive'
import VerifyOutline from './svgs/VerifyOutline'
import { VideoCamara } from './svgs/VideoCamara'
import Vidrio from './svgs/Vidrio'
import { View } from './svgs/View'
import { VigilanciaDeSeguridad } from './svgs/VigilanciaDeSeguridad'
import ViviendaAlquiler from './svgs/ViviendaAlquiler'
import { ViviendaAsilada } from './svgs/ViviendaAsilada'
import ViviendaIndependiente from './svgs/ViviendaIndependiente'
import ViviendaPareada from './svgs/ViviendaPareada'
import Volumetrico from './svgs/Volumetrico'
import { Warning } from './svgs/Warning'
import { Window } from './svgs/Window'
import Yate from './svgs/Yate'
import Yeso from './svgs/Yeso'
import HelpBlackCircle from './svgs/HelpBlackCircle'
import CheckBlackCircle from './svgs/CheckBlackCircle'
import StarBlackCircle from './svgs/StarBlackCircle'
import IconIndustria from './svgs/IconIndustria'

export const Icons: any = () => {
    var icons: any = {
      add: <Add />,
      agricultura: <Agricultura />,
      alarma: <Alarma />,
      alarmaElectronica: <AlarmaElectronica />,
      alert: <Alert />,
      alertTriangle: <AlertTriangle />,
      alimentacion: <Alimentacion />,
      apilado: <Apilado />,
      archive: <Archive />,
      autofitLeft: <AutofitLeft />,
      autofitRight: <AutofitRight />,
      back: <Back />,
      bar: <Bar />,
      bed: <Bed />,
      bell: <Bell />,
      BIES: <BIES />,
      etiquetaBIES: <EtiquetaBIES />,
      preguntaBIES: <PreguntaBIES />,
      breadcrumbs: <Breadcrumbs />,
      business: <Business />,
      cajaFuerte: <CajaFuerte />,
      calendar: <Calendar />,
      calendarOff: <CalendarOff />,
      camera: <Camera />,
      cancel: <Cancel />,
      centralita: <Centralita />,
      centroComercial: <CentroComercial />,
      chevronDoubleLeft: <ChevronDoubleLeft />,
      chevronDoubleRight: <ChevronDoubleRight />,
      chevronDown: <ChevronDown />,
      chevronLeft: <ChevronLeft />,
      chevronRight: <ChevronRight />,
      chevronUp: <ChevronUp />,
      clientes: <Clientes />,
      close: <Close />,
      checkBlackCircle: <CheckBlackCircle />,
      starBlackCircle: <StarBlackCircle />,
      comercialName: <ComercialName />,
      config: <Config />,
      connect: <Connect />,
      construction: <Construction />,
      content: <Content />,
      desconozco: <Desconozco />,
      desktop: <Desktop />,
      despoblado: <Despoblado />,
      download: <Download />,
      edificioMixto: <EdificioMixto />,
      edit: <Edit />,
      editSquare: <EditSquare />,
      electricidad: <Electricidad />,
      entre: <Entre />,
      entreM: <EntreM />,
      equipos: <Equipos />,
      error: <Error />,
      errorIcon: <ErrorIcon />,
      escaparate: <Escaparate />,
      estanteria: <Estanteria />,
      extintor: <Extintor />,
      file: <File />,
      fileComplete: <FileComplete />,
      filters: <Filters />,
      fire: <Fire />,
      fireMobile: <FireMobile />,
      flash: <Flash />,
      gimnasio: <Gimnasio />,
      GPS: <GPS />,
      graficas: <Graficas />,
      granel: <Granel />,
      help: <Help />,
      helpCircle: <HelpCircle />,
      helpBlackCircle: <HelpBlackCircle />,
      hidrante: <Hidrante />,
      history: <History />,
      home: <Home />,
      iconIndustria: <IconIndustria />,
      id: <Id />,
      industrial: <Industrial />,
      info: <Info />,
      inquilino: <Inquilino />,
      inspecciones: <Inspecciones />,
      local: <Local />,
      localMobile: <LocalMobile />,
      logic: <Logic />,
      logo: <Logo />,
      map: <Map />,
      maquinarias: <Maquinarias />,
      mas: <Mas />,
      masM: <MasM />,
      menos: <Menos />,
      menosM: <MenosM />,
      menu: <Menu />,
      mercado: <Mercado />,
      mercancias: <Mercancias />,
      minus: <Minus />,
      modelos: <Modelos />,
      movil: <Movil />,
      numberOne: <NumberOne />,
      office: <Office />,
      oficina: <Oficina />,
      oficinaBienes: <OficinaBienes />,
      oficinas: <Oficinas />,
      ok: <Ok />,
      okCircle: <OkCircle />,
      options: <Options />,
      order: <Order />,
      otros: <Otros />,
      otrosMobile: <OtrosMobile />,
      paletizado: <Paletizado />,
      photoViewMaximise: <PhotoVideoMaximise />,
      piscina: <Piscina />,
      pistas: <Pistas />,
      placeholder: <Placeholder />,
      plus: <Plus />,
      plusCircle: <PlusCircle />,
      plusFilters: <PlusFilters />,
      propietario: <Propietario />,
      puertaAcristalada: <PuertaAcristalada />,
      puertaMaciza: <PuertaMaciza />,
      refrigeracion: <Refrigeracion />,
      remove: <Remove />,
      removeImage: <RemoveImage />,
      restaurant: <Restaurant />,
      robo: <Robo />,
      rociador: <Rociador />,
      rural: <Rural />,
      search: <Search />,
      searchIcon: <SearchIcon />,
      send: <Send />,
      sendEmail: <SendEmail />,
      star: <Star />,
      subUrbana: <SubUrbana />,
      suitcase: <Suitcase />,
      template: <Template />,
      time: <Time />,
      timer: <Timer />,
      trash: <Trash />,
      turismo: <Turismo />,
      unlink: <Unlink />,
      urbana: <Urbana />,
      urgent: <Urgent />,
      user: <User />,
      videoCamara: <VideoCamara />,
      vehiculo: <Vehiculo />,
      ventanas: <Ventanas />,
      view: <View />,
      vigilanciaDeSeguridad: <VigilanciaDeSeguridad />,
      viviendaAsilada: <ViviendaAsilada />,
      warning: <Warning />,
      window: <Window />,
      asalariado: <Asalariado />,
      autonomo: <Autonomo />,
      empresario: <Empresario />,
      residenciaHabitual: <ResidenciaHabitual />,
      segundaResidencia: <SegundaResidencia />,
      viviendaAlquiler: <ViviendaAlquiler />,
      piso: <Piso />,
      estudio: <Estudio />,
      duplex: <Duplex />,
      loft: <Loft />,
      viviendaIndependiente: <ViviendaIndependiente />,
      adosado: <Adosado />,
      viviendaPareada: <ViviendaPareada />,
      casaFincaRustica: <CasaFincaRustica />,
      aseo: <Aseo />,
      cuartoDeBaño: <CuartoDeBaño />,
      dormitorioPrincipal: <DormitorioPrincipal />,
      cocina: <Cocina />,
      salon: <Salon />,
      salonAzul: <SalonAzul />,
      pistaDeportiva: <PistaDeportiva />,
      invernadero: <Invernadero />,
      garage: <Garage />,
      trastero: <Trastero />,
      trastero2: <Trastero2 />,
      ningunAnexo: <NingunAnexo />,
      oveja: <Oveja />,
      caballo: <Caballo />,
      vaca: <Vaca />,
      gallina: <Gallina />,
      sinMascotas: <SinMascotas />,
      plus2: <Plus2 />,
      noPosee: <NoPosee />,
      perro: <Perro />,
      gato: <Gato />,
      peligroso: <Peligroso />,
      noInfo: <NoInfo />,
      noInfo2: <NoInfo2 />,
      noInfo3: <NoInfo3 />,
      verifyOutline: <VerifyOutline />,
      verifyActive: <VerifyActive />,
      fingerPrint: <FingerPrint />,
      fingerPrintVerify: <FingerPrintVerify />,
      drone: <Drone />,
      embarcacion: <Embarcacion />,
      vehiculoTerrestre: <VehiculoTerrestre />,
      aeronave: <Aeronave />,
      velero: <Velero />,
      turismoVehiculo: <TurismoVehiculo />,
      quad: <Quad />,
      ciclomotor: <Ciclomotor />,
      furgoneta: <Furgoneta />,
      bicicleta: <Bicicleta />,
      bicicletaElectrica: <BicicletaElectrica />,
      motocicleta: <Motocicleta />,
      caravana: <Caravana />,
      autoCaravana: <AutoCaravana />,
      remolque: <Remolque />,
      motoDeAgua: <MotoDeAgua />,
      velero2: <Velero2 />,
      yate: <Yate />,
      bote: <Bote />,
      lancha: <Lancha />,
      kayak: <Kayak />,
      avion: <Avion />,
      helicoptero: <Helicoptero />,
      drone2: <Drone2 />,
      avioneta: <Avioneta />,
      calefaccionPrivativa: <CalefaccionPrivativa />,
      depositoCombustible: <DepositoCombustible />,
      aireAcondicionado: <AireAcondicionado />,
      placasSolares: <PlacasSolares />,
      eolica: <Eolica />,
      cubiertaVegetal: <CubiertaVegetal />,
      domotica: <Domotica />,
      aerotermia: <Aerotermia />,
      geotermia: <Geotermia />,
      puertaHueca: <PuertaHueca />,
      puertaAcorazada: <PuertaAcorazada />,
      puertaBlindada: <PuertaBlindada />,
      puertaMetalica: <PuertaMetalica />,
      cerramientoPerimetral: <CerramientoPerimetral />,
      madera: <Madera />,
      pvc: <Pvc />,
      aluminio: <Aluminio />,
      metal: <Metal />,
      panelSandwich: <PanelSandwich />,
      chapaMetalica: <ChapaMetalica />,
      acero: <Acero />,
      hormigon: <Hormigon />,
      ladrillo: <Ladrillo />,
      vidrio: <Vidrio />,
      azotea: <Azotea />,
      tejas: <Tejas />,
      yeso: <Yeso />,
      maderaNatural: <MaderaNatural />,
      similMadera: <SimilMadera />,
      pvc2: <Pvc2 />,
      piedraNatural: <PiedraNatural />,
      azulejos: <Azulejos />,
      acristalamientoSimple: <AcristalamientoSimple />,
      acristalamientoDoble: <AcristalamientoDoble />,
      acristalamientoMultiple: <AcristalamientoMultiple />,
      salud: <Salud />,
      textil: <Textil />,
      pintura: <Pintura />,
      car: <Car />,
      escuela: <Escuela />,
      otrosServicios: <OtrosServicios />,
      restauracion: <Restauracion />,
      localVacio: <LocalVacio />,
      pulsadorManualIncendios: <PulsadorManualIncendios />,
      volumetrico: <Volumetrico />,
      magnetico: <Magnetico />,
      etiquetaExtintor: <EtiquetaExtintor />,
      pulsadorManual: <PulsadorManual />,
      sistemaSismico: <SistemaSismico />,
      barreraElectronica: <BarreraElectronica />,
      cierreMetalico: <CierreMetalico />,
      cuadroElectrico: <CuadroElectrico />,
      inmueble: <Inmueble />,
    };

  return icons
}

