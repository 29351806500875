import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  handleExclusiveValue,
  handleValue,
} from "../../features/LayoutPregunta/preguntaSlice";
import { formatSiNo } from "../../utils";
import { CustomRadioButton } from "../CustomRadioButton/CustomRadioButton";
import { InputCurrency } from "../InputCurrency/InputCurrency";
import { NumberInput } from "../NumberInput/NumberInput";

export const ExclusiveMultipleChoice = (props: any) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<any>({});

  /*   const onChange = (id: number, index: number, checked: any) => {
    dispatch(handleValue({ path: [...props.path, "subopciones", index, "selected"], value: checked }))
  } */

  const onChangeSubpregunta = (value: any, index: number, id: number) => {
    setSelected(value);
    dispatch(
      handleExclusiveValue({
        path: [...props.path, "opciones", index, "selected"],
        value: true,
        multipleChoice: true,
        subpregunta: props.subpregunta,
      })
    );
  };

  const onChangeSubopcion = (value: any, index: number, id: number) => {
    setSelected(value);
    dispatch(
      handleExclusiveValue({
        path: [...props.path, "subopciones", index, "selected"],
        value: true,
        multipleChoice: true,
      })
    );
  };

  const onChangeOpcion = (value: any, index: number, id: number) => {
    setSelected(value);
    dispatch(
      handleExclusiveValue({
        path: [...props.path, index, "selected"],
        value: true,
      })
    );
  };

  const onTextareaChangeSubopcion = (
    id: number,
    index: number,
    value: string
  ) => {
    dispatch(
      handleValue({
        path: [...props.path, "subopciones", index, "comentarios"],
        value: value,
      })
    );
  };

  const onTextareaChangeOpcion = (id: number, index: number, value: string) => {
    dispatch(
      handleValue({ path: [...props.path, index, "comentarios"], value: value })
    );
  };

  const handleInputComentarioChangeSubopcion = (value: any, index: number) => {
    dispatch(
      handleValue({
        path: [...props.path, "subopciones", index, "comentarios"],
        value: value,
      })
    );
  };

  const handleInputComentarioChangeOpcion = (value: any, index: number) => {
    dispatch(
      handleValue({ path: [...props.path, index, "comentarios"], value: value })
    );
  };

  const onChange = () => {
    if (props.type === "opcion") {
      return onChangeOpcion;
    }
    if (props.type === "subopcion") {
      return onChangeSubopcion;
    }
    if (props.type === "subpregunta") {
      return onChangeSubpregunta;
    }
  };

  const renderInputComentario = (opcion: any, index: number) => {
    if (opcion.tipoInputComentarios === 0) {
      return (
        <textarea
          onChange={(e) =>
            props.type === "opcion"
              ? onTextareaChangeOpcion(opcion.id, index, e.target.value)
              : onTextareaChangeSubopcion(opcion.id, index, e.target.value)
          }
          className="text-area"
        />
      );
    }
    if (opcion.tipoInputComentarios === 1) {
      return (
        <InputCurrency
          label={opcion.comentariosPlaceholder}
          isSuboption
          prefetchedValue={opcion.comentarios}
          onChange={
            props.type === "opcion"
              ? handleInputComentarioChangeOpcion
              : handleInputComentarioChangeSubopcion
          }
          index={index}
        />
      );
    }
    if (opcion.tipoInputComentarios === 2) {
      return (
        <NumberInput
          label={opcion.comentariosPlaceholder}
          isSuboption
          prefetchedValue={opcion.comentarios}
          onChange={
            props.type === "opcion"
              ? handleInputComentarioChangeOpcion
              : handleInputComentarioChangeSubopcion
          }
          index={index}
        />
      );
    }
  };

  const renderMultipleChoice = (data: any) => {
    return (
      <>
        <p>{props.titulo}</p>
        <ul>
          {props.items.map((o: any, index: number) => (
            <li key={o.id}>
              <CustomRadioButton
                name={props.id}
                label={formatSiNo(o.titulo)}
                choiceIndex={index}
                rounded={true}
                itemId={o.id}
                value={o.id}
                selected={selected === o.id || (o.selected !== null && o.selected)}
                onChange={onChange()}
              />
              <div style={{marginTop: "8px"}}>
                {o.tieneComentarios && o.selected === true
                  ? renderInputComentario(o, index)
                  : null}
              </div>
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <div className="multiple-choice">{renderMultipleChoice(props.items)}</div>
  );
};
