import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlockTitle } from "../../Components/BlockTitle/BlockTitle";
import { CustomButton } from "../../Components/CustomButton/CustomButton";
import { Description } from "../../Components/Description/Description";
import { ModalAyuda } from "../../Components/ModalAyuda/ModalAyuda";
import { ModalEspera } from "../../Components/ModalEspera/ModalEspera";
import { Notification } from "../../Components/Notification/Notification";
import { SubBlockTitle } from "../../Components/SubBlockTitle/SubBlockTitle";
import { Subtitle } from "../../Components/Subtitle/Subtitle";
import { TipoItem } from "../../model/tipoItemEnum";
import "./LayoutCargaArchivo.scss";

import AttachFile from "../../Components/AttachFile/AttachFile";
import { ModalConexion } from "../../Components/ModalConexion/ModalConexion";
import {
  clearArchivos,
  getPreguntas,
  obtenerPreguntasStatus,
  selectArchivos,
  selectId,
  selectPreguntas,
  selectStatusGetPreguntas,
  validarCamposObligatorios
} from "../LayoutPregunta/preguntaSlice";
import { postRespuesta, subirArchivo } from "../LayoutPregunta/PreguntasAPI";

const LayoutCargaArchivo = () => {
  const dispatch = useDispatch();
  const preguntas = useSelector(selectPreguntas);
  const preguntaId: any = useSelector(selectId);
  const [openAyuda, setOpenAyuda] = useState(false);
  const [openEspera, setOpenEspera] = useState(false)
  const [loading, setLoading] = useState(false);
  const [noConnection, setNoConnection] = useState(false)
  const statusSiguientePregunta = useSelector(selectStatusGetPreguntas);
  const archivos = useSelector(selectArchivos)
  const containerRef = useRef<any>()

  useEffect(() => {
    window.scrollTo(0, 0);
    containerRef?.current?.scrollTo(0, 0)
    //@ts-ignore
    dispatch(obtenerPreguntasStatus(preguntaId))
  }, [preguntas.id]);

  const handleSiguiente = async () => {    
    setLoading(true);
    setOpenEspera(true)
    if (archivos && archivos.length) {
        let results: any = [];

        const archivosASubir = await Promise.all(archivos.map(async (b: any, index: number) => {
          return {
            idItem: preguntas.items[0].id,
            adjunto: b.file,
          };
        }));


        for (let i = 0; i < archivosASubir.length; i++) {
          // @ts-ignore
          try {
            const response = await subirArchivo(archivosASubir[i]);
            results = [...results, response];
            
          }
          catch(error) {
            console.log(error)
            
          }
        }

        const badRequests = results.filter((r: any) =>  r.status !== 200);

        const badStatus = badRequests.length > 0;
        if (!badStatus) {
          const response = await postRespuesta(preguntas);
        if (response.status === 200) {
          // @ts-ignore
            dispatch(getPreguntas(preguntaId));
            dispatch(clearArchivos())
        }
        } else {
          setLoading(false)
        }
      } else {
        const response = await postRespuesta(preguntas);
        if (response.status === 200)
        // @ts-ignore
          dispatch(getPreguntas(preguntaId));
      }
      setOpenEspera(false);
  };

  useEffect(() => {
    if(statusSiguientePregunta !== "loading")
      setLoading(false)
  }, [statusSiguientePregunta])

  const renderInputs = (e: any, index: number) => {
        return <AttachFile valorDefault={e.adjuntos} />;
  };

  const renderContent = () => {
    return (
      <>
        <div>
          <div className="layout-pregunta-content-title">
            <BlockTitle
              text={preguntas.titulo}
              icon={preguntas.iconoPrincipal}
              ayuda={preguntas.ayuda ? preguntas.ayuda : null}
              fn={setOpenAyuda}
            />
          </div>
          {preguntas.subtitulo ? <Subtitle text={preguntas.subtitulo} /> : null}
        </div>
        {preguntas.titulo1 ? <SubBlockTitle text={preguntas.titulo1} /> : null}
        {preguntas.texto ? <Description text={preguntas.texto} /> : null}
        {preguntas.items?.map((e: any, index: number) => {
          return <Fragment key={e.id} >{renderInputs(e, index)}</Fragment>;
        })}
        {preguntas.tip ? (
          <Notification text={preguntas.tip} icon={preguntas.iconoTip} />
        ) : null}
      </>
    );
  };

  const handleContinue = () => {
    const online = window.navigator.onLine
    if(!online) {
      setNoConnection(true);
      return; 
    }
    if (loading) {
      return () => null;
    } else {
      return handleSiguiente()
    }
  }

  return (
    <>
      <div className="layout-pregunta" ref={containerRef}>
        <div className="layout-pregunta-content">{renderContent()}</div>
      </div>
      <div className="layout-pregunta-content-actions">
        <CustomButton
          type="primary"
          text="Continuar"
          loading={loading}
          disable={loading}
          onClick={handleContinue}
        />
        {openAyuda ? (
          <ModalAyuda
            open={openAyuda}
            texto={preguntas.ayuda.texto}
            titulo={preguntas.ayuda.titulo}
            imagen={preguntas.ayuda.imagen}
            onClose={() => setOpenAyuda(false)}
          />
        ) : null}
        <ModalEspera key={preguntas.keyId} open={openEspera} />
        <ModalConexion open={noConnection} onClick={(e: boolean) => setNoConnection(e)} />
      </div>
    </>
  );
};

export default LayoutCargaArchivo;