import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { Map } from "../../Assets/svgs/Map";
import { BlockTitle } from "../../Components/BlockTitle/BlockTitle";
import { CustomButton } from "../../Components/CustomButton/CustomButton";
import { CustomInput } from "../../Components/CustomInput/CustomInput";
import { Description } from "../../Components/Description/Description";
import { IncidenciaAlert } from "../../Components/IncidenciaAlert/IncidenciaAlert";
import { Notification } from "../../Components/Notification/Notification";
import { SubBlockTitle } from "../../Components/SubBlockTitle/SubBlockTitle";
import { postRespuesta } from "../LayoutPregunta/PreguntasAPI";
import {
  getPreguntas, selectId,
  selectPreguntas,
  validarCamposObligatorios
} from "../LayoutPregunta/preguntaSlice";
import "./DatosEmpresa.scss";
import { ModalConexion } from "../../Components/ModalConexion/ModalConexion";

export const DatosIdentificativos = () => {
  const dispatch = useDispatch();
  const preguntas = useSelector(selectPreguntas);
  const preguntaId: any = useSelector(selectId);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false)
  const [inputHasError, setInputHasError] = useState(false)
  const [noConnection, setNoConnection] = useState(false)
  const obligatorios = useSelector(validarCamposObligatorios);
  const origen = localStorage.getItem("origen");

  useEffect(() => {
    if (preguntas.incidencias && preguntas.incidencias.length) {
      setOpen(true);
    }
  }, [preguntas]);

  const handleSiguiente = async () => {
    try { setLoader(true) } catch {}
    const response = await postRespuesta(preguntas);
    
    if (response.status === 200) {
      // @ts-ignore
      dispatch(getPreguntas(preguntaId));
      try { setLoader(false) } catch {}
    }else{
      try { setLoader(false) } catch {}
    }
  };

  const renderInputs = () => {
    return preguntas.items.map((e: any, index: number) => {
      const placeholder = e.placeholder.toLowerCase() === "cif" && origen === "2" ? "CIF o NIF" : e.placeholder
      return (
        <CustomInput
          key={e.keyId}
          inputKey={e.placeholder}
          hasError={(e) => setInputHasError(e)}
          type="text"
          label={placeholder}
          index={index}
          prefetchedValue={e.valor !== "Proyecto" ? e.valor: ""}
        />
      );
    });
  };

  const handleContinue = () => {
    const online = window.navigator.onLine
    if(!online) {
      setNoConnection(true);
      return; 
    } else {
      return handleSiguiente()
    }
  }

  return (
    <div className="datos-empresa">
      <div className="datos-empresa-content">
        <BlockTitle
          icon={preguntas.iconoPrincipal}
          text="Datos de la empresa"
        />
        <div className="datos-empresa-text">
          <SubBlockTitle text={preguntas.titulo1} />
          <Description text={preguntas.texto} />
        </div>
        <div className="datos-empresa-inputs">{renderInputs()}</div>
        <Notification
          iconComponent={<Map />}
          text="Hemos rellenado los datos a partir de la información aportada por su mediador."
        />
      </div>
      <IncidenciaAlert
        open={open}
        items={preguntas.incidencias ? preguntas.incidencias[0] : {}}
      />
      <div className="datos-empresa-buttons">
        <CustomButton
          type="primary"
          text="Continuar"
          loading={loader}
          disable={!obligatorios || inputHasError}
          onClick={handleContinue}
        >
        </CustomButton>
      </div>
      <ModalConexion open={noConnection} onClick={(e: boolean) => setNoConnection(e)} />
    </div>
  );
};
