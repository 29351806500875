import React from "react";

export const Error = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="32" fill="#FEF3F4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.9999 28C30.9391 28 29.9216 28.4214 29.1715 29.1716C28.4213 29.9217 27.9999 30.9391 27.9999 32V37.3333C27.9999 38.0697 27.403 38.6667 26.6666 38.6667C25.9302 38.6667 25.3333 38.0697 25.3333 37.3333V32C25.3333 30.2319 26.0356 28.5362 27.2859 27.286C28.5361 26.0357 30.2318 25.3333 31.9999 25.3333C33.768 25.3333 35.4637 26.0357 36.714 27.286C37.9642 28.5362 38.6666 30.2319 38.6666 32V37.3333C38.6666 38.0697 38.0696 38.6667 37.3333 38.6667C36.5969 38.6667 35.9999 38.0697 35.9999 37.3333V32C35.9999 30.9391 35.5785 29.9217 34.8283 29.1716C34.0782 28.4214 33.0608 28 31.9999 28Z"
        fill="#E64B54"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0001 18.6667C32.7365 18.6667 33.3334 19.2636 33.3334 20V21.3333C33.3334 22.0697 32.7365 22.6667 32.0001 22.6667C31.2637 22.6667 30.6667 22.0697 30.6667 21.3333V20C30.6667 19.2636 31.2637 18.6667 32.0001 18.6667ZM22.5239 22.5239C23.0446 22.0032 23.8889 22.0032 24.4096 22.5239L25.3429 23.4572C25.8636 23.9779 25.8636 24.8221 25.3429 25.3428C24.8222 25.8635 23.978 25.8635 23.4573 25.3428L22.5239 24.4095C22.0032 23.8888 22.0032 23.0446 22.5239 22.5239ZM41.4762 22.5239C41.9969 23.0446 41.9969 23.8888 41.4762 24.4095L40.5429 25.3428C40.0222 25.8635 39.178 25.8635 38.6573 25.3428C38.1366 24.8221 38.1366 23.9779 38.6573 23.4572L39.5906 22.5239C40.1113 22.0032 40.9555 22.0032 41.4762 22.5239ZM18.6667 32C18.6667 31.2636 19.2637 30.6667 20.0001 30.6667H21.3334C22.0698 30.6667 22.6667 31.2636 22.6667 32C22.6667 32.7364 22.0698 33.3333 21.3334 33.3333H20.0001C19.2637 33.3333 18.6667 32.7364 18.6667 32ZM41.3334 32C41.3334 31.2636 41.9304 30.6667 42.6667 30.6667H44.0001C44.7365 30.6667 45.3334 31.2636 45.3334 32C45.3334 32.7364 44.7365 33.3333 44.0001 33.3333H42.6667C41.9304 33.3333 41.3334 32.7364 41.3334 32Z"
        fill="#E64B54"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6667 38.6667C22.6667 37.1939 23.8607 36 25.3334 36H38.6667C40.1395 36 41.3334 37.1939 41.3334 38.6667V41.3333C41.3334 42.8061 40.1395 44 38.6667 44H25.3334C23.8607 44 22.6667 42.8061 22.6667 41.3333V38.6667ZM38.6667 38.6667H25.3334V41.3333H38.6667V38.6667Z"
        fill="#E64B54"
      />
    </svg>
  );
};
