import { handleBreakpoints } from "@mui/system";
import React, { useEffect, useState } from "react";
import { ErrorIcon } from "../../Assets/svgs/ErrorIcon";
import "./CustomInput.scss";
import { ItemControl } from "../../model/itemControl";
import { useDispatch } from "react-redux";
import { changeValue } from "../../features/LayoutPregunta/preguntaSlice";
import { validateCifFormat, validateDniFormat } from "../../utils/validations";

interface IProps {
  id?: number;
  type: string;
  label: string;
  prefetchedValue?: any;
  disable?: boolean;
  hasError?: (e: boolean) => void;
  index?: number;
  length?: number;
  onChange?: any;
  defaultValue?: any;
  isSuboption?: boolean;
  indexSubpregunta?: number;
  tworows?: boolean;
  bienes?: boolean;
  inputKey?: string;
  readOnly?: boolean;
}

export const CustomInput = ({
  index,
  type,
  label,
  disable,
  prefetchedValue,
  hasError,
  length,
  onChange,
  defaultValue = "",
  isSuboption,
  indexSubpregunta,
  tworows = false,
  bienes,
  inputKey,
  readOnly = false,
}: IProps) => {
  const [value, setValue] = useState("");
  const [validationError, setValidationError] = useState("");
  const origen = localStorage.getItem("origen");
  const dispatch = useDispatch();

  useEffect(() => {
    if (prefetchedValue) {
      if (!isSuboption)
        dispatch(changeValue({ value: prefetchedValue, index: index }));
      setValue(prefetchedValue);
      if ((type === "tel" || type === "email") && onChange) {
        console.log(type, prefetchedValue);
        onChange(prefetchedValue);
      }
    }
  }, []);

  const isDocument = (key: string) => {
    return (
      key?.toLowerCase() === "cif" ||
      key?.toLowerCase() === "dni" ||
      key?.toLowerCase() === "nif"
    );
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(
        isDocument(inputKey ? inputKey : "")
          ? defaultValue.toUpperCase()
          : defaultValue
      );
    }
  }, [defaultValue]);

  const handleChange = (e: any) => {
    setValue(isDocument(inputKey ? inputKey : "") ? e.toUpperCase() : e);
    if (indexSubpregunta || indexSubpregunta === 0) {
      onChange(e, index, indexSubpregunta);
      return;
    }
    if (onChange) onChange(e, index);
    if (!isSuboption && !bienes)
      dispatch(
        changeValue({
          value: isDocument(inputKey ? inputKey : "") ? e.toUpperCase() : e,
          index: index,
        })
      );
  };

  const handleValidData = (input: string) => {
    console.log(validateDniFormat(input) || validateCifFormat(input))
    if (
      ((label?.toLowerCase() === "cif" && !validateCifFormat(input)) ||
        (label?.toLowerCase() === "dni" && !validateDniFormat(input)) ||
        (label?.toLowerCase() === "nif" && !validateDniFormat(input))) ||
        (label.toLowerCase() === "cif o nif" && !(validateDniFormat(input) || validateCifFormat(input)))
    ) {
      setValidationError("El formato es incorrecto. Por favor, corrígelo.");
      hasError && hasError(true);
    } else {
      setValidationError("");
      hasError && hasError(false);
    }
  };

  return (
    <div className="custom-input" style={tworows ? { width: "50%" } : {}}>
      <div style={{ width: "98%", display: "flex", justifyContent: "center" }}>
        <input
          className={`custom-input-input ${
            validationError ? "input-error" : ""
          }`}
          type={type}
          value={value || defaultValue || ""}
          onChange={(e) => handleChange(e.target.value)}
          disabled={disable}
          maxLength={length || 524288}
          onBlur={() => handleValidData(value)}
          readOnly={readOnly}
        />
        <label className={value && "filled"}>{label}</label>
      </div>
      {validationError ? (
        <span className="error-message-input">
          <ErrorIcon />
          {validationError}
        </span>
      ) : null}
    </div>
  );
};
