import React from "react";

const Madera = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.5807 15.2833C29.455 15.02 28.6875 13.8911 28.1672 11.7324C27.9967 11.0245 28.0734 10.4009 28.3954 9.87857C28.8015 9.21977 29.5978 8.71021 30.5796 8.47995C30.924 8.4 31.1372 8.05567 31.0562 7.71135C30.9751 7.36702 30.6319 7.15382 30.2876 7.23484C28.9763 7.54185 27.889 8.26142 27.3059 9.20804C26.8059 10.0193 26.6737 10.9968 26.9232 12.033C27.3293 13.7205 28.1203 16.0231 30.2876 16.5306C30.3366 16.5423 30.3856 16.5476 30.4336 16.5476C30.7236 16.5476 30.9869 16.3483 31.0562 16.054C31.1382 15.7086 30.924 15.3643 30.5807 15.2833Z"
        fill="#635F76"
      />
      <path
        d="M6.02807 21.4108C5.77329 21.1667 5.3682 21.1752 5.12408 21.43C3.88216 22.7273 4.23075 24.9244 4.56868 27.05C4.78828 28.4327 5.01641 29.8633 4.6433 30.4688C4.45782 30.7694 4.55163 31.1638 4.85224 31.3483C4.95671 31.4122 5.07291 31.4431 5.18698 31.4431C5.40125 31.4431 5.61125 31.3355 5.73171 31.1393C6.3532 30.1309 6.09949 28.5361 5.83085 26.8486C5.55582 25.1163 5.24348 23.1527 6.04619 22.3148C6.29244 22.06 6.28285 21.6549 6.02807 21.4108Z"
        fill="#635F76"
      />
      <path
        d="M6.01204 1.71606C5.65918 1.71606 5.37242 2.00282 5.37242 2.35568C5.37242 3.64663 5.1443 4.86509 4.92469 6.04412C4.66885 7.41076 4.42793 8.70171 4.59956 9.89779C4.6454 10.2165 4.91936 10.4468 5.23171 10.4468C5.26156 10.4468 5.29247 10.4447 5.32339 10.4404C5.67304 10.3903 5.9161 10.0662 5.86599 9.71656C5.72421 8.72942 5.93635 7.59411 6.1826 6.27971C6.40327 5.10175 6.65272 3.76603 6.65272 2.35568C6.65165 2.00282 6.36489 1.71606 6.01204 1.71606Z"
        fill="#635F76"
      />
      <path
        d="M9.21435 5.0847C8.89561 4.93226 8.51397 5.06658 8.36153 5.38532C7.13348 7.94804 7.40531 10.0097 7.74857 12.6193C7.96178 14.2365 8.20376 16.069 8.14513 18.4249C8.14513 18.4324 8.14513 18.4388 8.14513 18.4452C8.14513 18.4569 8.14513 18.4697 8.1462 18.4814C8.14726 18.4931 8.14833 18.5059 8.1494 18.5176C8.15046 18.5251 8.15046 18.5315 8.15153 18.539C8.46281 20.5452 8.21549 21.85 7.9767 23.1111C7.67928 24.6846 7.39785 26.1706 8.17391 28.6214C8.26026 28.8943 8.51184 29.0681 8.78368 29.0681C8.84764 29.0681 8.91267 29.0585 8.97663 29.0382C9.31349 28.9316 9.50005 28.5724 9.39344 28.2355C8.71439 26.0928 8.95531 24.821 9.23354 23.3499C9.47873 22.0568 9.75482 20.5921 9.42436 18.4025C9.47979 15.9741 9.23461 14.1043 9.01714 12.453C8.6888 9.96069 8.45108 8.15911 9.51497 5.93965C9.66741 5.61985 9.53309 5.23821 9.21435 5.0847Z"
        fill="#635F76"
      />
      <path
        d="M5.36606 12.1194C5.03453 12.2409 4.86397 12.6087 4.98549 12.9402C5.69227 14.8676 4.97164 18.2565 4.96098 18.3044C4.88529 18.6498 5.10489 18.9899 5.44921 19.0656C5.49505 19.0752 5.54089 19.0805 5.58566 19.0805C5.87989 19.0805 6.14426 18.8758 6.21035 18.5773C6.2434 18.4238 7.0216 14.7823 6.18584 12.501C6.06537 12.1684 5.6976 11.9979 5.36606 12.1194Z"
        fill="#635F76"
      />
      <path
        d="M15.4303 17.8877C15.8482 16.4709 15.6723 14.7728 15.4868 12.9754C15.3195 11.3626 15.1468 9.69423 15.4175 8.21459C15.4815 7.86707 15.2512 7.53447 14.9037 7.47051C14.5562 7.40655 14.2225 7.63681 14.1596 7.98433C13.8569 9.64413 14.0392 11.4041 14.2151 13.1076C14.6106 16.924 14.6724 19.1093 11.3656 19.6541C11.017 19.7116 10.7814 20.041 10.839 20.3896C10.8901 20.703 11.162 20.9258 11.469 20.9258C11.5031 20.9258 11.5383 20.9226 11.5735 20.9173C13.6693 20.5698 14.9314 19.5795 15.4303 17.8877Z"
        fill="#635F76"
      />
      <path
        d="M27.8037 2.17119C27.7013 1.83326 27.3442 1.64138 27.0063 1.74372C26.6683 1.84606 26.4765 2.20317 26.5788 2.5411C27.1726 4.50791 26.5042 5.26479 25.4925 6.41183C24.709 7.29983 23.7336 8.40529 23.3008 10.289C22.8499 12.2515 23.0577 14.7609 23.9361 17.9419C23.8967 17.9206 23.8541 17.9036 23.8082 17.8908C23.4682 17.7959 23.1153 17.9942 23.0194 18.3342C22.7614 19.2553 23.0449 20.219 23.3445 21.2391C23.7293 22.5493 24.127 23.9042 23.5673 25.451C23.4468 25.7836 23.6185 26.1503 23.9511 26.2708C24.0235 26.2974 24.0971 26.3091 24.1685 26.3091C24.4297 26.3091 24.676 26.1471 24.7698 25.887C25.4733 23.9468 24.9734 22.2455 24.5726 20.8788C24.3348 20.0686 24.128 19.3619 24.2229 18.8118C24.3423 18.9792 24.5374 19.0804 24.7431 19.0804C24.8028 19.0804 24.8646 19.0719 24.9254 19.0538C25.2644 18.9536 25.4574 18.5975 25.3561 18.2585C23.1878 10.9552 24.9968 8.90526 26.4498 7.25825C27.5105 6.05898 28.6042 4.82026 27.8037 2.17119Z"
        fill="#635F76"
      />
      <path
        d="M28.2301 18.507C28.2301 18.1542 27.9433 17.8674 27.5905 17.8674C27.2376 17.8674 26.9509 18.1542 26.9509 18.507C26.9509 19.8716 26.8507 21.3821 26.7536 22.8426C26.5532 25.8562 26.3464 28.9722 26.9743 31.246C27.0521 31.5296 27.3101 31.7161 27.5905 31.7161C27.647 31.7161 27.7045 31.7087 27.761 31.6927C28.1011 31.5989 28.3015 31.246 28.2077 30.9059C27.6384 28.8411 27.8378 25.8349 28.0307 22.9268C28.1277 21.445 28.2301 19.9131 28.2301 18.507Z"
        fill="#635F76"
      />
      <path
        d="M18.6913 5.93328C18.396 5.02823 18.0901 4.09332 18.8096 2.64034C18.9664 2.32373 18.8363 1.93996 18.5197 1.78325C18.2031 1.62655 17.8193 1.7566 17.6626 2.07215C16.7117 3.99312 17.153 5.34377 17.475 6.32984C17.6946 7.00357 17.8118 7.40546 17.6423 7.82867C17.6082 7.91288 17.5944 8.0003 17.5976 8.08665C17.5954 8.17513 17.6125 8.26574 17.6498 8.35208C18.0986 9.39039 17.8769 11.3263 17.6615 13.1982C17.4441 15.0904 17.2191 17.0476 17.6221 18.4676C17.702 18.749 17.9579 18.9324 18.2372 18.9324C18.2948 18.9324 18.3534 18.9249 18.412 18.9078C18.7521 18.8119 18.9493 18.458 18.8523 18.1179C18.5186 16.9421 18.7286 15.1128 18.9322 13.3443C19.1646 11.3252 19.3832 9.41384 18.9099 8.06852C19.1337 7.29139 18.9099 6.60168 18.6913 5.93328Z"
        fill="#635F76"
      />
      <path
        d="M14.8152 6.20079C14.9144 6.20079 15.0146 6.17734 15.1084 6.12937C15.4218 5.96733 15.5455 5.58143 15.3834 5.26802C15.2086 4.92902 15.2886 4.47277 15.3813 3.94509C15.4762 3.40141 15.5838 2.78525 15.4026 2.17442C15.3024 1.83543 14.9464 1.64248 14.6074 1.74375C14.2684 1.84502 14.0754 2.20001 14.1767 2.539C14.2726 2.86201 14.1991 3.28095 14.1213 3.72549C14.0061 4.38642 13.875 5.13477 14.2471 5.85646C14.3601 6.075 14.5839 6.20079 14.8152 6.20079Z"
        fill="#635F76"
      />
      <path
        d="M18.3853 21.1582C18.1465 20.865 17.6946 20.5154 16.895 20.5154C16.894 20.5154 16.8929 20.5154 16.8929 20.5154C15.909 20.5164 15.0476 20.9716 14.4666 21.7989C13.5946 23.0397 12.9635 25.6515 14.9048 31.0498C14.9986 31.311 15.2448 31.473 15.5071 31.473C15.5785 31.473 15.6521 31.4602 15.7235 31.4346C16.0561 31.3153 16.2288 30.9485 16.1094 30.6159C14.1927 25.2858 15.054 23.1879 15.5145 22.5334C15.8535 22.0505 16.3311 21.7946 16.895 21.7935H16.8961C17.2554 21.7935 17.3556 21.9172 17.3939 21.9641C17.8694 22.5483 17.4185 24.7144 17.201 25.7559C16.8513 27.4328 17.2138 29.0372 17.4526 30.0989C17.5219 30.407 17.588 30.698 17.5986 30.8483C17.6242 31.2001 17.9312 31.4666 18.283 31.4389C18.6359 31.4133 18.9002 31.1063 18.8736 30.7545C18.8555 30.5083 18.7862 30.2034 18.6998 29.8175C18.4845 28.8634 18.1593 27.4221 18.4525 26.0161C18.8864 23.9384 19.1806 22.1357 18.3853 21.1582Z"
        fill="#635F76"
      />
      <path
        d="M30.4347 1.71606C30.0818 1.71606 29.795 2.00282 29.795 2.35568V17.8002H21.592V8.09834V5.66141V2.35568C21.592 2.00282 21.3052 1.71606 20.9524 1.71606C20.5995 1.71606 20.3128 2.00282 20.3128 2.35568V5.66141V7.45873H12.1087V5.82771V2.35568C12.1087 2.00282 11.8219 1.71606 11.4691 1.71606C11.1162 1.71606 10.8294 2.00282 10.8294 2.35568V5.82771V8.09834V17.8002H2.62639V2.35568C2.62639 2.00282 2.33963 1.71606 1.98678 1.71606C1.63393 1.71606 1.34717 2.00282 1.34717 2.35568V18.4398V26.0107V30.8035C1.34717 31.1564 1.63393 31.4432 1.98678 31.4432C2.33963 31.4432 2.62639 31.1564 2.62639 30.8035V26.0107V19.0794H10.8294V30.8334C10.8294 31.1863 11.1162 31.473 11.4691 31.473C11.8219 31.473 12.1087 31.1863 12.1087 30.8334V8.73795H20.3128V30.8334C20.3128 31.1863 20.5995 31.473 20.9524 31.473C21.3052 31.473 21.592 31.1863 21.592 30.8334V19.0794H29.795V30.8035C29.795 31.1564 30.0818 31.4432 30.4347 31.4432C30.7875 31.4432 31.0743 31.1564 31.0743 30.8035V18.4398V2.35568C31.0743 2.00282 30.7875 1.71606 30.4347 1.71606Z"
        fill="#635F76"
      />
    </svg>
  );
};

export default Madera;
