import React, { useState } from "react";
import { SearchIcon } from "../../Assets/svgs/SearchIcon";
import "./SearchInput.scss";

interface IProps {
  placeholder: string;
  onChange: (searchTerm: string) => unknown;
}

export const SearchInput = ({ placeholder, onChange }: IProps) => {
  const [value, setValue] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <div className="search-input">
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
      <SearchIcon />
    </div>
  );
};
