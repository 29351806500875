import React from 'react'

export const AutofitRight = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 12V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V14" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4 18H21" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18 15L21 18L18 21" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
