import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./CustomRadioButton.scss";

interface IProps {
  label?: string;
  rounded?: boolean;
  onChange?: any;
  itemId?: number;
  choiceIndex: number;
  selected?: any;
  name: string;
  value: any;
}

export const CustomRadioButton = ({ label, rounded, onChange, itemId, choiceIndex, selected, name, value }: IProps) => {
  const [checked, setChecked] = useState<any>(selected);
  const [selectedValue, setSelectedValue] = useState<any>()
  const dispatch = useDispatch()

  const handleChange = () => {
    if(selected) {
        onChange(undefined)
    } else {    
        onChange(itemId, choiceIndex)
    }
  };

  useEffect(() => {
    if (selected) setChecked(true)
    else setChecked(false)
  }, [selected])

  return (
    <div className="custom-checkbox">
      <label>
        <input key={itemId} type="radio" name={name} value={checked}/>
        <svg
          onClick={handleChange}
          className={`checkbox ${checked ? "checkbox-active" : ""} ${rounded ? "checkbox-rounded" : ""
            } ${rounded && checked ? "checkbox-rounded-active" : ""}`}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.99994 13L8.99994 18L19.9999 7.00001"
            stroke={checked ? "#ffffff" : "none"}
            strokeWidth="4"
            strokeLinecap="round"
          />
        </svg>
      </label>
      <span style={{ fontWeight: rounded ? 600 : 400 }}>{label}</span>
    </div>
  );
};
