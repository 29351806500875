import React from "react";

const CuadroElectrico = () => {
  return (
    <svg
      width="26"
      height="31"
      viewBox="0 0 26 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1604_3066)">
        <path
          d="M22.4311 0.363281H3.56875C1.79505 0.363281 0.357178 1.82538 0.357178 3.62898V27.371C0.357178 29.1746 1.79505 30.6367 3.56875 30.6367H22.4311C24.2048 30.6367 25.6427 29.1746 25.6427 27.371V3.62898C25.6427 1.82538 24.2048 0.363281 22.4311 0.363281Z"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
        />
        <path
          d="M21.5151 5.46857H4.26101C3.26591 5.46857 2.45923 6.28885 2.45923 7.30072V24.6474C2.45923 25.6593 3.26591 26.4795 4.26101 26.4795H21.5151C22.5102 26.4795 23.3169 25.6593 23.3169 24.6474V7.30072C23.3169 6.28885 22.5102 5.46857 21.5151 5.46857Z"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
        />
        <path
          d="M19.6657 7.1524H5.68289C4.68779 7.1524 3.8811 7.97269 3.8811 8.98455V13.5472C3.8811 14.559 4.68779 15.3793 5.68289 15.3793H19.6657C20.6607 15.3793 21.4674 14.559 21.4674 13.5472V8.98455C21.4674 7.97269 20.6607 7.1524 19.6657 7.1524Z"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
        />
        <path
          d="M7.30296 8.98395H5.39062"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M6.31226 11.5673V9.11414"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M10.4114 8.98395H8.49902"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M9.42041 11.5673V9.11414"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M13.6723 8.98395H11.76"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M12.6816 11.5673V9.11414"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M16.7807 8.98395H14.8684"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M15.7898 11.5673V9.11414"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M19.9907 8.98395H18.0786"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M19 11.5673V9.11414"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M5.76074 12.6571H6.76623"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M8.91479 12.6571H9.92009"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M12.1809 12.6571H13.1862"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M15.3347 12.6571H16.3402"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M18.4749 12.6571H19.4801"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M5.76074 13.7942H6.76623"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M8.91479 13.7942H9.92009"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M12.1809 13.7942H13.1862"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M15.3347 13.7942H16.3402"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M18.4749 13.7942H19.4801"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M19.6657 16.6544H5.68289C4.68779 16.6544 3.8811 17.4747 3.8811 18.4866V23.0492C3.8811 24.061 4.68779 24.8813 5.68289 24.8813H19.6657C20.6607 24.8813 21.4674 24.061 21.4674 23.0492V18.4866C21.4674 17.4747 20.6607 16.6544 19.6657 16.6544Z"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
        />
        <path
          d="M7.30296 18.4861H5.39062"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M6.31226 21.0694V18.6161"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M10.4114 18.4861H8.49902"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M9.42041 21.0694V18.6161"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M13.6723 18.4861H11.76"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M12.6816 21.0694V18.6161"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M16.7807 18.4861H14.8684"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M15.7898 21.0694V18.6161"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M19.9907 18.4861H18.0786"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M19 21.0694V18.6161"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M5.76074 22.1591H6.76623"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M8.91479 22.1591H9.92009"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M12.1809 22.1591H13.1862"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M15.3347 22.1591H16.3402"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M18.4749 22.1591H19.4801"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M5.76074 23.2964H6.76623"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M8.91479 23.2964H9.92009"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M12.1809 23.2964H13.1862"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M15.3347 23.2964H16.3402"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M18.4749 23.2964H19.4801"
          stroke="#635F76"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1604_3066">
          <rect width="26" height="31" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CuadroElectrico;
