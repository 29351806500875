import React from "react";

export const File = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33341 1.33333C9.7016 1.33333 10.0001 1.63181 10.0001 1.99999V4.66666H12.6667C13.0349 4.66666 13.3334 4.96514 13.3334 5.33333C13.3334 5.70152 13.0349 5.99999 12.6667 5.99999H10.0001C9.64646 5.99999 9.30732 5.85952 9.05727 5.60947C8.80722 5.35942 8.66675 5.02028 8.66675 4.66666V1.99999C8.66675 1.63181 8.96522 1.33333 9.33341 1.33333Z"
        fill="#89869B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66675 2.66666C4.48994 2.66666 4.32037 2.7369 4.19534 2.86192C4.07032 2.98695 4.00008 3.15652 4.00008 3.33333V12.6667C4.00008 12.8435 4.07032 13.013 4.19534 13.1381C4.32037 13.2631 4.48994 13.3333 4.66675 13.3333H11.3334C11.5102 13.3333 11.6798 13.2631 11.8048 13.1381C11.9298 13.013 12.0001 12.8435 12.0001 12.6667V5.60947L9.05727 2.66666H4.66675ZM3.25253 1.91911C3.62761 1.54404 4.13632 1.33333 4.66675 1.33333H9.33341C9.51023 1.33333 9.67979 1.40357 9.80482 1.52859L13.1382 4.86192C13.2632 4.98695 13.3334 5.15652 13.3334 5.33333V12.6667C13.3334 13.1971 13.1227 13.7058 12.7476 14.0809C12.3726 14.4559 11.8638 14.6667 11.3334 14.6667H4.66675C4.13631 14.6667 3.62761 14.4559 3.25253 14.0809C2.87746 13.7058 2.66675 13.1971 2.66675 12.6667V3.33333C2.66675 2.8029 2.87746 2.29419 3.25253 1.91911Z"
        fill="#89869B"
      />
    </svg>
  );
};


