import React from 'react'

export const Equipos = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.86675 6C4.39405 6 4.00004 6.39368 4.00004 6.86586V12.0618C3.99923 12.1192 4.00985 12.1761 4.03126 12.2294C4.05268 12.2826 4.08447 12.3311 4.12479 12.372C4.16511 12.4128 4.21316 12.4453 4.26614 12.4674C4.31911 12.4896 4.37597 12.501 4.4334 12.501C4.49083 12.501 4.54768 12.4896 4.60066 12.4674C4.65364 12.4453 4.70168 12.4128 4.742 12.372C4.78233 12.3311 4.81412 12.2826 4.83553 12.2294C4.85695 12.1761 4.86756 12.1192 4.86675 12.0618V6.86586H27.8346V21.5875H4.86675V15.5258C4.86756 15.4684 4.85695 15.4114 4.83553 15.3582C4.81412 15.3049 4.78233 15.2564 4.742 15.2156C4.70168 15.1747 4.65364 15.1423 4.60066 15.1201C4.54768 15.098 4.49083 15.0866 4.4334 15.0866C4.37597 15.0866 4.31911 15.098 4.26614 15.1201C4.21316 15.1423 4.16511 15.1747 4.12479 15.2156C4.08447 15.2564 4.05268 15.3049 4.03126 15.3582C4.00985 15.4114 3.99923 15.4684 4.00004 15.5258V21.5875C4.00004 22.0598 4.39405 22.4536 4.86675 22.4536H27.8346C28.3072 22.4536 28.7013 22.0598 28.7013 21.5875V6.86586C28.7013 6.39368 28.3072 6 27.8346 6H4.86675Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.21667 18.1337V19.0004H4.65003H28.0512H28.4845V18.1337H28.0512H4.65003H4.21667ZM12.0239 24.6168C11.9663 24.6158 11.9091 24.6262 11.8557 24.6475C11.8022 24.6688 11.7535 24.7006 11.7125 24.741C11.6714 24.7813 11.6388 24.8293 11.6165 24.8823C11.5943 24.9353 11.5828 24.9923 11.5828 25.0498C11.5828 25.1073 11.5943 25.1642 11.6165 25.2173C11.6388 25.2703 11.6714 25.3184 11.7125 25.3587C11.7535 25.399 11.8022 25.4307 11.8557 25.452C11.9091 25.4733 11.9663 25.4838 12.0239 25.4828H20.6791C20.7366 25.4838 20.7938 25.4733 20.8473 25.452C20.9007 25.4307 20.9494 25.399 20.9905 25.3587C21.0315 25.3184 21.0642 25.2703 21.0864 25.2173C21.1087 25.1642 21.1201 25.1073 21.1201 25.0498C21.1201 24.9923 21.1087 24.9353 21.0864 24.8823C21.0642 24.8293 21.0315 24.7813 20.9905 24.741C20.9494 24.7006 20.9007 24.6688 20.8473 24.6475C20.7938 24.6262 20.7366 24.6158 20.6791 24.6168H12.0239Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.1572 21.5876C15.0572 21.588 14.9605 21.623 14.8834 21.6865C14.8063 21.75 14.7535 21.8381 14.734 21.9361L14.0873 24.9551C14.072 25.0118 14.0684 25.0711 14.0768 25.1293C14.0851 25.1875 14.1053 25.2433 14.1359 25.2935C14.1666 25.3437 14.2071 25.3871 14.2551 25.4211C14.3031 25.4551 14.3575 25.4789 14.4151 25.4912C14.4726 25.5035 14.532 25.504 14.5897 25.4926C14.6474 25.4812 14.7022 25.4582 14.7507 25.425C14.7993 25.3918 14.8405 25.3491 14.872 25.2994C14.9034 25.2498 14.9245 25.1942 14.9337 25.1362L15.582 22.117C15.5966 22.0534 15.5965 21.9873 15.5819 21.9237C15.5673 21.8601 15.5385 21.8007 15.4976 21.7497C15.4568 21.6988 15.4049 21.6577 15.346 21.6297C15.287 21.6016 15.2225 21.5872 15.1572 21.5876ZM17.5321 21.5876C17.468 21.5892 17.405 21.6051 17.3477 21.634C17.2904 21.6629 17.2403 21.7043 17.201 21.7549C17.1616 21.8056 17.134 21.8644 17.1201 21.927C17.1063 21.9896 17.1065 22.0545 17.1208 22.117L17.7675 25.1362C17.7793 25.1918 17.8021 25.2445 17.8344 25.2914C17.8667 25.3382 17.9079 25.3783 17.9557 25.4092C18.0035 25.4402 18.0569 25.4614 18.1129 25.4717C18.1689 25.482 18.2264 25.4812 18.2821 25.4693C18.3378 25.4575 18.3906 25.4346 18.4375 25.4024C18.4844 25.3701 18.5244 25.329 18.5554 25.2812C18.5864 25.2335 18.6076 25.1801 18.6179 25.1241C18.6282 25.0682 18.6274 25.0107 18.6155 24.9551L17.9689 21.9361C17.9489 21.8358 17.8942 21.7459 17.8142 21.6821C17.7343 21.6184 17.6344 21.5848 17.5321 21.5876Z" fill="#635F76"/>
        <path d="M15.9172 20.3005C15.9172 20.3574 15.9284 20.4138 15.9502 20.4664C15.972 20.519 16.0039 20.5666 16.0441 20.6069C16.0844 20.6471 16.1321 20.679 16.1847 20.7008C16.2373 20.7226 16.2937 20.7338 16.3506 20.7338C16.4075 20.7338 16.4638 20.7226 16.5164 20.7008C16.569 20.679 16.6168 20.6471 16.657 20.6069C16.6972 20.5666 16.7292 20.519 16.7509 20.4664C16.7727 20.4138 16.7839 20.3574 16.7839 20.3005C16.7839 20.2436 16.7727 20.1873 16.7509 20.1347C16.7292 20.0821 16.6972 20.0343 16.657 19.9941C16.6168 19.9538 16.569 19.9219 16.5164 19.9001C16.4638 19.8784 16.4075 19.8671 16.3506 19.8671C16.2937 19.8671 16.2373 19.8784 16.1847 19.9001C16.1321 19.9219 16.0844 19.9538 16.0441 19.9941C16.0039 20.0343 15.972 20.0821 15.9502 20.1347C15.9284 20.1873 15.9172 20.2436 15.9172 20.3005ZM4.00061 13.7933C4.00061 14.0324 4.19467 14.2263 4.43397 14.2263C4.67326 14.2263 4.86732 14.0324 4.86732 13.7933C4.86732 13.5541 4.67326 13.3604 4.43397 13.3604C4.19467 13.3604 4.00061 13.5541 4.00061 13.7933Z" fill="#635F76"/>
    </svg>
  )
}
