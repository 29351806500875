import React from "react";

const Restauracion = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3647_10347)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9289 1.38682C13.1741 1.49182 13.3332 1.73291 13.3332 1.99969V13.9997C13.3332 14.3679 13.0347 14.6664 12.6665 14.6664H11.9998C11.6316 14.6664 11.3332 14.3679 11.3332 13.9997V11.9997C11.3332 11.6315 11.6316 11.333 11.9998 11.333V10.6664H9.33317C8.96661 10.6664 8.66881 10.3704 8.66652 10.0039C8.65879 8.76771 8.68665 7.43946 9.15722 6.01838C9.631 4.58757 10.5357 3.11091 12.2064 1.5173C12.3994 1.33316 12.6836 1.28183 12.9289 1.38682ZM11.9998 9.33302H10.0018C10.0167 8.35775 10.0985 7.41742 10.423 6.4375C10.706 5.58268 11.1829 4.673 11.9998 3.68385V9.33302ZM3.33317 1.99969C3.70136 1.99969 3.99984 2.29817 3.99984 2.66636V4.66636C3.99984 5.01998 4.14031 5.35912 4.39036 5.60917C4.47348 5.69228 4.56644 5.76329 4.6665 5.82106V2.66636C4.6665 2.29817 4.96498 1.99969 5.33317 1.99969C5.70136 1.99969 5.99984 2.29817 5.99984 2.66636V5.82106C6.0999 5.76329 6.19286 5.69228 6.27598 5.60917C6.52603 5.35912 6.6665 5.01998 6.6665 4.66636V2.66636C6.6665 2.29817 6.96498 1.99969 7.33317 1.99969C7.70136 1.99969 7.99984 2.29817 7.99984 2.66636V4.66636C7.99984 5.3736 7.71889 6.05188 7.21879 6.55198C6.8791 6.89167 6.45719 7.13025 5.99984 7.24835V13.9997C5.99984 14.3679 5.70136 14.6664 5.33317 14.6664C4.96498 14.6664 4.6665 14.3679 4.6665 13.9997V7.24835C4.20915 7.13025 3.78725 6.89167 3.44755 6.55198C2.94746 6.05188 2.6665 5.3736 2.6665 4.66636V2.66636C2.6665 2.29817 2.96498 1.99969 3.33317 1.99969Z"
          fill="#635F76"
        />
      </g>
      <defs>
        <clipPath id="clip0_3647_10347">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Restauracion;
