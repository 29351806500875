import React from "react";
import "./Subtitle.scss";

interface IProps {
  text: string;
}

export const Subtitle = ({ text }: IProps) => {
  return (
    <div className="subtitle">
      <h3>{text}</h3>
    </div>
  );
};
