import React from "react";

const Peligroso = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4437 13.4493C18.3959 13.4493 18.3472 13.4445 18.2993 13.4351C18.2514 13.4257 18.2044 13.4124 18.1598 13.3952C18.1153 13.3771 18.0724 13.3552 18.0319 13.3301C17.9907 13.3042 17.9527 13.2744 17.9189 13.2423C17.8842 13.2102 17.8537 13.1741 17.8264 13.1349C17.8 13.0965 17.7769 13.0557 17.7579 13.0134C17.7398 12.971 17.7257 12.9264 17.7158 12.8817C17.7059 12.8362 17.701 12.7892 17.701 12.7437C17.701 12.6975 17.7059 12.6512 17.7158 12.6057C17.7257 12.5603 17.7398 12.5164 17.7579 12.4732C17.7769 12.4309 17.8 12.3901 17.8264 12.3517C17.8537 12.3133 17.8842 12.2773 17.9189 12.2443C17.9527 12.2122 17.9907 12.1824 18.0319 12.1565C18.0724 12.1314 18.1153 12.1095 18.1598 12.0923C18.2044 12.0742 18.2514 12.0609 18.2993 12.0515C18.395 12.0335 18.4932 12.0335 18.589 12.0515C18.6368 12.0609 18.683 12.0742 18.7284 12.0923C18.773 12.1095 18.8159 12.1314 18.8563 12.1565C18.8968 12.1824 18.9347 12.2122 18.9694 12.2443C19.0032 12.2773 19.0346 12.3133 19.0618 12.3517C19.0882 12.3901 19.1113 12.4309 19.1295 12.4732C19.1485 12.5164 19.1625 12.5603 19.1724 12.6057C19.1815 12.6512 19.1864 12.6975 19.1864 12.7437C19.1864 12.7892 19.1815 12.8362 19.1724 12.8817C19.1625 12.9264 19.1485 12.971 19.1295 13.0134C19.1113 13.0557 19.0882 13.0965 19.0618 13.1349C19.0346 13.1741 19.0032 13.2102 18.9694 13.2423C18.8308 13.374 18.6393 13.4493 18.4437 13.4493Z"
        fill="#635F76"
      />
      <path
        d="M18.1656 24.1618C14.2885 24.1618 9.07376 22.1596 5.94027 18.0721C5.77935 17.862 5.82804 17.5681 6.04921 17.4152C6.27037 17.2623 6.57984 17.3086 6.74077 17.5187C9.87756 21.6101 15.2186 23.4711 18.8737 23.1952C19.1468 23.174 19.3845 23.3677 19.4068 23.6271C19.4282 23.8858 19.2252 24.1124 18.9521 24.1336C18.6979 24.1524 18.4346 24.1618 18.1656 24.1618Z"
        fill="#635F76"
      />
      <path
        d="M19.1897 12.8534C19.0957 12.8534 18.9999 12.8276 18.9157 12.7743C18.8786 12.7515 18.1681 12.329 16.8708 12.4521C16.5993 12.4779 16.3558 12.289 16.3294 12.0303C16.3022 11.7716 16.5011 11.5411 16.7734 11.516C18.4577 11.3569 19.4282 11.9676 19.4695 11.9942C19.6956 12.1401 19.755 12.4325 19.6015 12.6473C19.5042 12.7813 19.349 12.8534 19.1897 12.8534Z"
        fill="#635F76"
      />
      <path
        d="M26.6608 20.7015C26.5873 20.7015 26.5122 20.6858 26.4413 20.6529C26.1961 20.5376 26.0955 20.2554 26.2176 20.0226L26.5799 19.3272C26.7012 19.0944 26.9983 18.9988 27.2434 19.1148C27.4885 19.23 27.5892 19.5122 27.467 19.7451L27.1048 20.4404C27.0181 20.6051 26.8423 20.7015 26.6608 20.7015Z"
        fill="#635F76"
      />
      <path
        d="M25.0235 20.0415C24.9501 20.0415 24.875 20.0258 24.804 19.9929C24.5589 19.8776 24.459 19.5954 24.5803 19.3626L24.9426 18.6672C25.0639 18.4344 25.361 18.3395 25.6061 18.4548C25.8512 18.57 25.9511 18.8522 25.8298 19.0851L25.4675 19.7804C25.3808 19.9458 25.2059 20.0415 25.0235 20.0415Z"
        fill="#635F76"
      />
      <path
        d="M27.1889 17.38C26.9166 17.38 26.6954 17.1707 26.6938 16.912L26.6863 15.5071C26.6847 15.2477 26.9059 15.036 27.179 15.0344C27.1799 15.0344 27.1807 15.0344 27.1815 15.0344C27.4538 15.0344 27.675 15.2437 27.6767 15.5024L27.6841 16.9073C27.6857 17.1667 27.4646 17.3784 27.1914 17.38C27.1906 17.38 27.1898 17.38 27.1889 17.38Z"
        fill="#635F76"
      />
      <path
        d="M25.4922 17.0609C25.2298 17.0609 25.0103 16.865 24.9979 16.6125L24.9558 15.7596C24.9426 15.5001 25.1538 15.2798 25.427 15.2673C25.6993 15.2547 25.9321 15.4554 25.9453 15.7149L25.9874 16.5678C26.0006 16.8273 25.7893 17.0476 25.5161 17.0602C25.5079 17.0609 25.4996 17.0609 25.4922 17.0609Z"
        fill="#635F76"
      />
      <path
        d="M23.7237 17.1448C23.4621 17.1448 23.2434 16.9504 23.2294 16.6988L23.1873 15.9242C23.1733 15.6647 23.3829 15.4437 23.6561 15.4303C23.9292 15.4154 24.1619 15.6161 24.176 15.8756L24.2181 16.6502C24.2321 16.9096 24.0225 17.1307 23.7493 17.144C23.7411 17.1448 23.732 17.1448 23.7237 17.1448Z"
        fill="#635F76"
      />
      <path
        d="M23.3821 19.3822C23.3086 19.3822 23.2335 19.3665 23.1626 19.3336C22.9175 19.2183 22.8168 18.9361 22.9389 18.7033L23.3012 18.0079C23.4225 17.7751 23.7196 17.6802 23.9647 17.7955C24.2098 17.9107 24.3105 18.1929 24.1884 18.4258L23.8261 19.1211C23.7402 19.2865 23.5645 19.3822 23.3821 19.3822Z"
        fill="#635F76"
      />
      <path
        d="M19.4902 29.4228C19.3061 29.4228 19.1295 29.3248 19.0445 29.1579C18.3521 27.8009 17.819 26.4972 18.3224 24.288L19.0429 20.0626C19.0684 19.9144 19.1666 19.7858 19.3078 19.7184C19.4489 19.6502 19.6156 19.651 19.7567 19.7192C23.1972 21.3898 25.4559 22.0334 26.471 21.6312C26.7928 21.5035 26.9876 21.2683 27.1411 20.9343L22.3084 18.9917C21.5062 18.6695 21.0334 17.8722 21.158 17.0538C21.2851 16.2205 21.989 15.5824 22.8712 15.5016L26.4429 15.1747C27.7402 15.0555 28.7833 14.0764 28.9236 12.8456C28.95 12.6144 28.8675 12.3854 28.6967 12.2185C28.5259 12.0515 28.2874 11.9652 28.0439 11.9833L24.7066 12.2232C23.2913 12.3251 21.8644 11.8524 20.7916 10.9257C19.7014 9.98501 17.7703 8.97608 14.7573 9.51386C11.59 10.0791 10.683 11.7191 8.87655 14.9866L8.70325 15.3001C7.85076 16.8398 6.69623 18.5221 4.60008 19.3257C4.34673 19.4229 4.05789 19.3061 3.95555 19.0654C3.85322 18.8247 3.97619 18.5504 4.22954 18.4531C6.02942 17.7633 7.05769 16.2503 7.82682 14.8619L8.00013 14.5491C9.83137 11.237 10.9364 9.23792 14.5749 8.58881C17.9882 7.97969 20.2015 9.14463 21.4584 10.2288C22.3307 10.9822 23.4877 11.3671 24.6323 11.284L27.9696 11.0441C28.5085 11.0057 29.0326 11.1946 29.4089 11.5631C29.7844 11.9315 29.9668 12.4356 29.909 12.946C29.7184 14.6181 28.3014 15.9485 26.5395 16.1099L22.9678 16.4368C22.5411 16.476 22.2011 16.7841 22.1392 17.1871C22.079 17.583 22.3076 17.9679 22.6954 18.1239L27.9622 20.2413C28.1974 20.3362 28.3187 20.5855 28.2412 20.8175C28.0249 21.4627 27.6907 22.1667 26.8539 22.4983C25.5748 23.0047 23.4208 22.4951 19.9135 20.8457L19.2987 24.4503C19.297 24.4589 19.2954 24.4668 19.2937 24.4754C18.8514 26.4054 19.2814 27.4638 19.9366 28.7479C20.0563 28.9815 19.9531 29.2629 19.7072 29.3766C19.6362 29.4072 19.5628 29.4228 19.4902 29.4228Z"
        fill="#635F76"
      />
      <path
        d="M9.09526 14.0537C9.06803 14.0537 9.04079 14.0513 9.01356 14.0474C8.7437 14.0051 8.56132 13.7628 8.60588 13.5065L10.2977 3.8162C10.3307 3.62728 10.4809 3.47597 10.6781 3.43443C10.8753 3.39209 11.0792 3.46814 11.1939 3.62571L14.9669 8.80285C15.1229 9.01609 15.0668 9.30928 14.8415 9.45745C14.617 9.60561 14.3084 9.5523 14.1524 9.33829L11.075 5.11598L9.58299 13.6609C9.54255 13.8906 9.33293 14.0537 9.09526 14.0537Z"
        fill="#635F76"
      />
      <path
        d="M28.8328 14.8235C28.7982 14.8235 28.7627 14.8203 28.728 14.8125C27.6866 14.5985 26.8646 13.86 26.5824 12.8848C26.4462 12.4168 26.4462 11.9229 26.5807 11.4557C26.6533 11.2056 26.9257 11.0582 27.1889 11.1264C27.453 11.1954 27.6073 11.4541 27.5355 11.7042C27.4481 12.0076 27.4481 12.329 27.5372 12.6347C27.7212 13.2713 28.2576 13.7526 28.9368 13.8921C29.2042 13.947 29.3742 14.1971 29.3164 14.4511C29.2661 14.673 29.0606 14.8235 28.8328 14.8235Z"
        fill="#635F76"
      />
    </svg>
  );
};

export default Peligroso;
