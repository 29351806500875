import React from "react";

const PulsadorManual = () => {
  return (
    <svg
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="29" height="31" rx="2.5" stroke="#635F76" />
      <rect x="3.5" y="8.5" width="23" height="14" rx="2.5" stroke="#635F76" />
      <rect x="12.5" y="24.5" width="5" height="2" rx="1" stroke="#635F76" />
      <path
        d="M15.0371 18.0742C13.9137 18.0742 13 17.1605 13 16.0371C13 14.9137 13.9137 14 15.0371 14C16.1605 14 17.0742 14.9137 17.0742 16.0371C17.0742 17.1605 16.1605 18.0742 15.0371 18.0742ZM15.0371 15.1188C14.5308 15.1188 14.1188 15.5308 14.1188 16.0371C14.1188 16.5433 14.5308 16.9554 15.0371 16.9554C15.5433 16.9554 15.9554 16.5433 15.9554 16.0371C15.9554 15.5308 15.5433 15.1188 15.0371 15.1188Z"
        fill="#635F76"
      />
      <path
        d="M18.25 16H21.75"
        stroke="#635F76"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.25 16L19.75 17.5"
        stroke="#635F76"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.25 16L19.75 14.5"
        stroke="#635F76"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g clip-path="url(#clip0_1450_8693)">
        <path
          d="M11.75 16L8.25 16"
          stroke="#635F76"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.75 16L10.25 14.5"
          stroke="#635F76"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.75 16L10.25 17.5"
          stroke="#635F76"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g clip-path="url(#clip1_1450_8693)">
        <path
          d="M13.8333 5H13.5L15 3.5L16.5 5H16.1667"
          stroke="#635F76"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.8333 5V6.16667C13.8333 6.25507 13.8684 6.33986 13.9309 6.40237C13.9934 6.46488 14.0782 6.5 14.1666 6.5H15.8333C15.9217 6.5 16.0064 6.46488 16.069 6.40237C16.1315 6.33986 16.1666 6.25507 16.1666 6.16667V5"
          stroke="#635F76"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1450_8693">
          <rect
            width="6"
            height="6"
            fill="white"
            transform="translate(13 19) rotate(180)"
          />
        </clipPath>
        <clipPath id="clip1_1450_8693">
          <rect width="4" height="4" fill="white" transform="translate(13 3)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PulsadorManual;
