
import { CustomInputAyuware } from "../CustomInputAyuware/CustomInputAyuware";


export const NumberInputAyuware = (props: any) => {
  return (
    <CustomInputAyuware
      type="number"
      index={props.index}
      label={props.label}
      disable={props.disable}
      prefetchedValue={props.prefetchedValue}
      error={props.error}
      length={props.length}
      onChange={props.onChange}
      defaultValue={props.defaultValue}
      val={props.val}
    />
  );
};
