import React from 'react'

export const MasM = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M24.6716 12.4648L27.8536 15.6468C28.0488 15.842 28.0488 16.1586 27.8536 16.3539L24.6716 19.5359C24.4763 19.7311 24.1597 19.7311 23.9645 19.5359C23.7692 19.3406 23.7692 19.024 23.9645 18.8288L26.2929 16.5003H9.5C9.22386 16.5003 9 16.2765 9 16.0003C9 15.7242 9.22386 15.5003 9.5 15.5003H26.2929L23.9645 13.1719C23.7692 12.9767 23.7692 12.6601 23.9645 12.4648C24.1597 12.2695 24.4763 12.2695 24.6716 12.4648Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.5 22C4.22386 22 4 21.7558 4 21.4545L4 10.5455C4 10.2442 4.22386 10 4.5 10C4.77614 10 5 10.2442 5 10.5455L5 21.4545C5 21.7558 4.77614 22 4.5 22Z" fill="#635F76"/>
    </svg>
  )
}
