import dayjs from "dayjs";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlockTitle } from "../../Components/BlockTitle/BlockTitle";
import { CustomButton } from "../../Components/CustomButton/CustomButton";
import { CustomCheckbox } from "../../Components/CustomCheckbox/CustomCheckbox";
import { DateInput } from "../../Components/DateInput/DateInput";
import { Description } from "../../Components/Description/Description";
import { SubBlockTitle } from "../../Components/SubBlockTitle/SubBlockTitle";
import { Subtitle } from "../../Components/Subtitle/Subtitle";
import { TipoItem } from "../../model/tipoItemEnum";
import { postRespuesta } from "../LayoutPregunta/PreguntasAPI";
import {
  changeValue,
  getPreguntas,
  selectId,
  selectPreguntas,
} from "../LayoutPregunta/preguntaSlice";
import "./FechaInicioDesconozco.scss";
import { ModalConexion } from "../../Components/ModalConexion/ModalConexion";

export const FechaInicioDesconozco = () => {
  const dispatch = useDispatch();
  const preguntas = useSelector(selectPreguntas);
  const preguntaId: any = useSelector(selectId);
  const [filteredItems, setFilteredItems] = useState(
    preguntas.items[0].opciones
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState<any>({});
  const [loader, setLoader] = useState(false);
  const [enabledButton, setEnabledButton] = useState(false);
  const containerRef = useRef<any>()
  const [loading, setLoading] = useState(false);
  const [dateEnabled, setDateEnabled] = useState(true);
  const [triggerReset, setTriggerReset] = useState(false);
  const [noConnection, setNoConnection] = useState(false)
  
  let checkboxHasValue = false;
  let dateHasValue  = false;


  const handleSiguiente = async () => {
    try {
      setLoader(true);
    } catch {}
    const response = await postRespuesta(preguntas);

    if (response.status === 200) {
      // @ts-ignore
      dispatch(getPreguntas(preguntaId));
      try {
        setLoader(false);
      } catch {}
    } else {
      try {
        setLoader(false);
      } catch {}
    }
  };

  const filterCategories = (items: any, searchTerm: string) => {
    const lowerCaseTerm = searchTerm.toLowerCase();
    const categories = items.filter((item: any) => {
      return (
        item.titulo.toLowerCase().includes(lowerCaseTerm) ||
        item.subopciones.find((s: any) =>
          s.titulo.toLowerCase().includes(lowerCaseTerm)
        )
      );
    });

    return categories.map((c: any) => {
      if (c.titulo.toLowerCase().includes(lowerCaseTerm)) {
        return c;
      } else if (!c.titulo.toLowerCase().includes(lowerCaseTerm)) {
        const newSubopciones = c.subopciones.filter((o: any) => {
          return o.titulo.toLowerCase().includes(lowerCaseTerm);
        });
        return { ...c, subopciones: newSubopciones };
      }
    });
  };


  useEffect(() => {
    if (searchTerm.length > 0) {
      setFilteredItems(
        filterCategories(preguntas.items[0].opciones, searchTerm)
      );
    } else {
      setFilteredItems(preguntas.items[0].opciones);
    }
  }, [searchTerm]);

  useEffect(() => {
    const selectedCategory = preguntas.items[0].opciones?.find(
      (o: any) => o.selected
    );
    const selectedActivity = selectedCategory?.subopciones?.find(
      (s: any) => s.selected
    );
    setSelected({
      categoryId: selectedCategory?.id,
      subOptionId: selectedActivity?.id,
    });
    const desconozco = preguntas.items.find((e:any) => e.tipoItem === TipoItem.Checkbox);
    const date = preguntas.items.some((e:any) => e.valor)

    if(parseInt(desconozco.valor)){
      setDateEnabled(false);
      setTriggerReset(!triggerReset);
    }
    
    if(date)
      setEnabledButton(true);

  }, []);

  const controlButton = () => {
    setEnabledButton( checkboxHasValue  || dateHasValue  );
  }


  const onChangeCheckbox = (e: any, index: number, value:boolean) => {
    setDateEnabled(!value);
    setTriggerReset(!triggerReset);
    dispatch(changeValue({ value: value, index: index }))
    checkboxHasValue = value;
    controlButton();
  }

  const onChangeDate  = (value:string) => {
    dateHasValue = !!value.length
    controlButton();
  }

  const renderInputs = (e: any, index: number) => {
    switch (e.tipoItem) {
      case TipoItem.InputDate: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <DateInput
              key={e.keyId}
              index={index}
              label={e.placeholder}
              prefetchedValue={e.valor && dayjs(e.valor).format("YYYY-MM-DD")}
              disabled={!dateEnabled}
              triggerReset={triggerReset}
              callbackValue = {onChangeDate}
            />
          </>
        );
      }
      case TipoItem.Checkbox: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <CustomCheckbox
              label={e.placeholder}
              selected={parseInt(e.valor)}
              choiceIndex={index}
              singleCheckbox
              key={e.keyId}
              onChange={(itemId:number, choiceIndex:number, checked:boolean) => onChangeCheckbox(e, index, checked)}
            />
          </>
        );
      }
    }
  };


  const renderContent = () => {
    return (
      <>
        <div>
          <div className="layout-pregunta-content-title">
            <BlockTitle
              text={preguntas.titulo}
              icon={preguntas.iconoPrincipal}
            />
          </div>
          {preguntas.subtitulo ? <Subtitle text={preguntas.subtitulo} /> : null}
        </div>
        {preguntas.titulo1 ? <SubBlockTitle text={preguntas.titulo1} /> : null}
        {preguntas.texto ? <Description text={preguntas.texto} /> : null}
        {preguntas.items?.map((e: any, index: number) => {
          return <Fragment key={e.keyId} >{renderInputs(e, index)}</Fragment>;
        })}
      </>
    );
  };

  const handleContinue = () => {
    const online = window.navigator.onLine
    if(!online) {
      setNoConnection(true);
      return; 
    }
    if (loading) {
      return () => null;
    } else {
      return handleSiguiente()
    }
  }

  return (
    <>
    <div className="layout-pregunta" ref={containerRef}>
      <div className="layout-pregunta-content">{renderContent()}</div>
    </div>
    <div className="layout-pregunta-content-actions">
      <CustomButton
        type="primary"
        text="Continuar"
        loading={loading}
        disable={!enabledButton}
        onClick={handleContinue}
      />
      <ModalConexion open={noConnection} onClick={(e: boolean) => setNoConnection(e)} />
    </div>
  </>
  );
};
