import React from "react";

const Piso = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_919_1372)">
        <path
          d="M25.9077 5.40313H19.9177V4.20313H25.9077V2.41313H6.74767V4.21313H16.3277V5.41313H6.74767C6.08767 5.41313 5.54767 4.87313 5.54767 4.21313V2.41313C5.54767 1.75313 6.08767 1.21313 6.74767 1.21313H25.9077C26.5677 1.21313 27.1077 1.75313 27.1077 2.41313V4.21313C27.1077 4.87313 26.5677 5.41313 25.9077 5.41313V5.40313Z"
          fill="#635F76"
        />
        <path
          d="M18.7276 4.20312H17.5276V5.40313H18.7276V4.20312Z"
          fill="#635F76"
        />
        <path
          d="M25.9177 4.8031H24.7177V30.5531H25.9177V4.8031Z"
          fill="#635F76"
        />
        <path
          d="M7.94765 4.8031H6.74765V30.5531H7.94765V4.8031Z"
          fill="#635F76"
        />
        <path
          d="M23.5177 11.3931H9.13766V7.8031H23.5077V11.3931H23.5177ZM10.3377 10.1931H22.3177V8.9931H10.3377V10.1931Z"
          fill="#635F76"
        />
        <path
          d="M23.5177 16.7831H19.9277V15.5831H22.3177V14.3931H10.3377V15.5831H18.7277V16.7831H9.13766V13.1931H23.5177V16.7831Z"
          fill="#635F76"
        />
        <path
          d="M23.5177 22.1731H16.9277V20.9831H22.3177V19.7831H10.3377V20.9831H13.3377V22.1731H9.13766V18.5831H23.5177V22.1731Z"
          fill="#635F76"
        />
        <path
          d="M15.7276 20.9832H14.5276V22.1832H15.7276V20.9832Z"
          fill="#635F76"
        />
        <path
          d="M19.9276 30.5631H18.7276V25.7731H13.9376V30.5631H12.7376V24.5731H19.9276V30.5631Z"
          fill="#635F76"
        />
        <path
          d="M32.4977 29.9631H0.157654V31.1631H32.4977V29.9631Z"
          fill="#635F76"
        />
      </g>
      <defs>
        <clipPath id="clip0_919_1372">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.327698 0.18811)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Piso;
