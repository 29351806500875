import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { Icons } from "../../Assets/Icons";
import { ChevronDown } from "../../Assets/svgs/ChevronDown";
import { BlockTitle } from "../../Components/BlockTitle/BlockTitle";
import { CustomButton } from "../../Components/CustomButton/CustomButton";
import { Description } from "../../Components/Description/Description";
import { SearchInput } from "../../Components/SearchInput/SearchInput";
import { SubBlockTitle } from "../../Components/SubBlockTitle/SubBlockTitle";
import { postRespuesta } from "../LayoutPregunta/PreguntasAPI";
import {
  getPreguntas,
  handleActivity,
  selectId,
  selectPreguntas,
  validarCamposObligatorios,
} from "../LayoutPregunta/preguntaSlice";
import "./ActividadEmpresarial.scss";
import { ModalConexion } from "../../Components/ModalConexion/ModalConexion";

export const ActividadEmpresarial = () => {
  const dispatch = useDispatch();
  const preguntas = useSelector(selectPreguntas);
  const preguntaId: any = useSelector(selectId);
  const [filteredItems, setFilteredItems] = useState(
    preguntas.items[0].opciones
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [expand, setExpand] = useState<any>([]);
  const [selected, setSelected] = useState<any>({});
  const [loader, setLoader] = useState(false);
  const [noConnection, setNoConnection] = useState(false)
  const obligatorios = useSelector(validarCamposObligatorios);
  const buttonRef: any = useRef();
  const icons = Icons();

  const handleSiguiente = async () => {
    try {
      setLoader(true);
    } catch {}
    const response = await postRespuesta(preguntas);

    if (response.status === 200) {
      // @ts-ignore
      dispatch(getPreguntas(preguntaId));
      try {
        setLoader(false);
      } catch {}
    } else {
      try {
        setLoader(false);
      } catch {}
    }
  };

  useEffect(() => {
    if (searchTerm) {
      const expandedIndexes = Array.from(Array(filteredItems.length + 1).keys())
      setExpand(expandedIndexes);
    } else {
      setExpand([]);
    }
  }, [searchTerm]);

  const handleClick = (selectedIds: any) => {
    setSelected(selectedIds);
    dispatch(handleActivity(selectedIds));
    buttonRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const getCategoryOptions = (category: any, parentId: any) => {
    return category.map((c: any, index: number) => {
      return (
        <li
          key={c.id}
          className={`actividad-accordion-category-item ${
            selected.subOptionId && selected.subOptionId === c.id
              ? "actividad-accordion-category-item-selected"
              : ""
          }`}
          onClick={() =>
            handleClick({ categoryId: parentId, subOptionId: c.id })
          }
        >
          {c.titulo}
        </li>
      );
    });
  };

  const normalizedString = (str: string): string => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const filterCategories = (items: any, searchTerm: string) => {
    const normalizedTerm = normalizedString(searchTerm);
    const filteredCategories = items.filter((item: any) => {
      const normalizedTitle = normalizedString(item.titulo);
      const normalizedSubtitles = item.subopciones.map((s: any) =>
        normalizedString(s.titulo)
      );
      return (
        normalizedTitle.includes(normalizedTerm) ||
        normalizedSubtitles.some((subtitle: string) =>
          subtitle.includes(normalizedTerm)
        )
      );
    });
    const filteredItems = filteredCategories.map((category: any) => {
      const filteredSuboptions = category.subopciones.filter(
        (suboption: any) => {
          return normalizedString(suboption.titulo).includes(normalizedTerm);
        }
      );
      return { ...category, subopciones: filteredSuboptions };
    });
    return filteredItems;
  };

  const handleAccordionClick = (index: number) => {
    const alreadyExpanded = expand.includes(index)
    if(alreadyExpanded) {
      const newExpand = expand.filter((e: any) => e !== index)
      setExpand(newExpand)
    } else {
      setExpand((prevState: any) => [...prevState, index])
    }
  }

  const renderAccordion = () => {
    return filteredItems.map((e: any, index: number) => {
      return (
        <Accordion key={e.id} expanded={expand.includes(index)}>
          <AccordionSummary expandIcon={<ChevronDown />} onClick={() => handleAccordionClick(index)}>
            <div className="actividad-accordion-container">
              <div className="actividad-accordion-icon">{icons[e.icono]}</div>
              <span className="actividad-accordion-category">{e.titulo}</span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <ul>{getCategoryOptions(e.subopciones, e.id)}</ul>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  useEffect(() => {
    if (searchTerm.length > 0) {
      setFilteredItems(
        filterCategories(preguntas.items[0].opciones, searchTerm)
      );
    } else {
      setFilteredItems(preguntas.items[0].opciones);
    }
  }, [searchTerm]);

  useEffect(() => {
    const selectedCategory = preguntas.items[0].opciones?.find(
      (o: any) => o.selected
    );
    const selectedActivity = selectedCategory?.subopciones?.find(
      (s: any) => s.selected
    );
    setSelected({
      categoryId: selectedCategory?.id,
      subOptionId: selectedActivity?.id,
    });
  }, []);

  const handleContinue = () => {
    const online = window.navigator.onLine
    if(!online) {
      setNoConnection(true);
      return; 
    }
    if (loader) {
      return () => null;
    } else {
      return handleSiguiente()
    }
  }

  return (
    <>
      <div className="actividad">
        <BlockTitle
          icon={preguntas.iconoPrincipal}
          text="Actividad principal empresarial"
        />
        <div className="actividad-text">
          <SubBlockTitle text={preguntas.titulo1} />
          <Description text={preguntas.texto} />
        </div>
        <SearchInput
          placeholder="Buscar actividad"
          onChange={(e) => setSearchTerm(e)}
        />
        <div className="actividad-accordion">{renderAccordion()}</div>
      </div>
      <div className="actividad-buttons" ref={buttonRef}>
        <CustomButton
          type="primary"
          text="Continuar"
          loading={loader}
          disable={!obligatorios}
          onClick={handleContinue}
        />
      </div>
      <ModalConexion open={noConnection} onClick={(e: boolean) => setNoConnection(e)} />
    </>
  );
};
