import React from 'react'

export const EntreM = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.64645 16.3539C8.45118 16.1586 8.45118 15.842 8.64645 15.6468L11.8284 12.4648C12.0237 12.2695 12.3403 12.2695 12.5355 12.4648C12.7308 12.6601 12.7308 12.9767 12.5355 13.1719L10.2071 15.5003H21.7929L19.4645 13.1719C19.2692 12.9767 19.2692 12.6601 19.4645 12.4648C19.6597 12.2695 19.9763 12.2695 20.1716 12.4648L23.3536 15.6468C23.5488 15.842 23.5488 16.1586 23.3536 16.3539L20.1716 19.5359C19.9763 19.7311 19.6597 19.7311 19.4645 19.5359C19.2692 19.3406 19.2692 19.024 19.4645 18.8288L21.7929 16.5003H10.2071L12.5355 18.8288C12.7308 19.024 12.7308 19.3406 12.5355 19.5359C12.3403 19.7311 12.0237 19.7311 11.8284 19.5359L8.64645 16.3539Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.5 10C4.77614 10 5 10.2442 5 10.5455L5 21.4545C5 21.7558 4.77614 22 4.5 22C4.22386 22 4 21.7558 4 21.4545L4 10.5455C4 10.2442 4.22386 10 4.5 10Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M27.5 10C27.7761 10 28 10.2442 28 10.5455V21.4545C28 21.7558 27.7761 22 27.5 22C27.2239 22 27 21.7558 27 21.4545V10.5455C27 10.2442 27.2239 10 27.5 10Z" fill="#635F76"/>
    </svg>
  )
}
