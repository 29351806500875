import axios from "axios";
import conf from "../../app/config";

const preguntas = axios.create({
  baseURL: conf("BASE_URL") + "preguntas/",
});

const imagenes = axios.create({
  baseURL: conf("BASE_URL") + "imagenes/",
});

const adjuntos = axios.create({
  baseURL: conf("BASE_URL") + "adjuntos/",
});

const externos = axios.create({
  baseURL: conf("BASE_URL") + "externos/",
});

const createFormData = (data: any) => {
  const formData = new FormData();

  Object.entries(data).forEach(([k, v]) => {
    if (Array.isArray(v)) {
      //@ts-ignore
      v.forEach((e) => formData.append(k, e));
    } else {
      //@ts-ignore
      formData.append(k, v);
    }
  });

  return formData;
};

export const getPregunta = (id: any) => {
  return preguntas.post("siguiente", { id }).then((res) => res);
};

export const getPreguntaAnterior = (id: any) => {
  return preguntas.post("anterior", { id }).then((res) => res);
};

export const obtenerPregunta = (id: any) => {
  return preguntas.post("obtener", { id }).then((res) => res);
};

export const obtenerPreguntaStatus = (id: any) => {
  return preguntas.post("status", { id }).then((res) => res);
};

export const postRespuesta = (data: any) => {
  return preguntas.post("confirmar", data).then((res) => res);
};

export const validarCodigo = (code: any) => {
  return preguntas.post("validarCodigo", code).then((res) => res);
};

export const empezarInspeccion = (data: any) => {
  return preguntas.post("empezar", data).then((res) => res);
};

export const cambiarCoordenadas = (data: any) => {
  return preguntas.post("newcoords", data).then((res) => res);
};

export const subirArchivo = (data: any) => {
  return adjuntos
    .post("subir", createFormData(data))
    .then((res) => res)
    .catch((err) => err);
};

export const subirImagen = (data: any) => {
  return imagenes
    .post("subir", createFormData(data))
    .then((res) => res)
    .catch((err) => err);
};

export const subirGrupoImagen = (data: any) => {
  return imagenes
    .post("subirPorGrupo", createFormData(data))
    .then((res) => res);
};

export const confirmarFormulario = (id: any) => {
  return preguntas.post("confirmarInspeccion", { id }).then((res) => res);
};

export const borrarImagen = (id: any) => {
  return imagenes.post("borrarImagen", { id }).then((res) => res);
};

export const resetImagen = (id: any) => {
  return imagenes.post("resetImagen", { id }).then((res) => res);
};

export const volverLocal = (id: any) => {
  return preguntas.post("revisar", { id }).then((res) => res);
};

export const obtenerCalles = (text: string, inspeccionId: number) => {
  return externos
    .get(`obtenerDatosDireccion?direccion=${text}&inspeccionId=${inspeccionId}`)
    .then((res) => res);
};

export const validarDireccion = (data: any) => {
  return externos
    .get(
      `validarDirYObtenerVertical?cod_ine=${data.cod_ine}&cod_via=${data.cod_via}&numero=${data.numero}&inspeccionId=${data.inspeccionId}`
    )
    .then((res) => res);
};

export const obtenerBloques = (inspeccionId: any) => {
  return externos
    .get(`obtenerBloques?inspeccionId=${inspeccionId}`)
    .then((res) => res);
};

export const obtenerEscaleras = (data: any) => {
  return externos
    .get(
      `obtenerEscaleras?inspeccionId=${data.inspeccionId}&bloque=${data.bloque}`
    )
    .then((res) => res);
};

export const obtenerPlantas = (data: any) => {
  return externos
    .get(
      `obtenerPlantas?inspeccionId=${data.inspeccionId}&bloque=${data.bloque}&escalera=${data.escalera}`
    )
    .then((res) => res);
};

export const obtenerPuertas = (data: any) => {
  return externos
    .get(
      `obtenerPuertas?inspeccionId=${data.inspeccionId}&bloque=${data.bloque}&escalera=${data.escalera}&planta=${data.planta}`
    )
    .then((res) => res);
};

export const aceptarTerminosYCondiciones = (inspeccionId: string) => {
  return preguntas
    .post("/aceptarTerminosYCondiciones", { id: inspeccionId })
    .then((res) => res);
};

export const aceptarTerminosYCondicionesAseguradora = (inspeccionId: string) => {
  return preguntas
    .post("/aceptarTerminosYCondicionesAseguradora", { id: inspeccionId })
    .then((res) => res);
};

export const terminosYCondicionesStatus = (inspeccionId: string) => {
  return preguntas
    .post("/terminosYCondicionesStatus", { id: inspeccionId })
    .then((res) => res);
};

export const titulosOnboarding = (inspeccionId: string) => {
  return preguntas
    .post("/textosOnboarding", { id: inspeccionId })
    .then((res) => res);
};

export const politicasDePrivacidadCustom = (inspeccionId: string) => {
  return preguntas
    .post("/politicasDePrivacidadCustom", { id: inspeccionId })
    .then((res) => res);
};
interface Coords {
  lat: number;
  lng: number;
}

const toDirectionData = (coords: Coords, data: any) => {
  const pais = data.results[0].address_components.find((a: any) =>
    a.types.includes("country")
  )?.long_name;
  const provincia = data.results[0].address_components.find((a: any) =>
    a.types.includes("administrative_area_level_1")
  )?.long_name;
  const ciudad = data.results[0].address_components.find((a: any) =>
    a.types.includes("locality")
  )?.long_name;
  const calle = data.results[0].address_components.find((a: any) =>
    a.types.includes("route")
  )?.long_name;
  const numero = data.results[0].address_components.find((a: any) =>
    a.types.includes("street_number")
  )?.long_name;
  const codigoPostal = data.results[0].address_components.find((a: any) =>
    a.types.includes("postal_code")
  )?.long_name;

  return {
    direction: data.results[0].formatted_address,
    pais: pais || "",
    provincia: provincia || "",
    ciudad: ciudad || "",
    calle: calle || "",
    numero: numero || "",
    codigoPostal: codigoPostal || "",
    coordinates: coords,
  };
};

export const getDirectionByCoordinates = async (
  coords: Coords,
  apiKey: string
) => {
  console.log("coordenadas google", coords);
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.lat},${coords.lng}&key=${apiKey}`;
  try {
    const results = await axios.get(url);
    return toDirectionData(coords, results.data);
  } catch (error) {
    console.log(error);
  }
};
//

export const getMapDirectionSuggestion = (userInput: string) => {
  return externos
    .post("/obtenerDireccionSugeridaGoogle", { texto: userInput })
    .then((res) => res);
};

export const getInspeccion = (id: number) => {
  return axios
    .post("inspecciones/detalle", { id })
    .then((res) => res);
};

export const getDirectionByAddress = async (
  address: string,
  apiKey: string
) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`;
  try {
    const results = await axios.get(url);
    return results;
  } catch (error) {
    console.log(error);
  }
};

export const redirectToEvaluacion = async (url: string) => {
  return axios
    .get(url)
    .then((r) => r.data)
    .catch((e) => console.log(e));
};
