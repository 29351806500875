import { Modal } from "@mui/material";
import React, { Dispatch} from "react";

import "./LocationModal.scss";

export interface locationModal {
    open: boolean, 
    msg: string,
  }
  
  export interface LocationModalProps {
    geolocationModal: locationModal,
    setGeolocationModal : Dispatch<React.SetStateAction<locationModal>>
  }
  
  const positionError = (error: any, setGeolocationModal: Dispatch<React.SetStateAction<locationModal>>) => {
    if (error.PERMISSION_DENIED) {
      console.log("Error: permission denied");
      setGeolocationModal({open: true, msg: "Para continuar, activa la localización en los ajustes de tu dispositivo o habilita la localización en la configuración de tu navegador." });
    } else {
      setGeolocationModal({open: true, msg: "Lo sentimos. Un error ha ocurrido y no podemos obtener tu localización. Para continuar es imprescindible geolocalizarte. Por favor, intentálo más tarde o ponte en contacto con tu mediador." });
    }
  };
  
  export const getCoordinates = (setGeolocationModal: Dispatch<React.SetStateAction<locationModal>>) => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(pos => resolve(pos), error =>  positionError(error, setGeolocationModal), {enableHighAccuracy: true})
    })
  }

  export const getCoordinates2 = (setGeolocationModal: Dispatch<React.SetStateAction<locationModal>>) => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(pos => resolve(pos), error =>  resolve(null), {enableHighAccuracy: true})
    })
  }
  
  export const LocationModal = ({
    geolocationModal,
    setGeolocationModal,
    }: LocationModalProps) => 
    <Modal open={false} onClose={() => setGeolocationModal({open: false, msg: ""})}>
      <div className="gps-error">
        {geolocationModal.msg}
        <button onClick={() => setGeolocationModal({open: false, msg: ""})}>OK</button>
      </div>
    </Modal>
  
  
  