import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearToggleButtonValues, selectPreguntas } from "../../features/LayoutPregunta/preguntaSlice";
import { OpcionDto } from "../../model/PreguntasDto";
import './ToggleButtonList.scss';
interface IProps {
  children: any;
  exclusive?: boolean;
  value: any;
  onChange: any;
  path?: number[]
}

const isValueSelected = (value: any, selection: any) => {
  if (selection === undefined || value === undefined) {
    return false;
  }

  if (Array.isArray(selection)) {
    return selection.indexOf(value) >= 0;
  }

  return value === selection;
};

export const ToggleButtonList = ({
  children,
  exclusive,
  value,
  onChange,
  path,
}: IProps) => {
  const handleChangeExclusive = (childValue: string, index: number) => {
    if (childValue !== value) onChange(childValue, index);
  };

  const preguntas = useSelector(selectPreguntas)
  const dispatch = useDispatch()
  const [isNingunoSelected, setIsNingunoSelected] = useState(false)

  const isOptionNingunoSelected = () => {
    const opcionesSeleccionadas = preguntas.items[0].opciones.filter((o: OpcionDto) => o.selected)
    const opcionNingunoSeleccionada = opcionesSeleccionadas.filter((o: OpcionDto) => o.valor === "Ninguno")
    return opcionNingunoSeleccionada.length > 0
  }

  useEffect(() => {
    setIsNingunoSelected(isOptionNingunoSelected())
  }, [preguntas])

  const handleChange = (childValue: string, index :number) => {
    let newValue;
    if (value?.some((v: any) => v === childValue)) {
      newValue = value.filter((v: any) => v !== childValue);
    } else {
      newValue = [...value, childValue];
    }
    
    if(childValue === 'Ninguno') {
      dispatch(clearToggleButtonValues({itemIndex: path, optionIndex: index}))
    }
    
    if(childValue !== 'Ninguno' && isNingunoSelected) {
      return
    }

    onChange(newValue, index);
  };

  return (
    <div className="toggle-button-list">
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            // @ts-ignore
            onChange: exclusive ? handleChangeExclusive : handleChange,
            selected:
            // @ts-ignore
              child.props.selected === undefined
              // @ts-ignore
                ? isValueSelected(child.props.value, value)
                // @ts-ignore
                : child.props.selected,
          });
        }
        return child;
      })}
    </div>
  );
};
