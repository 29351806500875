import React from "react";
import { Icons } from "../../Assets/Icons";
import { formatSiNo } from "../../utils";
import "./ToggleButtons.scss";

interface IProps {
  value: any;
  onChange?: any;
  selected?: boolean;
  children?: any;
  icon?: React.ReactElement;
  subtitle?: string;
  index: number;
  path?: number[];
  backgroundColor?: string;
  hideCheckbox?: boolean;
}

const icons = Icons();

export const ToggleButtons = ({
  value,
  onChange,
  selected,
  children,
  icon,
  subtitle,
  index,
  path,
  backgroundColor = "#ffffff",
  hideCheckbox
}: IProps) => {
  const CheckBox = () => {
    return (
      <div className="toggle-buttons-checkbox">
        <label>
          <input type="checkbox" />
          <svg
            className={`toggle-buttons-checkbox-icon ${
              selected ? "toggle-buttons-checkbox-icon-active" : ""
            }`}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.99994 13L8.99994 18L19.9999 7.00001"
              stroke={selected ? "#ffffff" : "none"}
              strokeWidth="4"
              strokeLinecap="round"
            />
          </svg>
        </label>
      </div>
    );
  };

  return (
    <div className="toggle-buttons" style={{ background: backgroundColor }}>
      <div
        className="toggle-buttons-option"
        onClick={() => onChange(value, index)}
      >
        <div className="toggle-buttons-option-label">
          {/*@ts-ignore*/}
          <div>{icon ? icons[icon] : null}</div>
          <div className="toggle-buttons-option-label-text">
            {formatSiNo(value)}
            <span>{subtitle}</span>
          </div>
        </div>
        {hideCheckbox ? null : <CheckBox />}
      </div>
      {selected &&
      (Array.isArray(children) ? children.some((c) => c) : children) ? (
        <div className={`toggle-buttons-expanded ${hideCheckbox ? 'always-expanded-card' : ''}`}>{children}</div>
      ) : null}
    </div>
  );
};
