import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import { JsonAnimation } from "../JsonAnimation/JsonAnimation";
import "./ModalConexion.scss";
import { CustomButton } from "../CustomButton/CustomButton";
import { Error } from "../../Assets/svgs/Error";

export const ModalConexion = (props: any) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
      setOpen(props.open)
  }, [props])

  const handleClick = () => {
    props.onClick(false)
  }

  return (
    <Modal open={open}>
      <div className="conexion">
        <Error />
        <p>No se puede continuar debido a que se encuentra <b>sin conexión</b> a la red.</p>
        <CustomButton
          type="primary"
          text="OK"
          onClick={handleClick}
        />
      </div>
    </Modal>
  );
};
