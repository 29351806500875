import dayjs from "dayjs";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BienesList } from "../../Components/BienesList/BienesList";
import { BlockTitle } from "../../Components/BlockTitle/BlockTitle";
import { ChoiceCard } from "../../Components/ChoiceCard/ChoiceCard";
import { CustomButton } from "../../Components/CustomButton/CustomButton";
import { CustomCheckbox } from "../../Components/CustomCheckbox/CustomCheckbox";
import { CustomInput } from "../../Components/CustomInput/CustomInput";
import { DateInput } from "../../Components/DateInput/DateInput";
import { Description } from "../../Components/Description/Description";
import { ExclusiveToggleButtons } from "../../Components/ExclusiveToggleButtons/ExclusiveToggleButtons";
import { ImageBlock } from "../../Components/ImageBlock/ImageBlock";
import { InfoCard } from "../../Components/InfoCard/InfoCard";
import { InputCurrency } from "../../Components/InputCurrency/InputCurrency";
import { ModalAyuda } from "../../Components/ModalAyuda/ModalAyuda";
import { ModalEspera } from "../../Components/ModalEspera/ModalEspera";
import { MultipleToggleButtons } from "../../Components/MultipleToggleButtons/MultipleToggleButtons";
import { Notification } from "../../Components/Notification/Notification";
import { NumberInput } from "../../Components/NumberInput/NumberInput";
import { SubBlockTitle } from "../../Components/SubBlockTitle/SubBlockTitle";
import { Subtitle } from "../../Components/Subtitle/Subtitle";
import { YearDropdownInput } from "../../Components/YearDropdownInput/YearDropdownInput";
import { TipoItem } from "../../model/tipoItemEnum";
import "./LayoutPregunta.scss";
import { postRespuesta, subirImagen } from "./PreguntasAPI";
import {
  getPreguntas,
  obtenerPreguntasStatus,
  postGrupoImagenes, selectBienes,
  selectId,
  selectPreguntas,
  selectStatusGetPreguntas,
  selectUploadStatus,
  validarCamposObligatorios
} from "./preguntaSlice";
import { DropdownInput } from "../../Components/DropdownInput/DropdownInput";
import { OpcionDto } from "../../model/PreguntasDto";
import { ExpandedToggleButtons } from "../../Components/ExpandedToggleButtons/ExpandedToggleButtons";
import TextArea from "../../Components/TextArea/TextArea";
import { ModalConexion } from "../../Components/ModalConexion/ModalConexion";
import AttachFile from "../../Components/AttachFile/AttachFile";

const LayoutPregunta = () => {
  const dispatch = useDispatch();
  const preguntas = useSelector(selectPreguntas);
  const preguntaId: any = useSelector(selectId);
  const bienes = useSelector(selectBienes);
  const status = useSelector(selectUploadStatus);
  const [openAyuda, setOpenAyuda] = useState(false);
  const [openEspera, setOpenEspera] = useState(false)
  const [loading, setLoading] = useState(false);
  const [noConnection, setNoConnection] = useState(false)
  const [uploadError, setUploadError] = useState(false);
  const statusSiguientePregunta = useSelector(selectStatusGetPreguntas);
  const obligatorios = useSelector(validarCamposObligatorios);
  const containerRef = useRef<any>()

  //@ts-ignore
  const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

  useEffect(() => {
    window.scrollTo(0, 0);
    containerRef?.current?.scrollTo(0, 0)
    //@ts-ignore
    dispatch(obtenerPreguntasStatus(preguntaId))
  }, [preguntas.id]);

  const toBienesData = (data: any[], id: number) => {
    return data.reduce(
      (acc: any, d: any) => {
        return {
          ...acc,
          imagenes: [...acc.imagenes, d.imagen],
          detalles: [...acc.detalles, d.detalle],
          importes: [...acc.importes, d.importe],
        };
      },
      { id, imagenes: [], detalles: [], importes: [] }
    );
  };

  const toPhotosData = (data: any[], id: number) => {
    return data.reduce(
      (acc: any, d: any) => {
        return {
          ...acc,
          imagenes: [...acc.imagenes, d.imagen],
          ids: [...acc.ids, d.imagenId],
          idsGrupo: [...acc.idsGrupo, d.grupoImagenId],
        };
      },
      { id, imagenes: [], ids: [], idsGrupo: [] }
    );
  };

  const handleSiguiente = async () => {
    if (preguntas.items[0].tipoItem === TipoItem.listaBienes) {
      setLoading(true);
      setOpenEspera(true)
      setUploadError(false);

      if (bienes.length) {
        let results: any = [];
        const photos = bienes.map((b: any, index: number) => {
          return {
            id: preguntas.items[0].id,
            imagenes: [b.imagen],
            detalles: [b.detalle],
            importes: [b.importe],
            esUltima: index === bienes.length - 1,
          };
        });

        for (let i = 0; i < photos.length; i++) {
          // @ts-ignore
          try {
            const response = await subirImagen(photos[i]);
            results = [...results, response];
          }
          catch(error) {
            console.log(error)
          }
        }

        const badStatus = results.some((r: any) => {
          return r.status !== 200;
        });

        //Si todas las imagenes dieron OK, el postRespuesta(preguntas) YA lo hizo la API de imagenes
        if (!badStatus) {
          // @ts-ignore
          dispatch(getPreguntas(preguntaId));
        } else {
          //Alguna imagen fallo y no se proceso postPregunta
          setUploadError(true);
          console.log('entre else')
        }
      } else {
        const response = await postRespuesta(preguntas);
        if (response.status === 200)
        // @ts-ignore
          dispatch(getPreguntas(preguntaId));
      }
      setOpenEspera(false);
    } else if (preguntas.items[0].tipoItem === TipoItem.BloqueImagenes) {
      const response: any = await dispatch(
        // @ts-ignore
        postGrupoImagenes(toPhotosData(bienes, preguntas.items[0].id))
      );
      if (response.payload.status === 200) {
        // @ts-ignore
        dispatch(getPreguntas(preguntaId));
      }
    } else {
      setLoading(true)
      const response = await postRespuesta(preguntas);
      if (response.status === 200)
        // @ts-ignore
        dispatch(getPreguntas(preguntaId));
    }
  };

  useEffect(() => {
    if(statusSiguientePregunta !== "loading")
      setLoading(false)
  }, [statusSiguientePregunta])

  const renderInputs = (e: any, index: number) => {
    switch (e.tipoItem) {
      case TipoItem.InputNumber: {
        return (
          <>
            {/* <InfoCard consigna={e.consigna} items={e.opciones} /> */}
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <NumberInput
              key={e.keyId}
              index={index}
              label={e.placeholder}
              prefetchedValue={e.valor}
            />
          </>
        );
      }
      case TipoItem.InputDate: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <DateInput
              key={e.keyId}
              index={index}
              label={e.placeholder}
              prefetchedValue={e.valor && dayjs(e.valor).format("YYYY-MM-DD")}
            />
          </>
        );
      }
      case TipoItem.DropdownInputYear: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <YearDropdownInput
              prefetchedValue={e.valor}
              index={index}
              key={e.keyId}
              label={e.placeholder}
            />
          </>
        );
      }
      case TipoItem.OptionMultipleChoice: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <ExclusiveToggleButtons items={e} path={[index]} key={e.keyId} />
          </>
        );
      }
      case TipoItem.CardConOpciones: {
        return (
          <>
            <ChoiceCard item={e} path={[index]} key={e.keyId} />
          </>
        );
      }
      case TipoItem.SimpleOption: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <MultipleToggleButtons items={e} path={[index]} key={e.keyId} />
          </>
        );
      }
      case TipoItem.ExpandedToggleButtons: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <ExpandedToggleButtons items={e} path={[index]} key={e.keyId} />
          </>
        );
      }
      case TipoItem.InputText: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <CustomInput
              type="text"
              key={e.keyId}
              index={index}
              label={e.placeholder}
              prefetchedValue={e.valor}
            />
          </>
        );
      }
      case TipoItem.InputMovil: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <CustomInput
              type="tel"
              key={e.keyId}
              index={index}
              label={e.placeholder}
              prefetchedValue={e.valor}
            />
          </>
        );
      }
      case TipoItem.InputMail: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <CustomInput
              type="email"
              key={e.keyId}
              index={index}
              label={e.placeholder}
              prefetchedValue={e.valor}
            />
          </>
        );
      }
      case TipoItem.Checkbox: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <CustomCheckbox
              label={e.placeholder}
              selected={e.valor}
              choiceIndex={index}
              singleCheckbox
              key={e.keyId}
            />
          </>
        );
      }
      case TipoItem.listaBienes: {
        return (
          <>
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            {uploadError 
              ? <Notification text="La imagen que se ha intentado subir es muy grande, por favor intente reduciendo la calidad de imagen desde la configuración de su cámara. Máximo permitido: 10 MB por imagen." icon="warning" />
              : null
            }
            <BienesList key={e.keyId} buttonText={e.etiqueta} hasUnconfirmedCards={(e: boolean) => console.log(e)}/>
          </>
        );
      }
      case TipoItem.BloqueImagenes: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <ImageBlock items={e} key={e.keyId} />
          </>
        );
      }
      case TipoItem.CurrencyInput: {
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <InputCurrency
              key={e.keyId}
              index={index}
              label={e.placeholder}
              prefetchedValue={e.valor}
            />
          </>
        );
      }
      case TipoItem.CardSuperficie: {
        return <InfoCard consigna={e.consigna} items={e.opciones} />;
      }
      case TipoItem.DropdownInput: {
        const options = e.opciones.map((o: OpcionDto) => o.titulo);
        return (
          <>
            {e.etiqueta.length ? <SubBlockTitle text={e.etiqueta} /> : null}
            {e.consigna.length ? <Description text={e.consigna} /> : null}
            <DropdownInput
              options={options}
              type="text"
              prefetchedValue={e.valor}
              index={index}
              key={e.keyId}
              label={e.placeholder}
            />
          </>
        );
      }
      case TipoItem.TextArea: {
        return <TextArea consigna={e.consigna} etiqueta={e.etiqueta} index={index} defaultValue={e.valor}/>;
      }
    }
  };

  const renderContent = () => {
    return (
      <>
        <div>
          <div className="layout-pregunta-content-title">
            <BlockTitle
              text={preguntas.titulo}
              icon={preguntas.iconoPrincipal}
              ayuda={preguntas.ayuda ? preguntas.ayuda : null}
              fn={setOpenAyuda}
            />
          </div>
          {preguntas.subtitulo ? <Subtitle text={preguntas.subtitulo} /> : null}
        </div>
        {preguntas.titulo1 ? <SubBlockTitle text={preguntas.titulo1} /> : null}
        {preguntas.texto ? <Description text={preguntas.texto} /> : null}
        {preguntas.items?.map((e: any, index: number) => {
          return <Fragment key={e.id} >{renderInputs(e, index)}</Fragment>;
        })}
        {preguntas.tip ? (
          <Notification text={preguntas.tip} icon={preguntas.iconoTip} />
        ) : null}
      </>
    );
  };

  const handleContinue = () => {
    const online = window.navigator.onLine
    if(!online) {
      setNoConnection(true);
      return; 
    }
    if (loading) {
      return () => null;
    } else {
      return handleSiguiente()
    }
  }

  return (
    <>
      <div className="layout-pregunta" ref={containerRef}>
        <div className="layout-pregunta-content">{renderContent()}</div>
      </div>
      <div className="layout-pregunta-content-actions">
        <CustomButton
          type="primary"
          text="Continuar"
          loading={loading}
          disable={!obligatorios}
          onClick={handleContinue}
        />
        {openAyuda ? (
          <ModalAyuda
            open={openAyuda}
            texto={preguntas.ayuda.texto}
            titulo={preguntas.ayuda.titulo}
            imagen={preguntas.ayuda.imagen}
            onClose={() => setOpenAyuda(false)}
          />
        ) : null}
        <ModalEspera key={preguntas.keyId} open={openEspera} />
        <ModalConexion open={noConnection} onClick={(e: boolean) => setNoConnection(e)} />
      </div>
    </>
  );
};

export default LayoutPregunta;