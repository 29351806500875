import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ErrorIcon } from "../../Assets/svgs/ErrorIcon";
import { changeValue } from "../../features/LayoutPregunta/preguntaSlice";
import CurrencyInput from "react-currency-input-field";
import "./InputCurrency.scss";
import { formatDecimalComma, formatDecimalDot } from "../../utils/currencyHelpers";

interface IProps {
  id?: number;
  label: string;
  prefetchedValue?: any;
  disable?: boolean;
  error?: string;
  index?: number;
  length?: number;
  onChange?: any;
  defaultValue?: any;
  isSuboption?: boolean;
  indexSubpregunta?: any;
  bienes?: boolean;
}

export const InputCurrency = ({
  index,
  label,
  disable,
  prefetchedValue,
  error,
  length,
  onChange,
  defaultValue = "",
  isSuboption,
  indexSubpregunta,
  bienes,
}: IProps) => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (prefetchedValue) {
      if(!isSuboption) dispatch(changeValue({ value: prefetchedValue, index: index }))
      setValue(prefetchedValue);
    }
  }, []);

  useEffect(() => {
    if (defaultValue) {
      const number = isNaN(defaultValue) ? defaultValue : defaultValue.toString()
      setValue(formatDecimalComma(number));
    }
  }, [defaultValue])

  const handleChange = (e: any) => {
    const value = e || "";
    setValue(value);
    if(indexSubpregunta || indexSubpregunta === 0) {
      onChange(formatDecimalDot(value), index, indexSubpregunta.indexSub, indexSubpregunta.indexOpcion)
      return;
    }
    if(onChange) onChange(formatDecimalDot(value), index)
    if(!isSuboption && !bienes) dispatch(changeValue({ value: formatDecimalDot(value), index: index }))
  };

  return (
    <div className="custom-input">
      <CurrencyInput
        disabled={disable}
        className={`custom-input-input ${error ? 'input-error' : ''}`}
        decimalsLimit={2}
        onValueChange={(value: any) => handleChange(value)}
        decimalSeparator=","
        groupSeparator="."
        suffix="€"
        value={value}
      />
      <label className={value && "filled"}>{label}</label>
      {error ? <span className="error-message-input"><ErrorIcon />{error}</span> : null}
    </div>
  );
};
