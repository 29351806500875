import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlockTitle } from "../../Components/BlockTitle/BlockTitle";
import {
  CustomButton,
  debounce,
} from "../../Components/CustomButton/CustomButton";
import { CustomInput } from "../../Components/CustomInput/CustomInput";
import { Description } from "../../Components/Description/Description";
import { SubBlockTitle } from "../../Components/SubBlockTitle/SubBlockTitle";
import "./DatosEmpresa.scss";
import {
  getDirectionByAddress,
  getDirectionByCoordinates,
  getMapDirectionSuggestion,
  postRespuesta,
} from "../LayoutPregunta/PreguntasAPI";
import {
  changeValue,
  selectPreguntas,
  validarCamposObligatorios,
  getPreguntas,
  selectId,
  selectCoords,
} from "../LayoutPregunta/preguntaSlice";
import airaMarker from "../../Assets/svgs/Icon_mapa_aira.svg";
import { ErrorIcon } from "../../Assets/svgs/ErrorIcon";
import { Search } from "../../Assets/svgs/Search";
import { Autocomplete } from "@mui/material";
import { ModalConexion } from "../../Components/ModalConexion/ModalConexion";

const apiKey = "AIzaSyC8roI-M_UX76Xyxn1izKZcCzXhGTnXILI";

const DireccionMaps = () => {
  const [map, setMap] = useState<any>(null);
  const [center, setCenter] = useState<any>(null);
  const [direction, setDirection] = useState<string>("");
  const [directionToSearch, setDirectionToSearch] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);
  const preguntas = useSelector(selectPreguntas);
  const preguntaId: any = useSelector(selectId);
  const obligatorios = useSelector(validarCamposObligatorios);
  const coordenadas = useSelector(selectCoords);
  const [directionOptions, setDirectionOptions] = useState([]);
  const [noConnection, setNoConnection] = useState(false)
  const dispatch = useDispatch();

  const getOptionsDelayed = useCallback(
    debounce((texto: string, callback: any) => {
      /* setCallesOptions([]); */
      getMapDirectionSuggestion(texto).then((response: any) => {
        callback(response);
      });
    }, 200),
    []
  );

  const toOptions = (searchResults: any) => {
    return searchResults.data.predictions.map((p: any) => p.description);
  };

  useEffect(() => {
    getOptionsDelayed(directionToSearch, (e: any) => {
      setDirectionOptions(toOptions(e));
    });
  }, [directionToSearch]);

  const containerStyle = {
    width: "97%",
    height: "345px",
    display: "flex",
    alignSelf: "center",
    borderRadius: "10px",
  };

  const handleSiguiente = async () => {
    setLoading(true);
    const response = await postRespuesta(preguntas);
    if (response.status === 200)
      // @ts-ignore
      dispatch(getPreguntas(preguntaId));
  };

  const handleCenterChange = () => {
    if (map) {
      const newCenter = map.getCenter().toJSON();
      setCenter(newCenter);
    }
  };

  const getDirection = async () => {
    const direction = await getDirectionByCoordinates(center, apiKey);
    if (direction) {
      setDirectionToSearch(direction.direction);
      dispatch(changeValue({ index: 0, value: JSON.stringify(direction) }));
    }
  };

  useEffect(() => {
    if(!preguntas.confirmada) { 
      const coords = coordenadas
        ? { lat: Number(coordenadas.lat), lng: Number(coordenadas.lng) }
        : preguntas.items.length && JSON.parse(preguntas.items[0].etiqueta);
      setCenter(coords);
    } else {
      const valor = JSON.parse(preguntas.items[0].valor)
      setCenter(valor.coordinates)
    }
  }, []);

  useEffect(() => {
    getDirection();
  }, [center]);

  // const handleMarkerDragEnd = (event: google.maps.MapMouseEvent) => {
  //   setCenter(event.latLng?.toJSON());
  // };

  const handleInputChange = async (e: any) => {
    setDirectionToSearch(e);
    await handleInputSubmit(e)
  };

  const handleInputSubmit = async (direction: string) => {
    const direccion = await getDirectionByAddress(direction, apiKey);
    if (direccion) {
      const coords = direccion.data.results[0].geometry.location;
      setCenter(coords);
    }
  };

  const renderNoMap = () => {
    return (
      <div className="empty-map">
        <p>Introduce tu dirección para activar el mapa</p>
      </div>
    );
  };

  const renderInputs = (e: any, index: number) => {
    return (
      <>
        <div className="google-map">
          <LoadScript
            googleMapsApiKey={apiKey}
            onLoad={() => setIsGoogleLoaded(true)}
          >
            <div className="custom-input input-mapa">
              <Autocomplete
                id="calle-combo-box"
                key="combo-calle"
                options={directionOptions}
                getOptionLabel={(option: any) => option}
                value={directionToSearch}
                onChange={(e, v: any) => handleInputChange(v)}
                renderInput={(params: any) => {
                  return (
                    <div
                      className="dropdown-input-container"
                      ref={params.InputProps.ref}
                    >
                      <input
                        {...params.inputProps}
                        className={`dropdown-input-input`}
                        type="text"
                        key="combo-calle-input"
                        autoComplete="off"
                        value={directionToSearch}
                        onChange={(e) => {
                          setDirectionToSearch(e.target.value);
                        }}
                      />
                      <label className={params.inputProps.value && "filled"}>
                        Dirección
                      </label>
                      <button
                        className="input-mapa-button"
                        disabled={!directionToSearch}
                        onClick={() => handleInputSubmit(directionToSearch)}
                      >
                        <h3>Ir</h3>
                      </button>
                    </div>
                  );
                }}
              />
            </div>
            {center && center.lat ? (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={18}
                onLoad={(map) => setMap(map)}
                onDragEnd={handleCenterChange}
                options={{
                  disableDefaultUI: false,
                  streetViewControl: false,
                  fullscreenControl: false,
                  mapTypeId: 'satellite',
                  tilt: 0,
                }}
              ></GoogleMap>
            ) : (
              renderNoMap()
            )}
          </LoadScript>
          {center && center.lat ? (
            <img
              className="google-map-marker"
              src={airaMarker}
              alt="aira-marker"
            />
          ) : null}
        </div>
      </>
    );
  };

  const handleContinue = () => {
    const online = window.navigator.onLine
    if(!online) {
      setNoConnection(true);
      return; 
    }
    if (loading) {
      return () => null;
    } else {
      return handleSiguiente()
    }
  }

  return (
    <>
      <div className="datos-empresa">
        <div className="datos-empresa-content" style={{ paddingBottom: 0 }}>
          <BlockTitle icon={preguntas.iconoPrincipal} text={preguntas.titulo} />
          <div className="datos-empresa-text">
            <SubBlockTitle text={preguntas.titulo1} />
            <Description text={preguntas.texto} />
          </div>
          <div className="datos-empresa-inputs dropdown-input">
            {preguntas.items?.map((e: any, index: number) => {
              return <Fragment key={e.id}>{renderInputs(e, index)}</Fragment>;
            })}
          </div>
        </div>
      </div>
      <div className="layout-pregunta-content-actions">
        <CustomButton
          type="primary"
          text="Continuar"
          loading={loading}
          disable={!obligatorios}
          onClick={handleContinue}
        />
      </div>
      <ModalConexion open={noConnection} onClick={(e: boolean) => setNoConnection(e)} />
    </>
  );
};

export default React.memo(DireccionMaps);
