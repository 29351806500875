import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { handleValue } from "../../features/LayoutPregunta/preguntaSlice";
import { formatSiNo } from "../../utils";
import { CustomCheckbox } from "../CustomCheckbox/CustomCheckbox";
import "./MultipleChoice.scss";

export const MultipleChoice = (props: any) => {
  const [value, setValue] = useState(true)
  const dispatch = useDispatch()

  const onChange = (id: number, index: number, checked: any) => {
    dispatch(handleValue({ path: [...props.path, "subopciones", index, "selected"], value: checked }))
  }

  const onChangeSubPregunta = (id: number, index: number, checked: any) => {
    dispatch(handleValue({ path: [...props.path, "opciones", index, "selected"], value: checked }))
  }

  const onTextareaChange = (id: number, index: number, value: string) => {
    dispatch(handleValue({ path: [...props.path, "subopciones", index, "comentarios"], value: value }))
  }

  const onTextareaChangeSubpregunta = (id: number, index: number, value: string) => {
    dispatch(handleValue({ path: [...props.path, "opciones", index, "comentarios"], value: value }))
  }

  const renderMultipleChoice = (data: any) => {
    return (
      <>
        <p>{props.titulo}</p>
        <ul>
          {props.items.map((o: any, index: number) => (
            <li key={o.id}>
              <CustomCheckbox
                label={formatSiNo(o.titulo)}
                choiceIndex={index}
                rounded={true}
                itemId={o.id}
                selected={o.selected}
                onChange={props.subpregunta ? onChangeSubPregunta : onChange}
              />
              {o.tieneComentarios && o.selected === true ?
              <textarea onChange={(e) => props.subpregunta ? onTextareaChangeSubpregunta(o.id, index, e.target.value): onTextareaChange(o.id, index, e.target.value) } className="text-area"/> 
              : null}
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <div className="multiple-choice">{renderMultipleChoice(props.items)}</div>
  );
};
