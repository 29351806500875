import React from "react";

const Textil = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3647_10192)">
        <path
          d="M9.99979 4.66699C9.99979 4.27143 9.88249 3.88475 9.66273 3.55585C9.44296 3.22695 9.13061 2.97061 8.76515 2.81923C8.3997 2.66786 7.99757 2.62825 7.60961 2.70542C7.22164 2.78259 6.86528 2.97307 6.58557 3.25278C6.30587 3.53248 6.11539 3.88885 6.03821 4.27681C5.96104 4.66477 6.00065 5.06691 6.15203 5.43236C6.3034 5.79781 6.55975 6.11017 6.88865 6.32993C7.21754 6.54969 7.60422 6.66699 7.99979 6.66699V8.00033M7.99979 8.00033L2.25045 11.659C2.15058 11.7225 2.07401 11.8167 2.03224 11.9274C1.99048 12.0381 1.98578 12.1594 2.01884 12.2731C2.05191 12.3867 2.12096 12.4865 2.21562 12.5576C2.31028 12.6286 2.42544 12.667 2.54379 12.667H13.4558C13.5741 12.667 13.6893 12.6286 13.7839 12.5576C13.8786 12.4865 13.9477 12.3867 13.9807 12.2731C14.0138 12.1594 14.0091 12.0381 13.9673 11.9274C13.9256 11.8167 13.849 11.7225 13.7491 11.659L7.99979 8.00033V8.00033Z"
          stroke="#635F76"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3647_10192">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Textil;
