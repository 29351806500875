export const validateDniFormat = (documento: string) => {
    //DNI, NIF, NIE
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lastCharacter = documento.slice(-1).toUpperCase();
    const firstCharacter = documento.slice(0, 1).toUpperCase();
    const digitQty = documento.match(/\d+/);
    const isValidDigitQty =
        (digitQty !== null && digitQty[0].length === 8) || (digitQty !== null && digitQty[0].length === 7);
    if (letters.includes(firstCharacter) && letters.includes(lastCharacter) && isValidDigitQty) {
        return true;
    } else if (letters.includes(lastCharacter) && isValidDigitQty) {
        return true;
    } else return false;
};

export const validateCifFormat = (documento: string) => {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const firstCharacter = documento.slice(0, 1).toUpperCase();
    const digitQty = documento.match(/\d+/);
    const isValidDigitQty = digitQty !== null && digitQty[0].length === 8;
    if (letters.includes(firstCharacter) && isValidDigitQty && documento.length === 9) {
        return true;
    } else {
        return false;
    }
};
