import React from "react";

const Aluminio = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8407 23.6161V28.6661H3.38074V23.6161"
        stroke="#635F76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.38074 16.8861H16.8407"
        stroke="#635F76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8407 16.8861L28.6107 5.11609"
        stroke="#635F76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.38074 16.886L15.3307 4.93604"
        stroke="#635F76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8407 28.6661L28.9907 16.5161"
        stroke="#635F76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3507 4.76611H28.6107V8.47611L16.8407 20.2561"
        stroke="#635F76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.38074 28.6661L15.1607 16.8861"
        stroke="#635F76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8407 16.8861V20.2561"
        stroke="#635F76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.38074 16.8861V20.2561L6.05074 19.2661"
        stroke="#635F76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.38074 23.616L5.06074 21.936"
        stroke="#635F76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8406 23.616L15.1606 21.936"
        stroke="#635F76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.06067 21.9361V20.2561"
        stroke="#635F76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1606 21.9361V20.2561"
        stroke="#635F76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.06067 20.2561L6.05067 19.2661L8.43067 16.8861"
        stroke="#635F76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1606 20.256L16.8406 18.566"
        stroke="#635F76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.0406 11.566V16.516"
        stroke="#635F76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.0407 11.5661L27.3507 9.88611"
        stroke="#635F76"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Aluminio;
