import React from 'react'

export const Urbana = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.2666 20.8C20.2666 20.5057 20.0277 20.2667 19.7333 20.2667C19.439 20.2667 19.2 20.5057 19.2 20.8V21.8667C19.2 22.1611 19.439 22.4 19.7333 22.4C20.0277 22.4 20.2666 22.1611 20.2666 21.8667V20.8Z" fill="#635F76"/>
        <path d="M19.7332 26.6667C19.4388 26.6667 19.1999 26.9056 19.1999 27.2V29.3333C19.1999 29.6277 19.4388 29.8666 19.7332 29.8666C20.0276 29.8666 20.2665 29.6277 20.2665 29.3333V27.2C20.2666 26.9056 20.0276 26.6667 19.7332 26.6667Z" fill="#635F76"/>
        <path d="M22.3999 14.4C22.3999 14.1056 22.161 13.8667 21.8666 13.8667C21.5722 13.8667 21.3333 14.1056 21.3333 14.4V15.4667C21.3333 15.7611 21.5722 16 21.8666 16C22.161 16 22.3999 15.7611 22.3999 15.4667V14.4Z" fill="#635F76"/>
        <path d="M20.2666 17.6C20.2666 17.3056 20.0277 17.0667 19.7333 17.0667C19.439 17.0667 19.2 17.3056 19.2 17.6V18.6667C19.2 18.961 19.439 19.2 19.7333 19.2C20.0277 19.2 20.2666 18.961 20.2666 18.6667V17.6Z" fill="#635F76"/>
        <path d="M20.2666 24C20.2666 23.7056 20.0276 23.4667 19.7333 23.4667C19.4389 23.4667 19.1999 23.7056 19.1999 24V25.0667C19.1999 25.361 19.4388 25.6 19.7332 25.6C20.0276 25.6 20.2665 25.361 20.2665 25.0667V24H20.2666Z" fill="#635F76"/>
        <path d="M22.3999 17.6C22.3999 17.3056 22.161 17.0667 21.8666 17.0667C21.5722 17.0667 21.3333 17.3056 21.3333 17.6V18.6667C21.3333 18.961 21.5722 19.2 21.8666 19.2C22.161 19.2 22.3999 18.961 22.3999 18.6667V17.6Z" fill="#635F76"/>
        <path d="M18.1333 14.4C18.1333 14.1056 17.8944 13.8667 17.6 13.8667C17.3056 13.8667 17.0667 14.1056 17.0667 14.4V15.4667C17.0667 15.7611 17.3056 16 17.6 16C17.8944 16 18.1333 15.7611 18.1333 15.4667V14.4Z" fill="#635F76"/>
        <path d="M20.2666 14.4C20.2666 14.1056 20.0277 13.8667 19.7333 13.8667C19.439 13.8667 19.2 14.1056 19.2 14.4V15.4667C19.2 15.7611 19.439 16 19.7333 16C20.0277 16 20.2666 15.7611 20.2666 15.4667V14.4Z" fill="#635F76"/>
        <path d="M22.3999 9.06668V7.99999C22.3999 7.70561 22.161 7.46667 21.8666 7.46667C21.5722 7.46667 21.3333 7.70561 21.3333 7.99999V9.06668C21.3333 9.36105 21.5722 9.59999 21.8666 9.59999C22.161 9.59999 22.3999 9.36105 22.3999 9.06668Z" fill="#635F76"/>
        <path d="M18.1333 17.6C18.1333 17.3056 17.8944 17.0667 17.6 17.0667C17.3056 17.0667 17.0667 17.3056 17.0667 17.6V18.6667C17.0667 18.961 17.3056 19.2 17.6 19.2C17.8944 19.2 18.1333 18.961 18.1333 18.6667V17.6Z" fill="#635F76"/>
        <path d="M24.5334 7.99999C24.5334 7.70561 24.2945 7.46667 24.0001 7.46667C23.7057 7.46667 23.4668 7.70561 23.4668 7.99999V9.06668C23.4668 9.36105 23.7057 9.59999 24.0001 9.59999C24.2945 9.59999 24.5334 9.36105 24.5334 9.06668V7.99999Z" fill="#635F76"/>
        <path d="M17.6 20.2667C17.3056 20.2667 17.0667 20.5057 17.0667 20.8V21.8667C17.0667 22.1611 17.3056 22.4 17.6 22.4C17.8944 22.4 18.1333 22.1611 18.1333 21.8667V20.8C18.1333 20.5057 17.8944 20.2667 17.6 20.2667Z" fill="#635F76"/>
        <path d="M26.6667 24C26.6667 23.7056 26.4277 23.4667 26.1333 23.4667C25.839 23.4667 25.6 23.7056 25.6 24V25.0667C25.6 25.361 25.8389 25.6 26.1333 25.6C26.4277 25.6 26.6666 25.361 26.6666 25.0667V24H26.6667Z" fill="#635F76"/>
        <path d="M26.1333 26.6667C25.8389 26.6667 25.6 26.9056 25.6 27.2V29.3333C25.6 29.6277 25.8389 29.8666 26.1333 29.8666C26.4277 29.8666 26.6666 29.6277 26.6666 29.3333V27.2C26.6667 26.9056 26.4277 26.6667 26.1333 26.6667Z" fill="#635F76"/>
        <path d="M28.2667 20.2667C27.9723 20.2667 27.7334 20.5057 27.7334 20.8V21.8667C27.7334 22.1611 27.9723 22.4 28.2667 22.4C28.5611 22.4 28.8 22.1611 28.8 21.8667V20.8C28.8 20.5057 28.5611 20.2667 28.2667 20.2667Z" fill="#635F76"/>
        <path d="M19.7332 9.60001C20.0276 9.60001 20.2665 9.36107 20.2665 9.0667V6.81657L25.5999 5.4832V14.4C25.5999 14.6224 25.738 14.8213 25.946 14.8998L29.8666 16.3696V21.8667C29.8666 22.1611 30.1055 22.4 30.3999 22.4C30.6943 22.4 30.9332 22.1611 30.9332 21.8667V16C30.9332 15.7776 30.7951 15.5787 30.5871 15.5003L26.6665 14.0304V3.73332C26.6665 3.43895 26.4276 3.20001 26.1332 3.20001C25.8388 3.20001 25.5999 3.43895 25.5999 3.73332V4.38345L19.6036 5.88264C19.3663 5.94182 19.1999 6.1552 19.1999 6.39995V9.06664C19.1999 9.36107 19.4388 9.60001 19.7332 9.60001Z" fill="#635F76"/>
        <path d="M28.8 17.6C28.8 17.3056 28.5611 17.0667 28.2667 17.0667C27.9723 17.0667 27.7334 17.3056 27.7334 17.6V18.6667C27.7334 18.961 27.9723 19.2 28.2667 19.2C28.5611 19.2 28.8 18.961 28.8 18.6667V17.6Z" fill="#635F76"/>
        <path d="M26.6667 17.6C26.6667 17.3056 26.4278 17.0667 26.1334 17.0667C25.839 17.0667 25.6001 17.3056 25.6001 17.6V18.6667C25.6001 18.961 25.839 19.2 26.1334 19.2C26.4278 19.2 26.6667 18.961 26.6667 18.6667V17.6Z" fill="#635F76"/>
        <path d="M22.3999 24C22.3999 23.7056 22.161 23.4667 21.8666 23.4667C21.5722 23.4667 21.3333 23.7056 21.3333 24V25.0667C21.3333 25.361 21.5722 25.6 21.8666 25.6C22.161 25.6 22.3999 25.361 22.3999 25.0667V24Z" fill="#635F76"/>
        <path d="M26.6667 20.8C26.6667 20.5057 26.4278 20.2667 26.1334 20.2667C25.839 20.2667 25.6001 20.5057 25.6001 20.8V21.8667C25.6001 22.1611 25.839 22.4 26.1334 22.4C26.4278 22.4 26.6667 22.1611 26.6667 21.8667V20.8Z" fill="#635F76"/>
        <path d="M21.8666 26.6667C21.5722 26.6667 21.3333 26.9056 21.3333 27.2V29.3333C21.3333 29.6277 21.5722 29.8666 21.8666 29.8666C22.161 29.8666 22.3999 29.6277 22.3999 29.3333V27.2C22.3999 26.9056 22.161 26.6667 21.8666 26.6667Z" fill="#635F76"/>
        <path d="M22.3999 20.8C22.3999 20.5057 22.161 20.2667 21.8666 20.2667C21.5722 20.2667 21.3333 20.5057 21.3333 20.8V21.8667C21.3333 22.1611 21.5722 22.4 21.8666 22.4C22.161 22.4 22.3999 22.1611 22.3999 21.8667V20.8Z" fill="#635F76"/>
        <path d="M24 29.8667C24.2944 29.8667 24.5333 29.6277 24.5333 29.3334V12.2667C24.5333 12.0219 24.3664 11.8086 24.1295 11.7494L15.5962 9.61599C15.4379 9.57599 15.2677 9.61224 15.1386 9.71305C15.009 9.81387 14.9333 9.96905 14.9333 10.1333V21.8666C14.9333 22.161 15.1722 22.3999 15.4666 22.3999C15.761 22.3999 15.9999 22.161 15.9999 21.8666V10.8166L23.4666 12.6832V29.3334C23.4667 29.6277 23.7056 29.8667 24 29.8667Z" fill="#635F76"/>
        <path d="M11.7333 11.2C11.7333 10.9056 11.4944 10.6667 11.2 10.6667C10.9056 10.6667 10.6667 10.9056 10.6667 11.2V12.2667C10.6667 12.5611 10.9056 12.8 11.2 12.8C11.4944 12.8 11.7333 12.5611 11.7333 12.2667V11.2Z" fill="#635F76"/>
        <path d="M11.7333 7.99999C11.7333 7.70561 11.4944 7.46667 11.2 7.46667C10.9056 7.46667 10.6667 7.70561 10.6667 7.99999V9.06668C10.6667 9.36105 10.9056 9.59999 11.2 9.59999C11.4944 9.59999 11.7333 9.36105 11.7333 9.06668V7.99999Z" fill="#635F76"/>
        <path d="M7.46677 24C7.46677 23.7056 7.22783 23.4667 6.93346 23.4667C6.63909 23.4667 6.40009 23.7056 6.40009 24V25.0667C6.40009 25.361 6.63902 25.6 6.9334 25.6C7.22777 25.6 7.46671 25.361 7.46671 25.0667V24H7.46677Z" fill="#635F76"/>
        <path d="M2.66668 19.2C2.96105 19.2 3.19999 18.961 3.19999 18.6667V16H8.5333V29.3333C8.5333 29.6277 8.77224 29.8666 9.06661 29.8666C9.36099 29.8666 9.59993 29.6277 9.59993 29.3333V15.4667C9.59993 15.1723 9.36099 14.9333 9.06661 14.9333H2.66661C2.37224 14.9333 2.1333 15.1723 2.1333 15.4667V18.6667C2.1333 18.961 2.37224 19.2 2.66668 19.2Z" fill="#635F76"/>
        <path d="M8.5332 11.2V12.2667C8.5332 12.5611 8.77214 12.8 9.06651 12.8C9.36089 12.8 9.59983 12.5611 9.59983 12.2667V11.2C9.59983 10.9056 9.36089 10.6667 9.06651 10.6667C8.77214 10.6667 8.5332 10.9056 8.5332 11.2Z" fill="#635F76"/>
        <path d="M11.7333 4.79998C11.7333 4.5056 11.4944 4.26666 11.2 4.26666C10.9056 4.26666 10.6667 4.5056 10.6667 4.79998V5.86666C10.6667 6.16104 10.9056 6.39997 11.2 6.39997C11.4944 6.39997 11.7333 6.16104 11.7333 5.86666V4.79998Z" fill="#635F76"/>
        <path d="M6.9334 26.6667C6.63902 26.6667 6.40009 26.9056 6.40009 27.2V29.3333C6.40009 29.6277 6.63902 29.8666 6.9334 29.8666C7.22777 29.8666 7.46671 29.6277 7.46671 29.3333V27.2C7.46677 26.9056 7.22783 26.6667 6.9334 26.6667Z" fill="#635F76"/>
        <path d="M31.4667 30.9333H30.4V27.6575C31.3173 27.4192 32 26.5909 32 25.5999C32 24.4234 31.0432 23.4666 29.8667 23.4666C28.6902 23.4666 27.7334 24.4234 27.7334 25.5999C27.7334 26.5909 28.4161 27.4191 29.3334 27.6575V30.9333H16V28.7461C17.2154 28.4981 18.1333 27.4213 18.1333 26.1333C18.1333 24.6629 16.9371 23.4666 15.4666 23.4666C13.9963 23.4666 12.7999 24.6629 12.7999 26.1333C12.7999 27.4213 13.7178 28.4981 14.9333 28.7461V30.9333H3.2V25.5461C4.41544 25.2981 5.33331 24.2213 5.33331 22.9333C5.33331 21.4629 4.13706 20.2666 2.66663 20.2666C1.19625 20.2667 0 21.4629 0 22.9333C0 24.2213 0.917875 25.2981 2.13331 25.5461V30.9333H0.533312C0.238937 30.9333 0 31.1722 0 31.4666C0 31.761 0.238937 32 0.533312 32H31.4666C31.761 32 31.9999 31.761 31.9999 31.4667C31.9999 31.1723 31.7611 30.9333 31.4667 30.9333ZM1.06669 22.9333C1.06669 22.0512 1.78456 21.3333 2.66669 21.3333C3.54881 21.3333 4.26669 22.0512 4.26669 22.9333C4.26669 23.8154 3.54881 24.5333 2.66669 24.5333C1.78456 24.5333 1.06669 23.8154 1.06669 22.9333ZM13.8667 26.1333C13.8667 25.2512 14.5846 24.5333 15.4667 24.5333C16.3488 24.5333 17.0667 25.2512 17.0667 26.1333C17.0667 27.0154 16.3488 27.7333 15.4667 27.7333C14.5846 27.7333 13.8667 27.0154 13.8667 26.1333ZM28.8 25.6C28.8 25.0117 29.2784 24.5333 29.8667 24.5333C30.455 24.5333 30.9334 25.0117 30.9334 25.6C30.9334 26.1882 30.455 26.6667 29.8667 26.6667C29.2784 26.6667 28.8 26.1882 28.8 25.6Z" fill="#635F76"/>
        <path d="M4.26666 27.2V29.3333C4.26666 29.6277 4.5056 29.8666 4.79998 29.8666C5.09435 29.8666 5.33329 29.6277 5.33329 29.3333V27.2C5.33329 26.9056 5.09435 26.6667 4.79998 26.6667C4.5056 26.6667 4.26666 26.9056 4.26666 27.2Z" fill="#635F76"/>
        <path d="M7.46671 17.6C7.46671 17.3056 7.22777 17.0667 6.9334 17.0667C6.63902 17.0667 6.40009 17.3056 6.40009 17.6V18.6667C6.40009 18.961 6.63902 19.2 6.9334 19.2C7.22777 19.2 7.46671 18.961 7.46671 18.6667V17.6Z" fill="#635F76"/>
        <path d="M7.46671 20.8C7.46671 20.5057 7.22777 20.2667 6.9334 20.2667C6.63902 20.2667 6.40009 20.5057 6.40009 20.8V21.8667C6.40009 22.1611 6.63902 22.4 6.9334 22.4C7.22777 22.4 7.46671 22.1611 7.46671 21.8667V20.8Z" fill="#635F76"/>
        <path d="M4.26666 17.6V18.6667C4.26666 18.961 4.5056 19.2 4.79998 19.2C5.09435 19.2 5.33329 18.961 5.33329 18.6667V17.6C5.33329 17.3056 5.09435 17.0667 4.79998 17.0667C4.5056 17.0667 4.26666 17.3056 4.26666 17.6Z" fill="#635F76"/>
        <path d="M11.7333 20.8C11.7333 20.5057 11.4944 20.2667 11.2 20.2667C10.9056 20.2667 10.6667 20.5057 10.6667 20.8V21.8667C10.6667 22.1611 10.9056 22.4 11.2 22.4C11.4944 22.4 11.7333 22.1611 11.7333 21.8667V20.8Z" fill="#635F76"/>
        <path d="M13.8668 14.4C13.8668 14.1056 13.6279 13.8667 13.3335 13.8667C13.0391 13.8667 12.8002 14.1056 12.8002 14.4V15.4667C12.8002 15.7611 13.0391 16 13.3335 16C13.6279 16 13.8668 15.7611 13.8668 15.4667V14.4Z" fill="#635F76"/>
        <path d="M13.8668 11.2C13.8668 10.9056 13.6279 10.6667 13.3335 10.6667C13.0391 10.6667 12.8002 10.9056 12.8002 11.2V12.2667C12.8002 12.5611 13.0391 12.8 13.3335 12.8C13.6279 12.8 13.8668 12.5611 13.8668 12.2667V11.2Z" fill="#635F76"/>
        <path d="M13.8668 17.6C13.8668 17.3056 13.6279 17.0667 13.3335 17.0667C13.0391 17.0667 12.8002 17.3056 12.8002 17.6V18.6667C12.8002 18.961 13.0391 19.2 13.3335 19.2C13.6279 19.2 13.8668 18.961 13.8668 18.6667V17.6Z" fill="#635F76"/>
        <path d="M13.3334 20.2667C13.039 20.2667 12.8 20.5057 12.8 20.8V21.8667C12.8 22.1611 13.039 22.4 13.3334 22.4C13.6277 22.4 13.8667 22.1611 13.8667 21.8667V20.8C13.8667 20.5057 13.6278 20.2667 13.3334 20.2667Z" fill="#635F76"/>
        <path d="M13.8668 7.99999C13.8668 7.70561 13.6279 7.46667 13.3335 7.46667C13.0391 7.46667 12.8002 7.70561 12.8002 7.99999V9.06668C12.8002 9.36105 13.0391 9.59999 13.3335 9.59999C13.6279 9.59999 13.8668 9.36105 13.8668 9.06668V7.99999Z" fill="#635F76"/>
        <path d="M11.7333 24C11.7333 23.7056 11.4944 23.4667 11.2 23.4667C10.9056 23.4667 10.6667 23.7056 10.6667 24V25.0667C10.6667 25.361 10.9056 25.6 11.2 25.6C11.4944 25.6 11.7333 25.361 11.7333 25.0667V24Z" fill="#635F76"/>
        <path d="M6.93334 13.8667C7.22771 13.8667 7.46665 13.6277 7.46665 13.3334V9.39625L9.30502 8.47731C9.48584 8.38662 9.59996 8.20212 9.59996 8V3.52962L12.2667 2.19631L14.9333 3.52962V8C14.9333 8.29437 15.1723 8.53331 15.4667 8.53331C15.761 8.53331 16 8.29437 16 8V3.2C16 2.99787 15.8859 2.81331 15.7051 2.72269L12.8 1.27037V0.533312C12.8 0.238937 12.5611 0 12.2667 0C11.9723 0 11.7334 0.238937 11.7334 0.533312V1.27037L8.82834 2.72262C8.64752 2.81331 8.5334 2.99781 8.5334 3.19994V7.67031L6.69502 8.58925C6.51421 8.67994 6.40009 8.86444 6.40009 9.06656V13.3332C6.40002 13.6277 6.63896 13.8667 6.93334 13.8667Z" fill="#635F76"/>
        <path d="M11.7333 17.6C11.7333 17.3056 11.4944 17.0667 11.2 17.0667C10.9056 17.0667 10.6667 17.3056 10.6667 17.6V18.6667C10.6667 18.961 10.9056 19.2 11.2 19.2C11.4944 19.2 11.7333 18.961 11.7333 18.6667V17.6Z" fill="#635F76"/>
        <path d="M11.2 26.6667C10.9056 26.6667 10.6667 26.9056 10.6667 27.2V29.3333C10.6667 29.6277 10.9056 29.8666 11.2 29.8666C11.4944 29.8666 11.7333 29.6277 11.7333 29.3333V27.2C11.7333 26.9056 11.4944 26.6667 11.2 26.6667Z" fill="#635F76"/>
        <path d="M13.8668 4.79998C13.8668 4.5056 13.6279 4.26666 13.3335 4.26666C13.0391 4.26666 12.8002 4.5056 12.8002 4.79998V5.86666C12.8002 6.16104 13.0391 6.39997 13.3335 6.39997C13.6279 6.39997 13.8668 6.16104 13.8668 5.86666V4.79998Z" fill="#635F76"/>
        <path d="M11.7333 14.4C11.7333 14.1056 11.4944 13.8667 11.2 13.8667C10.9056 13.8667 10.6667 14.1056 10.6667 14.4V15.4667C10.6667 15.7611 10.9056 16 11.2 16C11.4944 16 11.7333 15.7611 11.7333 15.4667V14.4Z" fill="#635F76"/>
    </svg>  
  )
}
