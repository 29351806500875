import React from 'react'

export const PhotoVideoMaximise = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 21C7.44772 21 3 21 3 21V16" stroke="#89869B" strokeWidth="2" strokeLinecap="round"/>
        <path d="M21 16C21 16.5523 21 21 21 21L16 21" stroke="#89869B" strokeWidth="2" strokeLinecap="round"/>
        <path d="M16 3C16.5523 3 21 3 21 3L21 8" stroke="#89869B" strokeWidth="2" strokeLinecap="round"/>
        <path d="M3 8C3 7.44772 3 3 3 3L8 3" stroke="#89869B" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  )
}
