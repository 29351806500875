import { useEffect, useState } from "react";
import { Edit } from "../../Assets/svgs/Edit";
import { Remove } from "../../Assets/svgs/Remove";
import { borrarImagen } from "../../features/LayoutPregunta/PreguntasAPI";
import { formatStringMoney } from "../../utils/currencyHelpers";
import { AddPhoto } from "../AddPhoto/AddPhoto";
import { CustomInput } from "../CustomInput/CustomInput";
import { InputCurrency } from "../InputCurrency/InputCurrency";
import "./CardBienes.scss";
import { toBase64 } from "../../utils";

export const CardBienes = (props: any) => {
  const [open, setOpen] = useState(true);
  const [data, setData] = useState({detalle: "", importe: "", photo: ""})
  const [confirmada, setConfirmada] = useState(false);

  useEffect(() => {
    if (props.defaultDetalle) {
      setConfirmada(true);
      handleDataChange(props.defaultDetalle, "detalle");
    }
    if (props.defaultImporte) handleDataChange(props.defaultImporte, "importe");
    if (props.fetchedThumbnail) handleDataChange(props.fetchedThumbnail, "photo")
  }, []);

  useEffect(() => {
    if (confirmada) {
      setOpen(false);
      handleConfirmar()
    } else {
      setOpen(true);
    }
  }, [confirmada]);

  const handleDataChange = async (e: any, key: string) => {
    const isFile = e instanceof File;
    const dato = isFile ? await toBase64(e) : e
    setData((prevState) => {return {...prevState, [key]: dato}})
  };

  const handleConfirmar = () => {
    props.onChange({
        id: props.id,
        detalle: data.detalle,
        importe: data.importe,
        imagen: data.photo,
        confirmada: true,
      });
  }

  const handleAñadir = () => {
    setConfirmada(true);
  };

  const disableButton = (money: any, detail: string) => {
    const importeString = money.toString();
    return !importeString.length || !detail.length || !data.photo;
  };

  const handleBorrar = (id: number) => {
    if (props.alreadyUploaded) {
      borrarImagen(id);
      props.delete(id);
    } else {
      props.delete(id);
    }
  };

  const renderContent = () => {
    if (open) {
      return (
        <>
          <div className="bienes-remove-icon">
            <span className="borrar-msg" onClick={() => handleBorrar(props.id)}>
              <Remove />
            </span>
          </div>
          <div className="bienes-photo">
            <AddPhoto
              onChangeBienes={(e: any) => handleDataChange(e, "photo")}
              id={props.id}
              defaultThumbnail={props.fetchedThumbnail}
            />
          </div>
          <CustomInput
            bienes={true}
            label="Detalle"
            type="text"
            onChange={(e: any) => handleDataChange(e, "detalle")}
            defaultValue={data.detalle ? data.detalle : null}
          />
          <InputCurrency
            bienes={true}
            label="Importe (Euros)"
            onChange={(e: any) => handleDataChange(e, "importe")}
            defaultValue={data.importe ? data.importe : null}
          />
          <button
            disabled={disableButton(data.importe, data.detalle)}
            onClick={handleAñadir}
            className="añadir-msg"
          >
            {props.confirmarText || "Confirmar elemento"}
          </button>
        </>
      );
    } else {
      return (
        <>
          <div className="bienes-remove-icon">
            {!props.alreadyUploaded ? <div onClick={() => setConfirmada(false)}>
              <Edit />
            </div> : null}
            <div onClick={() => handleBorrar(props.id)}>
              <Remove />
            </div>
          </div>
          <div className="bienes-photo">
            <AddPhoto />
            <div className="bienes-data">
              <p>{data.detalle ? data.detalle : ""}</p>
              <span>{data.importe ? formatStringMoney(data.importe) : ""}</span>
            </div>
          </div>
        </>
      );
    }
  };

  return <div className="bienes">{renderContent()}</div>;
};
