import React from 'react'

export const LocalMobile = () => {
  return (
    <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.5692 29V17.3H27.3999V29M6.59998 23.8V17.2126H16.9999V23.8H6.59998Z" stroke="#635F76" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M30 13.66V29H3.99997V13.66M7.63994 10.54C7.63994 12.2631 6.24313 13.66 4.52003 13.66C2.79693 13.66 1.40002 12.2631 1.40002 10.54L3.99997 3H30L32.5999 10.54C32.5999 12.2631 31.203 13.66 29.4799 13.66C27.7568 13.66 26.36 12.2631 26.36 10.54" stroke="#635F76" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.1201 10.54C20.1201 12.2631 18.7232 13.66 17.0001 13.66C15.277 13.66 13.8801 12.2631 13.8801 10.54" stroke="#635F76" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.20002 3L7.64001 10.54C7.64001 12.2631 9.03692 13.66 10.76 13.66C12.4831 13.66 13.88 12.2631 13.88 10.54L14.4 3M19.6001 3L20.12 10.54C20.12 12.2631 21.5169 13.66 23.24 13.66C24.9631 13.66 26.36 12.2631 26.36 10.54L24.8 3H19.6001Z" stroke="#635F76" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
