import React from 'react'

export const Centralita = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.7041 0H4.29593C2.47845 0 1 1.47845 1 3.29593V28.7041C1 30.5216 2.47845 32 4.29593 32H27.7041C29.5216 32 31 30.5216 31 28.7041V3.29593C31 1.47845 29.5216 0 27.7041 0ZM29.8442 28.7041C29.8442 29.8839 28.8839 30.8442 27.7041 30.8442H4.29593C3.11606 30.8442 2.15579 29.8839 2.15579 28.7041V3.29593C2.15579 2.11606 3.11606 1.15579 4.29593 1.15579H27.7041C28.8839 1.15579 29.8442 2.11606 29.8442 3.29593V28.7041Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.77905 7.5247C5.77905 6.56721 6.55525 5.79102 7.51274 5.79102H24.5607C25.5181 5.79102 26.2943 6.56721 26.2943 7.5247V19.9495C26.2943 20.9069 25.5181 21.6831 24.5607 21.6831H7.51274C6.55525 21.6831 5.77905 20.9069 5.77905 19.9495V7.5247ZM7.51274 6.94681C7.19358 6.94681 6.93484 7.20554 6.93484 7.5247V19.9495C6.93484 20.2686 7.19358 20.5273 7.51274 20.5273H24.5607C24.8798 20.5273 25.1386 20.2686 25.1386 19.9495V7.5247C25.1386 7.20554 24.8798 6.94681 24.5607 6.94681H7.51274Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.95731 9.35428C8.95731 8.60957 9.56102 8.00586 10.3057 8.00586H21.8636C22.6084 8.00586 23.2121 8.60957 23.2121 9.35428V11.8585C23.2121 12.6032 22.6084 13.2069 21.8636 13.2069H10.3057C9.56102 13.2069 8.95731 12.6032 8.95731 11.8585V9.35428ZM10.3057 9.16165C10.1993 9.16165 10.1131 9.24789 10.1131 9.35428V11.8585C10.1131 11.9649 10.1993 12.0511 10.3057 12.0511H21.8636C21.97 12.0511 22.0563 11.9649 22.0563 11.8585V9.35428C22.0563 9.24789 21.97 9.16165 21.8636 9.16165H10.3057Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.34251 15.7107C9.34251 15.3915 9.60125 15.1328 9.92041 15.1328H11.943C12.2622 15.1328 12.5209 15.3915 12.5209 15.7107C12.5209 16.0299 12.2622 16.2886 11.943 16.2886H9.92041C9.60125 16.2886 9.34251 16.0299 9.34251 15.7107Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M19.4557 15.0379C19.4557 14.7187 19.7144 14.46 20.0336 14.46H20.2262C20.5453 14.46 20.8041 14.7187 20.8041 15.0379C20.8041 15.357 20.5453 15.6158 20.2262 15.6158H20.0336C19.7144 15.6158 19.4557 15.357 19.4557 15.0379Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.4785 15.0379C21.4785 14.7187 21.7372 14.46 22.0564 14.46H22.249C22.5682 14.46 22.8269 14.7187 22.8269 15.0379C22.8269 15.357 22.5682 15.6158 22.249 15.6158H22.0564C21.7372 15.6158 21.4785 15.357 21.4785 15.0379Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M19.4557 16.5779C19.4557 16.2587 19.7144 16 20.0336 16H20.2262C20.5453 16 20.8041 16.2587 20.8041 16.5779C20.8041 16.8971 20.5453 17.1558 20.2262 17.1558H20.0336C19.7144 17.1558 19.4557 16.8971 19.4557 16.5779Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.4785 16.5779C21.4785 16.2587 21.7372 16 22.0564 16H22.249C22.5682 16 22.8269 16.2587 22.8269 16.5779C22.8269 16.8971 22.5682 17.1558 22.249 17.1558H22.0564C21.7372 17.1558 21.4785 16.8971 21.4785 16.5779Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M19.4557 18.0222C19.4557 17.7031 19.7144 17.4443 20.0336 17.4443H20.2262C20.5453 17.4443 20.8041 17.7031 20.8041 18.0222C20.8041 18.3414 20.5453 18.6001 20.2262 18.6001H20.0336C19.7144 18.6001 19.4557 18.3414 19.4557 18.0222Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.4785 18.0222C21.4785 17.7031 21.7372 17.4443 22.0564 17.4443H22.249C22.5682 17.4443 22.8269 17.7031 22.8269 18.0222C22.8269 18.3414 22.5682 18.6001 22.249 18.6001H22.0564C21.7372 18.6001 21.4785 18.3414 21.4785 18.0222Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.34251 17.7332C9.34251 17.414 9.60125 17.1553 9.92041 17.1553H11.943C12.2622 17.1553 12.5209 17.414 12.5209 17.7332C12.5209 18.0523 12.2622 18.3111 11.943 18.3111H9.92041C9.60125 18.3111 9.34251 18.0523 9.34251 17.7332Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.1103 14.5557C16.2392 14.5585 16.3581 14.6254 16.4273 14.7341L16.795 15.312C16.8705 15.4306 16.8754 15.581 16.8077 15.7042C16.7401 15.8275 16.6106 15.9041 16.47 15.9041H15.6995C15.556 15.9041 15.4244 15.8243 15.3579 15.6971C15.2915 15.5699 15.3014 15.4163 15.3834 15.2985L15.7862 14.7206C15.8599 14.6149 15.9815 14.553 16.1103 14.5557Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.0589 18.6005C15.9301 18.5978 15.8112 18.5309 15.742 18.4222L15.3742 17.8443C15.2987 17.7256 15.2939 17.5753 15.3615 17.452C15.4292 17.3288 15.5587 17.2522 15.6993 17.2522H16.4698C16.6133 17.2522 16.7449 17.3319 16.8113 17.4592C16.8777 17.5864 16.8679 17.74 16.7859 17.8577L16.3831 18.4356C16.3094 18.5413 16.1878 18.6032 16.0589 18.6005Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.8694 16.5525C13.8721 16.4237 13.939 16.3047 14.0477 16.2356L14.6256 15.8678C14.7442 15.7923 14.8946 15.7875 15.0178 15.8551C15.1411 15.9228 15.2177 16.0522 15.2177 16.1928V16.9634C15.2177 17.1069 15.1379 17.2385 15.0107 17.3049C14.8835 17.3713 14.7299 17.3615 14.6122 17.2794L14.0343 16.8767C13.9286 16.803 13.8667 16.6813 13.8694 16.5525Z" fill="#635F76"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.2997 16.6037C18.297 16.7326 18.23 16.8515 18.1213 16.9207L17.5434 17.2884C17.4248 17.3639 17.2745 17.3688 17.1512 17.3011C17.0279 17.2335 16.9513 17.104 16.9513 16.9634V16.1929C16.9513 16.0494 17.0311 15.9177 17.1583 15.8513C17.2856 15.7849 17.4392 15.7948 17.5569 15.8768L18.1348 16.2796C18.2405 16.3533 18.3024 16.4749 18.2997 16.6037Z" fill="#635F76"/>
    </svg>
  )
}
