import React from 'react'

export const Entre = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="9.5" fill="#EBEAF0" stroke="#635F76"/>
        <circle cx="16" cy="16" r="5.5" fill="white" stroke="#635F76"/>
        <circle cx="16" cy="16" r="13.5" stroke="#F7F7F9"/>
    </svg>
  )
}
