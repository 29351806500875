import Lottie from 'lottie-web'
import React, { useEffect, useRef, useState } from 'react'
import * as map from "../../Assets/jsonAnimations/map-pin.json"
import * as camera from "../../Assets/jsonAnimations/camera.json"
import * as wheel from "../../Assets/jsonAnimations/wheel.json"
import * as form from "../../Assets/jsonAnimations/form.json"
import * as error from "../../Assets/jsonAnimations/error.json"
import * as loader from "../../Assets/jsonAnimations/loader.json"
import * as hogar from "../../Assets/jsonAnimations/hogar.json"
import * as buttonLoaderBlue from '../../Assets/jsonAnimations/buttonLoaderBlue.json';

interface IProps {
    width: string;
    height: string;
    animation: string;
}

export const JsonAnimation = ({ width, height, animation }: IProps) => {
    const lottieInstance = useRef<any>()
    const divRef = useRef<any>()

    useEffect(() => {
        const selectAnimation = () => {
            switch (animation) {
                case "map": {
                    return map;
                }
                case "cam": {
                    return camera;
                }
                case "wheel": {
                    return wheel;
                }
                case "form": {
                    return form;
                }
                case "error": {
                    return error;
                }
                case "loader": {
                    return loader;
                }
                case "hogar": {
                    return hogar;
                }
                case 'buttonLoaderBlue': {
                    return buttonLoaderBlue;
                }
            }
        }

        const animationData = selectAnimation()
        if (divRef.current) {
            lottieInstance.current = Lottie.loadAnimation({
                //@ts-ignore
                animationData,
                container: divRef.current,
            })
        }

        return (() => {
            try {
                lottieInstance.current.destroy();
            }
            catch { 
                console.log('TOMAS');
                return; }
        })
    }, [animation])

    return <div style={{ height: height, width: width }} ref={divRef}></div>
}
