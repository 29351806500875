import React from "react";

export const Help = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
        fill="#0046CF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16C12.5523 16 13 16.4477 13 17V17.01C13 17.5622 12.5523 18.01 12 18.01C11.4477 18.01 11 17.5622 11 17.01V17C11 16.4477 11.4477 16 12 16Z"
        fill="#0046CF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4746 6.34495C10.974 6.09784 11.5239 5.97003 12.0811 5.97157C12.6383 5.97312 13.1875 6.10398 13.6856 6.35386C14.1836 6.60373 14.6168 6.9658 14.9511 7.41156C15.2854 7.85732 15.5117 8.37459 15.6122 8.92266C15.7126 9.47073 15.6844 10.0346 15.5299 10.57C15.3754 11.1053 15.0987 11.5975 14.7216 12.0077C14.3446 12.4179 13.8774 12.7351 13.357 12.9341C13.3486 12.9373 13.3401 12.9404 13.3315 12.9434C13.2293 12.9794 13.1415 13.0476 13.0814 13.1378C13.0213 13.2279 12.9921 13.3352 12.9982 13.4434C13.0295 13.9948 12.6078 14.4672 12.0564 14.4984C11.505 14.5297 11.0327 14.1081 11.0014 13.5567C10.9707 13.0156 11.1167 12.4793 11.4173 12.0284C11.7148 11.5821 12.1478 11.2435 12.6522 11.0623C12.8797 10.9737 13.0839 10.8341 13.2491 10.6543C13.4167 10.472 13.5397 10.2532 13.6084 10.0153C13.677 9.77737 13.6896 9.52674 13.6449 9.28316C13.6003 9.03957 13.4997 8.80967 13.3511 8.61156C13.2025 8.41344 13.01 8.25252 12.7886 8.14147C12.5673 8.03041 12.3232 7.97225 12.0756 7.97156C11.8279 7.97088 11.5835 8.02768 11.3615 8.13751C11.1396 8.24734 10.9462 8.40719 10.7965 8.60447C10.4627 9.04446 9.83537 9.13052 9.39538 8.7967C8.9554 8.46289 8.86933 7.8356 9.20315 7.39562C9.53994 6.95172 9.97516 6.59206 10.4746 6.34495Z"
        fill="#0046CF"
      />
    </svg>
  );
};
