import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import comercio from "../../Assets/jsonAnimations/comercio.gif";
import industria from "../../Assets/jsonAnimations/industria.gif";
import onboardingCamera from "../../Assets/svgs/onboardingCamera.svg";
import onboardingRing from "../../Assets/svgs/onboardingRing.svg";
import onboardingMap from "../../Assets/svgs/onboardingMap.svg";

import { Back } from "../../Assets/svgs/Back";
import { OkCircle } from "../../Assets/svgs/OkCircle";
import { BlockSteps } from "../../Components/BlockSteps/BlockSteps";
import { CustomButton } from "../../Components/CustomButton/CustomButton";
import { JsonAnimation } from "../../Components/JsonAnimation/JsonAnimation";
import {
  LocationModal,
  locationModal,
} from "../../Components/LocationModal/LocationModal";

import { TipoRamos } from "../../model/ramos";
import {
  aceptarTerminosYCondiciones,
  aceptarTerminosYCondicionesAseguradora,
  politicasDePrivacidadCustom,
  terminosYCondicionesStatus,
  titulosOnboarding,
} from "../LayoutPregunta/PreguntasAPI";
import {
  comenzarInspeccion,
  handleInspeccion,
  saveInitialCoords,
  selectId,
} from "../LayoutPregunta/preguntaSlice";
import { aceptoToS, aceptoToSAseguradora, selectToS } from "../Registro/userSlice";
import ModalTerminosYCondiciones from "../terminosYCondiciones/ModalTerminosYCondiciones";
import "./Login.scss";
import { neutralizeBack } from "../../utils";
import { Icons } from "../../Assets/Icons";
import { IconsRamos } from "../../Assets/iconosRamos/IconsRamos";

const getCoordinates = async () => {
  const defaultCoords = { lat: null, long: null };
  const coords = await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (pos) =>
        resolve({ lat: pos.coords.latitude, long: pos.coords.longitude }),
      (error) => resolve(defaultCoords),
      { enableHighAccuracy: true }
    );
  });

  return coords;
};

export const Login = () => {
  const navigate = useNavigate();
  const id = useSelector(selectId);
  const yaAceptoToS = useSelector(selectToS);
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [geolocationModal, setGeolocationModal] = useState<locationModal>({
    open: false,
    msg: "",
  });
  const [ramo, setRamo] = useState("");
  const [terminosAceptados, setTerminosAceptados] = useState(false);
  const [terminosAseguradoraAceptados, setTerminosAseguradoraAceptados] = useState(false);
  const [textos, setTextos] = useState({
    titulo: "",
    subtitulo: "",
    icono: "",
  });
  const [politicaCustom, setPoliticaCustom] = useState({ valor: "", titulo: "" });
  const icons = Icons();

  const getToS = async (id: any) => {
    const tos = await terminosYCondicionesStatus(id);
    if (tos.data) {
      dispatch(aceptoToS(tos.data));
      setTerminosAceptados(true);
      setTerminosAseguradoraAceptados(true);
    }
  };

  const getTextos = async (id: any) => {
    const textos = await titulosOnboarding(id);
    if (textos.data.resultCode === 200) {
      setTextos(textos.data);
    }
  };

  const getPoliticasDePrivacidadCustom = async (id: any) => {
    const textos = await politicasDePrivacidadCustom(id);
    if (textos.data.resultCode === 200) {
      setPoliticaCustom({ valor: textos.data.valor, titulo: textos.data.titulo });
    }
  };

  const handleBackButton = () => {
    console.log(step);
    if (step === 1) {
      setStep(0);
    }
  };

  useEffect(() => {
    const email = localStorage.getItem("email");
    if (!id) {
      const inspeccionId = localStorage.getItem("idInspeccion");
      const user = localStorage.getItem("userName");
      dispatch(handleInspeccion({ id: inspeccionId, user }));
    }

    const ramoId = localStorage.getItem("ramoId");
    if (ramoId) ramoName(ramoId);
  }, []);

  useEffect(() => {
    getToS(id);
    getTextos(id);
    getPoliticasDePrivacidadCustom(id);
  }, [id]);

  console.log(politicaCustom);

  useEffect(() => {
    neutralizeBack(handleBackButton);
  }, [step]);

  const ramoName = (ramoId: any) => {
    switch (ramoId) {
      case TipoRamos.Hogar: {
        setRamo("VIVIENDA");
        return;
      }
      case TipoRamos.Comercio: {
        setRamo("COMERCIO");
        return;
      }
      case TipoRamos.Industria: {
        setRamo("PYME / INDUSTRIA");
        return;
      }
    }
  };

  const handleClick = async () => {
    if (politicaCustom.valor && terminosAceptados && terminosAseguradoraAceptados) {
      await aceptarTerminosYCondiciones(id);
      await aceptarTerminosYCondicionesAseguradora(id);
      dispatch(aceptoToS(true));
      dispatch(aceptoToSAseguradora(true));
      const coords = await getCoordinates();
      //@ts-ignore
      dispatch(comenzarInspeccion({ id: id, ...coords }));

      navigate("/form");
    } else if (terminosAceptados) {
      await aceptarTerminosYCondiciones(id);
      dispatch(aceptoToS(true));

      const coords = await getCoordinates();
      //@ts-ignore
      dispatch(comenzarInspeccion({ id: id, ...coords }));

      navigate("/form");
    }
  };

  const renderInfo = () => {
    return (
      <div className="login-info">
        <h3 className="login-info-title">
          Para la toma de datos solo necesitamos que:
        </h3>
        <ul className="login-info-list">
          <li className="login-info-list-item">
            <div className="login-info-icon">{icons["helpBlackCircle"]}</div>
            <p>Respondas a unas breves preguntas</p>
          </li>
          <li className="login-info-list-item">
            <div className="login-info-icon">{icons["checkBlackCircle"]}</div>
            <p>Confirmes la información precargada</p>
          </li>
          <li className="login-info-list-item">
            <div className="login-info-icon">{icons["starBlackCircle"]}</div>
            <p>Disfrutes de la experiencia</p>
          </li>
        </ul>
      </div>
    );
  };

  /* const showDefaultIcon = () => {
    <div className="login-svg-custom">{IconsRamos[textos.icono]}</div>
    <JsonAnimation width="72px" height="72px" animation="hogar" />
    <img src={industria} alt="" />    
  } */

  const renderStepsComercio = () => {
    return (
      <>
        <div className="codigo-correcto">
          <div className="codigo-correcto-svg">
            <OkCircle />
          </div>
          <span>¡Código correcto!</span>
        </div>
        <div className="login-svg">
          {textos.icono ? (
            <div className="login-svg-custom">{IconsRamos[textos.icono]}</div>
          ) : null}
        </div>
        <h1>{textos.titulo}</h1>
        <p>{textos.subtitulo}</p>

        {renderInfo()}
      </>
    );
  };

  const renderStepsHogar = () => {
    return (
      <>
        <div className="codigo-correcto">
          <div className="codigo-correcto-svg">
            <OkCircle />
          </div>
          <span>¡Código correcto!</span>
        </div>
        {textos.icono ? (
          <div className="login-svg-custom">{IconsRamos[textos.icono]}</div>
        ) : null}
        <h1>{textos.titulo}</h1>
        <p>{textos.subtitulo}</p>

        {renderInfo()}
      </>
    );
  };

  const renderStepsIndustria = () => {
    return (
      <>
        <div className="codigo-correcto">
          <div className="codigo-correcto-svg">
            <OkCircle />
          </div>
          <span>¡Código correcto!</span>
        </div>
        <div className="login-svg">
          {textos.icono ? (
            <div className="login-svg-custom">{IconsRamos[textos.icono]}</div>
          ) : null}
        </div>
        <h1>{textos.titulo}</h1>
        <p>{textos.subtitulo}</p>

        {renderInfo()}
      </>
    );
  };

  const renderTextButton = () => {
    return step ? "¡¡Comenzamos!!" : "Comenzar";
  };

  const renderHeader = () => {
    if (step !== 0) {
      return (
        <div className="login-header">
          <div className="login-header-main">
            <div
              className="login-header-arrow"
              onClick={() => setStep(step - 1)}
            >
              <Back />
            </div>
            <span>Evaluación {ramo}</span>
          </div>
        </div>
      );
    }
  };

  const renderSteps = () => {
    switch (ramo) {
      case "COMERCIO":
        return renderStepsComercio();
      case "VIVIENDA":
        return renderStepsHogar();
      case "PYME / INDUSTRIA":
        return renderStepsIndustria();
    }
  };

  const disableContinue = () => {
    if (politicaCustom.valor) {
      return !terminosAceptados || !terminosAseguradoraAceptados
    } else {
      return !terminosAceptados
    }
  }

  return (
    <div className="login">
      {renderHeader()}
      <div className={`login-content ${step === 1 ? "lista-bloques" : ""}`}>
        <div id="seifti_container_4"></div>
        {renderSteps()}
      </div>
      <div className="login-actions">
        {id && step === 0 && !yaAceptoToS ? (
          <div className="checkbox-tos">
            <ModalTerminosYCondiciones
              onChange={() => setTerminosAceptados(!terminosAceptados)}
              selected={terminosAceptados}
            />
          </div>
        ) : null}
        {id && step === 0 && !yaAceptoToS && politicaCustom.valor ? (
          <div className="checkbox-tos">
            <ModalTerminosYCondiciones
              onChange={() => setTerminosAseguradoraAceptados(!terminosAseguradoraAceptados)}
              selected={terminosAceptados}
              politicasCustom={politicaCustom}
            />
          </div>
        ) : null}
        <CustomButton
          type="primary"
          disable={disableContinue()}
          onClick={handleClick}
          text={renderTextButton()}
        />
      </div>
      <LocationModal
        geolocationModal={geolocationModal}
        setGeolocationModal={setGeolocationModal}
      />
    </div>
  );
};
