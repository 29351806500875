import { useEffect, useState } from "react";
import { Edit } from "../../Assets/svgs/Edit";
import { Remove } from "../../Assets/svgs/Remove";
import { borrarImagen } from "../../features/LayoutPregunta/PreguntasAPI";
import { formatStringMoney } from "../../utils/currencyHelpers";
import { AddPhoto } from "../AddPhoto/AddPhoto";
import { CustomInput } from "../CustomInput/CustomInput";
import { InputCurrency } from "../InputCurrency/InputCurrency";
import "./CardBienes.scss";
import { DropdownInput } from "../DropdownInput/DropdownInput";
import { ErrorIcon } from "../../Assets/svgs/ErrorIcon";
import { Autocomplete, MenuItem, Select } from "@mui/material";
import { ChevronDown } from "../../Assets/svgs/ChevronDown";
import { toBase64 } from "../../utils";

export const CardBienesHogar = (props: any) => {
  const [open, setOpen] = useState(true);
  const [data, setData] = useState({
    detalle: "",
    importe: "",
    photo: "",
    tipoElemento: "",
  });
  const [confirmada, setConfirmada] = useState(false);

  useEffect(() => {
    if (props.defaultDetalle) {
      setConfirmada(true);
      handleDataChange(props.defaultDetalle, "detalle");
    }
    if (props.defaultImporte) handleDataChange(props.defaultImporte, "importe");
    if (props.defaultTipoElemento)
      handleDataChange(props.defaultTipoElemento, "tipoElemento");
    if (props.fetchedThumbnail)
      handleDataChange(props.fetchedThumbnail, "photo");
  }, []);

  useEffect(() => {
    if (confirmada) {
      setOpen(false);
      handleConfirmar();
    } else {
      setOpen(true);
    }
  }, [confirmada]);

  const handleDataChange = async (e: any, key: string) => {
    const isFile = e instanceof File;
    const dato = isFile ? await toBase64(e) : e;
    setData((prevState) => {
      return { ...prevState, [key]: dato };
    });
  };

  const handleConfirmar = () => {
    props.onChange({
      id: props.id,
      detalle: data.detalle,
      importe: data.importe,
      imagen: data.photo,
      tipoElemento: data.tipoElemento,
      confirmada: true,
    });
  };

  const handleAñadir = () => {
    setConfirmada(true);
  };

  const disableButton = (money: any, detail: string, tipoElemento: string) => {
    const importeString = money.toString();
    return (
      !importeString.length ||
      !detail.length ||
      !data.photo ||
      !data.tipoElemento
    );
  };

  const handleBorrar = (id: number) => {
    if (props.alreadyUploaded) {
      borrarImagen(id);
      props.delete(id);
    } else {
      props.delete(id);
    }
  };

  const selectOptions = [
    "Joya",
    "Objeto especial de alto valor",
    "Objeto de uso cotidiano de alto valor",
  ];

  const handleChangeTipoElemento = (e: any) => {
    console.log(e);
    setData({ ...data, tipoElemento: e.target.value });
  };

  const renderContent = () => {
    if (open) {
      return (
        <>
          <div className="bienes-remove-icon">
            <span className="borrar-msg" onClick={() => handleBorrar(props.id)}>
              <Remove />
            </span>
          </div>
          <div className="bienes-photo">
            <AddPhoto
              onChangeBienes={(e: any) => handleDataChange(e, "photo")}
              id={props.id}
              defaultThumbnail={props.fetchedThumbnail}
            />
          </div>
          <div className="dropdown-input">
            <div className="dropdown-input-container">
              <Select
               MenuProps={{
                sx: {
                  "&& .MuiMenuItem-root": {
                    fontSize: 14,
                  },
                  "&& .Mui-selected": {
                    backgroundColor: "white",
                    color: '#0046CF',
                    fontWeight: 800
                  }
                }
              }}
                value={data.tipoElemento}
                className={`dropdown-input-input`}
                onChange={handleChangeTipoElemento}
              >
                {selectOptions.length
                  ? selectOptions.map((o: any) => {
                      return (
                        <MenuItem key={o} value={o}>
                          {o}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
              <label className={data.tipoElemento && "filled"}>
                Tipo de elemento de valor
              </label>
            </div>
            {/*  <Autocomplete
              id="custom-combo-box"
              options={selectOptions}
              value={data.tipoElemento}
              onChange={(e, v) => v && setData({ ...data, tipoElemento: v })}
              renderInput={(params: any) => (
                <div
                  className="dropdown-input-container"
                  ref={params.InputProps.ref}
                >
                  <input
                    {...params.inputProps}
                    className={`dropdown-input-input`}
                    autoComplete="none"
                    value={params.inputProps.value}
                  />
                  <div className="chevron-down">
                    <ChevronDown />
                  </div>
                  <label className={data.tipoElemento && "filled"}>
                    Tipo de elemento de valor
                  </label>
                </div>
              )}
            /> */}
          </div>
          <CustomInput
            bienes={true}
            label="Detalle"
            type="text"
            onChange={(e: any) => handleDataChange(e, "detalle")}
            defaultValue={data.detalle ? data.detalle : null}
          />
          <InputCurrency
            bienes={true}
            label="Importe (Euros)"
            onChange={(e: any) => handleDataChange(e, "importe")}
            defaultValue={data.importe ? data.importe : null}
          />
          <button
            disabled={disableButton(
              data.importe,
              data.detalle,
              data.tipoElemento
            )}
            onClick={handleAñadir}
            className="añadir-msg"
          >
            {props.confirmarText || "Confirmar elemento"}
          </button>
        </>
      );
    } else {
      return (
        <>
          <div className="bienes-remove-icon">
            {!props.alreadyUploaded ? (
              <div onClick={() => setConfirmada(false)}>
                <Edit />
              </div>
            ) : null}
            <div onClick={() => handleBorrar(props.id)}>
              <Remove />
            </div>
          </div>
          <div className="bienes-photo">
            <AddPhoto />
            <div className="bienes-data">
              <p className="bienes-data-detalle">
                {data.detalle ? data.detalle : ""}
              </p>
              <p className="bienes-tag">{data.tipoElemento}</p>
              <span className="bienes-data-importe">
                {data.importe ? formatStringMoney(data.importe) : ""}
              </span>
            </div>
          </div>
        </>
      );
    }
  };

  return <div className="bienes">{renderContent()}</div>;
};
