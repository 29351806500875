import React from 'react'

export const History = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 8V12L14 14" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.04999 11C3.27408 8.80006 4.30025 6.75962 5.93272 5.26797C7.56519 3.77633 9.68968 2.93789 11.9009 2.91264C14.112 2.88738 16.2551 3.67707 17.9213 5.13104C19.5874 6.58501 20.6599 8.60149 20.9342 10.7957C21.2084 12.99 20.6653 15.2084 19.4083 17.0278C18.1514 18.8471 16.2686 20.14 14.1193 20.6599C11.9699 21.1797 9.7044 20.89 7.75498 19.8461C5.80557 18.8022 4.30871 17.0771 3.54999 15M3.04999 20V15H8.04999" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
