import React from 'react'

export const Options = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.0001 13C12.5523 13 13.0001 12.5522 13.0001 12C13.0001 11.4477 12.5523 11 12.0001 11C11.4478 11 11.0001 11.4477 11.0001 12C11.0001 12.5522 11.4478 13 12.0001 13Z" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.0001 20C12.5523 20 13.0001 19.5523 13.0001 19C13.0001 18.4477 12.5523 18 12.0001 18C11.4478 18 11.0001 18.4477 11.0001 19C11.0001 19.5523 11.4478 20 12.0001 20Z" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.0001 6.00004C12.5523 6.00004 13.0001 5.55232 13.0001 5.00004C13.0001 4.44775 12.5523 4.00004 12.0001 4.00004C11.4478 4.00004 11.0001 4.44775 11.0001 5.00004C11.0001 5.55232 11.4478 6.00004 12.0001 6.00004Z" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
