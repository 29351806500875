import React from 'react'

export const Camera = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.66667 4C8.63483 4 8.60577 4.01262 8.58549 4.03276C8.56545 4.05264 8.55556 4.07803 8.55556 4.10294C8.55556 4.95559 8.21427 5.77181 7.60917 6.37245C7.00433 6.97284 6.18555 7.30882 5.33333 7.30882H4.22222C3.8957 7.30882 3.58402 7.43765 3.35537 7.66462C3.12696 7.89135 3 8.19728 3 8.51471V18.4412C3 18.7586 3.12696 19.0645 3.35537 19.2913C3.58402 19.5182 3.8957 19.6471 4.22222 19.6471H19.7778C20.1043 19.6471 20.416 19.5182 20.6446 19.2913C20.873 19.0645 21 18.7586 21 18.4412V8.51471C21 8.19728 20.873 7.89135 20.6446 7.66462C20.416 7.43765 20.1043 7.30882 19.7778 7.30882H18.6667C17.8145 7.30882 16.9957 6.97284 16.3908 6.37245C15.7857 5.77181 15.4444 4.95559 15.4444 4.10294C15.4444 4.07803 15.4345 4.05264 15.4145 4.03276C15.3942 4.01262 15.3652 4 15.3333 4H8.66667ZM4.22222 5.30882C3.37001 5.30882 2.55122 5.64481 1.94638 6.2452C1.34129 6.84584 1 7.66206 1 8.51471V18.4412C1 19.2938 1.34129 20.11 1.94638 20.7107C2.55122 21.3111 3.37001 21.6471 4.22222 21.6471H19.7778C20.63 21.6471 21.4488 21.3111 22.0536 20.7107C22.6587 20.11 23 19.2938 23 18.4412V8.51471C23 7.66206 22.6587 6.84584 22.0536 6.2452C21.4488 5.64481 20.63 5.30882 19.7778 5.30882H18.6667C18.3401 5.30882 18.0285 5.18 17.7998 4.95302C17.5714 4.7263 17.4444 4.42037 17.4444 4.10294C17.4444 3.54281 17.2202 3.00713 16.8235 2.61333C16.427 2.21978 15.8909 2 15.3333 2H8.66667C8.10914 2 7.57297 2.21978 7.1765 2.61333C6.77978 3.00713 6.55556 3.54281 6.55556 4.10294C6.55556 4.42037 6.42859 4.7263 6.20019 4.95302C5.97154 5.18 5.65986 5.30882 5.33333 5.30882H4.22222Z" fill="#89869B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.0001 10.4706C10.6032 10.4706 9.4707 11.603 9.4707 13C9.4707 14.3969 10.6032 15.5294 12.0001 15.5294C13.3971 15.5294 14.5295 14.3969 14.5295 13C14.5295 11.603 13.3971 10.4706 12.0001 10.4706ZM7.4707 13C7.4707 10.4985 9.49859 8.47058 12.0001 8.47058C14.5016 8.47058 16.5295 10.4985 16.5295 13C16.5295 15.5015 14.5016 17.5294 12.0001 17.5294C9.49859 17.5294 7.4707 15.5015 7.4707 13Z" fill="#89869B"/>
    </svg>
  )
}
