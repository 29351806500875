import React from "react";

const ViviendaPareada = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_981_1674)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.3236 23.9182C27.3236 23.6336 27.0928 23.4028 26.8082 23.4028H1.84307C1.55844 23.4028 1.3277 23.6336 1.3277 23.9182C1.3277 24.2028 1.55844 24.4336 1.84307 24.4336H26.8082C27.0928 24.4336 27.3236 24.2028 27.3236 23.9182Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8666 26.3085C12.8666 26.0238 12.6358 25.7931 12.3512 25.7931H9.96557C9.68094 25.7931 9.4502 26.0238 9.4502 26.3085C9.4502 26.5931 9.68094 26.8238 9.96557 26.8238H12.3512C12.6358 26.8238 12.8666 26.5931 12.8666 26.3085Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.6302 26.3085C31.6302 26.0238 31.3995 25.7931 31.1149 25.7931H28.7292C28.4446 25.7931 28.2139 26.0238 28.2139 26.3085C28.2139 26.5931 28.4446 26.8238 28.7292 26.8238H31.1149C31.3995 26.8238 31.6302 26.5931 31.6302 26.3085Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.2484 26.3085C22.2484 26.0238 22.0177 25.7931 21.733 25.7931H19.3474C19.0628 25.7931 18.832 26.0238 18.832 26.3085C18.832 26.5931 19.0628 26.8238 19.3474 26.8238H21.733C22.0177 26.8238 22.2484 26.5931 22.2484 26.3085Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.4451 28.5684C17.4451 28.2837 17.2144 28.053 16.9297 28.053H14.5441C14.2595 28.053 14.0287 28.2837 14.0287 28.5684C14.0287 28.853 14.2595 29.0837 14.5441 29.0837H16.9297C17.2144 29.0837 17.4451 28.853 17.4451 28.5684Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.827 28.5684C26.827 28.2837 26.5963 28.053 26.3116 28.053H23.926C23.6414 28.053 23.4106 28.2837 23.4106 28.5684C23.4106 28.853 23.6414 29.0837 23.926 29.0837H26.3116C26.5963 29.0837 26.827 28.853 26.827 28.5684Z"
          fill="#635F76"
        />
        <path
          d="M26.3024 14.652H25.5103V23.9181H26.3024V14.652Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.391 14.5326H26.4217V24.0373H25.391V14.5326ZM25.6296 14.7712V23.7987H26.1831V14.7712H25.6296Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.1303 23.9181C43.1303 23.6335 42.8995 23.4027 42.6149 23.4027H22.7286C22.444 23.4027 22.2133 23.6335 22.2133 23.9181C22.2133 24.2027 22.444 24.4335 22.7286 24.4335H42.6149C42.8995 24.4335 43.1303 24.2027 43.1303 23.9181Z"
          fill="#635F76"
        />
        <path
          d="M42.6517 15.9692C42.449 15.9692 42.2648 15.9047 42.1175 15.785L33.4592 8.361L25.7384 14.926L25.6484 14.0585L33.4592 7.32017L42.6333 15.1862L43.3978 14.2744C40.5516 11.8795 34.7488 6.87805 33.4592 5.77275L25.7384 12.4475V11.2082L33.4685 4.7135L33.7264 4.93456C33.8 4.99904 40.7266 10.9677 43.9228 13.6572C44.0978 13.8046 44.2083 14.0165 44.2083 14.2375C44.2083 14.4586 44.1438 14.6336 44.0241 14.781L43.2596 15.6928C43.1122 15.8771 42.8912 15.9784 42.6793 15.9784L42.6517 15.9692Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.469 4.55676L33.8049 4.84468C33.878 4.90864 40.804 10.8768 43.9996 13.5659C44.1976 13.7326 44.3276 13.9764 44.3276 14.2374C44.3276 14.4846 44.2546 14.6865 44.1167 14.8561L44.1156 14.8575L43.3519 15.7684C43.182 15.98 42.9273 16.0976 42.6793 16.0976H42.66L42.6318 16.0882C42.4108 16.0841 42.207 16.0113 42.0422 15.8775L42.0398 15.8755L33.4588 8.51765L26.1783 14.8119L25.5081 14.022L33.4598 7.16337L42.6194 15.0171L43.2298 14.2889C40.9343 12.3554 36.8458 8.84063 34.6382 6.94287C34.1307 6.50659 33.7227 6.15578 33.4586 5.92928L26.1502 12.0934L25.4801 11.3036L33.469 4.55676ZM25.7384 11.2082L26.1783 11.7576L33.4599 5.61605L33.5369 5.68207C33.7952 5.90351 34.2345 6.28112 34.7947 6.76271C37.0321 8.68613 41.1988 12.2681 43.4746 14.183L43.5657 14.2597L42.6472 15.3553L33.4587 7.4768L25.8446 14.0498L26.206 14.4758L33.4597 8.20417L42.1939 15.6932C42.3188 15.7943 42.4757 15.8498 42.6517 15.8498H42.671L42.6978 15.8587C42.8676 15.853 43.0459 15.7689 43.1664 15.6182L43.1682 15.6161L43.9315 14.7056C44.0328 14.5807 44.089 14.4321 44.089 14.2374C44.089 14.0564 43.9979 13.8764 43.846 13.7484C40.6553 11.0635 33.7482 5.11177 33.6483 5.02469L33.4679 4.87006L25.7384 11.2082Z"
          fill="#635F76"
        />
        <path
          d="M35.8449 23.9181H35.0528V15.573H31.875V23.9181H31.0829V15.573C31.0829 15.1309 31.4421 14.7809 31.875 14.7809H35.0528C35.4949 14.7809 35.8449 15.1401 35.8449 15.573V23.9181Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.875 14.9001C31.5071 14.9001 31.2022 15.1976 31.2022 15.5729V23.7987H31.7557V15.4536H35.1721V23.7987H35.7256V15.5729C35.7256 15.205 35.4281 14.9001 35.0528 14.9001H31.875ZM30.9636 15.5729C30.9636 15.064 31.3771 14.6615 31.875 14.6615H35.0528C35.5617 14.6615 35.9642 15.075 35.9642 15.5729V24.0373H34.9334V15.6923H31.9943V24.0373H30.9636V15.5729Z"
          fill="#635F76"
        />
        <path
          d="M8.22015 15.8954C8.42455 15.8954 8.61037 15.8304 8.75903 15.7096L17.4926 8.22101L25.7384 15.5456L25.8918 14.0263L17.4926 7.17112L8.23873 15.1057L7.46757 14.1859C10.3385 11.7702 16.1919 6.72515 17.4926 5.61022L25.1188 11.8278L25.3715 11.2034L17.4833 4.54175L17.2232 4.76473C17.1489 4.82977 10.162 10.8504 6.93798 13.5634C6.76145 13.712 6.64996 13.9257 6.64996 14.1487C6.64996 14.3717 6.715 14.5482 6.83578 14.6969L7.60694 15.6167C7.7556 15.8025 7.97858 15.9047 8.19228 15.9047L8.22015 15.8954Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.4828 4.38367L17.1439 4.67409C17.0702 4.73861 10.0839 10.7587 6.86048 13.4712C6.66073 13.6394 6.52959 13.8853 6.52959 14.1487C6.52959 14.3979 6.60331 14.6016 6.74236 14.7727L6.74353 14.7742L7.51386 15.693C7.68516 15.9064 7.94214 16.025 8.19226 16.025H8.21179L8.24022 16.0155C8.46317 16.0114 8.66874 15.938 8.83491 15.803L8.83738 15.8009L17.4931 8.37905L25.2047 14.7617L25.8807 13.9649L17.4921 7.01297L8.25276 14.9351L7.63694 14.2005C9.95243 12.2502 14.0766 8.70484 16.3034 6.79055C16.8153 6.35048 17.2269 5.99661 17.4933 5.76814L25.7384 12.4474V11.2082L17.4828 4.38367ZM25.2016 11.2175L25.1187 11.8278L17.492 5.45218L17.4143 5.51878C17.1537 5.74215 16.7106 6.12304 16.1456 6.60882C13.8887 8.54899 9.68568 12.1621 7.39007 14.0937L7.29815 14.1711L8.22467 15.2762L17.4931 7.32914L25.5413 13.993L25.1767 14.4226L17.4921 8.06284L8.68192 15.6171C8.55595 15.719 8.39769 15.775 8.22013 15.775H8.20061L8.17367 15.784C8.00233 15.7783 7.82251 15.6935 7.7009 15.5415L7.69917 15.5393L6.92917 14.6209C6.827 14.4949 6.77029 14.345 6.77029 14.1487C6.77029 13.966 6.86217 13.7845 7.01545 13.6554C10.234 10.947 17.2012 4.94351 17.3019 4.85567L17.4839 4.69969L25.2016 11.2175Z"
          fill="#635F76"
        />
        <path
          d="M10.673 10.581H11.472V5.47091H13.4789V8.17461H14.2779V5.47091C14.2779 5.02494 13.9155 4.67188 13.4789 4.67188H11.472C11.026 4.67188 10.673 5.03423 10.673 5.47091V10.581Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4789 4.79209C13.8501 4.79209 14.1576 5.09218 14.1576 5.47077V8.05413H13.5993V5.35042H11.3517V10.4605H10.7934V5.47077C10.7934 5.09965 11.0935 4.79209 11.4721 4.79209H13.4789ZM10.673 10.5809H10.5527V5.47077C10.5527 4.96854 10.9587 4.55139 11.4721 4.55139H13.4789C13.9812 4.55139 14.3983 4.95743 14.3983 5.47077V8.29482H13.3586V5.59112H11.5924V10.7012H10.673V10.5809Z"
          fill="#635F76"
        />
        <path
          d="M17.167 23.9135H17.966V15.4958H21.1714V23.9135H21.9705V15.4958C21.9705 15.0498 21.6081 14.6968 21.1714 14.6968H17.966C17.52 14.6968 17.167 15.0591 17.167 15.4958V23.9135Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.1716 14.8172C21.5427 14.8172 21.8502 15.1173 21.8502 15.4959V23.7933H21.2919V15.3756H17.8458V23.7933H17.2875V15.4959C17.2875 15.1248 17.5875 14.8172 17.9661 14.8172H21.1716ZM22.0909 15.4959C22.0909 14.9826 21.6738 14.5765 21.1716 14.5765H17.9661C17.4528 14.5765 17.0468 14.9937 17.0468 15.4959V24.034H18.0865V15.6163H21.0512V24.034H22.0909V15.4959Z"
          fill="#635F76"
        />
        <path
          d="M12.912 20.7081H14.1106C14.5565 20.7081 14.9096 20.3458 14.9096 19.9091V16.7037C14.9096 16.2577 14.5473 15.9047 14.1106 15.9047H12.912C12.4661 15.9047 12.113 16.267 12.113 16.7037V19.9091C12.113 20.3551 12.4753 20.7081 12.912 20.7081ZM14.1106 16.6944V19.8998H12.9027V16.6944H14.1013H14.1106Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1104 16.025C14.4815 16.025 14.7891 16.3251 14.7891 16.7037V19.9091C14.7891 20.2802 14.489 20.5878 14.1104 20.5878H12.9119C12.5407 20.5878 12.2332 20.2877 12.2332 19.9091V16.7037C12.2332 16.3326 12.5333 16.025 12.9119 16.025H14.1104ZM15.0298 16.7037C15.0298 16.1903 14.6126 15.7843 14.1104 15.7843H12.9119C12.3985 15.7843 11.9925 16.2015 11.9925 16.7037V19.9091C11.9925 20.4225 12.4096 20.8285 12.9119 20.8285H14.1104C14.6238 20.8285 15.0298 20.4113 15.0298 19.9091V16.7037ZM14.2308 16.574H12.7822V20.0202H14.2308V16.574ZM13.9901 16.8147V19.7795H13.0229V16.8147H13.9901Z"
          fill="#635F76"
        />
        <path
          d="M9.07487 14.5667H9.8739V23.9135H9.07487V14.5667Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99426 14.4463H8.95453V24.0338H9.99426V14.4463ZM9.75357 14.687V23.7931H9.19523V14.687H9.75357Z"
          fill="#635F76"
        />
        <path
          d="M19.5734 18.7012H20.3724V20.708H19.5734V18.7012Z"
          fill="#635F76"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.4929 18.5809H19.4531V20.8285H20.4929V18.5809ZM20.2522 18.8216V20.5878H19.6938V18.8216H20.2522Z"
          fill="#635F76"
        />
        <rect
          x="25.3277"
          y="10.1595"
          width="1"
          height="15"
          rx="0.5"
          fill="#635F76"
        />
      </g>
      <defs>
        <clipPath id="clip0_981_1674">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.327698 0.18811)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ViviendaPareada;
