import React from 'react'

export const Modelos = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.0001 3V7C14.0001 7.26522 14.1054 7.51957 14.293 7.70711C14.4805 7.89464 14.7348 8 15.0001 8H19.0001" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.0001 21H7.00006C6.46963 21 5.96092 20.7893 5.58585 20.4142C5.21077 20.0391 5.00006 19.5304 5.00006 19V5C5.00006 4.46957 5.21077 3.96086 5.58585 3.58579C5.96092 3.21071 6.46963 3 7.00006 3H14.0001L19.0001 8V19C19.0001 19.5304 18.7893 20.0391 18.4143 20.4142C18.0392 20.7893 17.5305 21 17.0001 21Z" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 9H10" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 13H15" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 17H15" stroke="#89869B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
